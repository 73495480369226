import {
  fiSession,
  fiStore,
  fiStoreLogview,
  fmgRemoteFaz,
  fiStoreRouting,
} from 'fistore';
import { negate } from 'lodash';
const {
  adomNames: an,
  inAdom,
  inGlobalAdom,
  inOthersAdom,
  showFazApps,
  hasRDPermitOn,
  getIsFazSupervisor,
} = fiSession;

const { isSiemDisabled } = fmgRemoteFaz;
const { fetchLogviewContentMenu, getLogviewContentMenu } = fiStoreLogview;

const hasSiemLog = (state) => {
  const logs = getLogviewContentMenu(state)?.records || [];
  return logs.some((x) => x.id === MACROS.FAZ_SYNTAX.LOGDEV_SIEM);
};

const hasOtherLogsThanSiem = (state) => {
  const logs = getLogviewContentMenu(state)?.records || [];
  return logs.some((x) => x.id !== MACROS.FAZ_SYNTAX.LOGDEV_SIEM);
};

export default {
  logview: {
    label: gettext('Log View'),
    icon: 'logview',
    color: '#87bde7',
    // state: 'adom.default.logview.logs',
    // reloadState: 'adom.default.logview.logs',
    gdprTool: true,
    showCheckers: [
      showFazApps,
      negate(inAdom([an.FortiSwitch])),
      negate(inOthersAdom),
      negate(inGlobalAdom),
      hasRDPermitOn('log_viewer'),
    ],
    // triggers when user expands the side menu returns the same as .
    // If it's a async function menu will show a spinner.
    onMenuExpand: async () => {
      return await fiStore.dispatch(fetchLogviewContentMenu());
    },
    // load sub tree when navigate to
    loadSubTree: async () => {
      // need this, otherwise F5 refresh will be too late to get menu
      const menu = getLogviewContentMenu(fiStore.getState());
      if (!menu) {
        // don't return the menu, this is to prevent calling insertAppTree after loadSubTree
        await fiStore.dispatch(fetchLogviewContentMenu());
      }
    },
  },
  logview_logs: {
    label: gettext('Logs'),
    path: '/logview/logs',
    hideIfNoChildren: true,
  },
  logview_all: {
    label: gettext('All'),
    icon: 'siem',
    path: '/logview/logs/logview_all/255/26',
    showCheckers: [hasSiemLog],
    stateParams: {
      _logtype: MACROS.FAZ_SYNTAX.LOGTYPE_SIEM,
      devType: MACROS.DVM.DVM_OS_TYPE_SIM,
    },
    olhTag: 'logview_fabric',
    olhCategory: 'logview',
    olhFaz: 'Log View -> Logs -> All',
  },
  logview_ftntlogs: {
    label: gettext('Fortinet Logs'),
    icon: 'fortinet-grid',
    path: '/logview/logs/ftntlogs',
    showCheckers: [hasOtherLogsThanSiem],
    olhTag: 'logview_ftntlogs',
    olhCategory: 'logview',
    olhFaz: 'Log View -> Logs -> Fortinet Logs',
  },
  logview_threat_hunting: {
    label: gettext('Threat Hunting'),
    icon: 'siem',
    path: '/logview/threat_hunting',
    showCheckers: [
      hasSiemLog,
      // negate(getIsFazSupervisor),
      negate(isSiemDisabled),
    ],
    olhTag: 'threat_hunting',
    olhCategory: 'logview',
    olhFaz: 'Log View -> Logs -> Threat Hunting',
  },
  logview_logbrowse: {
    label: gettext('Log Browse'),
    icon: 'log-browse',
    path: '/logview/logbrowse',
    showCheckers: [negate(getIsFazSupervisor)],
    olhTag: 'logview_log_browse',
    olhCategory: 'logview',
    olhFaz: 'Log View -> Logs -> Log Browse',
  },
  logview_logs_customview: {
    label: (app, state) => {
      let label = gettext('Custom Views');
      let num = fiStoreRouting.getAppTreeMap(state)?.[app.key]?.length;
      return num ? `${label} (${num})` : label;
    },
    path: '/logview/customviewlogs',
    hideIfNoChildren: true,
    olhTag: 'logview_customview',
    olhCategory: 'logview',
    olhFaz: 'Log View -> Custom Views',
  },
  logview_log_settings: {
    label: gettext('Log Settings'),
    path: '/logview/logsettings',
    hideIfNoChildren: true,
  },
  logview_loggroups: {
    label: gettext('Log Group'),
    icon: 'log-array',
    path: '/logview/loggroups',
    showCheckers: [negate(getIsFazSupervisor)],
    olhTag: 'logview_log_group',
    olhCategory: 'logview',
    olhFaz: 'Log View -> Log Settings -> Log Group',
  },
  // logview_siem_parser: {
  //   label: gettext('Log Parser'),
  //   state: 'adom.default.logview.logsettings.logparser',
  //   showCheckers: [hasRDPermitOn('log_viewer'), negate(isSiemDisabled)],
  // },
  // logview_assign_siem_parser: {
  //   label: gettext('Assigned Parsers'),
  //   state: 'adom.default.logview.logsettings.logparser.assignlogparser',
  // },
  // logview_siem_parser_list: {
  //   label: gettext('Log Parsers'),
  //   state: 'adom.default.logview.logsettings.logparser.logparserlist',
  // },
};
