import {
  fiStore,
  fiSession,
  fiDevicesSelector,
  fiAdomSyntax,
  fiWorkspaceDataSelector,
} from 'fistore';
import { isFunction, negate, overEvery, overSome, transform } from 'lodash';
// import { getShowScript } from 'fistore/session/sysConfig/selectors';
const {
  inAdom,
  adomNames: an,
  checkVersion,
  isRootAdom,
  inBackupAdom,
  inOthersAdom,
  inGlobalAdom,
  inFpxAdom,
  hasRDPermitOn,
  isFmg,
  isFaz,
  hasFmgFeature,
  hasFortiMeterFeature,
  getIsWorkspaceEnabled,
  inManageableAdoms,
} = fiSession;

const { isAdomLockedByMe } = fiWorkspaceDataSelector;
const { ptDisplayOptChecker, hasLTEDevices } = fiDevicesSelector;
const { getSupportSyntaxCates } = fiAdomSyntax;

const mrPRIV = MACROS.USER.DVM;

export const default_show_dvm_pt_apps = [
  'dvm_pt_tmplgrp',
  'dvm_pt_ipsectmpl',
  'dvm_pt_sdwantmpl',
  'dvm_pt_systmpl',
  'dvm_pt_routetmpl',
  'dvm_pt_clitmpl',
];

export const getDspOptChecker = (appkey) =>
  ptDisplayOptChecker(appkey, default_show_dvm_pt_apps.includes(appkey));

export const dvm_pt_apps = {
  // `showCheckers` here means if the feature is available, regardless of display options
  dvm_pt_tmplgrp: {
    olhTag: 'dvm_pt_tmplgrp',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "Template Group"',

    label: gettext('Template Groups'),
    icon: 'templates',
    path: '/dvm/prvtmpl/tmplgrp',
    dspOptChecker: getDspOptChecker('dvm_pt_tmplgrp'),
    routerConfig: {
      lazy: () =>
        import('react_apps/ra_pt/template_group/provisioning_template_group'),
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
  dvm_pt_ipsectmpl: {
    olhTag: 'dvm_pt_ipsec',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "IPsec Tunnel"',

    label: gettext('IPsec Tunnel'),
    icon: 'vpn-tunnel',
    path: '/dvm/prvtmpl/ipsectemplate',
    tmplkey: 'ipsec',
    dspOptChecker: getDspOptChecker('dvm_pt_ipsectmpl'),
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/ipsec_template/IPsecTemplate'),
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
  dvm_pt_bgptmpl: {
    olhTag: 'dvm_pt_bgp',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "BGP"',

    label: gettext('BGP'),
    icon: 'traffic',
    path: '/dvm/prvtmpl/bgptemplate',
    tmplkey: 'bgp',
    dspOptChecker: getDspOptChecker('dvm_pt_bgptmpl'),
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/bgp_template/BgpTemplate'),
    },
    showCheckers: [
      negate(inFpxAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE),
    ],
  },
  dvm_pt_sdwantmpl: {
    olhTag: 'dvm_pt_sdwan',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "SD-WAN"',

    label: gettext('SD-WAN'),
    icon: 'sd-wan',
    path: '/dvm/prvtmpl/sdwan',
    tmplkey: 'sdwan',
    dspOptChecker: getDspOptChecker('dvm_pt_sdwantmpl'),
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/sdwan_template/sdwan_template'),
    },
    showCheckers: [negate(inFpxAdom), hasRDPermitOn(mrPRIV.ADMINPRIV_WLLB)],
  },
  dvm_pt_overlaytmpl: {
    olhTag: 'dvm_pt_sdwan_overlay',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "SD-WAN Overlay"',

    label: gettext('SD-WAN Overlay'),
    icon: 'vpn-manager',
    path: '/dvm/prvtmpl/overlaytmpls',
    tmplkey: 'overlaytmpl',
    dspOptChecker: getDspOptChecker('dvm_pt_overlaytmpl'),
    routerConfig: {
      lazy: () =>
        import('react_apps/ra_pt/sdwan_overlay_template/SDWANOverlayTemplate'),
    },
    showCheckers: [
      negate(inFpxAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE),
    ],
  },
  dvm_pt_systmpl: {
    olhTag: 'dvm_pt_sys',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "System Templates"',

    label: gettext('System Templates'),
    icon: 'chart-table',
    path: '/dvm/prvtmpl/systmplall',
    dspOptChecker: getDspOptChecker('dvm_pt_systmpl'),
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/system_template/SystemTemplate'),
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
  dvm_pt_routetmpl: {
    olhTag: 'dvm_pt_route',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "Static Route"',

    label: gettext('Static Route'),
    icon: 'switch',
    path: '/dvm/prvtmpl/routertemplate',
    tmplkey: 'router',
    dspOptChecker: getDspOptChecker('dvm_pt_routetmpl'),
    routerConfig: {
      lazy: () =>
        import('react_apps/ra_pt/static_route_template/StaticRouteTemplate'),
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
  dvm_pt_ipstmpl: {
    olhTag: 'dvm_pt_ips',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "IPS"',

    label: gettext('IPS'),
    icon: 'profile-ips',
    path: '/dvm/prvtmpl/ipstemplate',
    dspOptChecker: getDspOptChecker('dvm_pt_ipstmpl'),
    showCheckers: [
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE),
      hasRDPermitOn(mrPRIV.ADMINPRIV_IPS_OBJ_CFG),
      // mantis #928468: IPS template are not supported in these ADOMs
      getSupportSyntaxCates(['ips global', 'ips settings', 'system ips']),
    ],
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/ips_template/ips_template'),
    },
  },
  dvm_pt_cstmpl: {
    olhTag: 'dvm_pt_nsxt',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "NSX-T Service"',

    label: gettext('NSX-T Service'),
    icon: 'address-nsx',
    path: '/dvm/prvtmpl/csttmpl',
    dspOptChecker: getDspOptChecker('dvm_pt_cstmpl'),
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/nsxt_template/NsxtTemplate'),
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
  dvm_pt_clitmpl: {
    olhTag: 'dvm_pt_cli',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "CLI"',

    label: gettext('CLI'),
    icon: 'cli',
    path: '/dvm/prvtmpl/clitmpl',
    dspOptChecker: getDspOptChecker('dvm_pt_clitmpl'),
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/cli_template/CliTemplate'),
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
  dvm_pt_fbauth: {
    olhTag: 'dvm_pt_fabric_auth',
    olhCategory: 'dvm',
    olhFmg:
      '"Device Manager" -> "Provisioning Template" -> "Fabric Authorization"',

    label: gettext('Fabric Authorization'),
    icon: 'fortiauthenticator',
    path: '/dvm/prvtmpl/fabricauth',
    dspOptChecker: getDspOptChecker('dvm_pt_fbauth'),
    routerConfig: {
      lazy: () =>
        import(
          'react_apps/ra_pt/fabric_auth_template/components/FabricAuthTemplate'
        ),
    },
    showCheckers: [
      checkVersion('7.2', '>='),
      negate(inFpxAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE),
    ],
  },
  dvm_pt_certprof: {
    olhTag: 'dvm_pt_cert',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "Certificate"',

    label: gettext('Certificate'),
    icon: 'certificate',
    path: '/dvm/prvtmpl/certprof',
    dspOptChecker: getDspOptChecker('dvm_pt_certprof'),
    routerConfig: {
      lazy: () => import('react_apps/ra_pt/cert_template/CertTemplate'),
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
  dvm_pt_crprof: {
    olhTag: 'dvm_pt_threat_weight',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Provisioning Template" -> "Threat Weight"',

    label: gettext('Threat Weight'),
    icon: 'threats',
    path: '/dvm/prvtmpl/crprof',
    dspOptChecker: getDspOptChecker('dvm_pt_crprof'),
    routerConfig: {
      lazy: () =>
        import(
          'react_apps/ra_pt/threat_weight_template/ThreatWeightTemplateList'
        ),
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
  dvm_pt_visibility: {
    olhTag: 'dvm_pt_visibility',
    olhCategory: 'dvm',
    olhFmg:
      '"Device Manager" -> "Provisioning Template" -> "Feature Visibility"',

    label: gettext('Feature Visibility'),
    icon: 'top-task',
    onClick: async () => {
      const { openPTDisplayOptions } = await import(
        'react_apps/ra_pt/display_options/PTDisplayOptions'
      );
      if (isFunction(openPTDisplayOptions)) {
        openPTDisplayOptions();
      }
    },
    showCheckers: [hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)],
  },
};

const getPtApps = () => {
  return transform(
    dvm_pt_apps,
    (result, app, key) => {
      const showCheckers = app.showCheckers || [];
      const mergedShowCheckers = [...showCheckers];
      if (app.dspOptChecker) {
        mergedShowCheckers.push(app.dspOptChecker);
      }
      result[key] = {
        ...app,
        showCheckers: mergedShowCheckers,
      };
    },
    {}
  );
};

const dvm_fortimeter = {
  dvm_fortimeter: {
    olhTag: 'dvm_vm_meter',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "VM Meter"',

    label: gettext('VM Meter'),
    path: '/dvm/fortimeter',
    showCheckers: [
      overEvery([
        hasFmgFeature,
        overSome([
          inAdom([an.FortiGate]),
          overEvery([inAdom([an.FortiWeb]), isRootAdom]),
        ]),
        hasFortiMeterFeature,
      ]),
    ],
  },
};

const dvm_monitors_apps = {
  // dvm_monitors_sdwan: {
  //   olhTag: 'dvm_monitor_sdwan',
  //   olhCategory: 'dvm',
  //   olhFmg: '"Device Manager" -> "Monitor" -> "SD-WAN Monitor"',

  //   label: gettext('SD-WAN Monitor'),
  //   icon: 'fortisdwancontroller',
  //   path: '/dvm/monitors/sdwan',
  //   // fortiproxy does not have sd wan feature
  //   showCheckers: [negate(inFpxAdom)],
  // },
  dvm_monitors_vpn: {
    olhTag: 'dvm_monitor_vpn',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Monitor" -> "VPN Monitor"',

    label: gettext('VPN Monitor'),
    icon: 'vpn-manager',
    path: '/dvm/monitors/vpn',
  },
  dvm_monitors_inventory: {
    olhTag: 'dvm_monitor_asset',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Monitor" -> "Asset Identity Center"',

    label: gettext('Asset Identity Center'),
    icon: 'fabric-device',
    path: '/dvm/monitors/inventory',
  },
  dvm_monitors_lte: {
    olhTag: 'dvm_monitor_lte',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager" -> "Monitor" -> "LTE Modem"',

    label: gettext('LTE Modem'),
    icon: 'fortiextender',
    path: '/dvm/monitors/lte',
    showCheckers: [hasLTEDevices],
  },
};
export default {
  fazdvm: {
    olhTag: 'dvm_fazlist',
    olhCategory: 'dvm',
    olhFaz: '"Device Manager"',

    label: gettext('Device Manager'),
    icon: 'device-manager',
    color: '#84bc41',
    path: '/dvm/main/fazgroups',
    showCheckers: [isFaz, hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_MANAGER)],
    hideSubItems: true,
  },
  dvm: {
    olhTag: 'dvm_list',
    olhCategory: 'dvm',
    olhFmg: '"Device Manager"',

    label: gettext('Device Manager'),
    icon: 'device-manager',
    color: '#84bc41',
    path: '/dvm/main/groups',
    showCheckers: [
      isFmg,
      negate(inGlobalAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_MANAGER),
    ],
  },
  dvm_device_list: {
    olhTag: 'dvm_dev_n_grp',
    olhCategory: 'dvm',
    olhText: '"Device Manager" -> "Device & Groups"',

    label: gettext('Device & Groups'),
    path: '/dvm/main/groups',
    showCheckers: [negate(inOthersAdom)],
  },
  dvm_scripts: {
    olhTag: 'dvm_script',
    olhCategory: 'dvm',
    olhText: '"Device Manager" -> "Scripts"',

    label: gettext('Scripts'),
    path: '/dvm/scripts',
    showCheckers: [
      hasFmgFeature,
      hasRDPermitOn(mrPRIV.ADMINPRIV_SCRIPT_ACCESS),
      negate(inOthersAdom),
    ],
  },
  dvm_pt: {
    olhTag: 'dvm_tmpl',
    olhCategory: 'dvm',
    olhText: '"Device Manager" -> "Provisioning Templates"',

    label: gettext('Provisioning Templates'),
    path: '/dvm/prvtmpl',
    showCheckers: [
      inManageableAdoms,
      hasFmgFeature,
      negate(inBackupAdom),
      overSome([hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE)]),
      negate(inOthersAdom),
    ],
    menuItemActions: [
      {
        key: 'export_pts',
        label: gettext('Export Templates'),
        icon: 'export',
        disabled: false,
        onClick: async () => {
          const { openExportTemplateModal } = await import(
            'react_apps/ra_pt/template_actions/export'
          );
          if (isFunction(openExportTemplateModal)) {
            openExportTemplateModal();
          }
        },
      },
      {
        key: 'import_pts',
        label: gettext('Import Templates'),
        icon: 'import',
        disabled: false,
        // disabled: () => {// wait for prolayout fix
        //   return !isAdomLockedByMe(fiStore.getState());
        // },
        onClick: async () => {
          const state = fiStore.getState();
          // check lock permission
          if (getIsWorkspaceEnabled(state) && !isAdomLockedByMe(state)) {
            return;
          }
          const { openImportTemplateModal } = await import(
            'react_apps/ra_pt/template_actions/import'
          );
          if (isFunction(openImportTemplateModal)) {
            openImportTemplateModal();
          }
        },
      },
    ],
  },
  ...getPtApps(),
  dvm_firmware_template: {
    olhTag: 'dvm_frmwr',
    olhCategory: 'dvm',
    olhText: '"Device Manager" -> "Firmware Templates"',

    label: gettext('Firmware Templates'),
    path: '/dvm/firmwaretemplate',
    showCheckers: [
      inManageableAdoms,
      hasFmgFeature,
      hasRDPermitOn(mrPRIV.ADMINPRIV_DEV_PROFILE),
      negate(inOthersAdom),
    ],
  },
  dvm_monitors: {
    olhTag: 'dvm_monitor',
    olhCategory: 'dvm',
    olhText: '"Device Manager" -> "Monitors"',

    label: gettext('Monitors'),
    path: '/dvm/monitors',
    showCheckers: [
      inManageableAdoms,
      hasFmgFeature,
      negate(inBackupAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_WLLB),
      negate(inOthersAdom),
    ],
  },
  ...dvm_monitors_apps,
  ...dvm_fortimeter,
  dvm_chassis: {
    olhTag: 'dvm_chassis',
    olhCategory: 'dvm',
    olhText: '"Device Manager" -> "Chassis"',

    label: gettext('Chassis'),
    path: '/dvm/chassis',
    showCheckers: [
      hasFmgFeature,
      negate(inBackupAdom),
      hasRDPermitOn(mrPRIV.ADMINPRIV_WLLB),
      inOthersAdom,
    ],
  },
};
