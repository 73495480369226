import { fiDvmListViewType } from 'fi-apps/fi-dvm';
import { hasFmgFeature } from 'fi-web/fi-session/sys_config';

export const getViewMap = () => {
  const _hasFmgFeature = hasFmgFeature();
  return {
    [fiDvmListViewType.MANAGED_FOLDER_VIEW]: {
      subPath: 'folder',
    },
    [fiDvmListViewType.MANAGED_RING_VIEW]: {
      subPath: 'ring/fmg-dash1',
    },
    [fiDvmListViewType.MANAGED_MAP_VIEW]: {
      subPath: _hasFmgFeature ? 'map' : 'fazmap',
    },
  };
};
