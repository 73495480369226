import EventHandler from './event_handler';

import {
  NOTIFY_BATCH_ADDED_ACTION,
  NOTIFY_BATCH_REMOVED_ACTION,
  NOTIFY_BATCH_CHANGED_ACTION,
} from './action_const';

export default class customerEventHandler implements EventHandler {
  batcher: Batcher;
  constructor() {
    this.batcher = new Batcher();
  }
  changed(data: any, dispatch: any, next: () => void) {
    if (data.collection === 'pkg-status' || data.collection === 'device') {
      this.batcher.doBatch(
        data,
        (batchedData: any) => {
          dispatch({
            type: NOTIFY_BATCH_CHANGED_ACTION,
            payload: batchedData,
          });
        },
        500
      );
    } else {
      next();
    }
  }
  removed(data: any, dispatch: any, next: () => void) {
    if (data.collection === 'device') {
      this.batcher.doBatch(
        data,
        (batchedData: any) => {
          dispatch({
            type: NOTIFY_BATCH_REMOVED_ACTION,
            payload: batchedData,
          });
        },
        500
      );
    } else {
      next();
    }
  }
  added(data: any, dispatch: any, next: () => void) {
    if (data.collection === 'device') {
      this.batcher.doBatch(
        data,
        (batchedData: any) => {
          dispatch({
            type: NOTIFY_BATCH_ADDED_ACTION,
            payload: batchedData,
          });
        },
        500
      );
    } else {
      next();
    }
  }
}

const ignoreKeys = ['msg', 'collection'];
class Batcher {
  batchedData: any;
  timeoutIds: any;
  constructor() {
    this.batchedData = {};
    /**
     * batchedData: {
     *  "device-added": { //collection-msg
     *       id: [...device oids],
     *       meta: [...],
     *       field: [...],
     *       collection: 'device'
     *     },
     * }
     */

    this.timeoutIds = {};
    return this;
  }

  addData = (data: any) => {
    const { msg, collection } = data;
    const collectionKey = `${collection}-${msg}`;

    if (!this.batchedData[collectionKey]) {
      this.batchedData[collectionKey] = {};
    }

    //push all values into array for current collection-msg object
    //don't include msg and collection keys
    const entries = Object.entries(data);
    entries.forEach(([key, value]) => {
      if (ignoreKeys.includes(key)) return;

      if (!this.batchedData[collectionKey][key]) {
        this.batchedData[collectionKey][key] = [];
      }
      this.batchedData[collectionKey][key].push(value);
    });

    this.batchedData[collectionKey].collection = collection;

    return {
      msg,
      collection,
    };
  };

  resetTimer = (timerId: any, fn: any, timeout: any) => {
    if (this.timeoutIds[timerId]) {
      clearTimeout(this.timeoutIds[timerId]);
    }
    this.timeoutIds[timerId] = setTimeout(fn, timeout);
  };

  doBatch = (data: any, callback: any, timeout = 1000) => {
    const { collection, msg } = this.addData(data);
    const collectionKey = `${collection}-${msg}`;

    const onTimerDone = () => {
      callback(this.batchedData[collectionKey]);
      this.batchedData[collectionKey] = null;
    };

    this.resetTimer(collectionKey, onTimerDone, timeout);

    return this;
  };
}
