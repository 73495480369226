import { escapeHtml } from 'kit-escape';
import { fiSysConfig } from 'fi-session';
import { isArray } from 'lodash';

export const fiJsTableDomUtil = {
  selectRow,
  toggleSelectAll,
  genCheckboxHtml,
  genSelectAllCheckboxHtml,
  genIconTextHtml,
  genIconTextListHtml,
  genLabelHtml,
  genProgressbar,
  genPerDevMappingHtml,
  genPlatformMappingHtml,
  flashDom,
  attachSrcLength,
};

/**
 * Automation test helper. Attach the current source's length to table element
 * @param {jQuery elm} table element
 * @param {Array src} the record count
 */
function attachSrcLength(elm, src) {
  elm.attr('data-table-size', isArray(src) ? src.length : 0);
}

/**
 * modify dom to select or de-select a row
 * @param {jQuery} rowElm
 * @param {boolean} checked
 */
function selectRow(rowElm, checked) {
  if (checked) {
    rowElm
      .addClass('selected')
      .find('.vu-td.check-box nw-icon')
      .attr('name', 'check-selected');
  } else {
    rowElm
      .removeClass('selected')
      .find('.vu-td.check-box nw-icon')
      .attr('name', 'check-empty');
  }
}

/**
 * toggle select all checkbox status
 * @param {jQuery obj} el
 * @returns {boolean} current status selected or unselected
 */
function toggleSelectAll(el) {
  var toSelectAll = el.attr('name') === 'check-empty';
  if (toSelectAll) {
    // update select all checkbox to selected
    el.attr('name', 'check-selected');
  } else {
    // update select all checkbox to unselected
    el.attr('name', 'check-empty');
  }
  return toSelectAll;
}

/**
 * generate html for checkbox
 * @param {boolean} selected
 * @param {boolean} disabled
 */
function genCheckboxHtml(selected, disabled) {
  return `<div class="vu-td fixed-td check-box${disabled ? ' disabled' : ''}">
              <nw-icon library="fafm" name="${
                selected ? 'check-selected' : 'check-empty'
              }"></nw-icon>
            </div>`;
}

/**
 * generate html for table header select all checkbox
 * @param {boolean} selected
 * @param {boolean} disabled
 */
function genSelectAllCheckboxHtml(selected = false, disabled = false) {
  return `<div class="vu-th fixed-th check-box unsortable${
    disabled ? ' disabled' : ''
  }">
              <nw-icon library="fafm" name="${
                selected ? 'check-selected' : 'check-empty'
              }"></nw-icon>
            </div>`;
}

function genIconTextHtml(data) {
  let html = '';
  let title = escapeHtml(data.title ? data.title : '');

  if (data.css) {
    html += '<span class="' + data.css + '" title="' + title + '"></span>&nbsp';
  }

  if (data.txt || data.name) {
    html +=
      '<span title="' +
      title +
      '">' +
      escapeHtml(data.txt || data.name) +
      '</span>';
  }

  return html;
}

function genIconTextListHtml(data) {
  let html = '';
  data = isArray(data) ? data : [];

  data.forEach(function (it) {
    html += '<div>' + genIconTextHtml(it) + '</div>';
  });

  return html;
}

function genLabelHtml(data) {
  return (
    '<span class="' + data.css + '">' + escapeHtml(data.label || '') + '</span>'
  );
}

function genProgressbar(data, txt) {
  let html =
    '<div class="progress"><div class="progress-bar ' +
    data.css +
    '" role="progressbar"';
  html +=
    ' aria-valuenow="' +
    data.per +
    '" aria-valuemin="0" aria-valuemax="100" style="width:' +
    data.per +
    '%">';
  html += '<span>' + data.val + '</span>' + txt + '</div></div>';
  return html;
}

function genPerDevMappingHtml(data) {
  if (data['dynamic_mapping']) {
    let mappingListHtml = '';
    if (!data.devCollapsed && data['dynamic_mapping'].length > 0) {
      data['dynamic_mapping'].forEach(function (mapping) {
        let dev = mapping.dev;
        mappingListHtml +=
          '' +
          '<div class="dev-mapping-entry">' +
          '<span class="tw-pr-1 ' +
          (mapping.icon ? mapping.icon : 'ffg ffg-empty') +
          '"></span>' +
          '<span class="entry-text">' +
          dev.name +
          ' (' +
          dev.vdom +
          '): ' +
          mapping.details +
          '</span>' +
          '</div>';
      });
    }
    return (
      '' +
      '<div class="per-dev-mapping-collapse">' +
      '<div class="pointer-cursor collapsable not-fire-row-selection-event">' +
      '<span class="tw-pr-1 ffg ' +
      (data.devCollapsed ? 'ffg-arrow-right' : 'ffg-arrow-down') +
      '">' +
      '</span>' +
      '<span class="amount-info">' +
      gettext('%(amount)s out of %(total)s').printfd({
        amount:
          '<span class="mappings-amount">' +
          (data['dynamic_mapping'].length || 0) +
          '</span>',
        total:
          '<span class="total-dev-amount"><span class="ffg ffg-spinner loading"></span></span>',
      }) +
      '</span>' +
      '</div>' +
      (data.devCollapsed
        ? ''
        : '' +
          '<div class="dev-list-container">' +
          mappingListHtml +
          '</div>') +
      '</div>'
    );
  } else {
    return '';
  }
}

function genPlatformMappingHtml(data) {
  if (data['platform_mapping']) {
    let mappingListHtml = '';
    if (!data.platformCollapsed && data['platform_mapping'].length > 0) {
      data['platform_mapping'].forEach((mapping) => {
        mappingListHtml +=
          '' +
          '<div class="platform-mapping-entry">' +
          '<span class="tw-pr-1 ffg ffg-empty"></span>' +
          '<span class="entry-text">' +
          mapping.name +
          ':' +
          mapping['intf-zone'] +
          '</span>' +
          '</div>';
      });
    }
    return (
      '' +
      '<div class="platform-mapping-collapse">' +
      '<div class="pointer-cursor collapsable not-fire-row-selection-event">' +
      '<span class="tw-pr-1 ffg ' +
      (data.platformCollapsed ? 'ffg-arrow-right' : 'ffg-arrow-down') +
      '"></span>' +
      '<span class="amount-info">' +
      gettext('%s Platform Mapping').printf([data['platform_mapping'].length]) +
      '</span>' +
      '</div>' +
      (data.platformCollapsed
        ? ''
        : '' +
          '<div class="platform-map-list-container">' +
          mappingListHtml +
          '</div>') +
      '</div>'
    );
  } else {
    return '';
  }
}

function flashDom(dom) {
  var animateCount = 0;
  var triggerCount = 0;
  var flashFn = function (dom) {
    var opacity = animateCount % 2 ? 0.0 : 1.0;
    dom.animate(
      { opacity: opacity },
      {
        done: function () {
          if (++triggerCount > 100) {
            // This case will happen if the entry keeps being not rendered.
            // To avoid dead loop, giving up the further animation.
            // This case should not happen. It is fault-tolerance logic.
            // eslint-disable-next-line
            console.warn(
              'The entry highlight animation is in dead loop. Abort.'
            );
            return;
          }
          //808578: reduce blinking to 3 times
          if (animateCount < 6) {
            if (animateCount === 5) {
              dom.removeClass('highlight');
            }
            animateCount++;
            flashFn(dom);
          }
        },
        complete: function () {
          // do not change the opacity, or policy table disabled row is not transparent
          if (animateCount === 6) {
            dom.css('opacity', '');
          }
        },
      }
    );
  };

  dom.addClass('highlight');

  if (fiSysConfig.current()['table_entry_blink']) {
    flashFn(dom);
  }
}
