import { createSelector } from '@reduxjs/toolkit';
import { getLogviewPageMap as _getLogviewPageMap } from './helpers';

const getLogView = (state) => state?.adom?.logview;

export const getLogDbRebuild = createSelector(getLogView, (logView) => {
  return logView?.logDbRebuild;
});

export const getLogFetcher = createSelector(getLogView, (logView) => {
  return logView?.logFetcher;
});

export const getLogviewContentMenu = createSelector(getLogView, (logView) => {
  return logView?.logviewContentMenu;
});

export const getLogviewCustomViewer = createSelector(getLogView, (logView) => {
  return logView?.logviewCustomViewer;
});
export const getLogviewCustomViewerActionParams = createSelector(
  getLogView,
  (logView) => {
    return logView?.logviewCustomViewerActionParams;
  }
);

export const getMaskableLogfields = createSelector(getLogView, (logView) => {
  return logView?.maskableLogfields || {};
});

export const getLogviewPageMap = createSelector(
  getLogviewContentMenu,
  (data) => {
    return _getLogviewPageMap(data?.records || []);
  }
);
