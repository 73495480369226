import { get, head, isString } from 'lodash';
import { TaskTextMap } from './const';
import { fiStore, fiSession } from 'fistore';
import { fiFmgHttp } from 'fi-http';
import moment from 'moment';
import { formatAdomTime } from 'fi-datetime';
import $ from 'jquery';

export {
  toFormatLocaleTime, // format start/end timestamp
  isInstallType,
  isRunScriptType,
  isDeviceError,
  isTaskDone,
  isTaskPending,
  isTaskRunning,
  isTaskSucess,
  isTaskWarning,
  isTaskError,
  isTaskCancelled,
  isTaskUnSucess,
  getTaskLineDetail,
  getTaskHistory,
  getLineHistoryFinishedTime,
  getLineHistoryDetail,
  formatGmtOffset,
  getTasksWithTimestamp,
  getTaskWithTimestampById,
  wait4Task,
  key2Text,
};

const toFormatLocaleTime = (timestamp) => {
  if (isNaN(timestamp) || timestamp <= 0) return 'N/A';
  return formatAdomTime({
    unix: timestamp,
    format: 'llll z',
  });
};

const isInstallType = (task) => {
  if (!task) return false;
  const { src } = task;

  return (
    src === MACROS.TASK.TASK_SRC_SC || // install package source
    src === MACROS.TASK.TASK_SRC_INSTDEV || // install device source
    src === MACROS.TASK.TASK_SRC_INSTOBJS || // install objects
    src === MACROS.TASK.TASK_SRC_DPM_INSTCONF
  ); // install configuration
};

const isRunScriptType = (task) =>
  task?.src === MACROS.TASK.TASK_SRC_DPM_INSTSCRIPT;

const isDeviceType = (task) => {
  return ['upddevtitle'].includes(task.title);
};

const isDeviceError = (task) => {
  return (
    task.state == MACROS.TASK.TASK_STATE_ERROR &&
    (isInstallType(task) || isDeviceType(task) || isRunScriptType(task))
  );
};

const isTaskDone = (task) => task.percent >= 100;
const isTaskPending = (task) => task.state === MACROS.TASK.TASK_STATE_PEND;
const isTaskRunning = (task) => task.state == MACROS.TASK.TASK_STATE_RUN;

const isTaskSucess = (task) => task.state === MACROS.TASK.TASK_STATE_DONE;
const isTaskWarning = (task) => task.state === MACROS.TASK.TASK_STATE_WARN;
const isTaskError = (task) => task.state === MACROS.TASK.TASK_STATE_ERROR;
const isTaskCancelled = (task) => {
  return (
    task.state === MACROS.TASK.TASK_STATE_CANCELLING ||
    task.state === MACROS.TASK.TASK_STATE_CANCELLED ||
    task.state === MACROS.TASK.TASK_STATE_ABORTING ||
    task.state === MACROS.TASK.TASK_STATE_ABORTED
  );
};
const isTaskUnSucess = (task) => isTaskError(task) || isTaskCancelled(task);

const getTaskLineDetail = (line) => {
  const detail = line.detail;
  return isString(detail)
    ? detail
        .split('|')
        .map((key) => TaskTextMap[key] || key)
        .join(" '")
    : detail || '';
};

const lineHistoryDetailSplitIndex = 19;

function formatGmtOffset(gmtoff) {
  const hours = gmtoff / 3600;
  if (hours > 0) return '+' + String(hours).padStart(2, 0);
  if (hours < 0) return '-' + String(-hours).padStart(2, 0);
  return 'Z';
}

/**
 * return task history by given line
 *        if no lineName specified, then return the history of first line
 * @param {int} taskId
 * @param {string} lineName
 */
function getTaskHistory(taskId, lineName, vdom) {
  const filter = _genTaskFilter(lineName, vdom);
  const req = {
    method: 'get',
    params: [
      {
        url: `task/task/${taskId}/line`,
        filter: filter ? filter : undefined,
      },
    ],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req).then(
      (resp) => {
        let data = resp[0].data;
        if (lineName) {
          if (data.length) {
            data = data.filter((x) => x.name === lineName);
            data = data[0]?.history || [];
          } else {
            data = [];
          }
        } else {
          data = data[0]?.history || [];
        }
        return resolve(data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function getLineHistoryFinishedTime(history) {
  try {
    const tz = fiSession.getSysConfig(fiStore.getState()).timezone;
    //2021-05-25 15:40:48
    const timestr = history.detail.substring(0, lineHistoryDetailSplitIndex);
    const tm = moment(timestr + formatGmtOffset(tz.gmtoff)).unix();

    return tm;
  } catch {
    return 0;
  }
}

function getLineHistoryDetail(history) {
  try {
    const detail = history.detail.substring(lineHistoryDetailSplitIndex + 1);
    return TaskTextMap[detail] ? TaskTextMap[detail] : detail;
  } catch {
    return '';
  }
}

function getTasksWithTimestamp(customParams) {
  const params = Object.assign(
    {},
    {
      url: 'task/task',
      loadsub: 0,
    },
    customParams
  );

  const req = {
    method: 'get',
    params: [params],
  };

  return new Promise((resolve, reject) => {
    fiFmgHttp.forward(req).then(
      (resp) => {
        resolve(get(resp, '0.data', []) || []);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

function getTaskWithTimestampById(taskId, abortCtrl) {
  return new Promise((resolve, reject) => {
    const req = {
      method: 'get',
      params: [{ url: '/task/task/' + taskId }],
    };

    fiFmgHttp.forward(req, undefined, abortCtrl).then(
      function (resp) {
        const code = get(resp, '0.status.code');
        if (code === MACROS.USER.RESPOSE_STATUS.OK) {
          resolve(head(resp));
        } else {
          reject(head(resp));
        }
      },
      function (error) {
        reject(get(error, 'errors.0.status'));
      }
    );
  });
}

/**
 * Wait until the task completes, could be error, warning or success
 * @param {{
 *  taskId: number;
 *  interval?: number;
 *  signal?: AbortSignal
 * }}
 * taksId - an object to controll the request, or the ID of the task needs to wait for
 * interval - Query interval
 * @return {{ cancel: VoidFunction; promise: any}} reject when error(s) happen. Resolve when the task is success or warning
 */
function wait4Task({ taskId, interval = 800, signal }) {
  const deferred = new $.Deferred();
  let isTaskDone = false;
  let isTaskCancelled = false;
  let hasError = false;
  let stop = false;
  let clrTimeout = null;

  const cancel = () => {
    if (clrTimeout) {
      clearTimeout(clrTimeout);
    }
    stop = true;
  };

  const queryFn = (resp) => {
    const data = resp?.data;
    if (!data) {
      getTaskWithTimestampById(taskId).then(queryFn, function (err) {
        deferred.reject(err);
      });
      return;
    }

    if (signal?.aborted) {
      deferred.reject();
      return;
    }

    isTaskDone = data.percent >= 100;
    isTaskCancelled = data.state === MACROS.TASK.TASK_STATE_CANCELLED;
    hasError = data.num_err;

    if (stop) {
      data.percent = 100;
      deferred.reject(data);
    } else {
      if (isTaskDone) {
        if (hasError) {
          deferred.reject(data);
        } else {
          deferred.resolve(data);
        }
      } else if (isTaskCancelled) {
        deferred.reject(data);
      } else {
        deferred.notify(data);
        clrTimeout = setTimeout(() => {
          getTaskWithTimestampById(taskId).then(queryFn);
        }, interval);
      }
    }
  };

  // start query task
  queryFn();

  return {
    cancel,
    promise: deferred.promise(),
  };
}

function getSplitText(key) {
  let ret = '';
  if (!key) return ret;

  const keys = key.split('|');
  if (keys.length) {
    keys.forEach((curr) => {
      ret = ret + ' ' + (TaskTextMap[curr] || curr);
    });
  }
  return ret;
}

function key2Text(key) {
  return TaskTextMap[key] || getSplitText(key) || key;
}

// ========= internal function ===========
function _genTaskFilter(lineName, vdom) {
  let filter = null;
  if (lineName && vdom) {
    filter = [['name', '==', lineName], 'and', ['vdom', '==', vdom]];
  } else if (lineName) {
    filter = ['name', '==', lineName];
  }
  return filter;
}
