import { useParams, useLocation } from 'react-router-dom';
import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import { ContentViewWrapper } from './contentview/ContentViewWrapper';
import React from 'react';
import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import { AdomWatchWrapper } from 'ra-shared-components';

const NOC_ROUTES = {
  path: '/fortiview/:type',
  RenderErrorBoundary,
  Component: AdomWatchWrapper,
  handle: {
    appUniKey: 'fortiview',
  },
  children: [
    getRedirectIndexRoute(),
    {
      path: 'fmg-dash1',
      RenderErrorBoundary,
      handle: {
        appUniKey: 'fortiview_fmg-dash1',
      },
      lazy: async () => {
        let { Layout } = await import('./dashboard/Layout');
        return {
          Component: () =>
            Layout({
              dashId: 'fmg-dash1',
            }),
        };
      },
    },
    {
      path: 'faz-performance-monitor',
      RenderErrorBoundary,
      handle: {
        appUniKey: 'fortiview_faz-performance-monitor',
      },
      lazy: async () => {
        let { Layout } = await import('./dashboard/Layout');
        return {
          Component: () => {
            const dashId = 'faz-performance-monitor';
            const params = useParams();
            return (
              <Layout
                key={`${dashId}_${Math.random()}`}
                dashId={dashId}
                isFtv={params.type === 'ftv'}
              />
            );
          },
        };
      },
    },
    {
      path: ':folder',
      RenderErrorBoundary,
      handle: {
        appUniKey: (params) => {
          return `fortiview_${params.folder}`;
        },
      },
      Component: ContentViewWrapper,
      children: [
        {
          path: ':dashId',
          RenderErrorBoundary,
          handle: {
            appUniKey: (params) => {
              return `fortiview_${params.folder}_${params.dashId}`;
            },
          },
          lazy: async () => {
            let { Layout } = await import('./dashboard/Layout');
            return {
              Component: () => {
                const params = useParams();
                const { state } = useLocation();
                return (
                  <Layout
                    key={`${params.dashId}_${Math.random()}`}
                    dashId={params.dashId}
                    isFtv={params.type === 'ftv'}
                    isEdit={state?.isEdit}
                    timeRange={state?.timeRange}
                  />
                );
              },
            };
          },
        },
      ],
    },
  ],
};
export default NOC_ROUTES;
