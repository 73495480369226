import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import {
  getInusedFilterFn,
  getMatchedAttrs,
  isMetaSupportSelect,
} from './syntax_util';

// For debugging meta supported attrs in form based on syntax and schema
export const useDebugSyntaxAttrs = ({
  syntax: syntaxProp,
  schema: schemaProp,
  processFn = getMatchedAttrs,
  attrMatch,
  filterFn,
  onDebugStart,
  onDebugDone,

  // remember to set it to false when done debugging
  enable = true,
}) => {
  if (!enable || !MACROS.SYS.CONFIG_DEBUG) return;

  const [result, setResult] = useState(null);

  useEffect(() => {
    if (!enable || !MACROS.SYS.CONFIG_DEBUG) return;

    if (!syntaxProp || isEmpty(syntaxProp) || result) {
      return;
    }

    try {
      if (!Object.keys(syntaxProp).length) {
        return;
      }

      onDebugStart && onDebugStart();

      const categories = Object.keys(syntaxProp);
      for (const category of categories) {
        // eslint-disable-next-line
        console.debug('Debugging for category:', category);

        const syntax = syntaxProp[category];
        const schema = schemaProp[category] || {}; // empty schema > considering all attrs
        if (!syntax || isEmpty(syntax)) {
          // eslint-disable-next-line
          console.debug(
            `useDebugSyntaxAttrs: missing syntax or schema for ${category}`
          );
          return;
        }

        // eslint-disable-next-line
        console.debug('syntax', syntax);
        // eslint-disable-next-line
        console.debug('schema', schema);

        const result = processFn({
          syntax,
          attrMatch,
          filterFn:
            schema && !isEmpty(schema)
              ? filterFn || getInusedFilterFn(schema)
              : () => true,
        });
        // eslint-disable-next-line
        console.debug('result', result);

        onDebugDone && onDebugDone();
        setResult(result);
      }
    } catch (err) {
      // eslint-disable-next-line
      console.debug(`useDebugSyntaxAttrs caused error: ${err}`);
    }
  }, [syntaxProp, schemaProp]);
};

export const useDebugMetaSupportedSelect = ({
  syntax,
  schema,
  filterFn = undefined,
}) => {
  useDebugSyntaxAttrs({
    syntax,
    schema,
    attrMatch: isMetaSupportSelect,
    filterFn,
    onDebugStart: () => {
      // eslint-disable-next-line
      console.debug(
        '-----Start Debug: Select fields that supports metadata variables-----'
      );
    },
    onDebugDone: () => {
      // eslint-disable-next-line
      console.debug(
        '-----End Debug: Select fields that supports metadata variables-----'
      );
    },
  });
};
