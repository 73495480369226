import { useEffect, useState } from 'react';

import { fiStore, fiStoreTheme, fiStoreUtils } from 'fistore';
import { isObject } from 'lodash';

import { NwButton } from '@fafm/neowise/react';
import { CellSpan } from 'rc_layout';

export {
  useNwChartTheme,

  //dvm table config status tooltip
  getDeviceCfgStatus,
  getVdomSummaryStatus,
  getDeviceConfStatus,
  getModifiedTemplateNames,
  getDeviceDBStatus,
  deviceConfStatus,
  getFormatCfgStatusTooltipData,

  //dvm table usePkg.js
  getIsPpkgModified,
  getModifiedTemplates,
  getIsProfModified,
};

function getChartTheme(isDark) {
  return isDark ? 'dark' : 'light';
}

function useNwChartTheme() {
  const [chartTheme, setChartTheme] = useState('light');
  const { getIsDarkTheme } = fiStoreTheme;
  useEffect(() => {
    return fiStoreUtils.observeStore(fiStore, getIsDarkTheme, (isDark) => {
      setChartTheme(getChartTheme(isDark));
    });
  }, []);

  return chartTheme;
}

/** For Config Status Tooltip (DVM Table, Dashboard > Config & Install Widget) */

const STATUS_ICON_PROPS = {
  MODIFIED: { name: 'warning', className: 'color-orange' },
  OUT_OF_SYNC: { name: 'no', className: 'color-red' },
  IN_SYNC: { name: 'yes', className: 'color-green' },
  UNKNOWN: { name: 'unregistered', className: 'color-grey' },
};

const STATUS_TXT = {
  MODIFIED: gettext('Modified'),
  MODIFIED_AUTOUPD: gettext('Modified (recent auto-updated)'),
  UNMODIFIED: gettext('Unmodified'),
  UNKNOWN: gettext('Unknown'),
  CONFLICT: gettext('Conflict'),
  OUT_OF_SYNC: gettext('Out of Sync'),
  AUTOUPD: gettext('Auto-update'),
  SYNC: gettext('Synchronized'),
  PENDING_AUTO: gettext('Pending Auto-install'),
};

const TEMPLATE_NAMES = {
  cli_prof: gettext('CLI Template'),
  prerun_cliprof: gettext('Pre-Run CLI Template'),
  dev_prof: gettext('System Template'),
  ipsec_prof: gettext('IPsec Tunnel Template'),
  router_prof: gettext('Static Route Template'),
  router_bgp: gettext('BGP Template'),
  sdwan_prof: gettext('SD-WAN Template'),
  fwm_prof: gettext('Firmware Template'),
  ips_prof: gettext('IPS Template'),
  tmpl_grp: gettext('Template Group'),
  fext_prof: gettext('FortiExtender Profile'),
  fap_prof: gettext('FortiAP Profile'),
  fsw_prof: gettext('FortiSwitch Profile'),
  sase_manager: gettext('FortiSASE Manager'),
};

const PROFILE_ORDER = ['fap_prof', 'fsw_prof', 'fext_prof'];

const CONFIG_STATUS = [
  MACROS.DVM.DVM_COND_OK,
  MACROS.DVM.DVM_COND_AUTO_INSTALL,
  MACROS.DVM.DVM_COND_PEND_CONF,
  MACROS.DVM.DVM_COND_OUT_OF_SYNC,
  MACROS.DVM.DVM_COND_CONFLICT,
];

const CONFIG_STATUS_MAP = CONFIG_STATUS.reduce((acc, cur, idx) => {
  acc[cur] = idx;
  return acc;
}, {});

function getVdomSummaryStatus({ sync, vdomRows, hasVdoms }) {
  if (!hasVdoms || !vdomRows) return { vdomSummaryCss: '', vdomSummaryTxt: '' };

  let worstStatus = sync ?? 0;
  let autoupd = 0,
    autosync = 0;
  let isProfModified = false;

  //worst sync status of vdom rows
  //also get worst autoupd/autosync attr (0 or 1)
  //and worst profmodified status
  worstStatus =
    CONFIG_STATUS[
      Math.max(
        ...vdomRows.map((vRow) => {
          isProfModified = vRow?.data?.isProfModified || isProfModified;
          autoupd = Math.max(
            autoupd,
            vRow?.data?.data?.autoupd ?? MACROS.PM2CAT.PM2_OPT_DISABLE
          );
          autosync = Math.max(
            autoupd,
            vRow?.data?.data?.autosync ?? MACROS.PM2CAT.PM2_OPT_DISABLE
          );
          return CONFIG_STATUS_MAP[vRow?.data?.data?.sync];
        })
      )
    ];

  const { cfgStatusTxt: vdomSummaryTxt, cfgStatusCss: vdomSummaryCss } =
    getDeviceCfgStatus({
      isProfModified,
      row: {
        data: {
          sync: worstStatus,
          isProfModified,
          autosync,
          autoupd,
        },
      },
    });

  return { vdomSummaryTxt, vdomSummaryCss };
}

//cfg status (summary of db status, templates and profiles)
//cfg status shows in table column
function getDeviceCfgStatus({ row, isProfModified = false, parent = null }) {
  let cfgStatusCss,
    cfgStatusTxt,
    detailsTxt = '';
  let hasInstallPreview = false,
    hasViewDiff = false;
  let isUnknown = false;
  const data = row.data;

  const deviceIsConnected = () => {
    //only show install preview / view diff if device is up
    if (data.platform === 'vdom')
      return parent?.data?.connection?.conn === MACROS.DVM.DVM_CONN_STATUS_UP;
    return row?.data?.connection?.conn === MACROS.DVM.DVM_CONN_STATUS_UP;
  };

  //only show worst status detail
  let detailsPriority = 0;
  const addModifiedDetail = () => {
    if (detailsPriority > 1) return;
    if (deviceIsConnected()) {
      hasInstallPreview = true;
      hasViewDiff = false;
    }
    detailsTxt = gettext(
      'Device configuration and/or provisioning templates have been changed, please install to apply changes to remote device.\n'
    );
    detailsPriority = 1;
  };

  const addConflictDetail = () => {
    if (detailsPriority > 2) return;
    if (deviceIsConnected()) {
      hasInstallPreview = true;
      hasViewDiff = true;
    }
    detailsTxt = gettext(
      'Remote device has configuration changes, please either install or retrieve.\n'
    );
    detailsPriority = 2;
  };

  const rules = deviceConfStatus;
  if (rules.pending_auto_update(row)) {
    cfgStatusCss = STATUS_ICON_PROPS.MODIFIED;
    cfgStatusTxt = STATUS_TXT.MODIFIED_AUTOUPD;
    addModifiedDetail();
  } else if (rules.pending(row)) {
    cfgStatusCss = STATUS_ICON_PROPS.MODIFIED;
    cfgStatusTxt = STATUS_TXT.MODIFIED;
    addModifiedDetail();
  } else if (rules.warning(row)) {
    cfgStatusCss = STATUS_ICON_PROPS.OUT_OF_SYNC;
    cfgStatusTxt = STATUS_TXT.CONFLICT;
    addConflictDetail();
  } else if (rules.out_of_sync(row)) {
    cfgStatusCss = STATUS_ICON_PROPS.OUT_OF_SYNC;
    cfgStatusTxt = STATUS_TXT.OUT_OF_SYNC;
    addConflictDetail();
  } else if (rules.auto_update(row)) {
    cfgStatusCss = STATUS_ICON_PROPS.IN_SYNC;
    cfgStatusTxt = STATUS_TXT.AUTOUPD;
  } else if (rules.synchronized(row)) {
    cfgStatusCss = STATUS_ICON_PROPS.IN_SYNC;
    cfgStatusTxt = STATUS_TXT.SYNC;
  } else if (rules.pending_auto_push(row)) {
    cfgStatusCss = STATUS_ICON_PROPS.MODIFIED;
    cfgStatusTxt = STATUS_TXT.PENDING_AUTO;
    addModifiedDetail();
  } else if (rules.modified(row)) {
    cfgStatusCss = STATUS_ICON_PROPS.MODIFIED;
    cfgStatusTxt = STATUS_TXT.MODIFIED;
    addModifiedDetail();
  } else {
    cfgStatusCss = STATUS_ICON_PROPS.UNKNOWN;
    cfgStatusTxt = STATUS_TXT.UNKNOWN;
    isUnknown = true;
  }

  //modified status can also be triggered by templates/profiles modified
  //don't set modified if conflict or out of sync
  if (isProfModified && detailsPriority < 2 && !isUnknown) {
    cfgStatusCss = STATUS_ICON_PROPS.MODIFIED;
    cfgStatusTxt = STATUS_TXT.MODIFIED;
    addModifiedDetail();
  }

  return {
    cfgStatusCss,
    cfgStatusTxt,
    detailsTxt,
    hasInstallPreview,
    hasViewDiff,
  };
}

//db status (raw status of device config db)
//db status shows in tooltip
function getDeviceDBStatus({ row }) {
  let dbStatusTxt, dbStatusCss;

  //raw dbstatus can be modified, not modified, or unknown
  const db_status = row?.data?.db_status ?? -1;
  if (db_status === MACROS.DVM.PM2_VDOM_STATUS_MODIFIED) {
    dbStatusCss = STATUS_ICON_PROPS.MODIFIED;
    dbStatusTxt = STATUS_TXT.MODIFIED;
  } else if (db_status === MACROS.DVM.PM2_VDOM_STATUS_NOT_MODIFIED) {
    dbStatusCss = STATUS_ICON_PROPS.IN_SYNC;
    dbStatusTxt = STATUS_TXT.UNMODIFIED;
  } else {
    dbStatusCss = STATUS_ICON_PROPS.UNKNOWN;
    dbStatusTxt = STATUS_TXT.UNKNOWN;
  }

  return {
    dbStatusCss,
    dbStatusTxt,
  };
}

//conf_status of device is compare local db to remote db
function getDeviceConfStatus({ row, isDevice }) {
  let confStatusTxt, confStatusCss;

  if (!isDevice)
    return {
      //only global device level has conf_status
      confStatusCss,
      confStatusTxt,
    };

  const conf_status = row?.data?.conf_status ?? -1;
  if (conf_status === MACROS.DVM.DVM_CONF_STATUS_OUT_OF_SYNC) {
    confStatusCss = STATUS_ICON_PROPS.MODIFIED;
    confStatusTxt = STATUS_TXT.MODIFIED;
  } else if (conf_status === MACROS.DVM.DVM_CONF_STATUS_IN_SYNC) {
    confStatusCss = STATUS_ICON_PROPS.IN_SYNC;
    confStatusTxt = STATUS_TXT.UNMODIFIED;
  } else {
    confStatusCss = STATUS_ICON_PROPS.UNKNOWN;
    confStatusTxt = STATUS_TXT.UNKNOWN;
  }

  return {
    confStatusCss,
    confStatusTxt,
  };
}

function getModifiedTemplateNames({ modifiedTemplates = [] }) {
  let templateNamesArr = modifiedTemplates
    .sort((a, b) => PROFILE_ORDER.indexOf(a) - PROFILE_ORDER.indexOf(b))
    .map((tmpl) => TEMPLATE_NAMES[tmpl]);

  return templateNamesArr;
}

const _DVM_M = MACROS.DVM;

function isAutoUpdated(oRec) {
  return (
    oRec.autoupd === MACROS.PM2CAT.PM2_OPT_ENABLE ||
    oRec.autosync === MACROS.PM2CAT.PM2_OPT_ENABLE
  );
}

const deviceConfStatus = {
  synchronized: (row) => {
    const data = row?.data;
    return (
      data &&
      data.sync === _DVM_M.DVM_COND_OK &&
      !isAutoUpdated(data) &&
      !profModified(row)
    );
  },
  auto_update: (row) => {
    const data = row?.data;
    return (
      data &&
      data.sync === _DVM_M.DVM_COND_OK &&
      isAutoUpdated(data) &&
      !profModified(row)
    );
  },
  out_of_sync: (row) => {
    const data = row?.data;
    return (
      data && data.sync === _DVM_M.DVM_COND_OUT_OF_SYNC && !profModified(row)
    );
  },
  pending: (row) => {
    const data = row?.data;
    return (
      data && data.sync === _DVM_M.DVM_COND_PEND_CONF && !profModified(row)
    );
  },
  pending_auto_update: (row) => {
    const data = row?.data;
    return (
      data &&
      data.sync === _DVM_M.DVM_COND_PEND_CONF &&
      isAutoUpdated(data) &&
      !profModified(row)
    );
  },
  warning: (row) => {
    const data = row?.data;
    return data && data.sync === _DVM_M.DVM_COND_CONFLICT && !profModified(row);
  },
  pending_auto_push: (row) => {
    const data = row?.data;
    return (
      data && data.sync === _DVM_M.DVM_COND_AUTO_INSTALL && !profModified(row)
    );
  },
  unknown: (row) => {
    const data = row?.data;
    return (
      data &&
      data.sync !== _DVM_M.DVM_COND_OK &&
      data.sync !== _DVM_M.DVM_COND_OUT_OF_SYNC &&
      data.sync !== _DVM_M.DVM_COND_PEND_CONF &&
      data.sync !== _DVM_M.DVM_COND_CONFLICT &&
      data.sync !== _DVM_M.DVM_COND_AUTO_INSTALL &&
      !profModified(row)
    );
  },
  modified: (row) => {
    const data = row?.data;
    return (
      (data && data.sync === _DVM_M.DVM_COND_AUTO_INSTALL) ||
      data.sync === _DVM_M.DVM_COND_PEND_CONF ||
      profModified(row)
    );
  },
};

function profModified(row) {
  return row.isProfModified;
}

function getFormatCfgStatusTooltipData(props) {
  const {
    cfgStatusCss,
    cfgStatusTxt,
    confStatusCss,
    confStatusTxt,
    dbStatusCss,
    dbStatusTxt,
    templateNamesArr,
    vdomSummaryCss,
    vdomSummaryTxt,
    hasVdoms,
    detailsTxt,
    buttonDivId,
    hasInstallPreview,
    hasViewDiff,

    getDBStatusRowLabel,
    openInstallPreview,
    openViewDiffModal,
  } = props;

  const entries = [
    {
      label: gettext('Config Status'),
      value: () => {
        return <CellSpan css={cfgStatusCss} txt={cfgStatusTxt} />;
      },
      condition: () => !!cfgStatusCss && !!cfgStatusTxt,
    },
    {
      label: 'divider',
      renderer: () => {
        return (
          <div
            style={{ maxWidth: '400px', borderBottom: '1px solid' }}
            className={'tw-col-span-2'}
          ></div>
        );
      },
    },
    {
      label: gettext('Remote Device Config'),
      value: () => {
        return <CellSpan css={confStatusCss} txt={confStatusTxt} />;
      },
      condition: () => !!confStatusCss && !!confStatusTxt,
    },
    {
      label: getDBStatusRowLabel(),
      value: () => {
        return <CellSpan css={dbStatusCss} txt={dbStatusTxt} />;
      },
      condition: () => !!dbStatusCss && !!dbStatusTxt,
    },
    {
      label: gettext('Provisioning Templates'),
      value: () => {
        return (
          <div className={'tw-flex tw-flex-col'} style={{ maxWidth: '300px' }}>
            {templateNamesArr.map((tmplStr) => (
              <CellSpan
                key={tmplStr}
                css={'ffg ffg-warning color-orange'}
                txt={tmplStr}
              />
            ))}
          </div>
        );
      },
      condition: () => templateNamesArr.length > 0,
    },
    {
      label: gettext('Device VDOM DBs'),
      value: () => {
        return <CellSpan css={vdomSummaryCss} txt={vdomSummaryTxt} />;
      },
      condition: () => hasVdoms && !!vdomSummaryCss && !!vdomSummaryTxt,
    },
    {
      label: 'detail',
      renderer: () => {
        return (
          <div style={{ maxWidth: '400px' }} className={'tw-col-span-2'}>
            <nw-alert type='neutral' open>
              <nw-icon slot='icon' name='online-help' />
              {detailsTxt}
            </nw-alert>
          </div>
        );
      },
      condition: () => !!detailsTxt,
    },
    {
      label: 'action_buttons',
      renderer: () => {
        return (
          <div id={buttonDivId} className={'tw-flex tw-flex-row tw-gap-1'}>
            {!hasViewDiff ? (
              <NwButton
                name={'install_preview'}
                label={gettext('Install Preview')}
                automation-id={'dvm_table_install_preview'}
                onClick={openInstallPreview}
              >
                {gettext('Install Preview')}
              </NwButton>
            ) : (
              <>
                <NwButton
                  name={'install_preview'}
                  label={gettext('Install Preview')}
                  automation-id={'dvm_table_install_preview'}
                  onClick={openInstallPreview}
                >
                  {gettext('Install Preview')}
                </NwButton>
                <NwButton
                  name={'view'}
                  label={gettext('View Diff')}
                  automation-id={'dvm_table_view_diff'}
                  onClick={openViewDiffModal}
                >
                  {gettext('View Diff')}
                </NwButton>
              </>
            )}
          </div>
        );
      },
      condition: () => hasInstallPreview,
    },
  ];

  const options = {
    placement: 'right-end',
  };

  return {
    entries,
    options,
  };
}

/** Device Provisioning Template/pkg status for dvm_table/.../usePkg.js or device dashboard > config & install */

function getIsPpkgModified(assignedPkgs) {
  return assignedPkgs?.pkg?.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED;
}

function isModifiedTemplate(key, val) {
  return (
    key !== 'pkg' &&
    isObject(val) &&
    val.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED
  );
}

function getModifiedTemplates(assignedPkgs) {
  if (!isObject(assignedPkgs)) return [];

  const templates = [];

  for (const [key, val] of Object.entries(assignedPkgs)) {
    if (isModifiedTemplate(key, val)) {
      templates.push(key);
    }
  }

  return templates;
}

function getIsProfModified({ assignedPkgs, vdomPkgs }) {
  if (!isObject(assignedPkgs)) return false;
  if (vdomPkgs.length === 0) return getVdomProfModified(assignedPkgs);

  let vdomsDirty = false;

  for (const vdomPkg of vdomPkgs) {
    if (vdomsDirty) return true;
    vdomsDirty = getVdomProfModified(vdomPkg);
  }

  return vdomsDirty || getVdomProfModified(assignedPkgs);
}

function getVdomProfModified(assignedPkgs) {
  if (!isObject(assignedPkgs)) return false;

  if (assignedPkgs.profileDirty) return true;

  for (const [key, val] of Object.entries(assignedPkgs)) {
    //#0948734 don't include firmware prof in device modified status calculation
    if (isModifiedTemplate(key, val) && key !== 'fwm_prof') {
      return true;
    }
  }

  return false;
}
