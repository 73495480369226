import { isNumber } from 'lodash';
import { fiFmgHttp } from 'fi-http';

export const pnoDisplayOpts = (adom, opts) => {
  const adomOid = adom.oid || adom;
  if (!isNumber(adomOid)) return [];
  const url = `/gui/adoms/${adomOid}/customize`;

  if (opts) {
    // Setter
    return fiFmgHttp.post({
      url: url,
      method: 'update',
      params: { pnoCustomize: opts },
    });
  }
  // Getter
  return fiFmgHttp
    .post({
      url: url,
      method: 'get',
    })
    .then((resp) => resp[0]?.data?.pnoCustomize || []);
};
