import { isNumber, isString, isFunction } from 'lodash';
import { gen } from 'fi-uuid';
import { fiWebSocket } from './services';
import $ from 'jquery';

const _buffers = {};

function _getId(id) {
  if (isString(id) || isNumber(id)) return id;
  return gen();
}
function _getWSReq(params, useBatchApi) {
  return fiWebSocket.genWSRequest(
    {
      method: 'post',
      params: params,
      url: useBatchApi ? '/gui/sys/proxy/json/batch' : '/gui/sys/proxy/json',
    },
    null,
    'post'
  );
}
function _parser(data) {
  try {
    return data.result[0].data;
  } catch (err) {
    console.error(err);
    return [];
  }
}

function send(
  {
    id,
    params,
    parser,
    chunksz,
    useBatchApi,
    timeout = 1000,
    acceptEmptyChuckData = () => false,
  },
  cancelWsFnDefer
) {
  id = _getId(id);
  _buffers[id] = {};
  if (!isFunction(parser)) {
    parser = _parser;
  }
  if (!chunksz) {
    chunksz = 20; //wait for response from 20 devices
  }

  let defer = new $.Deferred();
  let mybuf = _buffers[id];
  let isResolved = false;
  let isRejected = false;

  let wsReq = _getWSReq(params, useBatchApi);

  if (cancelWsFnDefer) {
    cancelWsFnDefer.resolve(() => {
      fiWebSocket.cancelRequest(wsReq);
      isRejected = true;
      const ERR_CODE_CLIENT_ABORT = 452;

      defer.reject({
        code: ERR_CODE_CLIENT_ABORT,
        message: gettext('Request was aborted.'),
      });
    });
  }

  fiWebSocket.send(wsReq).then(
    //eslint-disable-next-line
    function (all) {
      //leave empty
      isResolved = true;
    },
    function (err) {
      isRejected = true;
      defer.reject(err);
    },
    function ({ id: reqId, chunk }) {
      if (chunk.header) {
        mybuf.header = chunk.header;
        mybuf.result = [];
      }
      if (chunk.tail) {
        mybuf.tail = chunk.tail;
      }
      //Mantis: #0678963
      if (chunk.data || acceptEmptyChuckData(chunk)) {
        let newResults;
        if (chunk.batch) {
          newResults = chunk.data
            .map((d) => parser(d.data, d.meta, d.status, reqId))
            .flat();
        } else {
          newResults = parser(chunk.data, chunk.meta, chunk.status, reqId);
        }
        mybuf.result = mybuf.result.concat(newResults);
      }
    }
  );

  let curIdx = 0;
  let cleanup = (id) => {
    clearInterval(interval);
    if (_buffers[id]) {
      defer.resolve(_buffers[id].result || []);
      delete _buffers[id];
    }
  };
  let waitTimeout;
  let interval = setInterval(() => {
    if (isRejected) {
      return cleanup(id);
    }

    if (mybuf.result) {
      let chunksRemaining = mybuf.result.length - curIdx;
      const hasTotalRecords = !!mybuf.header.totalRecords;
      const checkRecord =
        (chunksRemaining >= chunksz && curIdx < mybuf.header.totalRecords) ||
        (mybuf.header.totalRecords === mybuf.result.length &&
          chunksRemaining !== 0);
      const hasRecord = hasTotalRecords ? checkRecord : chunksRemaining !== 0;
      const reachedEnd = hasTotalRecords
        ? curIdx >= mybuf.header.totalRecords
        : mybuf.tail && chunksRemaining === 0;

      if (hasRecord) {
        let sz = Math.min(chunksz, chunksRemaining);
        defer.notify(mybuf.result.slice(curIdx, curIdx + sz));
        curIdx += sz;
        if (waitTimeout) clearTimeout(waitTimeout);
      } else if (reachedEnd) {
        cleanup(id);
      } else if (isResolved && !waitTimeout) {
        waitTimeout = setTimeout(() => {
          if (chunksRemaining)
            defer.notify(mybuf.result.slice(curIdx, curIdx + chunksRemaining));
          cleanup(id);
        }, timeout);
      }
    } else if (isResolved) {
      cleanup(id);
    }
  }, 20);
  return defer.promise();
}

function cancel(id) {
  if (_buffers[id]) {
    delete _buffers[id];
  }
}

export const fiWSProxy = {
  send,
  cancel,
};
