import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fiSession } from 'fistore';

export const withAdomRefresh = (WrappedComponent, appUniKey = '') => {
  return (props) => {
    const adom = useSelector(fiSession.getSessionAdom)?.data || null;
    const [refreshKey, setRefresh] = useState(false);
    useEffect(() => {
      setRefresh((prevVal) => !prevVal);
    }, [adom]);
    return <WrappedComponent {...props} key={`${appUniKey}-${refreshKey}`} />;
  };
};
