import { useParams } from 'react-router-dom';

/**
 * TODO: Unify the routes and only keep one route for dev config?
 * From old route:
    case 'adom.default.dvm.main.groups.cfgdev':
      return stateObjectMap['cfgdev'];
    case 'adom.default.dvm.groups.folder.cfgdev':
      return stateObjectMap['cfgdev'];
    case 'adom.default.dvm.main.groups.cfgdev.cfgdevmain':
      return stateObjectMap['cfgdevmain'];
    case 'adom.default.dvm.groups.folder.cfgdev.cfgdevmain':
 */
export const dvmConfigRoutes = [
  {
    id: 'dvm_config',
    path: 'cfg/:deviceId/:vdomName',
    lazy: async () => {
      const { Component } = await import(
        'react_apps/ra_dvm_config/config_main/DvmConfigMain'
      );
      return {
        Component: () => {
          const params = useParams();
          return <Component params={params} />;
        },
      };
    },
    handle: {
      appUniKey: 'dvm_device_list',
    },
    children: [
      {
        path: ':cfggroup/:cfgitem',
        lazy: () =>
          import('react_apps/ra_dvm_config/config_main/DvmConfigMain'),
      },
    ],
  },
];
