// Util Service, KEEP DEPENDENCY AS LESS AS POSSIBLE
import {
  isArray,
  isNumber,
  isString,
  isNil,
  transform,
  negate,
  identity,
  get,
  capitalize,
  union,
  forEach,
} from 'lodash';
import { setIn } from 'ra-data-util';
import { fiAdom } from 'fi-session';

export {
  isSingleSelect,
  isTFType,
  isIPMaskType,
  isDSType,
  hasDSRef,
  isMultiIntType,
  isUintType,
  isInteger,
  isMultiStringType,
  isMultiSelect,
  isMultiOptions,
  isPasswordType,
  isCommentType,
  isMetaSupport,
  isMetaAddRef,
  isMetaNoRef,
  isSeqnum,
  isPortRange,
  isMetaSupportSelect,
  matchOpt,
  setOpt,
  findOptKeyByVal,
  getOpts,
  getOptValByKey,
  createSyntaxOpts,
  getSyntaxValue,
  getDefaultValue,
  getSyntaxUrl,
  getMatchedAttrs,
  getMetaSupportSelectFields,
  getInusedFilterFn,
  getAdvOptsFilterFn,
  genGrpMemberMap,
};

function isSingleSelect(attrSyntax) {
  return attrSyntax.opts && attrSyntax.excluded;
}

/**
 * True if the syntax is True/False choice.
 */
function isTFType(attrSyntax) {
  return (
    attrSyntax.type?.match(/^uint/) &&
    isSingleSelect(attrSyntax) &&
    Object.keys(attrSyntax.opts).length === 2 &&
    attrSyntax.opts?.hasOwnProperty('enable')
  );
}

function isIPMaskType(attrSyntax) {
  return attrSyntax.type === 'ipv4_mask';
}

function isDSType(attrSyntax) {
  return attrSyntax.type === 'datasrc';
}

function isMultiIntType(attrSyntax) {
  return attrSyntax.type === 'multi_int';
}

function isUintType(attrSyntax) {
  return attrSyntax.type?.match(/^uint/) ? true : false;
}

function isInteger(attrSyntax) {
  return attrSyntax.type == 'integer' ? true : false;
}

function isMultiStringType(attrSyntax) {
  return attrSyntax.type === 'multi_str';
}

function isMultiSelect(attrSyntax) {
  return (
    (attrSyntax.hasOwnProperty('max_argv') && attrSyntax['max_argv'] !== 1) ||
    (isDSType(attrSyntax) && !attrSyntax.hasOwnProperty('max_argv')) ||
    (attrSyntax.hasOwnProperty('opts') && !attrSyntax.excluded)
  );
}

function isMultiOptions(attrSyntax) {
  return (
    attrSyntax.type === 'uint32' &&
    attrSyntax.hasOwnProperty('opts') &&
    attrSyntax.excluded === false
  );
}

function isPasswordType(attrSyntax) {
  return ['password', 'passwd'].includes(attrSyntax.type);
}

function isCommentType(attr) {
  return ['comment', 'comments', 'description'].includes(attr);
}

function isMetaSupport(attrSyntax) {
  return !!attrSyntax.flags?.includes('meta-support');
}

// $SUPPORT_META_ADD_REF in syntax:
// this means if the attr value doesn't start with $, backend will still check datasrc
function isMetaAddRef(attrSyntax) {
  return attrSyntax.flags?.includes('meta-add-ref') || !isMetaNoRef(attrSyntax); // meta-add-ref is the default
}

// $SUPPORT_META_NO_REF in syntax:
// this means backend won't check datasrc, so user could type in any string, with or without $, or choose from datasrc
function isMetaNoRef(attrSyntax) {
  return !!attrSyntax.flags?.includes('meta-no-ref');
}

function hasDSRef(attrSyntax) {
  return attrSyntax.ref && attrSyntax.ref.length;
}

/**
 * True if the given `value` match any of the syntax `opts`
 * matchOpt(objSyntax, 'type', 'url-list', editValue.type)
 * @param {Object} syntax - Object/Policy syntax
 * @param {String} attr
 * @param {String|String[]} optkey - option key(s)
 * @param {*} value - value to match
 * @returns {Boolean}
 */
function matchOpt(syntax, attr, optkey, value) {
  if (isArray(optkey)) {
    return optkey.some((key) => matchOpt(syntax, attr, key, value));
  }
  const optVal = syntax.attr[attr]?.opts?.[optkey];
  return isNil(optVal) ? false : optVal === value;
}

function setOpt(obj) {
  return (syntax, attr, optkeyToSet) => {
    const optVal = syntax.attr[attr]?.opts?.[optkeyToSet];
    return isNil(optVal) ? false : setIn(obj, attr, optVal);
  };
}

function findOptKeyByVal(syntax, attr, val) {
  const opts = syntax.attr[attr]?.opts || {};
  return Object.keys(opts).find((optkey) => opts[optkey] === val);
}

function isSeqnum(attrSyntax) {
  return attrSyntax.type === 'seqnum';
}

function isPortRange(attrSyntax) {
  return attrSyntax.type === 'port_range';
}

function isMetaSupportSelect(attrSyntax) {
  const maybeSelect =
    (isDSType(attrSyntax) && hasDSRef(attrSyntax)) ||
    isSingleSelect(attrSyntax) ||
    isMultiSelect(attrSyntax);
  const notMultiSelect = !isMultiStringType(attrSyntax);
  const notTFType = !isTFType(attrSyntax);
  const supportMeta = isMetaSupport(attrSyntax);
  const metaAddRef = isMetaAddRef(attrSyntax);
  return (
    maybeSelect && notMultiSelect && notTFType && supportMeta && metaAddRef
  );
}

function getOpts(syntaxAttrs, attr) {
  return get(syntaxAttrs, [attr, 'opts'], {});
}

function getOptValByKey(syntaxAttrs, attr, optKey) {
  return get(getOpts(syntaxAttrs, attr), optKey);
}

function createSyntaxOpts(
  syntaxAttrs,
  attrName,
  choicesText = {
    disable: gettext('Disable'),
    enable: gettext('Enable'),
  },
  _capitalize = true
) {
  if (!syntaxAttrs?.[attrName]?.opts) return [];
  return Object.entries(syntaxAttrs[attrName].opts).reduce(
    (acc, [key, val]) => {
      const choiceText =
        choicesText[key] ||
        (isString(key) && _capitalize ? capitalize(key) : key);
      if (isNumber(val)) {
        acc.push({ id: val, text: choiceText, key });
      } else {
        acc.push({ id: val.value, text: choiceText, key });
      }
      return acc;
    },
    []
  );
}

/**
 * Gets the default value defined in the given attribute's syntax
 * @return - undefined if the default value is not defined in the syntax
 */
function getDefaultValue(attrSyntax) {
  let defaultVal = attrSyntax.default;
  if (isSingleSelect(attrSyntax)) {
    defaultVal = attrSyntax.opts[defaultVal];
  }
  return defaultVal;
}

function getSyntaxUrl({
  adom = fiAdom.current(),
  category, // Optional. If omitted, will query for the whole adom syntax
}) {
  return `pm/config/${
    fiAdom.isGlobalAdom(adom) ? 'global' : 'adom/' + adom.name
  }/obj${category ? `/${category.replace(/ +/g, '/')}` : ''}`;
}

function getAttrInused(schema) {
  return Object.keys(schema.attr || {});
}

function getSubobjAttrInused(schema, subobjKey) {
  return Object.keys(
    schema[subobjKey]?.attr || schema[subobjKey]?.schema || {}
  );
}

function getAttrFilterFn({ schema, negate: negateFlag = false }) {
  const fn = negateFlag ? negate(identity) : identity;

  const attrInused = getAttrInused(schema);

  // for debugging
  // console.log('attrInused', attrInused);

  return ({ attr, subobjKey }) => {
    if (!subobjKey) {
      return fn(attrInused.includes(attr));
    }

    const subobjAttrInused = getSubobjAttrInused(schema, subobjKey);
    // for debugging
    // console.log('subobjAttrInused', subobjAttrInused);
    return fn(subobjAttrInused.includes(attr));
  };
}

function getInusedFilterFn(schema) {
  return getAttrFilterFn({ schema });
}

function getAdvOptsFilterFn(schema) {
  return getAttrFilterFn({ schema, negate: true });
}

function getMatchedAttrs({
  syntax,
  attrMatch = () => true,
  filterFn = () => true,
}) {
  const parentAttr = syntax.attr;
  const subobj = syntax.subobj || {};

  function getByAttrs({ attrs, subobjKey }) {
    return transform(
      attrs,
      (result, attrSyntax, attr) => {
        if (attrMatch(attrSyntax) && filterFn({ attr, subobjKey })) {
          result[attr] = attrSyntax;
        }
      },
      {}
    );
  }

  const matchedParentAttrs = getByAttrs({ attrs: parentAttr });

  const matchedSubobjAttrs = transform(
    subobj || {},
    (result, subobjSyntax, subobjKey) => {
      const subobjAttrs = subobjSyntax.attr;
      result[subobjKey] = getByAttrs({ attrs: subobjAttrs, subobjKey });
    },
    {}
  );

  return {
    attr: matchedParentAttrs,
    subobj: matchedSubobjAttrs,
  };
}

/**
 * Get all single/multiple select fields that support meta
 */
function getMetaSupportSelectFields({ syntax, filterFn }) {
  return getMatchedAttrs({
    syntax,
    attrMatch: isMetaSupportSelect,
    filterFn,
  });
}

const getSyntaxValue = (syntaxObj, prop, defaultVal = null) => {
  return get(syntaxObj, ['attr', prop], defaultVal);
};

/**
 * output reversed group-member relationship like
 *{
 *  'firewall address': ['firewall addrgrp']
 *}
 */
const genGrpMemberMap = (adomSyntax) => {
  const map = {};
  const getMembers = (objSyntax) => {
    const ref = get(objSyntax, 'attr.member.ref');
    if (!ref) {
      return;
    }
    return ref.map((r) => r.category);
  };
  const addToMap = (category, objSyntax) => {
    const members = getMembers(objSyntax);
    if (!members) {
      return;
    }
    members.forEach((member) => {
      map[member] = union(map[member], [category]);
    });
    return;
  };
  forEach(adomSyntax, (objSyntax, category) => addToMap(category, objSyntax));
  return map;
};
