import { fiFmgHttp, fiProxyHttp } from 'fi-http';
import { taskUtil, openTaskModal } from 'rc_task_monitor';
import { isArray, isString, isObject, isNumber } from 'lodash';
import { fiMessageBox } from 'fi-messagebox';
import { useDeferred } from 'rh_util_hooks';
import { FileTransferService } from 'fi-file';
import { useMemo } from 'react';
import { NwProHeader, NwProBody, NwProSection, NwProInputRow } from 'rc_layout';
import { FmkSwitch, FmkFooter } from 'rc_form';
import { Formik } from 'formik';
import { ProToolkit } from '@fafm/neowise-pro';

export const get = function () {
  return fiProxyHttp.get('/gui/adom/um/queryserver', 'get');
};
export const exec_export = function (targets, download_names, delta) {
  if (!isArray(targets)) targets = [targets];
  let cate = {};
  targets.forEach((t) => {
    if (isString(t)) {
      cate[t] = {};
    } else if (isObject(t)) {
      cate = { ...cate, ...t };
    }
  });
  let req = {
    id: 1,
    method: 'exec',
    params: [
      {
        data: {
          category: cate,
          flags: delta ? 'delta' : undefined,
        },
        url: 'um/object/export',
      },
    ],
  };
  fiFmgHttp.query(req).then((resp) => {
    let taskid, category;
    try {
      taskid = resp[0].data.taskid;
      category = resp[0].data.category || {};
    } catch (e) {
      fiMessageBox.show(gettext('Fail to start export task.', 'danger'));
      return;
    }
    openTaskModal({
      title: gettext('Export'),
      taskid: taskid,
    });
    //jquery deferred, not finally, use always instead
    taskUtil.wait4Task({ taskId: taskid }).promise.then(() => {
      let keys = Object.keys(category);

      function exec_download() {
        if (!keys.length) return;
        let prop = keys.pop();
        if (!category.hasOwnProperty(prop)) {
          setTimeout(exec_download);
          return;
        }
        let path = category[prop] && category[prop].file;
        if (!isString(path)) {
          setTimeout(exec_download);
          return;
        }
        let arr = path.split('/');
        let filename = arr[arr.length - 1];
        let downloadname = `${taskid}_${download_names[prop] || filename}`;
        if (filename) {
          downLoad(filename, downloadname);
        }
        setTimeout(exec_download, 300);
      }

      setTimeout(exec_download);
    });
  });
};
export const exec_import = function (targets, folder, checksums) {
  let req = {
    url: '/gui/adom/um/queryserver',
    method: 'import',
    params: {
      folder: folder || 'upload',
      file: targets,
      checksum: checksums || {},
    },
  };

  let deferred = useDeferred();
  fiFmgHttp.post(req).then((resp) => {
    let taskid;
    try {
      taskid = resp[0].data.taskid;
    } catch (e) {
      fiMessageBox.show(gettext('Fail to start import task.'), 'danger');
      return;
    }
    openTaskModal({
      title: gettext('Import Task'),
      taskid: taskid,
    });
    taskUtil.wait4Task({ taskId: taskid }).promise.then(
      () => {
        deferred.resolve();
      },
      () => {
        fiMessageBox.show(gettext('Fail to start import task.'), 'danger');
        deferred.reject();
      }
    );
  });

  return deferred.promise;
};
export const confirm = function (selected) {
  if (!isArray(selected)) selected = [];
  const content = gettext(
    '%s %s with %s of data will be compressed and downloaded. Are you sure to continue?'
  ).printf([
    selected.length,
    selected.length > 1 ? 'objects' : 'object',
    FileTransferService.bytesFormatter(
      selected.reduce((acum, curr) => {
        let ver_list = curr['version_list'];
        if (isObject(ver_list)) {
          let total = 0,
            ver = curr['prefer_ver'];
          if (!ver) ver = curr['latest_ver'];
          for (let prop in ver_list) {
            let obj = ver_list[prop] || {};
            if (obj.version === ver) {
              if (Number.isInteger(obj.size)) total = obj.size;
              break;
            }
          }
          acum += total;
        } else {
          let r_size = curr['raw_size'];
          if (isNumber(r_size)) acum += r_size;
        }
        return acum;
      }, 0)
    ),
  ]);
  const delta = selected.filter((s) => s.delta).length > 0;
  return ProToolkit.openModal(
    <ConfirmExport content={content} delta={delta} />,
    {
      className: 'np-toolkit-modal fi-pro-confirm-modal',
      size: 'md',
      height: 'fit-content',
    }
  ).result;
};

const ConfirmExport = ({ $opener, content, delta }) => {
  const getAutoId = (name) => 'confirm-export:' + name;

  const data = useMemo(() => ({ delta: false }));

  const handleSubmit = (values) => {
    $opener.resolve(values['delta']);
  };

  const close = () => {
    $opener.reject();
  };

  return (
    <Formik
      initialValues={data}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {() => (
        <>
          <NwProHeader>{gettext('Confirm')}</NwProHeader>
          <NwProBody>
            <NwProSection>
              <NwProInputRow>{content}</NwProInputRow>
              {delta ? (
                <NwProInputRow label='Download Delta Data'>
                  <FmkSwitch name='delta' automation-id={getAutoId('delta')} />
                </NwProInputRow>
              ) : null}
            </NwProSection>
          </NwProBody>
          <FmkFooter
            canWrite={true}
            getAutoId={() => getAutoId('buttons-')}
            onCancel={close}
          />
        </>
      )}
    </Formik>
  );
};

function downLoad(filename, downloadname) {
  FileTransferService.fileExport({
    url: '/gui/um/export',
    filename,
    downloadname,
  });
}
