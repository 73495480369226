import { useState } from 'react';
import { useReload } from './useReload';
import { useAbortEffect } from './useEffects';
import { isFunction } from 'lodash';

export type ApiRequestParams = {
  loader: (params: { abortCtrl: AbortController }) => Promise<any>;
  defaultValue?: any;
  breakEffect?: () => boolean;
  parser?: (resp: any) => any;
  dependencies?: any[];
  onError?: (e: any) => void;
  cleanupFn?: () => void;
  debug?: boolean;
};

export const useApiRequest = ({
  // required
  defaultValue,
  loader,

  // optional
  parser,
  breakEffect = () => false,
  dependencies = [],
  onError,
  cleanupFn,
  debug = false,
}: ApiRequestParams) => {
  const [state, setState] = useState(defaultValue);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reload, setReload] = useReload();

  useAbortEffect(
    (abortCtrl: AbortController) => {
      if (breakEffect()) return;

      const query = async () => {
        setIsLoading(true);

        try {
          let resp = await loader({ abortCtrl });
          if (isFunction(parser)) resp = parser(resp);
          setError(null);
          setState(resp);
        } catch (e: any) {
          if (debug) console.error(e);
          setError(e);
          setState(defaultValue);
          isFunction(onError) && onError(e);
        } finally {
          setIsLoading(false);
        }
      };

      query();
      return cleanupFn;
    },
    [reload, ...dependencies]
  );

  return {
    state,
    setState,
    reloadCount: reload,
    refresh: setReload as () => void,
    isLoading,
    error,
  };
};
