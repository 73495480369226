import { createSelector } from '@reduxjs/toolkit';
import { getAllApps } from '../../routing/selectors';

const myState = (state) => state.soc.customView;

const findApp = (allApps, uuid, isNoc) => {
  if (!uuid) {
    return;
  }
  return Object.values(allApps).find(
    (x) => x.isNoc === isNoc && x.stateParams?.dashId === uuid
  );
};

export const getCustomViews = createSelector(myState, (state) => state.data);

const genUniKey = (id) => `fortiview_custom_view_${id}`;

export const getCustomViewMenu = createSelector(
  getAllApps,
  myState,
  (allApps, state) => {
    return state.data.map((dash) => {
      const isFtv = dash['is-fv'];
      let originalApp = findApp(allApps, dash['created-from'], !isFtv);
      return {
        label: originalApp?.label
          ? `${dash.name} [${originalApp.label}]`
          : dash.name,
        state: `adom.default.soc.${isFtv ? 'fortiview' : 'noc'}.dash`,
        path: `/fortiview/${isFtv ? 'ftv' : 'noc'}/custom_view/${dash.uuid}`,
        stateParams: {
          appUniKey: genUniKey(dash.uuid),
          dashId: dash.uuid,
          isUser: true,
          isFtv,
        },
        showCheckers: originalApp?.showCheckers || [],
      };
    });
  }
);
