import { fiMessageBox, BoxType } from 'fi-messagebox';
import { get } from 'lodash';
import { getZonePath } from 'fi-datetime';
import { fiFmgHttp, fiHttp } from 'fi-web/fi-http';
import { copilotSelectors, fiSession, fiStore } from 'fistore';
import { copilotActions } from 'fistore/copilot/slice';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsSuperAdmin } from 'fistore/session/profile/selectors';
const CONCURRENT_LOGIN_ERR = -30014;

export const useLoginState = () => {
  const state = useSelector(copilotSelectors.getCopilotLogin);
  return state;
};

export const getHeaders = () => ({}); // no longer keeping token on client side

export const useCloudAccount = () => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const loginState = useLoginState();
  const isLoggedIn = !!loginState?.token;
  const loginError = loginState?.error;
  const hasAiLicense = useSelector(fiSession.getHasAiLicense);
  const isAiUser = useSelector(fiSession.getIsAiUser);
  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const [aiAdmins, setAiAdmins] = useState([]);
  const isAiUserChanged = useSelector(copilotSelectors.getIsAiUserChanged);

  const getCloudLoginStatus = useCallback(async () => {
    try {
      await fiHttp.get('/p/ai/version/');
      fiStore.dispatch(copilotActions.setLogin({ token: 1, fromCheck: true }));
      return true;
    } catch (ex) {
      //eslint-disable-line
      fiStore.dispatch(copilotActions.setLogin({ token: '', fromCheck: true }));
      return false;
    }
  }, []);
  const cloudLogin = useCallback(
    async (forced = false) => {
      if (forced || !(await getCloudLoginStatus())) {
        setIsLoggingIn(true);
        return fiHttp
          .post('/p/ai/login/', {
            timezone: getZonePath(),
          })
          .then(
            (resp) => {
              setIsLoggingIn(false);
              fiStore.dispatch(copilotActions.setLogin(resp.data));
              return resp;
            },
            (err) => {
              setIsLoggingIn(false);
              if (
                !hasAiLicense &&
                err.response?.data?.error_code !== CONCURRENT_LOGIN_ERR
              ) {
                console.error(
                  'longin failed, and error display is skipped because we do not have license'
                );
                return;
              }
              let msg =
                err.response?.data?.error_desc ||
                err.response?.data?.detail ||
                err.response?.data?.message ||
                '' + err;
              fiStore.dispatch(copilotActions.setLogin({ error: msg }));
              fiMessageBox.show(
                // content
                <>
                  {/*<p>{gettext('FortiAI Error')}</p>*/}
                  <p>{gettext('Failed to authenticate.')}</p>
                  <p>{msg}</p>
                </>,
                BoxType.danger, // type
                null // timeoutMs
              );
              throw err;
            }
          );
      }
    },
    [getCloudLoginStatus]
  );
  const cloudLogout = useCallback(async () => {
    fiHttp.post('/p/ai/logout/', {});
    fiStore.dispatch(copilotActions.setLogin(null));
  }, []);

  useEffect(() => {
    fiFmgHttp
      .forward({
        method: 'get',
        params: [
          {
            url: '/cli/global/system/admin/user',
          },
        ],
      })
      .then((resp) => {
        const users = get(resp, '0.data', []);
        setAiAdmins(users.filter((user) => user.fortiai));
      });
  }, [isAiUserChanged]);

  const getFortiAiPermission = () => {
    let message;
    let access = false;
    let canEnable = false;

    if ((hasAiLicense || !!MACROS.SYS.CONFIG_DEBUG) && isAiUser) {
      access = true; // true means the user CAN use the AI feature, not necessary login or token
      message = 'Hi, how can I help you?';
      canEnable;
    } else if (!hasAiLicense) {
      message = gettext(
        'Thank you for your interest in FortiAI. To start using FortiAI features, please purchase a license through FortiCare.'
      );
    } else if (!isAiUser) {
      if (isSuperAdmin && aiAdmins.length < MACROS.USER.SYS.FORTIAI_MAX_USER) {
        canEnable = true;
        message = gettext(
          'Thank you for your interest in FortiAI. To access this feature, you need to be a FortiAI user. Would you like to enable yourself as a FortiAI user?'
        );
      } else if (aiAdmins.length < MACROS.USER.SYS.FORTIAI_MAX_USER) {
        message = gettext(
          'Thank you for your interest in FortiAI. To use this feature, you need to be a FortiAI user. Please contact a Super_User Administrator to enable FortiAI for you.'
        );
      } else {
        message = gettext(
          'Thank you for your interest in FortiAI. However, you have reached the limit of %s FortiAI users.'
        ).printf([MACROS.USER.SYS.FORTIAI_MAX_USER]);
      }
    }

    return {
      message,
      access,
      canEnable,
    };
  };

  return {
    getCloudLoginStatus,
    cloudLogin,
    cloudLogout,
    isLoggedIn,
    isLoggingIn,
    loginError,
    getFortiAiPermission,
  };
};
