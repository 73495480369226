import {
  fiStoreObserverBase,
  fiStoreClassConnector,
  fiWorkspaceRequest,
  fiWorkspaceDataSelector,
} from 'fistore';
import { _currentAdom } from './util';
import { getAdomLockInfo } from './adomlock';
import { getAllPkgLockInfo, getPkgLockInfo } from './pkglock';
import { getAllPolicyLockInfo, getPolicyLockInfo } from './policylock';
import { getAllObjLockInfo, getObjLockInfo } from './objectlock';
import { getAllDevLockInfo, getDevLockInfo } from './devicelock';

class fullWorkspace extends fiStoreObserverBase {
  constructor(props) {
    super(props);
    this.previous = {};
  }
  // eslint-disable-next-line
  update(preProps) {
    this.previous = preProps;
  }

  initAdom() {
    return this.props.initAdom();
  }
  initPackage(adom) {
    return this.props.initPackage(adom);
  }
  initPolicy(cate, pkg, adom) {
    return this.props.initPolicy(cate, pkg, adom);
  }
  initObject(cates, adom) {
    return this.props.initObject(cates, adom);
  }
  initDevice(adom) {
    return this.props.initDevice(adom);
  }
  adomLock(adom) {
    return this.props.adomLock(adom);
  }
  adomUnlock(adom, unlock_dirty, unlock_other) {
    return this.props.adomUnlock(adom, unlock_dirty, unlock_other);
  }
  adomSave(adom) {
    return this.props.adomSave(adom);
  }
  saveAll(save_n_unlock) {
    return this.props.saveAll(save_n_unlock);
  }
  unlockAll(unlock_dirty, unlock_other) {
    return this.props.unlockAll(unlock_dirty, unlock_other);
  }
  pkgLock(pkg, adom) {
    return this.props.pkgLock(pkg, adom);
  }
  pkgUnlock(pkg, unlock_dirty, unlock_other, adom) {
    return this.props.pkgUnlock(pkg, unlock_dirty, unlock_other, adom);
  }
  pkgSave(pkg, adom) {
    return this.props.pkgSave(pkg, adom);
  }
  policyUnlock(cate, pkg, adom) {
    return this.props.policyUnlock(cate, pkg, adom);
  }
  objLock(objsByCate, adom) {
    return this.props.objLock(objsByCate, adom);
  }
  objUnlock(objsByCate, unlock_dirty, unlock_other, adom) {
    return this.props.objUnlock(objsByCate, unlock_dirty, unlock_other, adom);
  }
  devLock(dev, adom) {
    return this.props.devLock(dev, adom);
  }
  devUnlock(dev, unlock_dirty, unlock_other, adom) {
    return this.props.devUnlock(dev, unlock_dirty, unlock_other, adom);
  }
  devUnlockMultiple(dev, adom) {
    return this.props.devUnlockMultiple(dev, adom);
  }
  devSave(dev, save_n_unlock, adom) {
    return this.props.devSave(dev, save_n_unlock, adom);
  }
  devSaveMultiple(dev, adom) {
    return this.props.devSaveMultiple(dev, adom);
  }
  check(adom) {
    return this.props.check(adom);
  }
}

export const wsMapDispatchToProps = (dispatch) => {
  return {
    initAdom: () => {
      return fiWorkspaceRequest.load_workspace_adoms(dispatch);
    },
    initPackage: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.load_workspace_pkgs(dispatch, adom.oid || adom);
    },
    initPolicy: (cate, pkg, adom = _currentAdom()) => {
      return fiWorkspaceRequest.load_workspace_policies(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg,
        cate
      );
    },
    initObject: (cates, adom = _currentAdom()) => {
      cates = Array.isArray(cates) ? cates : [cates];
      cates.forEach((it) => {
        fiWorkspaceRequest.load_workspace_objects(
          dispatch,
          adom.oid || adom,
          it
        );
      });
    },
    initDevice: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.load_workspace_devices(
        dispatch,
        adom.oid || adom
      );
    },
    adomLock: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.lock_workspace_adom(dispatch, adom.oid || adom);
    },
    adomUnlock: (
      adom = _currentAdom(),
      unlock_dirty = false,
      unlock_other = false
    ) => {
      return fiWorkspaceRequest.unlock_workspace_adom(
        dispatch,
        adom.oid || adom,
        unlock_dirty,
        unlock_other
      );
    },
    adomSave: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.save_workspace_adom(dispatch, adom.oid || adom);
    },
    saveAll: (save_n_unlock) => {
      return fiWorkspaceRequest.save_workspace_adoms(dispatch, save_n_unlock);
    },
    unlockAll: (unlock_dirty, unlock_other) => {
      return fiWorkspaceRequest.unlock_workspace_adoms(
        dispatch,
        unlock_dirty,
        unlock_other
      );
    },
    pkgLock: (pkg, adom = _currentAdom()) => {
      return fiWorkspaceRequest.lock_workspace_pkg(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg
      );
    },
    pkgUnlock: (
      pkg,
      unlock_dirty = false,
      unlock_other = false,
      adom = _currentAdom()
    ) => {
      return fiWorkspaceRequest.unlock_workspace_pkg(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg,
        unlock_dirty,
        unlock_other
      );
    },
    pkgSave: (pkg, adom = _currentAdom()) => {
      return fiWorkspaceRequest.save_workspace_pkg(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg
      );
    },
    policyUnlock: (cate, pkg, adom = _currentAdom()) => {
      return fiWorkspaceRequest.unlock_workspace_pkg_cate(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg,
        cate
      );
    },
    objLock: (objsByCate, adom = _currentAdom()) => {
      let proms = [];
      Object.keys(objsByCate).forEach((it) => {
        proms.push(
          fiWorkspaceRequest.lock_workspace_objects(
            dispatch,
            adom.oid || adom,
            it,
            objsByCate[it]
          )
        );
      });
      return Promise.all(proms);
    },
    objUnlock: (
      objsByCate,
      unlock_dirty,
      unlock_other,
      adom = _currentAdom()
    ) => {
      let proms = [];
      Object.keys(objsByCate).forEach((it) => {
        proms.push(
          fiWorkspaceRequest.unlock_workspace_objects(
            dispatch,
            adom.oid || adom,
            it,
            objsByCate[it],
            unlock_dirty,
            unlock_other
          )
        );
      });
      return Promise.all(proms);
    },
    devLock: (dev, adom = _currentAdom()) => {
      return fiWorkspaceRequest.lock_workspace_device(
        dispatch,
        adom.oid || adom,
        dev.oid || dev
      );
    },
    devUnlockMultiple: (dev = [], adom = _currentAdom()) => {
      // array of {key:dev_oid}, not dev object / oid
      return fiWorkspaceRequest.unlock_workspace_devices(
        dispatch,
        adom.oid || adom,
        dev
      );
    },
    devUnlock: (
      dev,
      unlock_dirty = false,
      unlock_other = false,
      adom = _currentAdom()
    ) => {
      return fiWorkspaceRequest.unlock_workspace_device(
        dispatch,
        adom.oid || adom,
        dev.oid || dev,
        unlock_dirty,
        unlock_other
      );
    },
    devSaveMultiple: (dev = [], adom = _currentAdom()) => {
      // array of {key:dev_oid}, not dev object / oid
      return fiWorkspaceRequest.save_workspace_devices(
        dispatch,
        adom.oid || adom,
        dev
      );
    },
    devSave: (dev, save_n_unlock = false, adom = _currentAdom()) => {
      let proms = [];
      dev = Array.isArray(dev) ? dev : dev ? [dev] : [];
      dev.forEach((it) => {
        proms.push(
          fiWorkspaceRequest.save_workspace_device(
            dispatch,
            adom.oid || adom,
            it.oid || it,
            save_n_unlock
          )
        );
      });
      return Promise.all(proms);
    },
    check: (adom = _currentAdom()) => {
      return fiWorkspaceRequest.get_lockers_in_workspace_adom(
        dispatch,
        adom.oid || adom
      );
    },
    highlightSaveBtn: (tof) => {
      return fiWorkspaceRequest.highlight_save_btn(tof);
    },
  };
};

export const wsMapStateToProps = (state) => {
  // do not put inline functions if may cause shadow compare fail?
  return {
    // properties
    // better not use it directly and no modify on it, otherwise need a copy
    adoms: fiWorkspaceDataSelector.get_workspace_adoms(state),
    pkgs: fiWorkspaceDataSelector.get_workspace_pkgs(state),
    pkgChildrenStatus:
      fiWorkspaceDataSelector.get_workspace_pkgs_children_status(state),
    policies: fiWorkspaceDataSelector.get_workspace_policies(state),
    objs: fiWorkspaceDataSelector.get_workspace_objs(state),
    devs: fiWorkspaceDataSelector.get_workspace_devs(state),
    savebtn: fiWorkspaceDataSelector.get_workspace_save_btn(state),

    // operations
    adomInfo: getAdomLockInfo,
    allPkgInfo: getAllPkgLockInfo,
    pkgInfo: getPkgLockInfo,
    policyInfo: getPolicyLockInfo,
    allPolicyInfo: getAllPolicyLockInfo,
    objInfo: getObjLockInfo,
    allObjInfo: getAllObjLockInfo,
    allDevInfo: getAllDevLockInfo,
    devInfo: getDevLockInfo,
  };
};

export const wsConnector = fiStoreClassConnector(
  wsMapStateToProps,
  wsMapDispatchToProps
)(fullWorkspace);
