import {
  getValidNetMask,
  validIp4,
  validIp6,
  validIp6Net,
  validIPRange,
} from 'fiutil/ip_util';
import {
  SyntaxAttribute,
  SyntaxAttributeTypes,
  TypeValidationFunction,
} from '../types';
import { isNumber, isString, isUndefined } from 'lodash';
import { RegExpCommon } from 'kit/kit-regexp';
import { isValidDate } from 'react_components/rc_time_selector';

const VALIDATE_ERRORS = {
  IPV4NETMASK: 'Invalid IPv4 netmask address. Format: x.x.x.x y.y.y.y.',
  IPV4NETMASK_2:
    'Invalid IPv4 netmask address. Format: x.x.x.x y.y.y.y or x.x.x.x/yy.',
  IPV4NETMASK_3:
    'Invalid IPv4 netmask address. Format: x.x.x.x y.y.y.y or x.x.x.x/yy or x.x.x.x/y.y.y.y.',
  IPV4: 'Invalid IPv4 address. Format: x.x.x.x.',
  IPV6: 'Invalid IPv6 address.',

  IP6_OPTIONAL_NETMASK: 'Invalid IPv6 address. Subnet optional.',

  NO_ARGS: 'Requires at least one argument.',
  TIMEDATE: 'Invalid timedate. Format: hh:mm yyyy/mm/dd.',
  DATETIME: 'Invalid timedate. Format: yyyy/mm/dd hh:mm:ss.',
};

enum IP_TYPE {
  'IPv4' = 'IPv4',
  'IPv6' = 'IPv6',
}

export const defaultValidate = (
  attribute: SyntaxAttribute,
  values: string[]
) => {
  if (!values.length) return 'At least one argument needed.';
};

const validateSingleString = (str: string, max?: number) => {
  let maxLength = Number.MAX_SAFE_INTEGER;
  if (max) {
    maxLength = max;
  }

  if (str.includes('"')) return 'Improper quotations.';

  if (str.length > maxLength)
    return `String too long, maximum ${maxLength} characters.`;

  return '';
};

//returns string if error, string[] if successfully split
const _splitIp = (
  values: string[],
  errMsg: string,
  allowNoSplit?: boolean
): string | string[] => {
  let _values: string[] = [];
  if (values.length === 1) {
    if (values[0].split('/').length === 2) _values = values[0].split('/');
  } else if (values.length === 2) _values = values;
  else {
    //values.length >= 3
    return errMsg;
  }
  if (_values.length !== 2 && !allowNoSplit) return errMsg;

  return _values;
};

const validateNumberMinMax = (attribute: SyntaxAttribute, input: string) => {
  const num = parseInt(input);
  const max = attribute.max;
  const min = attribute.min;
  if (!isInteger(input)) return 'Should be a number.';
  if (isNumber(min)) {
    if (num < min) return `Must be at least ${min}`;
  } else if (num < 0) return 'Must be at least 0';
  if (isNumber(max) && num > max) return `Must be less than ${max}.`;
};

//check if more than max_argv arguments
//if max_argv >= 1 then we check
//if max argv undefined, usually it is unlimited, in some cases max one
const validateMaxArgv = (
  attribute: SyntaxAttribute,
  values: string[],
  errorTemplate: string,
  defaultMaxOne?: boolean
) => {
  const maxArgs = attribute.max_argv;
  if (maxArgs && maxArgs > 0) {
    if (maxArgs && values.length > maxArgs)
      return errorTemplate.replace('%maxargs', `${maxArgs}`);
  }

  if (isUndefined(maxArgs) && defaultMaxOne && values.length > 1)
    return errorTemplate.replace('%maxargs', 'one');
};

const validateInt = (attribute: SyntaxAttribute, values: string[]) => {
  if (!values.length) return VALIDATE_ERRORS.NO_ARGS;
  const validArgvMsg = validateMaxArgv(
    attribute,
    values,
    'Up to %maxargs numbers.',
    true
  );
  if (validArgvMsg) return validArgvMsg;
  for (const numStr of values) {
    return validateNumberMinMax(attribute, numStr);
  }
};

const validatePort = (attribute: SyntaxAttribute, portString: string) => {
  const port = parseInt(portString);
  if (!isInteger(portString)) return 'Port should be a number.';
  if (port < 0) return 'Port should be at least 0 (any).';
  if (port > 65535) return 'Port should be at most 65535.';
};

//values should be only a single port range (to handle multi_dstsrc_port_range)
//rangeIndex is for multi_dstsrc_port_range
const validatePortRange = (
  attribute: SyntaxAttribute,
  values: string[],
  rangeIndex: number
) => {
  if (!values.length) return 'Need at least one value.';
  for (const range of values) {
    if (!range) return;
    const ports = range.split('-');
    if (ports.length === 1) return validatePort(attribute, range);
    if (ports.length !== 2)
      return 'Improper port range (Format: <start>-<end>).';
    const validPort1 = validatePort(attribute, ports[0]);
    const validPort2 = validatePort(attribute, ports[1]);
    if (validPort1)
      return `Range ${rangeIndex} start port is invalid: ${validPort1}.`;
    if (validPort2)
      return `Range ${rangeIndex} end port is invalid: ${validPort2}.`;
    if (parseInt(ports[0]) >= parseInt(ports[1]))
      return 'Start port must be less than end port.';
    if (parseInt(ports[0]) === 0) return 'Cannot use range if start is 0';
  }
};

const validateMultiIp = (
  attribute: SyntaxAttribute,
  values: string[],
  type: IP_TYPE
) => {
  if (!values.length) return `Requires at least one ${type} address`;
  const upToMaxargvMsg = validateMaxArgv(
    attribute,
    values,
    `At most %maxargs ${type} addresses`,
    true
  );
  if (upToMaxargvMsg) return upToMaxargvMsg;
  for (const ip of values) {
    if (
      (type === IP_TYPE.IPv6 && !validIp6(ip)) ||
      (type === IP_TYPE.IPv4 && !validIp4(ip))
    )
      return `Invalid ${type} address ${ip}`;
  }
};

const validateMacAddress = (input: string) => {
  const pattern = RegExpCommon.MAC_ADDRESS;
  if (input && !pattern.test(input)) {
    return gettext('Invalid MAC address.');
  }
};

function isInteger(n: any) {
  return !isNaN(parseInt(n)) && isFinite(n);
}

const validHHMMstring = (str: string) =>
  /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);

const validHHMMSSstring = (str: string) =>
  /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/.test(str);

const validYYYYMMDDString = (str: string) => {
  const segments = str.split('/');
  if (segments.length !== 3) return false;
  if (!segments.every((seg: string) => !!seg.length)) return false;
  return true;
};

export const validationFunctions: Record<
  SyntaxAttributeTypes,
  TypeValidationFunction
> = {
  //can't validate these
  multi_str: defaultValidate,
  user: defaultValidate,
  startend_datetime: defaultValidate,
  datasrc: defaultValidate,
  array: defaultValidate,
  hbdev: defaultValidate,
  multi_ipmaskv4: defaultValidate, //skip for now only one instance, too ambiguous

  string: (attribute, values) => {
    if (!values.length)
      return 'The setting type string must have some value. Use "" for empty string.';
    if (values.length > 1)
      return 'The setting type string must have one value. Use "" to wrap a value with spaces.';

    return validateSingleString(values[0], attribute.max);
  },
  seqnum: (attribute, values) => {
    if (values.length > 1) return 'Too many values.';
    return validateNumberMinMax(attribute, values[0]);
  },
  password: (attribute, values) => {
    if (!values.length) return 'Must set a password or remove this line.';
  },

  //ipv4 and ipv6
  ipv4_mask: (attribute, values) => {
    //allow x.x.x.x x.x.x.x, x.x.x.x/xx, x.x.x.x/x.x.x.x
    const _valuesOrMessage = _splitIp(values, VALIDATE_ERRORS.IPV4NETMASK_3);
    if (isString(_valuesOrMessage)) return _valuesOrMessage;
    if (!validIp4(_valuesOrMessage[0])) return VALIDATE_ERRORS.IPV4NETMASK_3;
    //in case x.x.x.x 32
    if (values.length === 2 && isInteger(values[1]))
      return VALIDATE_ERRORS.IPV4NETMASK_3;
    if (!getValidNetMask(_valuesOrMessage[1]))
      return VALIDATE_ERRORS.IPV4NETMASK_3;
  },
  ip4: (attribute, values) => {
    //only x.x.x.x
    if (!validIp4(values[0])) return 'Invalid IPv4 address.';
    if (values.length > 1) return 'Too many values.';
  },
  ip6prefix: (attribute, values) => {
    //only ip6/prefix e.g. ::/128
    const _valuesOrMessage = _splitIp(
      values,
      VALIDATE_ERRORS.IP6_OPTIONAL_NETMASK,
      true
    );
    if (isString(_valuesOrMessage)) return _valuesOrMessage;
    if (!validIp6Net(values[0])) return 'Invalid IPv6 address.';
    if (values.length > 1) return 'Too many values.';
  },
  ip6addr: (attribute, values) => {
    if (!validIp6(values[0])) return 'Invalid IPv6 address.';
    if (values.length > 1) return 'Too many values.';
  },
  ip4addrmask2: (attribute, values) => {
    //can be 1.1.1.1/32 or 1.1.1.1 255.255.255.255 NOT x.x.x.x/y.y.y.y
    if (!values.length) return 'At least one argument.';
    if (values.length > 2) return VALIDATE_ERRORS.IPV4NETMASK_2;
    if (values.length === 2) {
      //ip <space> netmask
      if (!validIp4(values[0])) return VALIDATE_ERRORS.IPV4NETMASK_2;
      if (isInteger(values[1])) return VALIDATE_ERRORS.IPV4NETMASK_2;
      if (!getValidNetMask(values[1])) return VALIDATE_ERRORS.IPV4NETMASK;
      return;
    }
    //length == 1
    //could be x.x.x.x/32 but do not allow x.x.x.x/y.y.y.y
    const _values = values[0].split('/');
    if (_values.length > 2) return VALIDATE_ERRORS.IPV4NETMASK_2;
    if (!validIp4(_values[0])) return VALIDATE_ERRORS.IPV4NETMASK_2;
    if (!isInteger(_values[1])) return VALIDATE_ERRORS.IPV4NETMASK_2;
    if (!getValidNetMask(_values[1])) return VALIDATE_ERRORS.IPV4NETMASK_2;
  },
  ip4mask: (attribute, values) => {
    //only the netmask
    if (!values.length) return 'Requires netmask (format: x.x.x.x).';
    if (isInteger(values[0])) return 'Invalid netmask. Format: x.x.x.x.';
    if (!getValidNetMask(values[0])) return 'Invalid netmask. Format: x.x.x.x.';
  },

  multi_ipv4: (attribute, values) => {
    return validateMultiIp(attribute, values, IP_TYPE.IPv4);
  },
  multi_ipv6: (attribute, values) => {
    return validateMultiIp(attribute, values, IP_TYPE.IPv6);
  },

  //ip range
  ipv4range: (attribute, values) => {
    //single ipv4 addr or range <start>-<end>
    if (!values) return 'Single IPv4 address or range <start>-<end>.';
    const _values = values[0].split('-');
    if (_values.length === 1) {
      //validate single addr
      if (!validIp4(_values[0])) return VALIDATE_ERRORS.IPV4;
      return;
    }
    //range, start should be lower than end
    if (_values.length !== 2) return 'Invalid IPv4 range.';
    const validIp1 = validIp4(_values[0]);
    const validIp2 = validIp4(_values[1]);
    if (!validIp1) return 'Invalid start IPv4 address.';
    if (!validIp2) return 'Invalid end IPv4 address.';
    if (!validIPRange(_values[0], _values[1])) return 'Invalid IPv4 range.';
  },
  ipv6range: (attribute, values) => {
    //single ipv6 addr or range <start>-<end>
    if (!values) return 'Single IPv6 address or range <start>-<end>.';
    const _values = values[0].split('-');
    if (_values.length === 1) {
      //validate single addr
      if (!validIp6(_values[0])) return VALIDATE_ERRORS.IPV4;
      return;
    }
    //range, start should be lower than end
    if (_values.length !== 2) return 'Invalid IPv6 range.';
    const validIp1 = validIp6(_values[0]);
    const validIp2 = validIp6(_values[1]);
    if (!validIp1) return 'Invalid start IPv6 address.';
    if (!validIp2) return 'Invalid end IPv6 address.';
    if (!validIPRange(_values[0], _values[1])) return 'Invalid IPv6 range.';
  },

  macaddr: (attribute, values) => {
    const maxargs = validateMaxArgv(
      attribute,
      values,
      'Up to %maxargs MAC addresses',
      true
    );
    if (maxargs) return maxargs;
    if (!validateMacAddress(values[0])) return 'Invalid MAC address.';
  },

  //number types
  //could be opts, but opts get validated before this
  uint8: validateInt,
  uint64: validateInt,
  uint16: validateInt,
  uint32: validateInt,
  int8: validateInt,

  //multi args
  multi_int: (attribute, values) => {
    if (!values.length) return 'Need at least one number.';
    if (values.some((value: string) => !isInteger(value)))
      return 'All values should be numbers for multi_int type.';
    return validateMaxArgv(attribute, values, 'At most %maxargs numbers.');
  },
  opt_array: (attribute, values) => {
    if (!values.length) return 'Need at least one setting.';
    return validateMaxArgv(attribute, values, 'At most %maxargs options.');
  },

  //port range and multi port range
  port_range: (attribute, values) => {
    if (!values.length)
      return 'Single port or port range (<port> or <start>-<end>).';
    const maxArgs = validateMaxArgv(
      attribute,
      values,
      'At most %maxargs port ranges.',
      true
    );
    if (maxArgs) return maxArgs;
    return validatePortRange(attribute, values, 1);
  },
  multi_dstsrc_port_range: (attribute, values) => {
    const invalidRangeMsg =
      'Invalid port range. (Format: <dstport_low>[-<dstport_high>:<srcport_low>-<srcport_high>])';
    if (!values.length)
      return '<dstport_low>[-<dstport_high>:<srcport_low>-<srcport_high>].';
    const maxArgs = attribute.max_argv;
    if (maxArgs && values.length > maxArgs)
      return `At most ${maxArgs} port ranges.`;
    let idx = 1;
    const ranges = values.map((val) => val.split(':'));
    let msg: string | undefined = '';
    for (const range of ranges) {
      if (range.length > 2) return invalidRangeMsg;
      for (const part of range) {
        if (!part) return invalidRangeMsg;
      }
      msg = validatePortRange(attribute, [range[0], range[1]], idx++);
    }
    return msg;
  },

  uint16_range: (attribute, values) => {
    if (values.length > 1) return 'Requires port range. Format: <start>-<end>.';
    return validatePortRange(attribute, values, 1);
  },

  time: (attribute, values) => {
    if (values.length > 1) return 'Only one time allowed. Format: hh:mm.';
    if (!validHHMMstring(values[0])) return 'Invalid time. Format: hh:mm.';
  },
  timedate: (attribute, values) => {
    //timedate and datetime essentially reversed
    if (values.length !== 2) return VALIDATE_ERRORS.TIMEDATE;
    if (!validHHMMstring(values[0])) return VALIDATE_ERRORS.TIMEDATE;
    if (!validYYYYMMDDString(values[1])) return VALIDATE_ERRORS.TIMEDATE;
    if (!isValidDate(values.join(' '))) return VALIDATE_ERRORS.TIMEDATE;
  },
  date_time: (attribute, values) => {
    if (values.length !== 2) return VALIDATE_ERRORS.DATETIME;
    if (!validHHMMSSstring(values[1])) return VALIDATE_ERRORS.DATETIME;
    if (!validYYYYMMDDString(values[0])) return VALIDATE_ERRORS.TIMEDATE;
    if (!isValidDate(values.join(' '))) return VALIDATE_ERRORS.DATETIME;
  },
};
