import { fiAdminProfile } from 'fi-session';
import { parser as parseLogdbRebuilding } from '../logdbRebuildingParser';
import { openModal as openLogdbRebuilding } from '../LogdbRebuildingProgress';
import { dispatch } from 'fistore';
import { go } from 'fistore/routing/slice';
import { pnoGo } from 'fistore/pno/slice';

export default {
  task_monitor: {
    exec: (/* params */) => {
      if (fiAdminProfile.isRestrictedAdmin()) {
        dispatch(pnoGo({ appUniKey: 'rstadm_taskmonitor' }));
      } else {
        dispatch(go({ to: '/sys/taskmonitor' }));
      }
    },
  },
  log_fetcher_req_running: {
    exec: () => dispatch(go({ to: '/sys/logfetch' })),
  },
  log_fetcher_running: {
    exec: () => dispatch(go({ to: '/sys/logfetch' })),
  },
  task_faz: {
    exec: (/*logdb*/) => {
      openLogdbRebuilding(/*()=>parseLogdbRebuilding(logdb.params)*/);
    },
  },
  log_db_rebuild: {
    exec: (logdb) => {
      openLogdbRebuilding(() => parseLogdbRebuilding(logdb.params));
    },
  },
};
