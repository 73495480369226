import { fiDvmListViewType } from '../constants';

export { selectedManagedView, getCurrentConfigState };

let _managedView = fiDvmListViewType.MANAGED_TABLE_VIEW;

const selectedManagedView = (viewType) => {
  if (viewType) {
    _managedView = viewType;
  }
  return _managedView;
};

const getCurrentConfigState = (suffix = '') => {
  let prefix =
    _managedView === fiDvmListViewType.MANAGED_FOLDER_VIEW
      ? 'adom.default.dvm.groups.folder'
      : 'adom.default.dvm.main.groups';
  return prefix + '.' + suffix;
};
