import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fiFmgHttp } from 'fi-http';
import {
  showAlertPanel,
  showDisclaimer,
} from 'react_apps/ra-auth/modals/alert';
import { getLoginEnv } from 'fistore/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginEnv, initCSRF } from 'fistore/auth/slice';
import { setLocalStorage } from 'fiutil/storage';
import { SELECT_ADOM_REQUIRED_KEY } from 'fistore/routing/slice';
import { useAuth } from './useAuth';
import { Spinner } from './Spinner';
import { get } from 'lodash';

const isRedirectUrlValid = (url) => {
  // Check if a redirection url is allowed.
  //
  // This is stricter(no domain name, no relative urls) because we want to
  // prevent open redirection attacks(Also need to avoid '//' which is
  // protocol relative urls).
  return url && url[0] === '/' && url[1] !== '/';
};

const getHAAlert = async () => {
  try {
    let reqObj = {
      method: 'check',
      url: '/gui/sys/ha',
      params: {},
    };

    const resp = await fiFmgHttp.post(reqObj);
    const prod = get(resp, '0.data', {});
    const alertMe = prod.alertme;
    if (alertMe > 0) {
      await showAlertPanel(prod.msg);
    }
  } catch {
    // pass
  }
};

export function PostLogin() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const loginEnv = useSelector(getLoginEnv);
  const dispatch = useDispatch();
  const auth = useAuth();
  const isPostDisclaimerShown = useRef(false);

  const goRoot = useCallback(async () => {
    await getHAAlert();
    // After the post login work is done, no need to come back again.
    // Use localStorage for sharing information cross tabs.
    setLocalStorage('justLogin', false);
    setLocalStorage(SELECT_ADOM_REQUIRED_KEY, true);
    const next = params.get('next');
    // use history state to separate login from refresh
    navigate(isRedirectUrlValid(next) ? next : '/');
  }, [params]);

  useEffect(() => {
    (async () => {
      // in case user refreshes the page
      if (!loginEnv) {
        return dispatch(fetchLoginEnv());
      }
      dispatch(initCSRF());

      if (loginEnv.post_login_banner_message) {
        if (isPostDisclaimerShown.current) return;
        isPostDisclaimerShown.current = true;
        try {
          await showDisclaimer({
            title: gettext('Post-login Disclaimer'),
            message: loginEnv.post_login_banner_message,
          });
          goRoot();
        } catch {
          auth.logout();
        }
        return;
      }
      goRoot();
    })();
  }, [loginEnv]);

  return (
    <div className='tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full'>
      <Spinner />
    </div>
  );
}
