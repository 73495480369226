import { fiFmgHttp } from 'fi-http';
import { get, isFunction } from 'lodash';

let _pnoCached = {};

export {
  dvmGlobalDisplayOpts,
  dvmGuiOpts,
  pnoDisplayOpts,
  pnoDisplayOptsCache, // Make sure display opts async is finished before you call this!
  getValueTrueItems,
  updateMenuItemStatus,
  // admin setting in GUI DB
  withEscapedAdminName,
  deleteAdminCustomize,
  getAdminCustomize,
  makeUpdateAdminCustomize,
};

// ================ function definitions ========================
function _request(url, method = 'get', params = {}) {
  return new Promise(function (resolve, reject) {
    fiFmgHttp
      .post({
        url: url,
        method: method,
        params: params,
      })
      .then(
        function (resp) {
          let res = resp[0];
          res = res.data || {};
          resolve(res);
        },
        function (err) {
          reject(err);
        }
      );
  });
}

function pnoDisplayOptsCache(adomOid) {
  return _pnoCached[adomOid];
}

// getter/setter for display option of device at adom level
// if has opt, it will write opts to server side
function dvmGlobalDisplayOpts(adom, opts) {
  let url = 'gui/adoms/%s/customize'.printf([adom.oid || adom]);

  if (opts) {
    return _request(url, 'update', {
      dvmCustomize: opts,
    });
  }

  return _request(url).then(
    (resp) => {
      return resp.dvmCustomize || [];
    },
    (err) => err
  );
}

// getter/setter for display option of device & vdom level
// if has opt, it will write opts to server side
function dvmGuiOpts(adom, device, vdom, opts) {
  let url = '';
  if (vdom && parseInt(vdom) !== MACROS.DVM.CDB_DEFAULT_GLOBAL_OID) {
    url = '/gui/adoms/%s/devices/%s/vdoms/%s/customize'.printf([
      adom?.oid || adom,
      device.oid || device,
      vdom.oid || vdom,
    ]);
  } else {
    url = '/gui/adoms/%s/devices/%s/customize'.printf([
      adom?.oid || adom,
      device.oid || device,
    ]);
  }

  if (opts) {
    return _request(url).then((resp) => {
      return _request(url, 'update', {
        customize: {
          ...resp.customize,
          ...opts,
        },
      });
    });
  }

  return _request(url).then(
    (resp) => {
      return Array.isArray(resp.customize)
        ? { displayOpts: resp.customize }
        : resp.customize || {};
    },
    (err) => err
  );
}

// getter/setter for display option of policy&objects at adom level
// if has opt, it will write opts to server side
function pnoDisplayOpts(adom, opts) {
  const adomOid = adom.oid || adom;
  let url = '/gui/adoms/%s/customize'.printf([adomOid]);

  if (opts) {
    delete _pnoCached[adomOid];
    return _request(url, 'update', {
      pnoCustomize: opts,
    });
  }

  if (_pnoCached[adomOid]) {
    return Promise.resolve(_pnoCached[adomOid]);
  }

  return _request(url).then(
    (resp) => {
      const disp = resp.pnoCustomize || [];
      _pnoCached[adomOid] = disp;
      return disp;
    },
    (err) => err
  );
}

/*********************/

function getValueTrueItems(devItem, arrResult, fnFilter) {
  if (devItem) {
    if (isFunction(fnFilter) && fnFilter(devItem)) {
      arrResult.push(devItem.id);
    } else if (devItem.value === true) {
      if (!devItem.items || devItem.items.length === 0) {
        arrResult.push(devItem.id);
      }
    }
    if (Array.isArray(devItem.items) && devItem.items.length > 0) {
      for (var i = 0; i < devItem.items.length; i++) {
        getValueTrueItems(devItem.items[i], arrResult, fnFilter);
      }
      devItem.value = undefined;
    }
  }
}

function updateMenuItemStatus(devItems, adom_opt, readonly) {
  var toshow = Array.isArray(adom_opt) ? adom_opt : [];
  var items = JSON.parse(JSON.stringify(devItems));
  var item, sub;
  for (var ii = 0; ii < items.length; ii++) {
    item = items[ii];
    if (typeof item.value === 'string') continue;
    item.value = toshow.indexOf(item.id) >= 0;
    item.readonly = readonly || false; //For JK's controll..
    if (item.items) {
      for (var kk = 0, sz = item.items.length; kk < sz; kk++) {
        sub = item.items[kk];
        if (typeof sub.value === 'string') continue;
        const show = get(devItems, [ii, 'items', kk, 'show'], null);
        if (isFunction(show)) {
          sub.value = show(toshow);
          continue;
        }
        sub.value = toshow.indexOf(sub.id) >= 0;
      }
    }
  }
  return items;
}
/*********************/

function getAdminCustomize(adminName) {
  return fiFmgHttp.post({
    id: 1,
    method: 'get',
    url: `/gui/admin/${adminName}/customize`,
  });
}

// This will override the whole customize data.
// In order to avoid erasing data unexpectedly, should use `makeUpdateAdminCustomize`
function setAdminCustomize(adminName, data) {
  return fiFmgHttp.post({
    id: 1,
    method: 'update',
    url: `/gui/admin/${adminName}/customize`,
    params: {
      customize: data,
    },
  });
}

function makeUpdateAdminCustomize(adminName) {
  let cache;
  return async (customize) => {
    if (!cache) {
      const resp = await getAdminCustomize(adminName);
      cache = get(resp, '[0].data.customize', {});
    }
    return setAdminCustomize(adminName, { ...cache, ...customize });
  };
}

function deleteAdminCustomize(adminNames) {
  const proms = adminNames.map((n, idx) =>
    fiFmgHttp.post({
      id: idx,
      method: 'remove',
      url: `/gui/admin/${n}/customize`,
    })
  );
  return Promise.all(proms);
}

function withEscapedAdminName(request) {
  return (adminNames, ...rest) => {
    const escaped = (function escapeName(name) {
      if (Array.isArray(name)) {
        return name.map(escapeName);
      }
      return name.replaceAll('/', '\\/');
    })(adminNames);
    return request(escaped, ...rest);
  };
}
