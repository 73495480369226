import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id='error-page' className='tw-text-neutral-0 tw-p-4'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>{error.statusText || error.message}</p>
      {process.env.NODE_ENV === 'development' && <pre>{error.stack}</pre>}
    </div>
  );
}
