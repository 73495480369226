export const usePollRequest = ({
  init = undefined,
  pollFn = async () => {},
  onNotify = () => true,
  maxAttempts = undefined,
  timeout = 1000,
}) => {
  let attempts = 0;
  let promise = undefined;

  init && init();

  const instance = setInterval(async () => {
    if (promise || (maxAttempts && attempts > maxAttempts)) return;
    attempts += 1;

    promise = pollFn(attempts).then((resp) => {
      promise = null;
      return resp;
    });

    const resp = await promise;
    const isComplete = onNotify(resp);
    if (isComplete) clearInterval(instance);
  }, timeout);

  return () => {
    clearInterval(instance);
  };
};
