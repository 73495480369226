import { fiFmgHttp } from 'fi-http';

export {
  getPackageList,
  getPolicyBlockList,
  getPackage,
  genQueryURL as genPPkgQueryUrl,
};

/**
 * Get Policy Package list
 * @param {Object} param - See `DEF_PARAM`
 */
function getPackageList(param) {
  const DEF_PARAM = {
    adom: {},
    option: ['schedule'],
    sortings: [{ name: 1 }],
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    return {
      url: genQueryURL({
        adom: prm.adom,
      }),
      option: prm.option,
      sortings: prm.sortings,
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

function getPolicyBlockList(param) {
  const DEF_PARAM = {
    adom: {},
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    return {
      url: genQueryURL({
        adom: prm.adom,
        isPblock: true,
      }),
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

function getPackage(param) {
  const DEF_PARAM = {
    adom: {},
    path: null, // pkg path
  };
  if (!Array.isArray(param)) {
    param = [param];
  }
  const reqParams = param.map((pm) => {
    const prm = Object.assign({}, DEF_PARAM, pm);
    return {
      url: genQueryURL({
        adom: prm.adom,
        pkg: prm.path,
        isPblock: prm.isPblock,
      }),
    };
  });
  return fiFmgHttp.query({
    id: 1,
    method: 'get',
    params: reqParams,
  });
}

function genQueryURL(param) {
  const pm = Object.assign(
    {
      adom: null, // adom obj, NOT name string
      pkg: null, // pkg obj or path string
      isPblock: false,
    },
    param
  );
  if (!Object.hasOwnProperty.call(param, 'isPblock')) {
    pm.isPblock = param.pkg?.pblock || false;
  }
  let url = 'pm/';
  if (pm.isPblock) {
    url += 'pblock';
  } else {
    url += 'pkg';
  }
  url += pm.adom.globaldb ? '/global' : `/adom/${pm.adom.name}`;
  if (pm.pkg) {
    let path;
    if (typeof pm.pkg === 'string') {
      path = pm.pkg;
    } else {
      path = pm.pkg.path || pm.pkg.name;
    }
    if (pm.isPblock) {
      path = getPBlockPath(path);
    }
    url += `/${path}`;
  }
  return url;
}

/**
 * Get the original policy block path.
 * Path of a Policy Block is modified by GUI side, as needed by ppkg tree,
 * "Policy Blocks/" is prepended to the path to generate the tree structure.
 * What this function does is to revert whatever modified the original path.
 * @param {String} path - path of a pblock
 */
function getPBlockPath(path) {
  // For Policy Block's path, it is 'Policy Blocks/pb name' and 'Policy Blocks' is a fake, mock-up folder path, which needs to be removed.
  let matched = path.match(/Policy Blocks\/(.*)/);
  return matched ? matched[1] : path;
}
