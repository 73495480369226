import { useEffect, useState } from 'react';
import createTerminmal from '../../terminal/terminal';
import { saveBlob } from 'fiutil';
import HistoryAddon from '../../terminal/historyAddon';
import { fiMessageBox } from 'fi-messagebox';
import FitToContainerAddon from '../../terminal/fitToContainerAddon';
import ConsoleScript from './ConsoleScript';
import postRobot from '@fafm/post-robot';
import { shortcuts } from 'fi-shortcuts';
import { Disposable } from '../../terminal/util';
import { ProToolkit } from '@fafm/neowise-pro';
import { fiWebSocket } from 'fi-websocket';
import './terminal.less';

export { getDefaultActions, getDefaultAddons };

export default function renderTerminal(props) {
  const { instance, initAddons, renderHeader, $opener } = props;

  const [, _] = useState(1);
  instance.forceUpdate = () => _((prev) => prev + 1);

  // init addons and xterm
  useEffect(() => {
    const addons = initAddons(instance, props);

    const xterm = createTerminmal(instance.containerEl, Object.values(addons));

    Object.assign(instance, {
      ...addons,
      xterm,
    });

    const unReg = $opener.registerAfterShow(() => {
      // fit to container after animation is done or xterm-helper-textarea lineHeight is incorrect
      const initialDimension = addons.fit.afterXtermReady();
      addons.websocket.afterXtermReady(initialDimension);
      addons.fit.addResizeListener(addons.websocket.updateTerminalDimension);
      unReg();
    });

    return xterm.dispose.bind(xterm);
  }, []);

  return (
    <>
      {renderHeader(instance, props)}
      <div
        className='terminal_content'
        ref={(el) => el && (instance.containerEl = el)}
      ></div>
    </>
  );
}

function getDefaultActions(instance, { getAutoId, getCLI }) {
  const runCurrentPageCLI = () => {
    const cli = getCLI();
    const history = instance.history?.getHistory(false);

    if (cli && Array.isArray(history)) {
      let _last = history[history.length - 1];
      let _cli = [];
      if (/\([\w]+\)#/.test(_last)) {
        _cli.push('end\n');
      }
      _cli.push(cli);
      _cli.push('\n');

      instance.websocket?.sendData(_cli.join(''));
    }
    focus();
  };

  const focus = () => {
    instance.xterm?.focus();
  };

  const clearConsole = () => {
    instance.xterm?.clear();
    instance.websocket?.sendData('\f'); // proper clear wrapped command
    focus();
  };

  const reconnectConsole = async (...args) => {
    await instance.websocket?.disconnect();
    await instance.websocket?.connect.apply(instance.websocket, args);
    clearConsole();
  };

  const detachConsole = (param) => {
    const sid = instance.websocket?.getConsoleId();
    const connectParamKeys = new Set(['ipaddr', 'port', 'user', 'oid']);
    const remoteParams = Object.entries(param || {}).reduce(
      (acc, [key, val]) => {
        if (connectParamKeys.has(key)) {
          acc[key] = val;
        }
        return acc;
      },
      {}
    );
    const urlSearchParams = new URLSearchParams({ sid });
    const queryParams = urlSearchParams.toString();

    const detachedWindow = window.open(
      `${location.origin}${MACROS.USER.SYS.WEB_CONSOLE_HOST}?${queryParams}`,
      '_blank'
    );

    postRobot.send(detachedWindow, 'console-service', {
      fiWebSocket: fiWebSocket,
      remoteParams,
    });
  };

  const saveStringToFile = (string, fileName) => {
    if (string) {
      saveBlob(
        new Blob([string], {
          type: 'text/plain',
        }),
        fileName
      );
    }
  };

  const copyStringToClipboard = async (string) => {
    if (string) {
      try {
        await navigator.clipboard.writeText(string);
        fiMessageBox.show(gettext('Copied to Clipboard.'), 'success');
      } catch (ex) {
        fiMessageBox.show(
          gettext('Fail to Copy: %s.').printf([ex?.message]),
          'danger'
        );
      }
    } else {
      fiMessageBox.show(gettext('No Content Recorded.'), 'warning');
    }
  };

  const donwloadHistory = () => {
    const history = instance.history?.getHistory();
    saveStringToFile(
      history,
      `console_history_${new Date().toDateString()}.txt`
    );
  };

  const copyHistory = () => {
    const history = instance.history?.getHistory();
    copyStringToClipboard(history);
  };

  const runScript = async () => {
    try {
      focus(); // modal will restore focus to the terminal when console script modal close

      const { content } = await ProToolkit.openModal(<ConsoleScript />, {
        size: 'lg',
        height: '600px',
      }).result;

      content && instance.websocket?.sendData(content);
    } catch {
      //
    }
  };

  const isRecording = !!instance.history?.isRecording();

  const startRecord = () => {
    instance.history?.startRecord();
    instance.forceUpdate?.();
    focus();
  };

  const stopRecord = () => {
    let commands = instance.history?.stopRecord();
    copyStringToClipboard(commands);
    instance.forceUpdate?.();
  };

  Object.assign(instance, {
    focus,
    reconnectConsole,
    detachConsole,
  });

  const callWithNoParam = (fn) => () => fn();

  return {
    cli_current_page: {
      key: 'cli_current_page',
      'automation-id': getAutoId('cli_current_page'),
      name: 'cli',
      label: gettext('CLI of Current Page (If Available)'),
      onClick: runCurrentPageCLI,
    },

    clear_console: {
      key: 'clear_console',
      'automation-id': getAutoId('clear_console'),
      name: 'delete',
      label: gettext('Clear Console'),
      onClick: clearConsole,
    },

    reconnect_console: {
      key: 'reconnect_console',
      'automation-id': getAutoId('reconnect_console'),
      name: 'refresh',
      label: gettext('Reconnect Console'),
      onClick: callWithNoParam(reconnectConsole),
    },

    run_script: {
      key: 'run_script',
      'automation-id': getAutoId('run_script'),
      name: 'run-report',
      label: gettext('Run CLI Script'),
      onClick: runScript,
    },

    record_command: {
      key: 'record_command',
      'automation-id': getAutoId('record_command'),
      library: 'fa-solid',
      name: 'circle',
      /* eslint-disable */
      ...(isRecording
        ? {
            label: gettext('Stop Recording'),
            class: 'recording',
            onClick: stopRecord,
          }
        : {
            label: gettext('Record CLI Commands'),
            onClick: startRecord,
          }),
      /* eslint-enable */
    },

    copy_history: {
      key: 'copy_history',
      'automation-id': getAutoId('copy_history'),
      name: 'copy',
      label: gettext('Copy History to Clipboard'),
      onClick: copyHistory,
    },

    download_history: {
      key: 'download_history',
      'automation-id': getAutoId('download_history'),
      name: 'download',
      label: gettext('Download History'),
      onClick: donwloadHistory,
    },

    detach: {
      key: 'detach',
      'automation-id': getAutoId('detach'),
      name: 'launch-portal',
      label: gettext('Detach'),
      onClick: callWithNoParam(detachConsole),
    },
  };
}

function getDefaultAddons(instance, { $opener }) {
  return {
    fit: new FitToContainerAddon({
      observeEl: instance.containerEl,
      shouldFit: () => {
        return !$opener.isMinimized;
      },
    }),
    history: new HistoryAddon(),
    keyboard: new KeyboardSupportAddon($opener),
  };
}

class KeyboardSupportAddon extends Disposable {
  constructor($opener) {
    super();
    this.$opener = $opener;
  }

  activate(xterm) {
    const { toolbar } = shortcuts.getShortcuts('cli_console');

    const keyboardSupport = () =>
      xterm.onKey(({ domEvent: { key, ctrlKey } }) => {
        if (!ctrlKey) return;

        switch (`ctrl+${key || ''}`) {
          case toolbar.key:
            return this.$opener.focusHeader?.();
        }
      });

    this.addDisposable(keyboardSupport());
  }
}
