import { Outlet } from 'react-router-dom';
import { ReactContentViewContainer } from 'react_apps/ra_main/main_layout/ReactContentViewContainer';

export default [
  // Device list
  getCentralDeviceList(),

  // Monitors
  getCentralMonitorRoute(),

  // Ap profiles
  getCentralApProfilesRoute(),
];

function getCentralDeviceList() {
  return {
    path: 'centrallist',
    handle: {
      appUniKey: 'fap_device',
    },
    lazy: () => import('./ap_table/ApList'),
  };
}

function getCentralMonitorRoute() {
  return {
    path: 'centralmonitor',
    handle: {
      appUniKey: 'fap_device',
    },
    element: (
      <div className='tw-w-full tw-h-full'>
        <Outlet />
      </div>
    ),
    children: [
      {
        path: 'health',
        handle: {
          appUniKey: 'fap_device',
        },
        lazy: async () => {
          const { ApHealthMonitor } = await import(
            './managed_ap/ApHealthMonitor/ApHealthMonitor'
          );
          return { Component: ApHealthMonitor };
        },
      },
    ],
  };
}

function getCentralApProfilesRoute() {
  return {
    path: 'wifitemplates',
    handle: {
      appUniKey: 'fap_template',
    },
    element: <ReactContentViewContainer menuId='fap_wifi_templates_menu' />,
    children: [
      // SSIDs
      {
        path: 'ssids',
        handle: {
          appUniKey: 'fap_template_ssid',
        },
        lazy: () => import('./ap_profiles/Ssids/SsidsTable'),
      },
      // AP Profiles
      {
        path: 'profiles',
        handle: {
          appUniKey: 'fap_template_opprofs_approf',
        },
        lazy: () => import('./ap_profiles/WtpProfile/WtpProfileTable'),
      },
      // QoS Profiles
      {
        path: 'qosprofiles',
        handle: {
          appUniKey: 'fap_template_opprofs_qosprof',
        },
        lazy: () => import('./ap_profiles/QosProfile/QosProfileTable'),
      },
      // FortiAP Configuration Profiles
      {
        path: 'apcfgprofiles',
        handle: {
          appUniKey: 'fap_template_opprofs_cfgprof',
        },
        lazy: () => import('./ap_profiles/ApcfgProfile/ApcfgProfileTable'),
      },
      // ARRP Profiles
      {
        path: 'arrpprofiles',
        handle: {
          appUniKey: 'fap_template_opprofs_arrpprof',
        },
        lazy: () => import('./ap_profiles/ArrpProfile/ArrpProfileTable'),
      },
      // MPSK Profiles
      {
        path: 'mpskprofiles',
        handle: {
          appUniKey: 'fap_template_conprofs_mpskprof',
        },
        lazy: () => import('./ap_profiles/MpskProfile/MpskProfileTable'),
      },
      // Bonjour Profiles
      {
        path: 'bjrprofiles',
        handle: {
          appUniKey: 'fap_template_conprofs_bjrprof',
        },
        lazy: () => import('./ap_profiles/BonjourProfile/BonjourProfileTable'),
      },
      // Bonjour Profiles
      {
        path: 'bjrprofiles',
        handle: {
          appUniKey: 'fap_template_conprofs_bjrprof',
        },
        lazy: () => import('./ap_profiles/BonjourProfile/BonjourProfileTable'),
      },
      // Bluetooth Profiles
      {
        path: 'bleprofiles',
        handle: {
          appUniKey: 'fap_template_conprofs_btprof',
        },
        lazy: () => import('./ap_profiles/BleProfile/BleProfileTable'),
      },
      // WIDS Profiles
      {
        path: 'widsprofiles',
        handle: {
          appUniKey: 'fap_template_protprof_widsprof',
        },
        lazy: () => import('./ap_profiles/WidsProfile/WidsProfileTable'),
      },
      // WIDS Profiles
      {
        path: 'accesscontrol',
        handle: {
          appUniKey: 'fap_template_protprof_l3prof',
        },
        lazy: () => import('./ap_profiles/AccessControl/AccessControlTable'),
      },
      // WIDS Profiles
      {
        path: 'setting',
        handle: {
          appUniKey: 'fap_template_wifisetting',
        },
        lazy: () => import('./ap_profiles/ApSetting/ApSettingTmplTable'),
      },
    ],
  };
}
