import { fiFmgHttp } from 'fi-http';
import { fiSysConfig } from 'fi-session';
import { fiSysHostname } from 'fi-hostname';
import { SysDashboardService } from 'fi-dashboard';
import { getStatus as getBackupStrategyStatus } from '../tabs/backup_strategy/apis';
import { isObject, omit, isNil } from 'lodash';
import { compose } from '@fafm/fp';
import { getFortiCareLicense } from 'react_apps/ra_dashboard_util/api.js';

let _skippedSetupSteps = false;

export const STARTUP_STEPS = {
  STARTUP_WELCOME: 'STARTUP_WELCOME',
  STARTUP_SSO: 'STARTUP_SSO',
  STARTUP_HOSTNAME: 'STARTUP_HOSTNAME',
  STARTUP_PASSWORD: 'STARTUP_PASSWORD',
  STARTUP_FIRMWARE: 'STARTUP_FIRMWARE',
  STARTUP_BACKUP: 'STARTUP_BACKUP',
  STARTUP_FINISH: 'STARTUP_FINISH',
};

export function setSkippedStep() {
  _skippedSetupSteps = true;
}

export function getContractInfo() {
  return fiFmgHttp
    .query({
      method: 'get',
      params: [
        {
          url: '/um/license/self',
        },
      ],
    })
    .then((resp) => {
      return resp[0]?.data?.contract?.[0] ?? {};
    });
}

export async function getStartupWizardOptions() {
  const sysConfig = fiSysConfig.current();
  const [licenseData, hostnameStatus, ssoSettings, backupStrategyStatus] =
    await Promise.all([
      getFortiCareLicense(),
      fiSysHostname.getStatus(),
      fiFmgHttp
        .forward({
          method: 'get',
          params: [
            {
              url: '/cli/global/system/saml',
            },
          ],
        })
        .then((resp) => resp?.[0]?.data || {}),
      getBackupStrategyStatus(),
    ]);

  const productName = fiSysConfig.isFmg() ? 'FortiManager' : 'FortiAnalyzer';
  const title = fiSysConfig.isFmg()
    ? gettext('FortiManager Setup')
    : gettext('FortiAnalyzer Setup');

  const isForticareRegistered = licenseData.account_id !== 'N/A';

  const isFmgMember = fiSysConfig.isFmgMember();
  // Do not allow skip if admin is forced to change password
  const isPasswordDefault = sysConfig.admin_has_default_pwd;
  const isPasswordChangeRequired = sysConfig.need_change_password;

  const imageToUpgradeTo = await findNextImageVersion();

  const stepTabs = [
    {
      id: STARTUP_STEPS.STARTUP_SSO,
      text: gettext('Register and SSO with FortiCare'),
      // Mantis #732116: remove sso check for setup wizard, only check registration
      isComplete: isForticareRegistered,
      supportEntitlementUpload: true,
    },
    {
      id: STARTUP_STEPS.STARTUP_HOSTNAME,
      text: gettext('Specify Hostname'),
      isComplete: hostnameStatus.changed,
    },
    {
      id: STARTUP_STEPS.STARTUP_PASSWORD,
      text: gettext('Change Your Password'),
      isComplete: isFmgMember || !isPasswordDefault,
      isRequired: !isFmgMember && isPasswordChangeRequired,
    },
    {
      id: STARTUP_STEPS.STARTUP_FIRMWARE,
      text: gettext('Upgrade Firmware'),
      isComplete: !imageToUpgradeTo,
    },
    backupStrategyStatus.hasStep && {
      id: STARTUP_STEPS.STARTUP_BACKUP,
      text: gettext('Backup Strategy'),
      isComplete: backupStrategyStatus.changed,
      props: omit(backupStrategyStatus, ['hasStep', 'changed']),
    },
  ].filter(isObject);

  const incompleteTabs = stepTabs.filter((tab) => !tab.isComplete);

  const tabs = [
    {
      id: STARTUP_STEPS.STARTUP_WELCOME,
      title: gettext('Welcome'),
      text: gettext('Welcome'),
      description: gettext(
        'Perform the following steps to complete the setup of this %(productName)s.'
      ).printfd({ productName }),
      stepTabs,
      isRequired: true,
    },
    ...incompleteTabs,
    {
      id: STARTUP_STEPS.STARTUP_FINISH,
      getTitle: () =>
        _skippedSetupSteps
          ? gettext('Setup Incomplete')
          : gettext('Setup Complete'),
      text: _skippedSetupSteps
        ? gettext('Setup Incomplete')
        : gettext('Setup Complete'),
      getDescription: () =>
        getSetupSummaryDescription({
          isComplete: !_skippedSetupSteps,
          productName,
        }),
    },
  ];

  const options = {
    title,
    tabs,
    shareObjs: {
      sysConfig,
      licenseData,
      isForticareRegistered,
      ssoSettings,
      imageToUpgradeTo,
    },
  };

  return {
    options,
    isComplete: incompleteTabs.length === 0,
  };
}

function getSetupSummaryDescription(params) {
  return params.isComplete
    ? gettext(
        'The setup of this %(productName)s is now complete. Click "Finish" to exit this dialog.'
      ).printfd(params)
    : gettext(
        'The setup of this %(productName)s is incomplete. It is strongly recommended to complete all configurations in this setup. Click "Finish" to exit this dialog.'
      ).printfd(params);
}

async function findNextImageVersion() {
  const shouldCheckFirmware = await (async function () {
    try {
      const resp = await fiFmgHttp
        .forward({
          method: 'get',
          params: [
            {
              url: '/cli/global/system/admin/setting',
            },
          ],
        })
        .then((resp) => resp?.[0] || {});
      return resp.data['firmware-upgrade-check'];
    } catch {
      return 1;
    }
  })();
  if (!shouldCheckFirmware) return null;

  const sysConfig = fiSysConfig.current();
  const hasFirmwareLicense = fiSysConfig.hasValidLicense('FMWR');

  const [filteredImage, upgMap, abbrData] = await Promise.all([
    SysDashboardService.getFilteredImage(MACROS.SYS.CONFIG_PROD_NAME),
    SysDashboardService.getFirmwareUpgradePath(fiSysConfig.isFmg()),
    SysDashboardService.getDevPlatformAbbrByName(MACROS.SYS.CONFIG_PROD_NAME),
  ]);

  const availableImages = filteredImage.sort((a, b) => {
    const valA = a.version
      .split('.')
      .reduce((acc, val) => parseInt(val) + parseInt(acc) * 10);
    const valB = b.version
      .split('.')
      .reduce((acc, val) => parseInt(val) + parseInt(acc) * 10);
    return valB - valA;
  });
  const curVerBuild = SysDashboardService.parseVersionBuildNumber(
    sysConfig.fmgversion
  );

  const { build, version: cVersion } = curVerBuild;
  const cBuild = parseInt(build);
  const [cVer, cMr, cPatch] = cVersion.split('.');

  if (hasFirmwareLicense) {
    return getLatestVersionOnPath(
      availableImages,
      curVerBuild,
      upgMap,
      abbrData
    );
  } else {
    //get latest patch of current major/minor version
    const matchedImages = availableImages.reduce((acc, img) => {
      const { version, buildnumber } = img;
      const [ver, mr, patch] = version.split('.');

      if (cVer === ver && cMr === mr) {
        if (
          parseInt(patch) > parseInt(cPatch) ||
          (patch === cPatch && parseInt(buildnumber) > parseInt(cBuild))
        ) {
          acc.push({
            ...img,
            text: `${version} (${parseInt(buildnumber, 10)})`,
          });
        }
      }

      return acc;
    }, []);

    return matchedImages.length > 0 ? matchedImages[0] : null;
  }
}

/**
 * Make a check function for each name.
 * @param {String} name the name to be checked
 */
const makeSingleCkFn = (name) => (pre) => {
  if (pre === false) return pre;
  if (isNil(pre)) return false;
  return pre[name];
};

/**
 * Deep check an object to see if value exists.
 * @param {Object} obj object to be checked.
 * @param  {...any} theArgs the args name array
 */
function deepCheckObject(obj, ...theArgs) {
  const compFn = compose(
    ...theArgs.reverse().map((name) => makeSingleCkFn(name))
  );
  const result = compFn(obj);
  return result === false ? result : !isNil(result);
}

function getLatestVersionOnPath(
  availableImages,
  curVerBuild,
  upgMap,
  abbrData
) {
  const platAbbrev = abbrData['abbr'];
  let recommendedVer = '';

  const supportedVersions = availableImages.map((v) => {
    return {
      ...v,
      text: `${v.version} (${parseInt(v.buildnumber, 10)})`,
    };
  });

  if (
    deepCheckObject(upgMap, platAbbrev, curVerBuild.version, curVerBuild.build)
  ) {
    recommendedVer = getLatestInPath(
      upgMap[platAbbrev],
      curVerBuild.version,
      curVerBuild.build
    );
  }

  if (!recommendedVer) return null;

  const suggestedVer = supportedVersions.find((v) => {
    const ver = v.text.split(' ')[0].trim();
    if (recommendedVer === ver) {
      return true;
    }
    return false;
  });

  return suggestedVer;
}

// 7.2.1 vs 7.2.2
const isMajorMinorSame = (v1, v2) => {
  const ver1 = v1.split('.');
  const ver2 = v2.split('.');
  return ver1.slice(0, ver1.length - 1).every((e, i) => e === ver2[i]);
};

/***
 *  { 7.0.4: { 0306: [{ upgradBuild: 1215, upgradeVer: 7.2.1 }]}}
 *  only allow patch upgrade for startup wizard
 *  returns null, if there are major version in pathlist, or not find
 *          latest version in upgrade path e.g. '7.2.1'
 */
function getLatestInPath(pathList, version, build) {
  if (pathList[version] && pathList[version][build]) {
    const { upgradeVer, upgradeBuild } =
      pathList[version][build].filter(({ upgradeVer }) =>
        isMajorMinorSame(upgradeVer, version)
      )?.[0] || {};
    if (upgradeVer) {
      return getLatestInPath(pathList, upgradeVer, upgradeBuild);
    }
  }
  return null;
}
