import $ from 'jquery';
import { isFunction, isNil, isUndefined } from 'lodash';

import postRobot from '@fafm/post-robot';
import { fiStore, fiStoreTheme, observeStore } from 'fistore';
import { fiFmgHttp, fiHttp } from 'fi-http';
import { fiAdom, fiSysConfig } from 'fi-session';
import { useDeferred } from 'rh_util_hooks';
import Momenttz from 'moment-timezone';

const GMAP_FEATURES = {
  DVM_EDIT: 'dvmedit',
  DVM_LIST: 'dvmlist',
  DVM_LOCATION: 'dvmlocation',
  FAZ_DVM: 'fazdvm',
  FORTIAP: 'fortiap',
  NOC: 'noc',
  SDWAN: 'sdwan',
  SYS_SETTINGS: 'syssetting',
  VPN: 'vpn',
  SDWAN_OVERLAY: 'sdwan_overlay',
};

const GMAP_FEATURE_MAP = {
  [GMAP_FEATURES.DVM_EDIT]: '/dvm/index.html',
  [GMAP_FEATURES.DVM_LIST]: '/dvmlist/index.html',
  [GMAP_FEATURES.DVM_LOCATION]: '/dvmEdit/index.html',
  [GMAP_FEATURES.FAZ_DVM]: '/fazdvm/index.html',
  [GMAP_FEATURES.FORTIAP]: '/fortiap/index.html',
  [GMAP_FEATURES.NOC]: '/noc/index.html',
  [GMAP_FEATURES.SDWAN]: '/sdwan/index.html',
  [GMAP_FEATURES.SYS_SETTINGS]: '/sys/index.html',
  [GMAP_FEATURES.VPN]: '/vpn/index.html',
  [GMAP_FEATURES.SDWAN_OVERLAY]: '/sdwan_overlay/index.html',
};

/**
 * Use this function to get a gmap url providing a specific url
 * @param {*} url string
 * @param {*} feature map feature
 * @param {*} region specific region to use for gmap localization
 * @returns string
 */
function _getFMGFeatureChannelUrl(url, feature, region = null) {
  let gmapUrl = `${url}?channel=${_genChannel() + '_' + feature}`;
  if (region) gmapUrl = `${gmapUrl}&region=${region}`;
  return gmapUrl;
}

function _getFeatureUrl(feature, sessionId) {
  let featurePath = GMAP_FEATURE_MAP[feature] || '';
  if (featurePath) return `/server/${sessionId}/html${featurePath}`;

  return '';
}

function getFMGGoogleMapUrlByFeature(sessionId, feature, region) {
  const featureUrl = _getFeatureUrl(feature, sessionId);
  if (featureUrl) {
    return _getFMGFeatureChannelUrl(featureUrl, feature, region);
  }

  return '';
}

/**
 * This is an inner function used to generate google map channel string.
 */
function _genChannel(feature) {
  const product =
    MACROS.SYS.IMG_TYPE === MACROS.SYS.PRODUCT_FMG ? 'FMG' : 'FAZ';
  const version = [
    MACROS.SYS.CONFIG_MAJOR_NUM,
    MACROS.SYS.CONFIG_MINOR_NUM,
    MACROS.SYS.CONFIG_PATCH_NUM,
  ].join('');
  let arr = [product, version];
  if (feature) {
    arr.push(feature);
  }
  return arr.join('_');
}

observeStore(
  (state) => state?.session?.sysConfig?.data?.mapserver_url,
  (mapServerUrl) => {
    // This registered function will handle all map channel initialization from a specific child map view
    postRobot.on(
      'gmap.channel',
      {
        domain: mapServerUrl || MACROS.USER.SYS.MAP_SERVER_HOST,
      },
      // eslint-disable-next-line
      function (event, callback) {
        return _genChannel();
      }
    );
    // Pass theme service to external iframes
    const { getThemeVars, getPaletteVars } = fiStoreTheme;
    postRobot.on(
      'gmap.store.theme',
      {
        domain: mapServerUrl || MACROS.USER.SYS.MAP_SERVER_HOST,
      },
      function () {
        return {
          getThemeVars: (varNames) => {
            const vars = getThemeVars(varNames)(fiStore.getState());
            return varNames.reduce((acc, varName, index) => {
              acc[varName] = vars[index];
              return acc;
            }, {});
          },
          getPaletteVars: (varNames) =>
            getPaletteVars(varNames)(fiStore.getState()),
        };
      }
    );
  }
);
//This function is deprecated, as the map server won't call this function anymore.
//The channel's information will be provided from corresponding features in main frame.

var sessionId;
const MAP_API_HREF = 'https://maps.googleapis.com';
const STATIC_MAP_URL =
  '/maps/api/staticmap?scale=%(scale)s&center=%(lat)s,%(lng)s&zoom=%(zoom)s&size=%(size)s&maptype=%(maptype)s&markers=size:small%7Ccolor:%(color)s%7C%(lat)s,%(lng)s&client=gme-fortinetinc&channel=%(channel)s';
const DEFAULT_MAP_OPTS = {
  scale: 1,
  zoom: 15,
  size: '363x130',
  color: '0x60c0dd',
  maptype: 'roadmap',
};
const GEOCODING_URL =
  '/maps/api/geocode/json?latlng=%(latlng)s&sensor=false&client=gme-fortinetinc&channel=%(channel)s';

/*
 * https://developers.google.com/maps/documentation/maps-static/get-api-key#digital-signature
 * Note: Modified Base64 for URLs replaces the + and / characters of standard Base64 with - and _ respectively, so that these Base64 signatures no longer need to be URL-encoded.
 */
const _getSignature = (msg, abortCtrl) => {
  return geoProxy(
    {
      data: {
        action: 'getRequestSignature',
        msg,
      },
    },
    abortCtrl
  ).then((resp) => {
    let mac = resp[0].data.mac.replace(/\+/g, '-').replace(/\//g, '_');
    return `${MAP_API_HREF}${msg}&signature=${mac}`;
  });
};

let _getStaticMapUrl = (opts, abortCtrl) => {
  if (isNil(opts.lat) || isNil(opts.lng) || isNil(opts.feature)) {
    console.error('Need to specify lat, lng and feature to use static map');
  }
  opts = {
    ...DEFAULT_MAP_OPTS,
    ...opts,
    channel: _genChannel(opts.feature),
  };
  let msg = STATIC_MAP_URL.printfd(opts);
  return _getSignature(msg, abortCtrl);
};

let _resend = (max) => (fn, params, defer) => {
  let count = 1;
  if (!max) max = 10;
  setTimeout(() => {
    if (count <= max) {
      fn(params, defer);
      count++;
    }
  }, 250);
};

let _getAddress = (opts, abortCtrl) => {
  if (isNil(opts.latlng) || isNil(opts.feature)) {
    console.error('Need to specify latlng and feature to use geocode');
  }
  opts.channel = _genChannel(opts.feature);
  let msg = GEOCODING_URL.printfd(opts);
  return _getSignature(msg, abortCtrl).then((req) => {
    let defer = useDeferred();
    let resend = _resend();
    let send = (req, q) => {
      $.get(req)
        .done((resp) => {
          if (resp.status === 'OK') {
            if (resp?.results && opts?.raw) q.resolve(resp.results);
            else if (resp.results[0]) {
              q.resolve(resp.results[0].formatted_address);
            } else {
              q.resolve(gettext('Unknown Location'));
            }
          } else if (
            resp.status === 'OVER_QUERY_LIMIT' &&
            opts.lat !== 0 &&
            opts.lng !== 0
          ) {
            resend(send, req, q);
          } else {
            q.resolve('Unknown Location');
          }
        })
        .fail(() => {
          resend(send, req, q);
        });
    };
    send(req, defer);
    return defer.promise;
  });
};

function geoProxy(event, abortCtrl) {
  var req, adom_name;
  if (event.data.action === 'getRequestSignature') {
    req = {
      method: 'get',
      url: '/gui/geoip/signature',
      params: {
        msg: event.data.msg,
        useBatch: Boolean(event.data.useBatch),
      },
    };
    return fiFmgHttp.post(req, abortCtrl);
  }

  if (event.data.data.action === 'getLatLngByIp') {
    adom_name = fiAdom.current().name;
    req = {
      method: 'get',
      url: `/gui/adom/${adom_name}/ipaddrs/geoip`,
      params: {
        filter: {
          ips: event.data.data.ips.split(','),
        },
      },
    };
    return fiFmgHttp.post(req);
  }
}

// Gets the Country Code of the FMG based on the lat/lng coordinates seting in SS -> Advanced Settings.
// If no appropriate country code is found, then return an empty string.
// Ex: Morocco -> MA
const getRegionByCoordinates = (feature) => {
  const deferred = useDeferred();

  fiFmgHttp
    .post({ method: 'get', url: '/gui/geo-location' })
    .then(async (resp) => {
      // Try and find country code using the coordinates
      if (resp.latitude && resp.longitude) {
        const { latitude, longitude } = resp;
        const opts = {
          latlng: `${latitude},${longitude}`,
          feature,
          raw: true,
        };

        const addressResp = await _getAddress(opts);
        for (const addressObj of addressResp) {
          if (Array.isArray(addressObj?.address_components)) {
            for (const addrComponent of addressObj.address_components) {
              if (
                Array.isArray(addrComponent.types) &&
                addrComponent.types.includes('country') &&
                addrComponent.short_name
              ) {
                deferred.resolve(addrComponent.short_name);
                return;
              }
            }
          }
        }
      }

      deferred.resolve(null);
    });

  return deferred.promise;
};

const getRegionByTimezone = () => {
  let country = null;
  const sysConfig = fiSysConfig.current();
  let moment = Momenttz.tz(sysConfig?.timezone?.timezonename);
  // eslint-disable-next-line
  if (isFunction(moment?._z?.countries) && moment._z.countries().length > 0)
    country = moment._z.countries()[0];
  return country;
};

const _getRegion = async (feature) => {
  let region = await getRegionByCoordinates(feature);
  if (region) return region;

  // Will return null if it fails
  return getRegionByTimezone();
};

/*
 * get google maps session id from backend
 * Note that when noRetry is set to true, we won't sent another request again
 * regardless of whether the first request sent has returned or not
 */
function _getSessionId(noRetry) {
  if (noRetry && !isUndefined(sessionId)) {
    return Promise.resolve(sessionId);
  }
  return fiFmgHttp
    .query({
      id: 1,
      method: 'exec',
      params: [
        {
          url: '/dmworker/get/gmap/session',
        },
      ],
    })
    .then(
      function (resp) {
        if (resp[0] && resp[0].data) {
          sessionId = resp[0].data.session;
          return sessionId;
        } else {
          sessionId = '';
          return sessionId;
        }
      },
      function () {
        sessionId = '';
        return sessionId;
      }
    );
}

/**
 * Use this function to both request a gmap session id and then generate a url based off a feature.
 * The feature must be included in the GMAP_FEATURE_MAP otherwise will return an empty string
 * @param {*} feature string, feature to use, example: sdwan
 * @param {*} retry bool, passed to getSessionId
 * @returns resourceUrl or ''
 */
async function _getFMGGoogleMapSessionAndUrlByFeature(feature, retry = false) {
  let sessionId = '';
  let resourceUrl = '';
  let serverUrl = '';

  const region = await _getRegion(feature);
  try {
    sessionId = await _getSessionId(retry);
    resourceUrl = getFMGGoogleMapUrlByFeature(sessionId, feature, region);
    serverUrl = resourceUrl
      ? (fiSysConfig.current().mapserver_url ||
          MACROS.USER.SYS.MAP_SERVER_HOST) + resourceUrl
      : '';
  } catch {
    // skip
  }

  return {
    sessionId,
    resourceUrl,
    serverUrl,
  };
}

function _getMapApiHref() {
  return MAP_API_HREF;
}

function _queryGeoIpUtil(params) {
  return geoProxy(params).then((resp) => resp[0].data);
}

function _getLatLngByIPs(ips) {
  if (!Array.isArray(ips)) {
    ips = [ips];
  }
  return _queryGeoIpUtil({
    data: {
      data: {
        action: 'getLatLngByIp',
        ips: ips.join(','),
      },
    },
  });
}
function _getRequestSignature(request) {
  return _queryGeoIpUtil({
    data: {
      action: 'getRequestSignature',
      msg: request,
    },
  });
}

// eslint-disable-next-line
function _createTooltip($dom, $map) {
  let $cdom = $('<div></div>');
  //support customized CSS later
  $cdom.css({
    position: 'absolute',
    padding: '5px',
    display: 'none',
    backgroundColor: 'rgba(50, 50, 50, 0.7)',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'rgb(245, 245, 240)',
    borderRadius: '4px',
    color: 'rgb(255, 255, 255)',
  });
  $cdom.mouseout(() => {
    _hide();
  });
  $dom.append($cdom);

  //API functions exposed to be used by callers
  let _destroy = function () {
    $cdom.remove();
  };

  let _show = function (position, text) {
    $cdom.css({ left: position.pageX, top: position.pageY });
    $cdom.html(`<span>${text}</span>`);
    $cdom.show();
  };

  let _hide = function () {
    $cdom.hide();
  };

  return {
    hide: _hide,
    show: _show,
    destory: _destroy,
  };
}

function _parseGeocodingMetaData(arrAddress) {
  const ADDR_COMP_MAP = {
    route: 'itemRoute',
    locality: 'itemLocality',
    administrative_area_level_1: 'itemProv',
    country: 'itemCountry',
    postal_code_prefix: 'itemPc',
    street_number: 'itemSnumber',
  };

  var output = {};

  arrAddress.forEach((address_component) => {
    let key = ADDR_COMP_MAP[address_component.types[0]];
    if (key) {
      output[key] = address_component.long_name;
    }
  });

  return output;
}

function _parseAddress(metaData) {
  if (!metaData) return '';
  var itemCountry = '',
    itemProv = '',
    itemLocality = '',
    itemRoute = '',
    itemSnumber = '',
    addr = '';
  itemCountry = metaData.itemCountry || '';
  itemProv = metaData.itemProv || '';
  itemLocality = metaData.itemLocality || '';
  itemRoute = metaData.itemRoute || '';
  itemSnumber = metaData.itemSnumber || '';
  if (itemRoute) {
    addr += itemRoute;
    if (itemSnumber) addr += ' ' + itemSnumber;
  }
  if (itemLocality) {
    if (addr) addr += ', ';
    addr += itemLocality;
  }
  if (itemProv) {
    if (addr) addr += ', ';
    addr += itemProv;
  }
  if (itemCountry) {
    if (addr) addr += ', ';
    addr += itemCountry;
  }
  return addr;
}

function _getChannelNameForFeature(feature) {
  return _genChannel() + '_' + feature;
}

function _getAddressFromLatLng(feature, { lat, lng }) {
  const GOOGLE_MAP_CLIENT_KEY = 'gme-fortinetinc';
  const url = `/maps/api/geocode/json?latlng=${lat},${lng}&client=${GOOGLE_MAP_CLIENT_KEY}&channel=${_getChannelNameForFeature(
    feature
  )}`;
  return _getRequestSignature(url).then((auth) => {
    let macId = auth.mac;
    return fiHttp.get(
      `https://maps.googleapis.com${url}&signature=${macId
        .replace(/\+/g, '-')
        .replace(/\//g, '_')}`,
      {
        transformRequest: [
          (data, headers) => {
            // Need to remove the headers to make this 'simple request' so the cross-origin request will work.
            // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests
            delete headers['X-CSRFToken'];
            delete headers['XSRF-TOKEN'];
            return data;
          },
        ],
      }
    );
  });
}

export const MapServerService = {
  GMAP_FEATURES,
  getSessionId: _getSessionId,
  getProductGoogleMapChannelUrl: _getFMGFeatureChannelUrl,
  getFMGGoogleMapSessionAndUrlByFeature: _getFMGGoogleMapSessionAndUrlByFeature,
  getLatLngByIPs: _getLatLngByIPs,
  getRequestSignature: _getRequestSignature,
  getStaticMapUrl: _getStaticMapUrl,
  getAddress: _getAddress,
  getRegion: _getRegion,
  getMapApiHref: _getMapApiHref,
  parseGeocodingMetaData: _parseGeocodingMetaData,
  parseAddress: _parseAddress,
  createTooltip: _createTooltip,
  getChannelNameForFeature: _getChannelNameForFeature,
  getAddressFromLatLng: _getAddressFromLatLng,
};
