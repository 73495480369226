import { ReactContentViewContainer } from 'react_apps/ra_main/main_layout/ReactContentViewContainer';

export default {
  children: [
    {
      path: 'devices',
      element: <ReactContentViewContainer menuId='fsw_pdm' />,
      handle: {
        appUniKey: 'fsw_device_pdm_root',
      },
      children: [
        {
          path: 'list',
          lazy: async () => {
            const { FswTableRoot } = await import(
              './device_table/FswTableRoot'
            );
            return {
              Component: FswTableRoot,
            };
          },
          handle: {
            appUniKey: 'fsw_device_pdm',
          },
        },
        {
          path: 'templates',

          children: [
            {
              path: 'systeminterface',
              lazy: async () => {
                const { VlanInterfaceTable } = await import(
                  './profile_list_pages/VlanInterfaceTable'
                );
                return {
                  Component: VlanInterfaceTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_vlan',
              },
            },

            {
              path: 'securitypolicy',
              lazy: async () => {
                const { SecurityPolicy8021xTable } = await import(
                  './profile_list_pages/SecurityPolicy8021xTable'
                );
                return {
                  Component: SecurityPolicy8021xTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_securitypolicy',
              },
            },
            {
              path: 'ddp',
              lazy: async () => {
                const { DynamicPortPolicyTable } = await import(
                  './profile_list_pages/DynamicPortPolicyTable'
                );
                return {
                  Component: DynamicPortPolicyTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_ddp',
              },
            },

            {
              path: 'nacpolicy',
              lazy: async () => {
                const { NacPolicyTable } = await import(
                  './profile_list_pages/NacPolicyTable'
                );
                return {
                  Component: NacPolicyTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_nacpolicy',
              },
            },

            {
              path: 'lldpprofile',
              lazy: async () => {
                const { LldpProfileTable } = await import(
                  './profile_list_pages/LldpProfileTable'
                );
                return {
                  Component: LldpProfileTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_lldpprofile',
              },
            },

            {
              path: 'lldpprofile',
              lazy: async () => {
                const { LldpProfileTable } = await import(
                  './profile_list_pages/LldpProfileTable'
                );
                return {
                  Component: LldpProfileTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_lldpprofile',
              },
            },

            {
              path: 'qospolicy',
              lazy: async () => {
                const { QosQosPolicyTable } = await import(
                  './profile_list_pages/QosQosPolicyTable'
                );
                return {
                  Component: QosQosPolicyTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_qospolicy',
              },
            },
            {
              path: 'queuepolicy',
              lazy: async () => {
                const { QosQueuePolicyTable } = await import(
                  './profile_list_pages/QosQueuePolicyTable'
                );
                return {
                  Component: QosQueuePolicyTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_queuepolicy',
              },
            },
            {
              path: 'ipdscpmap',
              lazy: async () => {
                const { QosIpDscpMapTable } = await import(
                  './profile_list_pages/QosIpDscpMapTable'
                );
                return {
                  Component: QosIpDscpMapTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_ipdscpmap',
              },
            },
            {
              path: 'dot1pmap',
              lazy: async () => {
                const { QosDot1pMapTable } = await import(
                  './profile_list_pages/QosDot1pMapTable'
                );
                return {
                  Component: QosDot1pMapTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_dot1pmap',
              },
            },

            {
              path: 'command',
              lazy: async () => {
                const { FswCustomCommandTable } = await import(
                  './profile_list_pages/FswCustomCommandTable'
                );
                return {
                  Component: FswCustomCommandTable,
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_cuscmd',
              },
            },

            {
              path: 'aclassign',
              lazy: async () => {
                const { onlyVdomScope } = await import(
                  './module_util/withDeviceScope'
                );
                const { SwcAclAssignment } = await import(
                  './profile_list_pages/SwcAclAssignment'
                );
                return {
                  Component: onlyVdomScope(SwcAclAssignment),
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_aclassign',
              },
            },

            {
              path: 'cliconf',
              lazy: async () => {
                const { onlyVdomScope } = await import(
                  './module_util/withDeviceScope'
                );
                const { DeviceSwitchProfileCLI } = await import(
                  './module_pages/DeviceSwitchProfileCLI'
                );
                return {
                  Component: onlyVdomScope(DeviceSwitchProfileCLI),
                };
              },
              handle: {
                appUniKey: 'fsw_device_template_cliconf',
              },
            },
          ],
        },
      ],
    },
  ],
};
