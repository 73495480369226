import { dashboards as nocDashboards } from '../consts/noc/dashboard-const';
import { dashboards as ftvDashboards } from '../consts/ftv/dashboard-const';
import {
  getFtvWidgetByName,
  getNocWidgetByName,
  getCustomWidgetProps,
  filterWidgetsByFeature,
} from './socWidget';
import { fiStore, fiSession, fiStoreSoc } from 'fistore';

const dashboards = [...nocDashboards, ...ftvDashboards];

const isFtv = (uuid) => ftvDashboards.some((dash) => dash.uuid === uuid);

const getWidgetProps = (uuid) => (widget) => {
  return isFtv(uuid)
    ? getFtvWidgetByName(widget.name)
    : widget.props && widget.props.isDataset
    ? getCustomWidgetProps(widget.props.uuid || widget.name)
    : // user dashboard
      getNocWidgetByName(widget.name);
};

const findWidgetGrid = (uuid) => (widget) => {
  let dash = dashboards.find((dash) => dash.uuid === uuid);
  let tWidget = dash?.widgets?.find?.((w) => w.name === widget.name);
  let grid = tWidget?.grid || {
    id: widget.name,
    cells: 2,
    sizeX: 8,
    sizeY: 8,
  };
  return grid;
};

const getAllWidgetTempl = (templateId) => {
  const templates = templateId
    ? [getTemplateById(templateId)]
    : getNocTemplates();
  return templates.reduce((res, dash) => {
    dash?.widgets?.forEach((widget) => {
      res[widget.name] = { ...widget };
    });
    return res;
  }, {});
};

export const updateDashPayload = (payload) => {
  let dashData = payload['dash-data'] || {};
  let templateId = payload['is-template']
    ? payload.uuid
    : payload['created-from'];
  const template = dashboards.find((dash) => dash.uuid === templateId);
  const widgetTemplMap = getAllWidgetTempl(templateId);
  // in case a widget is removed/changed
  // especially from a blank custom view, can't rely on current template
  const widgets = (payload.widgets || [])
    .map((widget) => {
      const widgetTmpl = widgetTemplMap[widget.name];
      if (widgetTmpl) {
        return {
          ...widgetTmpl,
          ...widget,
        };
      }
      if (widget.props.isDataset) {
        const customWidget = fiStoreSoc.customWidget.selectors.getCustomWidget(
          widget.props.uuid
        )(fiStore.getState());
        if (customWidget) {
          return { ...widget };
        }
      }
      return null;
    })
    .filter((x) => x);
  const state = fiStore.getState();
  const adomOid = fiSession.getSessionAdomOid(state);
  let updatedPayload = {
    ...payload,
    templateId,
    devOsTypes: template?.devOsTypes,
    adoms: template?.adoms,
    licenses: template?.licenses,
    timePeriodType: template?.timePeriodType || 'normal',
    isDeviceHidden: template?.isDeviceHidden,
    isDateTimeHidden: template?.isDateTimeHidden,
    hasIndustrySelector: template?.hasIndustrySelector,
    hasInterfaceSelector: template?.hasInterfaceSelector,
    isCsf: template?.isCsf,
    isSdWan: template?.isSdWan,
    'dash-data': {
      selectedTime: dashData.selectedTime,
      devices:
        templateId === 'faz-performance-monitor'
          ? ['All_Device']
          : dashData.devices,
      industries: dashData.industries,
      intf: dashData.intf,
    },
    widgets: widgets.map((widget) => {
      if (!widget.grid?.cells) {
        widget.grid = findWidgetGrid(templateId)(widget);
      }
      let props = getWidgetProps(templateId)(widget);
      return {
        ...widget,
        props: props || {},
      };
    }),
    _currentAdomOid: adomOid,
  };
  return updatedPayload;
};

export const getFtvTemplates = () => ftvDashboards;

const filterTemplatesByFeature = (templates) => {
  const state = fiStore.getState();
  let adomType = fiSession.getSessionAdomData(state).type;
  return templates
    .filter((dashboard) => {
      return (
        (fiSession.hasFazFeature(state) &&
          dashboard.faz &&
          (!dashboard.disableFmgHasFaz ||
            (dashboard.disableFmgHasFaz && !fiSession.isFmg(state)))) ||
        (fiSession.hasFmgFeature(state) && dashboard.fmg) ||
        (fiSession.hasRemoteFAZ(state) && dashboard.faz)
      );
    })
    .filter(
      (dashboard) =>
        adomType === MACROS.DVM.DVM_RESTRICTED_PRD_FSF ||
        (!dashboard.adoms && adomType === MACROS.DVM.DVM_RESTRICTED_PRD_FOS) ||
        (dashboard.adoms &&
          (dashboard.adoms.includes(MACROS.DVM.DVM_RESTRICTED_PRD_FSF) ||
            dashboard.adoms.includes(adomType)))
    )
    .map((dashboard) => {
      return {
        ...dashboard,
        widgets: filterWidgetsByFeature(dashboard.widgets),
      };
    });
};

export const getNocTemplates = () => filterTemplatesByFeature(nocDashboards);

export const getTemplateById = (dashId) => {
  let nocTemplates = getNocTemplates();
  let ftvTemplates = getFtvTemplates();
  return [...nocTemplates, ...ftvTemplates].find(
    (temp) => temp.uuid === dashId
  );
};
