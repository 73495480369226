import React from 'react';

import { ReactContentViewContainer } from 'react_apps/ra_main/main_layout/ReactContentViewContainer';

export const monitorsRoute = {
  path: 'monitors',
  // element: <TemplateModuleToolbar />,
  handle: {
    appUniKey: 'dvm_monitors',
  },
  element: <ReactContentViewContainer menuId='dvm_monitors' />,
  children: [
    // {
    //   path: 'sdwan',
    //   handle: {
    //     appUniKey: 'dvm_monitors_sdwan',
    //   },
    //   lazy: async () => {
    //     const { SDWANMonitorRoot } = await import(
    //       /* webpackChunkName: "mod-dvm_monitors_sdwan" */ 'react_apps/ra_sdwan/sdwan_monitor_root/SDWANMonitorRoot'
    //     );

    //     return { Component: SDWANMonitorRoot };
    //   },
    // },
    {
      path: 'vpn',
      handle: {
        appUniKey: 'dvm_monitors_vpn',
      },
      lazy: async () => {
        const { VPNMonitorMap } = await import(
          /* webpackChunkName: "mod-dvm_monitors-vpn" */ 'react_apps/ra_vpn/ipsec_vpn/ipsec_map_monitor/VPNMonitorMap'
        );

        return { Component: () => <VPNMonitorMap rInDVM={true} /> };
      },
    },
    {
      path: 'inventory',
      handle: {
        appUniKey: 'dvm_monitors_inventory',
      },
      lazy: async () => {
        const { DeviceInventory } = await import(
          /* webpackChunkName: "mod-dvm_monitors-device-inventory" */ 'react_apps/ra_dvm_components/monitor/inventory/DeviceInventory'
        );

        return { Component: DeviceInventory };
      },
    },
    {
      path: 'lte',
      handle: {
        appUniKey: 'dvm_monitors_lte',
      },
      lazy: async () => {
        const { LteMonitor } = await import(
          /* webpackChunkName: "mod-dvm_monitors-lte" */ 'react_apps/ra_dvm/monitor/lte/lteMonitor'
        );

        return { Component: LteMonitor };
      },
    },
  ],
};
