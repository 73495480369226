import { Fragment } from 'react';
import _ from 'lodash';
import cn from 'classnames';

import { ProLego } from '@fafm/neowise-pro';
const { Tooltip } = ProLego;

// entry structure:
// {
//   label: string,
//   value: string | Function,
//   condition: boolean | Function,
//   renderer: Function,
// }
export function SimpleTableTooltip({ entries, style = {}, className }) {
  return (
    <div
      className={cn('tw-gap-2', className)}
      // label column cannot exceed 50% of the width
      style={{
        display: 'grid',
        gridTemplateColumns: 'fit-content(50%) auto',
        maxWidth: '550px',
        ...style,
      }}
    >
      {entries.map(
        ({ label, value, condition, renderer, defaultVal = 'N/A' }) => {
          // only render if condition is true
          if (
            (_.isFunction(condition) && !condition()) ||
            (!_.isUndefined(condition) && !condition)
          ) {
            return null;
          }

          let content = _.isFunction(value) ? value() : value;
          if (_.isUndefined(content)) {
            if (defaultVal) {
              content = defaultVal;
            } else {
              return null;
            }
          }

          const renderEntry = () => {
            if (_.isFunction(renderer)) return renderer();

            return (
              <>
                <div className='tw-text-neutral-500 tw-whitespace-nowrap'>
                  {label}
                </div>
                <div>{content}</div>
              </>
            );
          };

          return <Fragment key={label}>{renderEntry()}</Fragment>;
        }
      )}
    </div>
  );
}

export const ProLegoTooltipWrapper = ({
  children,
  isSimpleTooltip = true,
  ...tooltipConfig
}) => {
  const tooltipProps =
    isSimpleTooltip && tooltipConfig.entries
      ? make_simpleTooltipProps_2(tooltipConfig)
      : tooltipConfig;

  return (
    <Tooltip
      placement='right'
      distance={0}
      showDelay={300}
      hideDelay={600}
      {...tooltipProps}
    >
      <div className='tw-flex tw-items-center'>{children}</div>
    </Tooltip>
  );
};

export const make_simpleTooltipProps_2 = ({
  entries,
  style,
  className,
  ...rest
}) => {
  return {
    content: () => {
      return (
        <SimpleTableTooltip
          entries={entries}
          style={style}
          className={className}
        />
      );
    },
    ...rest,
  };
};
