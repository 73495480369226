import React, { useEffect, useRef } from 'react';
import {
  isFunction,
  Formik as OriginFormik,
  useFormikContextSelector,
  useFormikContext,
} from '@fafm/formik';

export * from '@fafm/formik';

function withScrollToErrorField(children, autoFocus) {
  return (formikBag) => {
    const formikBagRef = useRef();
    formikBagRef.current = formikBag;
    const placeholder = useRef(null);

    useEffect(() => {
      if (formikBag.isSubmitting) {
        // isSubmitting change from 'true' to 'false'
        // either there is error or submit successfully
        return () => {
          // timeout for cases that error input field is not visible and need to open 'nw-details'
          // and 'nw-details' has animation. example: 'AdministratorMetaFields.jsx'
          setTimeout(() => {
            const errorElSelector = getErrorNames(formikBagRef.current.errors)
              .map((name) => {
                return `[name="${name}"]`;
              })
              .join(',');

            const firstErrorEl =
              errorElSelector &&
              placeholder.current?.parentElement.querySelector(errorElSelector);
            focusElement(firstErrorEl);
          }, 500);
        };
      }
    }, [formikBag.isSubmitting]);

    // focus form input element
    useEffect(() => {
      // timeout to set focus. drawer takes focus after open
      autoFocus &&
        setTimeout(() => {
          const inputs =
            placeholder.current?.parentElement.querySelectorAll(
              '[automation-id]'
            ) || [];
          for (let node of inputs) {
            const input = node.querySelector('input');
            if (input) {
              node = input;
            }
            if (!node.disabled) {
              focusElement(node);
              break;
            }
          }
        }, 500);
    }, []);

    return (
      <>
        {isFunction(children) ? children(formikBag) : children}
        <div ref={placeholder} style={{ display: 'none' }} />
      </>
    );
  };
}

function focusElement(el) {
  el &&
    ((el.setFocus && el.setFocus()) || //nw-component
      (el.focus && el.focus()));
}

function getErrorNames(error = {}) {
  const names = [];

  for (let key in error) {
    const value = error[key];
    if (typeof value !== 'string') {
      getNestedPaths(value).forEach((suffix) => {
        names.push(key + suffix);
      });
    } else {
      names.push(key);
    }
  }
  return names;
}

function getNestedPaths(error) {
  const names = [];
  const isArray = Array.isArray(error);
  for (let key in error) {
    const value = error[key];
    const path = isArray ? '[' + key + ']' : '.' + key;

    if (typeof value === 'string') {
      names.push(path);
    } else if (value) {
      getNestedPaths(value).forEach((suffix) => {
        names.push(path + suffix);
      });
    }
  }
  return names;
}

export const Formik = ({ children, autoFocus = true, ...rest }) => (
  <OriginFormik {...rest}>
    {withScrollToErrorField(children, autoFocus)}
  </OriginFormik>
);
Formik.displayName = 'FormikWithScrollToErrorField';

export const FormikConsumer = ({ children, selector }) => {
  let selected;
  if (!selector) {
    selected = useFormikContext();
  } else {
    selected = {};
    for (let key in selector) {
      selected[key] = useFormikContextSelector(selector[key]);
    }
  }
  return <>{children(selected)}</>;
};
FormikConsumer.displayName = 'FormikWithSelector';
