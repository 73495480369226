export const CODEMIRROR_MODE = {
  text: 'text',
  htmlmixed: 'htmlmixed',
  jinja2: 'jinja2',
  cli: 'cli',
};

export const CM_TXT_MAP = {
  find_previous: gettext('Find Previous ' + '(Ctrl + Shift + G)'),
  find_next: gettext('Find Next ' + '(Ctrl + G)'),
  revert: gettext('Revert All Changes'),
  create_new_ds: gettext('Create New'),
};

export const DIFF_VIEW_CM_ID = 'diff-view-cm';
