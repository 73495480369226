import React, { useEffect, useState } from 'react';
import { NwButton, NwIcon } from '@fafm/neowise-core';
import { NwButtonGroup } from '@fafm/neowise/react';
import { fiMessageBox } from 'fi-messagebox';
import { fiWorkspace } from 'fi-workspace';
import { ProToolkit } from '@fafm/neowise-pro';
import { AdomSelectModal, getLockStyle } from 'ra_adom_edit';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAccessibleAdomCount,
  getAllowChangeAdom,
  getIsAdomEnabled,
  getIsHaSlave,
  getIsRestrictedAdmin,
} from 'fistore/session/sysConfig/selectors';
import { hasRDWRPermitOn } from 'fistore/session/profile/selectors';
import { switchSessionAdom } from 'fistore/session/adom/slice';
import {
  getSessionAdomName,
  getSessionAdom,
} from 'fistore/session/adom/selectors';
import { fiStoreRouting as fr, fiStore } from 'fistore';
import { frGo } from 'fi-routing';

import { showWorkspaceLockError } from 'fi-apps/fi-workspace/confirm';

export const AdomSelect = () => {
  const dispatch = useDispatch();
  const allowChangeAdom = useSelector(getAllowChangeAdom);
  const isRestrictedAdmin = useSelector(getIsRestrictedAdmin);
  const hasAdomLock = useSelector(
    hasRDWRPermitOn('adom_lock', { fmgFabricCheck: false })
  );
  const isHaSlave = useSelector(getIsHaSlave);
  const accessibleAdomCount = useSelector(getAccessibleAdomCount);
  const isAdomEnabled = useSelector(getIsAdomEnabled);
  const adomName = useSelector(getSessionAdomName);
  const adom = useSelector(getSessionAdom);

  const [enableChangeAdom, setEnableChangeAdom] = useState(true);
  const [lockStyle, setLockStyle] = useState({ btnType: 'primary' });
  const [showLock, setShowLock] = useState(false);
  const [showUnlock, setShowUnlock] = useState(false);
  const [disabeLock, setDisableLock] = useState(false);

  const enableLockAdom = isRestrictedAdmin || (hasAdomLock && !isHaSlave);

  const reloadExtension = () => {
    const currentState = fr.getCurrentState(fiStore.getState());
    if (currentState?.pathname.includes('/extension')) {
      frGo('/extension/dashboard');
    }
  };
  const lock = () => {
    return fiWorkspace
      .adomLock()
      .then(() => {
        reloadExtension();
      })
      .catch(showWorkspaceLockError);
  };

  const unlock = () => {
    return fiWorkspace
      .adomUnlock()
      .then(() => {
        reloadExtension();
      })
      .catch(showWorkspaceLockError);
  };

  const _enableChange = allowChangeAdom && enableChangeAdom;

  const changeAdomClick = async () => {
    let _enableChangeAdom = false;
    const adomCount = accessibleAdomCount;
    if (adomCount > 1) {
      try {
        const selectedAdom = await ProToolkit.openModal(
          <AdomSelectModal allowCancel={true} />,
          { size: 'lg', closable: true, maximizable: false }
        ).result;

        // Happens when user clicks "OK".
        await dispatch(switchSessionAdom(selectedAdom.oid));
      } catch (err) {
        // user close
        // console.error(err);
      } finally {
        // no matter the user clicks ok or cancel, recover the button state.
        _enableChangeAdom = true;
      }
    } else {
      // happens when there is only one adom.
      if (adomCount === 1) {
        fiMessageBox.show(gettext('You have only one ADOM.'), 'danger');
      }
      _enableChangeAdom = true;
    }
    setEnableChangeAdom(_enableChangeAdom);
  };

  const isWsEnabled = () => fiWorkspace.isWorkspaceEnabled();

  // init
  useEffect(() => {
    // setCurrentAdom(adom);
    const unBindWorkspaceRedux = fiWorkspace.bindAdomUpdateFn((props) => {
      if (!props || fiWorkspace.isWorkspaceDisabled()) {
        setShowLock(false);
        setShowUnlock(false);
        setLockStyle({});
        return;
      }

      if (!adom.loaded) {
        return;
      }
      // update adom lock status
      // let adom = fiAdom.current();
      // setCurrentAdom(adom);
      const info = props.get(adom.data);

      const lockInfo = {
        lock: info.adom.lock,
        user: info.adom.lock_user,
        time: info.adom.lock_time,
        other: info.adom.other,
      };
      const ipsTypeLock = info.isIPSTypeLock();
      if (ipsTypeLock && !info.isLockedByMe()) {
        lockInfo.lock = MACROS.SYS.LOCK_STATE_UNLOCKED;
        (lockInfo.user = ''), (lockInfo.time = '');
      }

      // if it is ips admin,
      // if it is locked
      // but it is not locked by ips admin,
      // then need to disable lock button
      setDisableLock(isRestrictedAdmin && info.isLocked() && !ipsTypeLock);

      // has locked adom
      setLockStyle(
        getLockStyle({
          adomLock: lockInfo.lock,
          lockBy: lockInfo.user,
          lockTime: lockInfo.time,
          otherLocks: lockInfo.other,
        })
      );
      setShowLock(!info.isLockedByMe());
      setShowUnlock(info.isLockedByMe());
    });

    return () => unBindWorkspaceRedux();
  }, [adom, adomName, isRestrictedAdmin]);

  if (isWsEnabled() || isAdomEnabled) {
    return (
      <NwButtonGroup
        id='adom_select'
        title={lockStyle.text}
        class='tw-mx-2.5 tw-min-w-max'
      >
        {showLock && (
          <NwButton
            id='id_lock_btn'
            disabled={!enableLockAdom || disabeLock}
            className={lockStyle.style}
            onClick={lock}
            title={lockStyle.text}
            size='medium'
            type={lockStyle.btnType}
            automation-id='adom-lock'
          >
            <NwIcon name={lockStyle.icon} className='button-text-color' />
          </NwButton>
        )}

        {showUnlock && (
          <NwButton
            id='id_unlock_btn'
            disabled={!enableLockAdom}
            className={lockStyle.style}
            onClick={unlock}
            title={gettext('Click to unlock')}
            size='medium'
            type={lockStyle.btnType}
            automation-id='adom-unlock'
          >
            <NwIcon name={lockStyle.icon} className='button-text-color' />
          </NwButton>
        )}

        {isAdomEnabled ? (
          <NwButton
            id='id_adom_selector'
            className={lockStyle.style}
            disabled={!_enableChange}
            onClick={() => changeAdomClick()}
            title={gettext('Switch ADOM (Current ADOM: %s)').printf([adomName])}
            size='medium'
            type={lockStyle.btnType}
            automation-id='adom-selector'
          >
            <span className='tw-max-w-xs tw-font-bold tw-truncate button-text-color'>
              ADOM: {adomName}
            </span>
          </NwButton>
        ) : null}

        {isWsEnabled() && !isAdomEnabled && (
          <div className='tw-flex tw-items-center tw-mx-2'>
            {' '}
            {gettext('Workspace')}
          </div>
        )}
      </NwButtonGroup>
    );
  }
  return null;
};
