import goog from '@fafm/goog';
import { fiAdom, fiSysConfig } from 'fi-session';
import { sortBy, findElementInArrayByKey } from 'kit-array';
import { compareFirmwares } from 'kit-compare';
import { fiCache } from 'kit-cache';
import { fiDeviceDBFetcher, fiDeviceDataFetcher } from 'ra_device_util';
import { setInstlTarget } from 'ra_pno_pkg_util';
import { fiDvmFirmwareUpgradeService, fiFirmwareUpgradeAction } from 'fi-dvm';
import { fiDvmTableSort } from './dvmTableSort';
import { fiLocalStorageService } from 'fi-localstorage';
import {
  fiStoreConnector,
  fiStoreRouting as fr,
  fiStore,
  fiSession,
} from 'fistore';
import { frGo } from 'fi-web/fi-routing';
import { promiseWhen } from 'fiutil';
import { get, isObject, isUndefined, findIndex, capitalize } from 'lodash';
import moment from 'moment';

// eslint-disable-next-line
let _needReloadDeviceList = false;
function setNeedReloadDeviceList(needReload) {
  _needReloadDeviceList = needReload;
}
function despatchLazyReload() {
  if (_needReloadDeviceList) {
    return fiDeviceDBFetcher.reloadDevices();
  }
}
// compare selected upgrade image version with FMG version, only check major version and minor version.
const IGNORE_PATCH_NUMBER = true;

// Refresh device group cache after redux data change
fiStoreConnector((state) => ({
  deviceGroups: state.adom.deviceGroups,
  deviceGroupsMemb: state.adom.deviceGroupsMemb,
  defaultGroups: state.adom.defaultGroups,
  unregDevices: state.adom.unregDevices,
}))(() => {
  fiCache.clearDeviceGroupCache();
});

function getDevManagementVdomName(device) {
  let _mgtvdom = 'root';

  if (!device || !device._isDevice) {
    return null;
  }

  if (device.vdom_status === 1) {
    if (Array.isArray(device.vdoms)) {
      _mgtvdom = device.vdoms.find((x) => x.mgtvdom === 1);
    }
  }
  return _mgtvdom.name ? _mgtvdom.name : _mgtvdom;
}

function isDevHasManagementVdom(device) {
  let _isAdvancedAdom = fiSysConfig.isAdomAdvancedMode();
  if (!device || !device._isDevice) {
    return false;
  }
  if (_isAdvancedAdom && device.vdom_status === 1) {
    let _mgtvdom = null;
    if (Array.isArray(device.vdoms)) {
      _mgtvdom = device.vdoms.find((x) => x.mgtvdom === 1);
    }
    return _mgtvdom ? true : false;
  }
  return true;
}

function findDeviceFirstVdom(currentDevice, vdomName) {
  let _isAdvancedAdom = fiSysConfig.isAdomAdvancedMode();

  if (
    _isAdvancedAdom &&
    currentDevice &&
    currentDevice.vdom_status === 1 &&
    (vdomName === '' || vdomName === 'global')
  ) {
    let _mgtvdom = null;
    if (Array.isArray(currentDevice.vdoms)) {
      _mgtvdom = currentDevice.vdoms.find((x) => x.mgtvdom === 1);
    }
    // device management vdom is not always root.
    // In advanced Adom, 'global' means click on device only,
    // we need check if current device has management vdom,
    // if yes, returns vdomName without change.
    // if not, return the first vdom in current device.
    return _mgtvdom ? vdomName : get(currentDevice, 'vdoms.0.name');
  }
  // other cases return without change.
  return vdomName;
}

const fiDvmColorSet = {
  line1: '#2076B4',
  line2: '#1C42AC',
  line3: '#0A8F9B',
  line4: '#0091FF',
  line5: '#FF7F0F',
  line6: '#FFB20D',
  line7: '#0BC622',
  line8: '#09B9C9',
  line9: '#E44187',
  line10: '#6E61AF',
  line11: '#465161',
  line12: '#840BA8',
  line13: '#FF1B0F',
  red: '#FF1B0F',
  lineA: '#1F77B4',
  lineB: '#FF800E',
};

function getColorSet(type) {
  switch (type) {
    case 'seriesLine':
      return [
        fiDvmColorSet.line1,
        fiDvmColorSet.line2,
        fiDvmColorSet.line3,
        fiDvmColorSet.line4,
        fiDvmColorSet.line5,
        fiDvmColorSet.line6,
        fiDvmColorSet.line7,
        fiDvmColorSet.line8,
        fiDvmColorSet.line9,
        fiDvmColorSet.line10,
        fiDvmColorSet.line11,
        fiDvmColorSet.line12,
        fiDvmColorSet.line13,
        fiDvmColorSet.lineA,
        fiDvmColorSet.lineB,
      ];
    case 'markLine':
      return [
        fiDvmColorSet.line13,
        fiDvmColorSet.line12,
        fiDvmColorSet.line11,
        fiDvmColorSet.line10,
        fiDvmColorSet.line9,
      ]; // 5 colors most for each sla mark-line
  }
  return [];
}

// helper function to create a new char data object
function genNewChartData(
  title,
  name,
  type,
  chartid,
  key,
  timeMax,
  timeMin,
  unit
) {
  class cData {
    constructor(title, name, type, chartId, chartType, timeMax, timeMin, unit) {
      this.title = title;
      this.chartName = name;
      this.type = type;
      this.chartId = chartId;
      this.chartData = {};
      this.chartType = chartType;
      this.timeMax = timeMax;
      this.timeMin = timeMin;
      this.unit = unit;
      this.legend = [];
      this.hasData = false;
    }
  }
  return new cData(title, name, type, chartid, key, timeMax, timeMin, unit);
}

function getOptionsFromObject(object) {
  let _arr = [];
  for (let p in object) {
    if (object.hasOwnProperty(p)) {
      if (typeof object[p] === 'object') {
        _arr.push({ id: object[p].value, text: object[p].text });
      } else {
        _arr.push({ id: object[p], text: p });
      }
    }
  }
  return _arr;
}

/**
 * Return a generic function of m-select choices search.
 **/
function getMselectSearchFn(fields) {
  return function (data, searchVal) {
    return fields.some(function (field) {
      if (data[field].toLowerCase().indexOf(searchVal) !== -1) {
        return true;
      }
    });
  };
}

/**
 * Given a object contains some arrays, returns a array contains all attrs arrays
 * Connect array index with map
 * @param {Map} map - {key1: value1, key2:value2}
 * @return {Array, Map} {arr: [value1, value2], idxmap: {key1:0, key2:1}
 **/
function mergeArrayObject(obj) {
  let _arr = [];

  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      _arr = _arr.concat(obj[key]);
    }
  }
  return _arr;
}

/**
 * Given a map, returns a array and index map of the array
 * Connect array index with map
 * @param {Map} map - {key1: value1, key2:value2}
 * @return {} {arr: [value1, value2], idxmap: {key1:0, key2:1}
 **/
function mapToArray(map) {
  let _arr = [];
  let _idx = 0;
  let _indexMap = {};

  for (let key in map) {
    _arr.push(map[key]);
    _indexMap[key] = _idx;
    _idx++;
  }
  return { arr: _arr, idxmap: _indexMap };
}

function getPreferFwVerOptions(platform_str, adom_version, os_type) {
  const optList = [];
  const _specialImages = [],
    _specialImagesSet = new Set();
  const _officialImages = [];

  if (platform_str) {
    const parameter = {
      method: 'getOptions',
      params: {
        platform_str: platform_str,
      },
    };
    return fiDvmFirmwareUpgradeService
      .getAvaliableFirmwares(parameter)
      .then(function (resp) {
        const getSupportedVersions = () => {
          const sysCfg = fiSession.getSysConfig(fiStore.getState()),
            ret = [];
          const all =
            sysCfg.supported_adom_vers_by_os_type?.[os_type] ??
            sysCfg.supported_adom_vers;
          const foundIdx = findIndex(all, {
            ver: adom_version.ver,
            mr: adom_version.mr,
          });
          if (foundIdx < 0) return ret;
          const nextVer = all[foundIdx + 1];
          if (nextVer) ret.push(`${nextVer.ver}.${nextVer.mr}`);
          ret.push(`${adom_version.ver}.${adom_version.mr}`);
          const lastVer = all[foundIdx - 1];
          if (lastVer) ret.push(`${lastVer.ver}.${lastVer.mr}`);
          return ret;
        };

        const parseLocalFirmwareText = (path) => {
          const splitPath = path.split('/');
          return splitPath.pop();
        };

        const populateList = (verAdom, isSpecial) => {
          resp.forEach(function (item) {
            let _arr = item.id.split('_'); // parse version entry into array ['0#FortiGate-VM64', 5.0.13-b0322]
            let _isSpecialImage = parseInt(item.id[0]); // id which starts with '0' is official image, id starts with '1' is special image.
            let _ver, _ver0, _ver1;
            _ver = _arr[1] ? _arr[1].split('-b') : []; // parse version part ['5.0.13', '0322']
            _ver0 = _ver[0] ? _ver[0].split('.') : null; // to extract version and minor ['5', '0', '13']
            _ver1 = _ver0 ? `${_ver0[0]}.${_ver0[1]}` : null; // '5.0'
            if (_ver1 && compareFirmwares(_ver1, verAdom, '==')) {
              let idx = 0,
                s = _ver[1] || ''; // '0322' to '322'
              while (idx < s.length && s[idx] === '0') idx++;
              let build = s.substring(idx);
              let id = `${_ver[0]}-b${build}`;
              if (_isSpecialImage) {
                if (isSpecial) {
                  _specialImages.push({
                    id: id,
                    text: parseLocalFirmwareText(item.text),
                  });
                  _specialImagesSet.add(id);
                }
              } else {
                if (!isSpecial && !_specialImagesSet.has(id)) {
                  _officialImages.push({ id: id, text: _arr[1] });
                }
              }
            }
          });
        };
        const supported = getSupportedVersions();
        supported.forEach((v) => {
          populateList(v, true);
          populateList(v, false);
        });
        if (_officialImages.length > 0) {
          optList.push({
            id: 'fortiguard_images',
            text: gettext('FortiGuard Images'),
            children: _officialImages,
          });
        }
        if (_specialImages.length > 0) {
          optList.push({
            id: 'local_images',
            text: gettext('Local Images'),
            children: _specialImages,
          });
        }
        return optList;
      });
  }
  return promiseWhen(optList);
}

function assignModelDevToPkg(pkg, dev, isRemove) {
  return new Promise((resolve, reject) => {
    if (pkg) {
      // step 1 add device to install target
      let scope_member = isRemove
        ? []
        : [
            {
              oid: parseInt(dev.oid),
              vdom_oid: dev.vdom_oid
                ? parseInt(dev.vdom_oid)
                : MACROS.DVM.CDB_DEFAULT_ROOT_OID,
            },
          ];
      if (pkg['scope member']) {
        pkg['scope member'].forEach((member) => {
          if (isRemove && parseInt(dev.oid) === member.oid) {
            return; // skip removed device
          } else {
            scope_member.push(member);
          }
        });
      }
      setInstlTarget({
        adom: fiAdom.current(),
        'scope member': scope_member,
        path: pkg.name,
      }).then(
        function () {
          // step 2 set policy status to modified
          if (isRemove) {
            resolve();
          } else {
            fiDeviceDataFetcher.assignModelDevPkg(dev.name, pkg).then(
              function () {
                resolve();
              },
              function () {
                reject(gettext('Set policy package status failed'));
              }
            );
          }
        },
        function () {
          reject(gettext('Set policy package install target failed'));
        }
      );
    } else {
      resolve();
    }
  });
}

function getPkgFromList(list) {
  let pkgList = [];
  let getPkgFromFolder = function (folderObj, currentPath) {
    currentPath = !isUndefined(currentPath) ? currentPath : folderObj.name;
    folderObj.subobj.forEach((obj) => {
      if (obj.type === 'pkg') {
        obj.name = currentPath + '/' + obj.name;
        pkgList.push(obj);
      } else {
        if (!isUndefined(obj.subobj)) {
          getPkgFromFolder(obj, currentPath + '/' + obj.name);
        }
      }
    });
  };
  list.forEach((obj) => {
    if (obj.type === 'pkg') {
      pkgList.push(obj);
    } else {
      getPkgFromFolder(obj);
    }
  });
  return pkgList;
}

/**
 * Converts the selected table rows to scope members
 * @param {Array} selectedRows - the selected table rows
 * @return {Array} an array of scope members
 **/
function toScopeMember(selectedRows) {
  let scopeMember = [];
  for (let i = 0, l = selectedRows.length; i < l; i++) {
    let row = selectedRows[i];
    let obj = {};
    if (row._oData) {
      // when delete targets
      if (row._oData.did) {
        // is device
        obj.oid = parseInt(row._oData.did, 10);
        obj.vdom_oid = parseInt(row._oData.vid, 10) || 3;
      } else {
        // is group
        obj.oid = parseInt(row._oData.r_oid || row._oData.oid, 10);
      }
    } else {
      // when add targets
      obj.oid = parseInt(row.oid, 10);

      if (row.vdom_oid) {
        obj.vdom_oid = parseInt(row.vdom_oid, 10) || 3;
      }
    }
    scopeMember.push(obj);
  }
  return scopeMember;
}

/* function used for select and unselect child device when parent device is selected or unselected
       parameter : selectedRow: current selected row,
                   dataSet: all rows dataset, normally it is safe_source of the fist-table
                   select: if true, all child devices select, if false, all child devices unselect
                   tableCtrl: fist-table controller, used for operating fi-table

    */
function selectRelatedRows(selectedRow, dataSet, select, tableCtrl) {
  // skip select all case
  if (selectedRow instanceof Set || Array.isArray(selectedRow)) {
    return;
  }
  // skip group header
  if (selectedRow.isHeader) {
    return;
  }
  // sometimes there is no oid instead of did
  let oid = selectedRow._oData.oid
    ? selectedRow._oData.oid
    : selectedRow._oData.did;

  if (selectedRow && dataSet) {
    // global device level is 1
    if (selectedRow._level === 1) {
      // find all its vdom devices by its oid
      let vdomNumber = selectedRow._oData.vdoms
        ? selectedRow._oData.vdoms.length
        : 0;
      for (let i = 0; i < dataSet.length; ++i) {
        if (0 === vdomNumber) {
          break;
        }
        if (dataSet[i]._level > 1 && dataSet[i]._oData.did === oid) {
          tableCtrl.select(dataSet[i], 'multiple', 'selected_by_logic', select);
          vdomNumber--;
        }
      }
    } else if (selectedRow._level === 2) {
      // select vdom level
      let parentDev = null;
      let parentOid = -1;
      for (let k = 0; k < dataSet.length; ++k) {
        if (dataSet[k]._level === 1) {
          parentOid = dataSet[k]._oData.oid
            ? dataSet[k]._oData.oid
            : dataSet[k]._oData.did;
          if (parentOid === selectedRow._oData.did) {
            parentDev = dataSet[k];
            break;
          }
        }
      }

      if (select) {
        // select a vdom, it's parent device should be selected too
        tableCtrl.select(parentDev, 'multiple', 'selected_by_logic', true);
      } else {
        // unselect a vdom, it's parent device should be unselected if it's all vdoms are unselected
        let rows = tableCtrl.getSelectedRows();
        let noSelectedVdom = true;

        for (let j = 0; j < rows.length; ++j) {
          if (rows[j]._level > 1 && rows[j]._oData.did === parentOid) {
            // a selected row is vdom and its parent is parentDev
            noSelectedVdom = false;
            break;
          }
        }
        if (noSelectedVdom) {
          tableCtrl.select(parentDev, 'multiple', 'selected_by_logic', false);
        }
      }
    }
  }
}

/**
 * Handles auto selection logic for device/vdom relations (JS table)
 *
 * @param {*} newRows Newly selected entries
 * @param {*} dataSet All available entries
 * @param {*} accessor Table accessor (For executing table operations)
 */
function selectRelatedRowsJsTable(newRows, deletedRows, dataSet, accessor) {
  // Entries to be auto selected/unselected
  let entriesToBeChecked = [];
  let entrySet = [];

  // Perform select or unselect logic
  if (!Array.isArray(newRows)) newRows = [newRows];
  if (!Array.isArray(deletedRows)) deletedRows = [deletedRows];

  let select;
  if (newRows.length && deletedRows.length) {
    select = true;
  } else {
    select = newRows.length > 0;
  }
  entriesToBeChecked = select ? newRows : deletedRows;

  // Auto select/unselect related rows
  entriesToBeChecked.forEach((selectedRow) => {
    // Skip group header
    if (selectedRow.isHeader) return;

    // Sometimes there is no oid instead of did
    let oid = selectedRow._oData.oid
      ? selectedRow._oData.oid
      : selectedRow._oData.did;
    if (selectedRow && dataSet) {
      // Case1: A device level entry is selected/unselected
      if (selectedRow._level === 1) {
        // Find all its vdom devices by its oid
        let vdomNumber = selectedRow._oData.vdoms
          ? selectedRow._oData.vdoms.length
          : 0;

        // Select/Unselect all of its vdom entries
        for (let i = 0; i < dataSet.length; ++i) {
          if (0 === vdomNumber) {
            break;
          }
          if (dataSet[i]._level > 1 && dataSet[i]._oData.did === oid) {
            entrySet.push(dataSet[i]);
            vdomNumber--;
          }
        }
        // Case2: A vdom level entry is selected
      } else if (selectedRow._level === 2) {
        // Find parent entry
        let parentDev = null;
        let parentOid = -1;
        for (let k = 0; k < dataSet.length; ++k) {
          if (dataSet[k]._level === 1) {
            parentOid = dataSet[k]._oData.oid
              ? dataSet[k]._oData.oid
              : dataSet[k]._oData.did;
            if (parentOid === selectedRow._oData.did) {
              parentDev = dataSet[k];
              break;
            }
          }
        }

        if (select) {
          // Select a vdom, it's parent device should be selected too
          entrySet.push(parentDev);
        } else {
          // Unselect a vdom, it's parent device should be unselected if it's all vdoms are unselected
          let rows = accessor.getSelectedRows();
          let noSelectedVdom = true;

          for (let j = 0; j < rows.length; ++j) {
            if (rows[j]._level > 1 && rows[j]._oData.did === parentOid) {
              // another selected row is vdom and its parent is parentDev
              noSelectedVdom = false;
              break;
            }
          }
          if (noSelectedVdom) {
            entrySet.push(parentDev);
          }
        }
      }
    }
  });
  accessor.select(entrySet, select);
}

function genUUID() {
  function s42() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(2);
  }
  return s42().trim() + '_' + s42().trim() + '_' + s42().trim() + '_';
}

function getTimeStr(withDate) {
  return withDate
    ? moment(new Date()).format('yyyy-MM-ddTHH:mm:ss')
    : moment(new Date()).format('HH:mm:ss');
}

function log() {}

function _getDvmListStateName() {
  return fiSysConfig.isFaz() ? '/dvm/main/fazgroups' : '/dvm/main/groups';
}

function refreshDevList(force) {
  const state = fr.getCurrentState(fiStore.getState());

  // clear dev group cache
  fiCache.clearDeviceGroupCache();

  let dvmListStateName = _getDvmListStateName();
  if (force) {
    fiDeviceDBFetcher.fetchFullDeviceList(fiAdom.current().oid, force);
  } else {
    fiDeviceDBFetcher.reloadDevices();
  }
  frGo(dvmListStateName, state.params);
  setNeedReloadDeviceList(false);
}

function refreshGrpDevList() {
  const state = fr.getCurrentState(fiStore.getState());
  // clear dev group cache
  fiCache.clearDeviceGroupCache();

  let adom = fiAdom.current();
  let dvmListStateName = _getDvmListStateName();
  if (adom.is_others) {
    frGo('/dvm/main/groups', state.params);
  } else {
    frGo(dvmListStateName, state.params);
  }
}

function reloadGroupNDeviceList() {
  fiDeviceDBFetcher.reloadDevices().finally(function () {
    refreshGrpDevList();
  });
}

function deviceTreeConfig(data, adom) {
  adom = adom || fiAdom.current();

  let STORAGE_ID = 'fiDVMDeviceTreeConfig:' + (adom.name || adom);
  let STORAGE_KEY = 'TreeViewStatus';

  // setter
  if (data) {
    fiLocalStorageService.setObject(STORAGE_ID, STORAGE_KEY, data);
  }

  return fiLocalStorageService.getObject(STORAGE_ID, STORAGE_KEY);
}

/*  Function to get all interface admin access options
        parameter : accessSet is undefined, returns all options
                    accessSet is attrName array, returns selected options in array
                    accessSet is attrName, return the attr in options

                    {"https" $ADMACCESS_HTTPS}
                    {"ping" $ADMACCESS_PING}
                    {"ssh" $ADMACCESS_SSH}
                    {"snmp" $ADMACCESS_SNMP}
                    {"http" $ADMACCESS_HTTP}
                    {"telnet" $ADMACCESS_TELNET}
                    {"fgfm" $ADMACCESS_FGFM}
                    {"auto-ipsec" $ADMACCESS_AUTO_IPSEC}
                    {"radius-acct" $ADMACCESS_RADIUS_ACCT}
                    {"probe-response" $ADMACCESS_PROBE_RESPONSE}
                    {"capwap" $ADMACCESS_CAPWAP}
                    {"dnp" $ADMACCESS_DNP}
                    {"ftm" $ADMACCESS_FTM}
                    {'fabric' MACROS.PM2CAT.PM2_ADMACCESS_FABRIC}
    */
function getIntfAdminAccessOptions(accessSet) {
  let options = {
    https: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_HTTPS,
      txt: 'HTTPS',
      checked: false,
      show: true,
    },
    ping: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_PING,
      txt: 'PING',
      checked: false,
      show: true,
    },
    ssh: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_SSH,
      txt: 'SSH',
      checked: false,
      show: true,
    },
    snmp: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_SNMP,
      txt: 'SNMP',
      checked: false,
      show: true,
    },
    http: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_HTTP,
      txt: 'HTTP',
      checked: false,
      show: true,
      msg: gettext('HTTP traffic will be automatically redirected to HTTPS'),
    },
    telnet: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_TELNET,
      txt: 'TELNET',
      checked: false,
      show: true,
    },
    fgfm: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_FGFM,
      txt: 'FMG-Access',
      checked: false,
      show: true,
    },
    'auto-ipsec': {
      value: MACROS.PM2CAT.PM2_ADMACCESS_AUTO_IPSEC,
      txt: 'Auto-Ipsec',
      checked: false,
      show: true,
    },
    'radius-acct': {
      value: MACROS.PM2CAT.PM2_ADMACCESS_RADIUS_ACCT,
      txt: gettext('RADIUS Accounting'),
      checked: false,
      show: true,
    },
    'probe-response': {
      value: MACROS.PM2CAT.PM2_ADMACCESS_PROBE_RESPONSE,
      txt: gettext('Probe Response'),
      checked: false,
      show: true,
    },
    dnp: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_DNP,
      txt: 'DNP',
      checked: false,
      show: true,
    },
    ftm: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_FTM,
      txt: 'FTM',
      checked: false,
      show: true,
    },
    capwap: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_CAPWAP,
      txt: 'CAPWAP',
      checked: false,
      show: true,
    },
    fortitelemetry: {
      value: 'fortitelemetry',
      txt: 'FortiTelemetry',
      checked: false,
      show: true,
    },
    fabric: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_FABRIC,
      txt: gettext('Security Fabric Connection'),
      checked: false,
      show: true,
      msg: gettext(
        'Security Fabric Connection Combines CAPWAP and FortiTelemetry'
      ),
    },
    'speed-test': {
      value: MACROS.PM2CAT.PM2_ADMACCESS_SPEED_TEST,
      txt: gettext('Speed Test'),
      checked: false,
      show: true,
    },
    scim: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_SCIM,
      txt: 'SCIM',
      checked: false,
      show: true,
    },
    icond: {
      value: MACROS.PM2CAT.PM2_ADMACCESS_ICOND,
      txt: gettext('Industrial Connectivity'),
      checked: false,
      show: true,
    },
  };

  // accessSet is name
  if (typeof accessSet === 'string') {
    return options[accessSet];
  }
  // accessSet is syntax object
  if (isObject(accessSet)) {
    let _supportOpts = [];
    for (let p in accessSet) {
      if (options[p]) {
        _supportOpts.push(options[p]);
      } else {
        _supportOpts.push({
          value: accessSet[p],
          txt: capitalize(p),
          id: p,
          checked: false,
          show: true,
        });
      }
    }
    sortBy(_supportOpts, 'value');
    return _supportOpts;
  }
  // access is not defined, returns all options
  if (isUndefined(accessSet)) {
    let _supportOpts = [];
    for (let property in options) {
      if (options.hasOwnProperty(property)) {
        _supportOpts.push(options[property]);
      }
    }
    return _supportOpts;
  }
  return null;
}

function checkUpgradePath(
  upgradePathMap,
  chkVerObj,
  currVerObj,
  thisFMGVersion
) {
  let currVer = currVerObj.version;
  let currBuild = currVerObj.build.toString();
  let currModel = currVerObj.model;
  let unknown = {
    status: fiFirmwareUpgradeAction.UNKNOWN,
    msg: gettext(
      'The firmware version is not on firmware upgrade path of the current device. Upgrading the image may cause the current device syntax to break.'
    ),
  };

  try {
    let notRecommendPath = {
      status: fiFirmwareUpgradeAction.UPGRADE_ON_PATH,
      msg: gettext(
        'The firmware version is not on firmware upgrade path of the current device. Upgrading the image may cause the current device syntax to break.'
      ),
    };
    let recommended = {
      status: fiFirmwareUpgradeAction.UPGRADE_RECOMMEND,
      msg: null,
    };
    let downgrade = {
      status: fiFirmwareUpgradeAction.DOWNGRADE,
      msg: gettext(
        'The firmware version is older than the current device, downgrading is not recommended.'
      ),
    };

    if (compareFirmwares(chkVerObj.version, currVer, '<')) {
      return downgrade;
    } else if (compareFirmwares(chkVerObj.version, currVer, '==')) {
      if (parseInt(currBuild) < parseInt(chkVerObj.build)) {
        return downgrade;
      } else {
        return notRecommendPath; // check version equals current version but build >= current version.
      }
    }
    // chkVerObj.version is bigger than current version and find out if the checked version is on upgrade path
    // also recommend version should lower than current FMG version.
    let _upgvers = upgradePathMap[currModel][currVer][currBuild];
    for (let i = 0; i < _upgvers.length; ++i) {
      if (
        _upgvers[i].upgradeVer === chkVerObj.version &&
        parseInt(_upgvers[i].upgradeBuild) === parseInt(chkVerObj.build) &&
        compareFirmwares(
          chkVerObj.version,
          thisFMGVersion,
          '<=',
          IGNORE_PATCH_NUMBER
        )
      ) {
        return recommended;
      }
    }
    return notRecommendPath;
    // eslint-disable-next-line
  } catch (e) {
    return unknown;
  }
}
// according to device model and upgrade path, gives the best recommend available upgrade version.
function getTheLatestUpgVersion(upgradePathMap, currVerObj) {
  try {
    let currVer = currVerObj.version;
    let currBuild = currVerObj.build.toString();
    let currModel = currVerObj.model;

    if (
      upgradePathMap &&
      upgradePathMap[currModel] &&
      upgradePathMap[currModel][currVer]
    ) {
      upgradePathMap[currModel][currVer][currBuild].sort(function (a, b) {
        return goog.string.compareVersions(a.upgradeVer, b.upgradeVer);
      });
      let _len = upgradePathMap[currModel][currVer][currBuild].length;
      if (_len > 0) {
        return upgradePathMap[currModel][currVer][currBuild][_len - 1];
      }
    }
    return null;
  } catch (e) {
    return null;
  }
}

// Find out the recommend versions according the bestUpgradePath value and dev list
// function returns a map {'5.6.3':1, '6.0.1':4 .....} that finds all best upgrade versions from upgrade path file.
function findRecommendVerionsFromDevList(
  devs,
  bestPathMap,
  platformAbbrs,
  bestVersions
) {
  let _map = {};
  try {
    devs.forEach((dev) => {
      if (dev._oData) {
        dev = dev._oData;
      }
      const firmwareUpgrade = dev.firmware_upgrade || {};
      const curVerObj = {
        version: firmwareUpgrade.curr_ver?.split(' ')?.[0],
        build: firmwareUpgrade.curr_build || dev._build,
      };
      const currentModel = findElementInArrayByKey(
        platformAbbrs,
        'oid',
        dev.devoid || dev.oid || dev.did
      )?.abbr;
      const builds = get(bestPathMap, [
        currentModel,
        curVerObj.version,
        curVerObj.build,
      ]);
      if (builds) {
        builds.forEach((item) => {
          let key = item.upgradeVer + '-' + item.upgradeBuild;
          if (_map[key]) {
            _map[key] += 1;
          } else {
            _map[key] = 1;
          }
        });
      }
    });
    // If a version count match device list number, that version is the best choices of all devices in the list.
    for (let v in _map) {
      if (_map.hasOwnProperty(v) && _map[v] === devs.length) {
        const [version, build] = v.split('-');
        bestVersions.push({
          version,
          build,
        });
      }
    }
    // eslint-disable-next-line
  } catch (e) {
    return null;
  }
}

function filterFAZDevOrGrp(data, filterGrp) {
  if (filterGrp) {
    return data.filter((d) => d.oid !== MACROS.DVM.DVM_GRP_REMOTE_FAZ_OID);
  } else {
    return data.filter((d) => d.os_type !== MACROS.DVM.DVM_OS_TYPE_FAZ);
  }
}
/**
 *@description append group header for a table data list
 *@param <string> dataArray: table data list
 *@param <string> index: index of the header
 *@param <string> groupName: group name
 *@param <string> label: displayed group name
 *@param <string> isCollapsed : is group collapsed by default
 *@return data array
 */
function _appendGroupHeader(
  dataArray,
  index,
  label,
  cnt,
  groupName,
  isCollapsed
) {
  let _gitem = {
    groupName: groupName,
    groupSpan: cnt,
    isGroup: 1,
    isHeader: 1,
    txt: label,
    name: groupName,
    selectable: false,
    collapsed: isCollapsed,
    _level: 0,
  };
  return dataArray.splice(index, 0, _gitem);
}

function parseUpgradeFirmware(data) {
  let _officials = [];
  let _specials = [];
  let _result = [];
  if (data && Array.isArray(data)) {
    data.forEach((ele) => {
      ele.isGroup = 0;
      if (ele.type === 'GA') {
        if (ele.image_path) {
          let tmpEle = Object.assign({}, ele);
          let bIndex = tmpEle.version.indexOf('b');
          tmpEle.version =
            tmpEle.version.substr(0, bIndex + 1) +
            '0' +
            tmpEle.version.substr(bIndex + 1);
          tmpEle.groupName = 'special';
          _specials.push(tmpEle);
        }
        ele.groupName = 'official';
        _officials.push(ele);
      } else {
        ele.groupName = 'special';
        _specials.push(ele);
      }
    });
    _specials.length &&
      _appendGroupHeader(
        _specials,
        0,
        gettext('Special Images'),
        _specials.length,
        'special',
        false
      );
    _appendGroupHeader(
      _officials,
      0,
      gettext('Official Images'),
      _officials.length,
      'official',
      false
    );
  }
  _result = fiDvmTableSort(
    [..._specials, ..._officials],
    'version',
    true,
    false
  );
  return _result;
}

/**
 *  Validate selected firmware:
 *  1. Device license;
 *  2. selected firmware is supported by current FMG.
 *  return object warning messages.
 **/
function upgradeFirmwareValidate(firmware, device) {
  let _ret = {
    msgCustomImage: null,
    notSupportedVersion: false,
    msgSupportedVersion: '',
    invalid_license: false,
    msgInvalidLicense: '',
  };

  const is_license_valid = (dev) => {
    if (dev && dev['firmware_upgrade'].is_license_valid) {
      return true;
    } else if (
      dev &&
      dev.license &&
      dev.license.status === MACROS.USER.DVM.LIC_VALID
    ) {
      return true;
    }
    return false;
  };
  // ==================== device warning =====================================================
  if (device && !is_license_valid(device)) {
    _ret.invalid_license = true;
    _ret.msgInvalidLicense = gettext(
      "Current device don't have firmware upgrade license."
    );
  }
  // ==================== firmware warning =====================================================
  let _thisFMGVer = fiSysConfig.getThisFMGVersion().version;
  let _earliest = fiSysConfig.earliest_supported_adom_version();
  let _earliestSupportVerStr = _earliest.ver + '.' + _earliest.mr;
  // check FMG version and upgrade image version, skip local image check
  if (firmware.type !== 'GA') {
    _ret.msgCustomImage = gettext('You selected a customized firmware image.');
  } else if (
    compareFirmwares(
      firmware.version,
      _earliestSupportVerStr,
      '<',
      IGNORE_PATCH_NUMBER
    )
  ) {
    // compare selected upgrade image version and earliest adom version, only check major version and minor version.
    _ret.notSupportedVersion = true;
    _ret.msgSupportedVersion = gettext(
      'This FortiManager does not support the selected firmware version.'
    );
  } else if (
    _thisFMGVer &&
    firmware.version &&
    compareFirmwares(_thisFMGVer, firmware.version, '<', IGNORE_PATCH_NUMBER)
  ) {
    // compare selected upgrade image version and FMG version, only check major version and minor version.
    _ret.notSupportedVersion = true;
    _ret.msgSupportedVersion = gettext(
      'This FortiManager does not support the selected firmware version. Please upgrade the FortiManager firmware first before upgrade the managed FGT.'
    );
  }
  return _ret;
}

export const fiDvmUtils = {
  getTimeStr: getTimeStr,
  log: log,
  genUUID: genUUID,
  refreshDevList: refreshDevList,
  refreshGrpDevList: refreshGrpDevList,
  reloadGroupNDeviceList: reloadGroupNDeviceList,
  setNeedReloadDeviceList: setNeedReloadDeviceList,
  despatchLazyReload: despatchLazyReload,
  selectRelatedRows: selectRelatedRows,
  selectRelatedRowsJsTable: selectRelatedRowsJsTable,
  deviceTreeConfig: deviceTreeConfig,
  getIntfAdminAccessOptions: getIntfAdminAccessOptions,
  toScopeMember: toScopeMember,
  checkUpgradePath: checkUpgradePath,
  getTheLatestUpgVersion: getTheLatestUpgVersion,
  findRecommendVerionsFromDevList: findRecommendVerionsFromDevList,
  getPkgFromList: getPkgFromList,
  assignModelDevToPkg: assignModelDevToPkg,
  getPreferFwVerOptions: getPreferFwVerOptions,
  filterFAZDevOrGrp: filterFAZDevOrGrp,
  getMselectSearchFn: getMselectSearchFn,
  mapToArray: mapToArray,
  mergeArrayObject: mergeArrayObject,
  parseUpgradeFirmware: parseUpgradeFirmware,
  upgradeFirmwareValidate: upgradeFirmwareValidate,
  getOptionsFromObject: getOptionsFromObject,
  genNewChartData: genNewChartData,
  getColorSet: getColorSet,
  findDeviceFirstVdom: findDeviceFirstVdom,
  isDevHasManagementVdom: isDevHasManagementVdom,
  getDevManagementVdomName,
};
