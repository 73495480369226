import $ from 'jquery';
import goog from '@fafm/goog';
import { isUndefined, cloneDeep, get, isEmpty } from 'lodash';

import { fiAdom } from 'fi-session';
import {
  fiStore,
  fiStoreObserverBase,
  fiStoreConnector,
  fiStoreClassConnector,
  fiDevicesSelector,
  fiDevicesAction,
} from 'fistore';
import { useDeferred } from 'rh_util_hooks';
import { toArray, findIndexInArrayByKey } from 'kit-array';

export const fiDeviceRedux = {
  loadDevices,
  loadDevice,
  loadMultipleDevices,
  getDevice,
  getDeviceVdoms,
  getDevicesByChunks,
  getDevicesAsArray,
  getDevicesByIds,
  isReady,
  isLoaded,
  bindDeviceUpdate,
  bindDeviceGrpUpdate,
  loadAssignedPkgs,
  isAssignedPkgReady,
  bindAssignedPkgUpdate,
  loadLicenses,
  isLicenseReady,
  loadFirmwares,
  isFirmwareReady,
  loadPsirtData,
  getPsirtData,
};

function currentAdom() {
  return fiAdom.current() || {};
}

class BaseDvmObserver extends fiStoreObserverBase {
  constructor(props) {
    super(props);
    this.pendingReads = [];
  }

  update() {
    // On update, run any pending read callbacks
    if (this.pendingReads.length > 0) {
      const readCallbacks = [...this.pendingReads];
      this.pendingReads = [];
      // Use primitive for-loop to preserve "this"
      for (let i = 0; i < readCallbacks.length; i++) {
        const cb = readCallbacks[i];
        typeof cb === 'function' && cb(this.props.state);
      }
    }
  }

  getState(adomOid) {
    const state = this.props.state || {};
    return state[adomOid] || state;
  }

  // Sends request to load data
  load(adomOid, forceReload = false) {
    const state = this.getState(adomOid);
    if (forceReload || (!state.loading && !state.loaded)) {
      this.props.load(adomOid);
    }

    return this.isReady(adomOid);
  }

  // Returns promise that resolves when data is loaded
  isReady(adomOid) {
    const _this = this;
    const state = this.getState(adomOid);
    if (state.loaded) {
      return Promise.resolve(state);
    }
    const defer = useDeferred();
    this.addPendingRead(checkReady);
    return defer.promise;

    function checkReady(state) {
      if ((state[adomOid] || state).loaded) {
        defer.resolve(state[adomOid]);
      } else {
        _this.addPendingRead(checkReady);
      }
    }
  }

  addPendingRead(cb) {
    this.pendingReads.push(cb);
  }
}

// ========================
// Device
// ========================
class DevicesObserver extends BaseDvmObserver {
  constructor(props) {
    super(props);
  }

  // fetch one device and update redux
  loadDevice(adomOid, deviceId) {
    return this.props.loadOne(adomOid, deviceId);
  }

  // fetch multiple devices and update redux
  loadMultipleDevices(adomOid, deviceIds) {
    return this.props.loadMultiple(adomOid, deviceIds);
  }

  // Return device by id
  getDevice(adomOid, deviceId) {
    const state = this.getState(adomOid);
    const device = deepCopy(get(state, ['byId', deviceId], {}));
    if (!isEmpty(device)) {
      attachExtraDeviceData(adomOid)(device);
    }
    return device;
  }

  /**
   * Reads devices and sends notify as chunks are ready.
   * All data is returned as a copy to prevent modification.
   * @param {number} adomOid
   * @param {number} customOpts.chunkSize Max size of each chunk
   * @param {function} customOpts.filterFn (item) -> bool
   *    Function that takes the current item. Should returns true if item passes filter.
   * @param {Promise} customOpts.promCancel When this promise resolves, it stops any further reads
   * @returns {Promise} Notifies when each item chunk is ready. Resolves with all copied items.
   */
  getDevicesByChunks(adomOid, customOpts) {
    // NOTE: need to keep $q here because it uses defer.notify api
    const defer = $.Deferred();
    let opts = {
      firstChunkSize: 200, // Smaller first chunk for faster initial load
      chunkSize: 5000,
      filterFn: null,
      promCancel: null,
      useRawData: false,
    };

    if (customOpts) {
      opts = { ...opts, ...customOpts };
    }

    const _this = this;
    let isFirstChunk = true;
    let numRead = 0;
    const allDataCopy = [];
    let cancelled = false;
    const copyItemFn = opts.useRawData ? (object) => object : deepCopy;

    // Handle cancel read operation
    if (opts.promCancel && opts.promCancel.then) {
      opts.promCancel.then(() => {
        cancelled = true;
        defer.reject();
      });
    }

    const state = _this.getState(adomOid);
    // Start load if not loaded
    if (!state.loaded && !state.loading) {
      _this.props.load(adomOid);
    }

    setTimeout(() => read(_this.getState()));
    return defer.promise();

    function read(devices) {
      if (cancelled) {
        return;
      }

      const devicesState = devices[adomOid];
      const collectionSize = devicesState?.allIds?.length ?? 0;
      const nextChunkEnd = isFirstChunk
        ? numRead + opts.firstChunkSize
        : numRead + opts.chunkSize;

      // readEnd should be the smaller of numRead+chunkSize, or collectionSize
      const readEnd =
        nextChunkEnd < collectionSize ? nextChunkEnd : collectionSize;

      if (numRead === readEnd) {
        // Nothing more to read
        if (devicesState?.loaded) {
          defer.resolve(allDataCopy);
        } else {
          _this.addPendingRead(read);
        }
        return;
      }

      // Slice entries and notify
      copyAndNotify(
        devicesState.allIds
          .slice(numRead, readEnd)
          .map((id) => devicesState.byId[id])
      );

      numRead = readEnd;
      isFirstChunk = false;

      // Re-run read() if there are remaining entries
      if (collectionSize - numRead > 0 || devicesState.loaded) {
        setTimeout(() => read(_this.getState()));
      } else {
        // No more entries left, re-run read() when there is a state update
        _this.addPendingRead(read);
      }
    }

    function copyAndNotify(devices) {
      if (typeof opts.filterFn === 'function') {
        devices = devices.filter(opts.filterFn);
      }

      if (devices.length > 0) {
        const devicesCopy = copyItemFn(devices);
        // After copying device data, attach extra data if they have already been loaded
        devicesCopy.forEach(attachExtraDeviceData(adomOid));
        allDataCopy.push(...devicesCopy);
        defer.notify(devicesCopy);
      }
    }
  } // end getDevicesByChunks()

  /**
   * Reads all devices in cache and returns as simple array.
   * All data is returned as a copy to prevent modification.
   * @param {number} adomOid
   * @returns {array} Array of devices
   */
  getDevicesAsArray(adomOid) {
    const state = this.getState(adomOid);
    return state.allIds
      .map((id) => deepCopy(get(state, ['byId', id])))
      .map(attachExtraDeviceData(adomOid));
  }
}

const devicesConnector = fiStoreClassConnector(
  (state) => ({
    state: state.dvm.devices,
  }),
  (dispatch) => ({
    load: (adomOid) =>
      dispatch(fiDevicesAction.fetchDevicesAction({ adomOid })),
    loadOne: (adomOid, deviceOid) =>
      dispatch(fiDevicesAction.fetchSingleDeviceAction({ adomOid, deviceOid })),
    loadMultiple: (adomOid, deviceOids) => {
      deviceOids = toArray(deviceOids);
      return dispatch(
        fiDevicesAction.fetchMultipleDeviceAction({ adomOid, deviceOids })
      );
    },
  })
)(DevicesObserver);

function getDeviceVdoms(adomOid, deviceOid) {
  const vdomState = fiDevicesSelector.get_device_vdoms(
    fiStore.getState(),
    adomOid,
    deviceOid
  );
  return !vdomState
    ? null
    : vdomState.allIds.reduce((acc, vdomId) => {
        const vdomCopy = deepCopy(get(vdomState, ['byId', vdomId]));
        if (vdomCopy) {
          // when deleting vdom, then vdom may be not found.
          vdomCopy._pkey = deviceOid;
          acc.push(vdomCopy);
        }
        return acc;
      }, []);
}

function deepCopy(object) {
  return object ? cloneDeep(object) : null;
}

// Add extra attributes to the device if they have already been loaded
function attachExtraDeviceData(adomOid) {
  return function _attachDeviceData(device) {
    if (!device || isEmpty(device)) {
      return;
    }

    // Always attach VDOMs
    const vdoms = getDeviceVdoms(adomOid, device.oid);
    if (device.vdom_status && vdoms) {
      device.vdoms = vdoms;
    }

    // _children is used in tree view
    // Note: this is duplicating the VDOMs data. treeview should access .vdoms directly instead
    device._children = device.vdoms;

    // Attach root vdom meta fields if device is not vdom enabled
    if (!device.vdom_status && vdoms) {
      const root_vdom = vdoms[0];
      const metaFieldPreFix = new RegExp(/^meta_.+/);
      for (const prop in root_vdom) {
        if (root_vdom.hasOwnProperty(prop) && metaFieldPreFix.test(prop)) {
          device[prop] = root_vdom[prop];
        }
      }
    }

    // Attach optionally loaded data
    assignedPkgConnector.attachDeviceData(adomOid, device);
    licenseConnector.attachDeviceData(device);
    firmwareConnector.attachDeviceData(device);
    return device;
  };
}

// ========================
// Assigned Packages
// ========================
// AssignedPkg also stores CLI template, system template data
class AssignedPkgObserver extends BaseDvmObserver {
  constructor(props) {
    super(props);
  }

  getDeviceAssignedPkg(adomOid, deviceOid, vdomOid) {
    const state = this.getState()[adomOid] || {};
    if (!state.loaded) {
      return null;
    }

    return state.byId[deviceOid + '-' + vdomOid];
  }

  attachDeviceData(adomOid, device) {
    const state = this.getState()[adomOid] || {};
    if (!state.loaded) {
      return;
    }

    const updateAttrs = (device, pkgData) => {
      device.pp_sync = device.pp_sync || {
        name: '',
        oid: 0,
        status: -1,
      };

      device.prof_sync = device.prof_sync || null;

      if (!pkgData) {
        return;
      }

      let isDirty = pkgData.profileDirty;
      if (pkgData.pkg) {
        device.pp_sync = pkgData.pkg;
      }

      //CLI template (global + vdom)
      if (pkgData.cli_prof) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.cli_prof = pkgData.cli_prof;
        if (pkgData.cli_prof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
          isDirty = true;
        }
      }

      // Pre-run CLI template (global, model device only)
      if (
        pkgData.prerun_cliprof &&
        !!device.model_dev &&
        pkgData.vdomOid === `${MACROS.DVM.CDB_DEFAULT_GLOBAL_OID}`
      ) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.prerun_cliprof = pkgData.prerun_cliprof;
        if (
          pkgData.prerun_cliprof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED
        ) {
          isDirty = true;
        }
      }

      if (pkgData.ipsec_prof && (!device.vdoms || device.vdoms.length === 0)) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.ipsec_prof = pkgData.ipsec_prof;
        if (pkgData.ipsec_prof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
          isDirty = true;
        }
      }

      if (pkgData.router_prof && (!device.vdoms || device.vdoms.length === 0)) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.router_prof = pkgData.router_prof;
        if (pkgData.router_prof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
          isDirty = true;
        }
      }

      // BGP Template
      if (pkgData.router_bgp && (!device.vdoms || device.vdoms.length === 0)) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.router_bgp = pkgData.router_bgp;
        if (pkgData.router_bgp.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
          isDirty = true;
        }
      }

      //SDWAN profile
      if (pkgData.sdwan_prof && (!device.vdoms || device.vdoms.length === 0)) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.sdwan_prof = pkgData.sdwan_prof;
        if (pkgData.sdwan_prof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
          isDirty = true;
        }
      }

      //Template group
      if (pkgData.tmpl_grp && (!device.vdoms || device.vdoms.length === 0)) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.tmpl_grp = pkgData.tmpl_grp;
        if (pkgData.tmpl_grp.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
          isDirty = true;
        }
      }

      //FortiExtender profile
      if (pkgData.fext_prof && (!device.vdoms || device.vdoms.length === 0)) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.fext_prof = pkgData.fext_prof;
        if (pkgData.fext_prof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
          isDirty = true;
        }
      }

      //Firmware template
      if (pkgData.fwm_prof) {
        device.fwmprof_sync = pkgData.fwm_prof;
        if (pkgData.fwm_prof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED) {
          isDirty = true;
        }
      }

      // Update "sync" if pkg is dirty
      if (device.sync === MACROS.DVM.DVM_COND_OK && isDirty) {
        device.sync = MACROS.DVM.DVM_COND_PEND_CONF;
      }
    };

    // ID 3 for device root vdom in case vdom not enabled, otherwise use 1
    const globalId = device.vdom_status
      ? MACROS.DVM.CDB_DEFAULT_GLOBAL_OID
      : MACROS.DVM.CDB_DEFAULT_ROOT_OID;
    const pkgData = this.getDeviceAssignedPkg(adomOid, device.oid, globalId);
    updateAttrs(device, pkgData);

    // ID 0 for device global vdom, where dev_prof data is stored
    const otherProfData = this.getDeviceAssignedPkg(adomOid, device.oid, 0);
    if (otherProfData) {
      // dev_prof
      if (otherProfData.dev_prof) {
        device.prof_sync = device.prof_sync || {};
        device.prof_sync.dev_prof = otherProfData.dev_prof;
        // Update "sync" if dev_prof is dirty
        const isDirty =
          otherProfData.dev_prof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED;
        if (device.sync === MACROS.DVM.DVM_COND_OK && isDirty) {
          device.sync = MACROS.DVM.DVM_COND_PEND_CONF;
        }
      }

      //Firmware template: if template is assigned but not actually perform the upgrade action, the vdom_oid is 0
      if (otherProfData.fwm_prof) {
        device.fwmprof_sync = otherProfData.fwm_prof;
        if (
          otherProfData.fwm_prof.status === MACROS.PO.PM3_PKG_STATUS_MODIFIED
        ) {
          device.sync = MACROS.DVM.DVM_COND_PEND_CONF;
        }
      }
    }

    // Assigned pkgs for VDOMs
    if (Array.isArray(device.vdoms)) {
      device.vdoms.forEach((vdom) => {
        const vdomPkgData = this.getDeviceAssignedPkg(
          adomOid,
          device.oid,
          vdom.oid
        );
        updateAttrs(vdom, vdomPkgData);
      });
    }
  }
}

const assignedPkgConnector = fiStoreClassConnector(
  (state) => ({
    state: state.dvm.assignedpkg,
  }),
  (dispatch) => ({
    load: (adomOid) =>
      dispatch(fiDevicesAction.fetchDevicesAssignedPkgAction({ adomOid })),
  })
)(AssignedPkgObserver);

// ========================
// License
// ========================
class LicenseObserver extends BaseDvmObserver {
  constructor(props) {
    super(props);
    this.pendingReads = [];
  }

  attachDeviceData(device) {
    const state = this.getState() || {};
    if (!state.loaded) {
      return;
    }

    // Get license by device serial number
    let licenseData;
    const ha_mode = get(device, ['connection', 'ha_mode'], false);
    const ha_vsn = get(device, ['connection', 'ha_vsn'], '');
    if (ha_mode && ha_vsn) licenseData = state.byId[ha_vsn];
    else licenseData = state.byId[device.sn];
    if (licenseData) {
      Object.assign(device, licenseData);
    } else {
      device.license = {
        status: 0,
        txt: gettext('Unknown'),
      };
    }
  }
}

const licenseConnector = fiStoreClassConnector(
  (state) => ({
    state: state.deviceLicenses,
  }),
  (dispatch) => ({
    load: () => dispatch(fiDevicesAction.fetchDevicesLicenseAction()),
  })
)(LicenseObserver);

// ========================
// Firmware
// ========================
class FirmwareObserver extends BaseDvmObserver {
  constructor(props) {
    super(props);
    this.pendingReads = [];
  }

  getTheLatestUpgVersion(upgradePathMap, currVerObj) {
    try {
      const currVer = currVerObj.version;
      const currBuild = currVerObj.build.toString();
      const currModel = currVerObj.model;

      if (
        upgradePathMap &&
        upgradePathMap[currModel] &&
        upgradePathMap[currModel][currVer]
      ) {
        upgradePathMap[currModel][currVer][currBuild].sort(function (a, b) {
          return goog.string.compareVersions(a.upgradeVer, b.upgradeVer);
        });
        const _len = upgradePathMap[currModel][currVer][currBuild].length;
        if (_len > 0) {
          return upgradePathMap[currModel][currVer][currBuild][_len - 1];
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  attachDeviceData(device) {
    const _statusText = {};
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_ACCEPT] = gettext('Accepted');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_CANCLED] = gettext('Canceled');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_PENDING] = gettext('Pending');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_PROCING] = gettext('Processing');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_SUCCESS] = gettext('Success');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_FAILED] = gettext('Failed');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_ABORTED] = gettext('Aborted');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_SKIPPED] = gettext('Skipped');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_UNAUTHD] = gettext('Unauthorized');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_RETRY_WAITING] =
      gettext('Waiting for Retry');
    _statusText[MACROS.DVM.FWM_ACTION_STATUS_ERROR] = gettext('Error');

    const state = this.getState() || {};
    if (!state.loaded) {
      return;
    }

    const tbData = state.fwById;
    const bestPath = state.fwUpgradeMap;
    const platforms = [];

    if (tbData[device.did]) {
      // any data handle by redux-toolkit's createReducer is immutable, so need to create a copy
      const data = cloneDeep(tbData[device.did]);
      // change upgrade available version according to upgrade path
      if (!data.isGroup) {
        const _currVer = {
          version: data.groupName,
          build: data.curr_build,
          model: data.model,
        };
        const _upgVer = this.getTheLatestUpgVersion(bestPath, _currVer);
        if (_upgVer) {
          data.upd_ver = '%s-b%s'.printf([
            _upgVer.upgradeVer,
            parseInt(_upgVer.upgradeBuild).padding0(4),
          ]);
          data.key_for_download_release = _upgVer.upgradeVer;
        }
      }
      if (
        !data.isGroup &&
        findIndexInArrayByKey(platforms, 'matchStr', data.platform_str) < 0
      ) {
        platforms.push({
          label: data.platform_str,
          checked: false,
          matchStr: data.platform_str,
        });
      }
      const searchFactor = {};
      let rowspan = 0;
      if (data['is_license_valid'] === 0) {
        searchFactor.invalid_date =
          data['invalid_date'] === ''
            ? gettext('Firmware Upgrade License Not Found')
            : gettext('Firmware Upgrade License Expired') +
              ' ' +
              data['invalid_date'];
        rowspan++;
      }
      const status = [];
      searchFactor.status = '';
      data['status'].forEach(function (stat) {
        if (
          stat['upgrade_status'] === MACROS.DVM.FWM_ACTION_STATUS_ACCEPT ||
          stat['upgrade_status'] === MACROS.DVM.FWM_ACTION_STATUS_PENDING ||
          stat['upgrade_status'] === MACROS.DVM.FWM_ACTION_STATUS_PROCING ||
          stat['upgrade_status'] === MACROS.DVM.FWM_ACTION_STATUS_FAILED
        ) {
          rowspan++;
          const target = {};
          target.upgrade_status = _statusText[stat['upgrade_status']] + ': ';
          if (stat['is_special_image']) {
            target.upgrade_status +=
              gettext('Build') +
              '-' +
              parseInt(stat['scheduled_release'], 10) +
              ' (' +
              gettext('Special Image') +
              ')';
          } else {
            target.upgrade_status += stat['scheduled_release'];
          }
          target.scheduled_time = stat['scheduled_time'];
          target.scheduled_key = stat['scheduled_key'];
          target.o_upgrade_status = stat['upgrade_status'];
          status.push(target);
          searchFactor.status += target.upgrade_status + target.scheduled_time;
        }
      });
      data.searchFactor = searchFactor;
      data.rowHeight = MACROS.USER.DEF_ROW_HEIGHT * (rowspan || 1);

      device.firmware_upgrade = {
        ...data,
        searchFactor: searchFactor || {},
        status: status || [],
        txt: data.upd_ver || '',
      };
      device.curr_ver = data.curr_ver;
    }
  }
}

const firmwareConnector = fiStoreClassConnector(
  (state) => ({
    state: state.deviceFirmwares,
  }),
  (dispatch) => ({
    load: () => dispatch(fiDevicesAction.fetchDevicesFirmwareAction()),
  })
)(FirmwareObserver);

// ========================
// PSIRT
// ========================
class PsirtObserver extends BaseDvmObserver {
  constructor(props) {
    super(props);
    this.pendingReads = [];
  }

  getPsirtData() {
    const state = this.props.state;
    return state;
  }
}

const psirtConnector = fiStoreClassConnector(
  (state) => ({
    state: state.adom.devicesPsirt,
  }),
  (dispatch) => ({
    load: () => {
      dispatch(fiDevicesAction.fetchDevicesPsirtAction());
    },
  })
)(PsirtObserver);

// ========================
// Public interface
// ========================

function loadDevices(adomOid, reload = false) {
  adomOid = adomOid || currentAdom().oid;
  return devicesConnector.load(adomOid, reload);
}

function loadDevice(adomOid, deviceOid) {
  adomOid = adomOid || currentAdom().oid;
  return devicesConnector.loadDevice(adomOid, deviceOid);
}

function loadMultipleDevices(adomOid, deviceOids) {
  adomOid = adomOid || currentAdom().oid;
  return devicesConnector.loadMultipleDevices(adomOid, deviceOids);
}

function isReady(adomOid) {
  adomOid = adomOid || currentAdom().oid;
  return devicesConnector.isReady(adomOid);
}

function isLoaded(adomOid) {
  adomOid = adomOid || currentAdom().oid;
  return devicesConnector.getState(adomOid).loaded;
}

function getDevice(adomOid, deviceId) {
  adomOid = adomOid || currentAdom().oid;
  return devicesConnector.getDevice(adomOid, deviceId);
}

function getDevicesByChunks(adomOid, customOpts) {
  adomOid = adomOid || currentAdom().oid;
  return devicesConnector.getDevicesByChunks(adomOid, customOpts);
}

function getDevicesAsArray(adomOid) {
  adomOid = adomOid || currentAdom().oid;
  return devicesConnector.getDevicesAsArray(adomOid);
}

function getDevicesByIds(adomOid, deviceIds, opts) {
  adomOid = adomOid || currentAdom().oid;
  const idsMap = deviceIds.reduce((acc, cur) => {
    acc[cur] = 1;
    return acc;
  }, {});

  return devicesConnector.getDevicesByChunks(adomOid, {
    ...opts,
    filterFn: (device) => {
      return !isUndefined(idsMap[device.oid]);
    },
  });
}

function bindDeviceUpdate(fn) {
  return fiStoreConnector((state) => ({
    devices: state.dvm.devices,
    vdoms: state.dvm.vdoms,
  }))(fn);
}

function bindDeviceGrpUpdate(fn) {
  return fiStoreConnector((state) => ({
    deviceGroups: state.adom.deviceGroups,
    deviceGroupsMemb: state.adom.deviceGroupsMemb,
  }))(fn);
}

// AssignedPkgs data include CLI template, system template, assigned policy package
function loadAssignedPkgs(adomOid, reload = false) {
  adomOid = adomOid || currentAdom().oid;
  return assignedPkgConnector.load(adomOid, reload);
}

function isAssignedPkgReady(adomOid) {
  adomOid = adomOid || currentAdom().oid;
  return assignedPkgConnector.isReady(adomOid);
}

function bindAssignedPkgUpdate(fn) {
  return fiStoreConnector((state) => ({
    assignedpkg: state.dvm.assignedpkg,
  }))(fn);
}

function loadLicenses(adomOid, reload = false) {
  return licenseConnector.load(adomOid, reload);
}

function isLicenseReady() {
  return licenseConnector.isReady();
}

function loadFirmwares(reload = false) {
  return firmwareConnector.load(null, reload);
}

function isFirmwareReady() {
  return firmwareConnector.isReady();
}

function loadPsirtData(adomOid) {
  adomOid = adomOid || currentAdom().oid;
  return psirtConnector.load(adomOid);
}

function getPsirtData() {
  return psirtConnector.getPsirtData();
}
