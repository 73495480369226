import { castArray } from 'lodash';
import { fiHttpPost } from 'fi-http';
import { ProForm, ProToolkit } from '@fafm/neowise-pro';
import { useEffect, useState } from 'react';
import { CancelBtn } from 'rc_layout';
import { showErrorMessage } from 'fi-messagebox';
import { saveBlob } from 'fiutil';
const { Header, Body, Footer } = ProForm;

export const excelExport = {
  exportExcelStart,
  exportExcelNoModal,
  exportExcelTaskMonitor,
};

function exportExcelStart(data) {
  return fiHttpPost('/p/util/export/excel/', {
    action: 'start',
    data: castArray(data),
  }).then(
    (resp) => {
      return resp || false;
    },
    () => {
      return false;
    }
  );
}

function exportExcelTrack(fileName) {
  return fiHttpPost('/p/util/export/excel/', {
    action: 'track',
    track: fileName,
  }).then(
    (resp) => {
      return resp || false;
    },
    (err) => {
      return err;
    }
  );
}

function exportExcelGet(name, excelFileName) {
  return fiHttpPost(
    '/p/util/export/excel/',
    {
      action: 'get_excel_data',
      target: excelFileName,
    },
    {
      responseType: 'arraybuffer',
    }
  ).then(
    function (resp) {
      var blob = new Blob([resp], {
        type: 'application/vnd.ms-excel',
      });
      saveBlob(blob, name + '.xlsx');
      return true;
    },
    function () {
      return false;
    }
  );
}

function ExportMonitor({ $opener, outputName, excelName, statusName }) {
  const [percent, setPercent] = useState(0);
  const [message, setMessage] = useState(
    gettext('Download will begin once the process is done.')
  );
  const [error, setError] = useState(false);

  const onClose = () => {
    $opener.resolve();
  };

  useEffect(() => {
    const abort = trackResult();
    return abort;
  }, []);

  const trackResult = () => {
    let abortController = {
      stop: false,
    };
    const abort = () => (abortController.stop = true);

    exportExcelTrack(statusName).then((resp) => {
      if (!resp) return;
      if (abortController.stop) return;
      const { progress, complete } = resp;

      if (progress == 100 && complete) {
        setPercent(100);
        exportNow();
      } else {
        setPercent((prev) => (parseInt(progress) ? parseInt(progress) : prev));
        setTimeout(() => {
          trackResult();
        }, 800);
      }
    });

    return abort;
  };

  const exportNow = () => {
    exportExcelGet(outputName, excelName).then(
      (res) => {
        if (!res) {
          showErrorMessage(gettext('Failed to export to Excel.'));
          setError(true);
        } else {
          setMessage(gettext('File downloaded.'));
        }
      },
      () => {
        setMessage(gettext('Errors. Fail to export to Excel.'));
        showErrorMessage(gettext('Failed to export to Excel.'));
        setError(true);
      }
    );
  };

  return (
    <>
      <Header>{gettext('Exporting to Excel')}</Header>
      <Body>
        <div>{message}</div>
        <nw-progress-bar
          percentage={percent}
          style={{
            '--indicator-color': error
              ? 'rgb(var(--nw-color-danger-500))'
              : 'rgb(var(--nw-color-success-500))',
          }}
        >
          <b>{percent + '%'}</b>
        </nw-progress-bar>
      </Body>
      <Footer>
        <CancelBtn onClick={onClose}>{gettext('Close')}</CancelBtn>
      </Footer>
    </>
  );
}

function exportExcelTaskMonitor(name, excelFileName, statusFileName) {
  return ProToolkit.openModal(
    <ExportMonitor
      outputName={name}
      excelName={excelFileName}
      statusName={statusFileName}
    />,
    {
      size: 'md',
      height: '200px',
    }
  );
}

function exportExcelNoModal(name, excelFileName, statusFileName) {
  function trackResult() {
    exportExcelTrack(statusFileName).then((resp) => {
      if (resp) {
        let progress = resp.progress,
          complete = resp.complete;
        if (progress == 100 && complete) {
          exportExcelGet(name, excelFileName);
        } else {
          setTimeout(() => {
            trackResult();
          }, 800);
        }
      }
    });
  }
  trackResult();
}
