const macros={
  FAZ_SYNTAX: {
    'ADDRESS_TYPE_GRP': 1,
    'ADDRESS_TYPE_OBJ': 0,
    'ADDROBJ_IP_PREFIX': 0,
    'ADDROBJ_IP_RANGE': 1,
    'AGGR_MODE_AGGREGATION': 1,
    'AGGR_MODE_BOTH': 2,
    'AGGR_MODE_DISABLE': 3,
    'AGGR_MODE_REALTIMT': 0,
    'ALIGN_CENTER': 1,
    'ALIGN_LEFT': 0,
    'ALIGN_RIGHT': 2,
    'ALL_FORTIGATE': 'All_FortiGate',
    'ALOG_SUBTYPE_ATTACK_CHAIN': 0,
    'ALOG_SUBTYPE_MALWARE': 1,
    'AMAZON_CLOUDWATCH': 2,
    'AMAZON_S3': 0,
    'AMAZON_SQS': 11,
    'AUTO': 2,
    'AZURE_BLOB': 18,
    'AZURE_LOGANALYTICS': 17,
    'BOOL_FALSE': 0,
    'BOOL_TRUE': 1,
    'CHART_OPTION_CALC_AVERAGE': 1,
    'CHART_OPTION_NONE': 0,
    'CLOUD_STORAGE_TYPE_AZURE_BLOB': 2,
    'CLOUD_STORAGE_TYPE_GOOGLE_CLOUD': 1,
    'CLOUD_STORAGE_TYPE_S3': 0,
    'CLOUD_SYNC_ON_ROLLING': 1,
    'CLOUD_SYNC_ON_SCHEDULE': 0,
    'COLOR_BLUE': 1,
    'COLOR_DEFAULT': 0,
    'COLOR_GREEN': 2,
    'COLOR_RED': 3,
    'COLUMN_GRAPH_TYPE_BAR': 1,
    'COLUMN_GRAPH_TYPE_LINE_DOWN': 3,
    'COLUMN_GRAPH_TYPE_LINE_UP': 2,
    'COLUMN_GRAPH_TYPE_NONE': 0,
    'COMP_TYPE_CB': 8,
    'COMP_TYPE_CHART': 0,
    'COMP_TYPE_GRAPHIC': 1,
    'COMP_TYPE_HD1': 2,
    'COMP_TYPE_HD2': 3,
    'COMP_TYPE_HD3': 4,
    'COMP_TYPE_MACRO': 10,
    'COMP_TYPE_MINI_COVER': 9,
    'COMP_TYPE_PB': 6,
    'COMP_TYPE_SECTION': 7,
    'COMP_TYPE_TXT': 5,
    'CONNECTOR_AUTH_TYPE_CERTIFICATE': 3,
    'CONNECTOR_AUTH_TYPE_NONE': 0,
    'CONNECTOR_AUTH_TYPE_PASSWORD': 1,
    'CONNECTOR_AUTH_TYPE_SAML': 4,
    'CONNECTOR_AUTH_TYPE_TOKEN': 2,
    'CONNECTOR_TYPE_AD': 'AD',
    'CONNECTOR_TYPE_ALIENVAULT': 'ALIENVAULT',
    'CONNECTOR_TYPE_EMS': 'EMS',
    'CONNECTOR_TYPE_FAC': 'FAC',
    'CONNECTOR_TYPE_FCASB': 'FCASB',
    'CONNECTOR_TYPE_FEDR': 'FEDR',
    'CONNECTOR_TYPE_FGD': 'FGD',
    'CONNECTOR_TYPE_FML': 'FML',
    'CONNECTOR_TYPE_FOS': 'FOS',
    'CONNECTOR_TYPE_FSA': 'FSA',
    'CONNECTOR_TYPE_FWEB': 'FWEB',
    'CONNECTOR_TYPE_K8S': 'K8S',
    'CONNECTOR_TYPE_LOCALHOST': 'LOCALHOST',
    'CONNECTOR_TYPE_MS_TEAMS': 'MS_TEAMS',
    'CONNECTOR_TYPE_SERVICENOW': 'SERVICENOW',
    'CONNECTOR_TYPE_VIRUSTOTAL': 'VIRUSTOTAL',
    'CONTENT_CDR': 9,
    'CONTENT_EDISC': 8,
    'CONTENT_EMAIL': 2,
    'CONTENT_FTP': 4,
    'CONTENT_HTTP': 1,
    'CONTENT_IM': 3,
    'CONTENT_IPS': 7,
    'CONTENT_MMS': 5,
    'CONTENT_NONE': 0,
    'CONTENT_QUARANTINE': 6,
    'CORALOGIX': 10,
    'CUSTOM_SCHEDULE': 1,
    'DATADOG': 13,
    'DATA_ACCU_DEFAULT': 0,
    'DATA_ACCU_HIGH': 1,
    'DATA_FUNCTION_TYPE_CUSTOM': 4,
    'DATA_FUNCTION_TYPE_MAX': 1,
    'DATA_FUNCTION_TYPE_MIN': 2,
    'DATA_FUNCTION_TYPE_NONE': 0,
    'DATA_FUNCTION_TYPE_SUM': 3,
    'DEFAULT_LANG': 'Default',
    'DEF_LAYOUT_FONT_FAMILY': '"Open Sans"',
    'DISABLE': 0,
    'DOM_GLOBAL': 1,
    'DOM_VDOM': 2,
    'ELOG_SUBTYPE_AGGD': 21,
    'ELOG_SUBTYPE_CIFS_AUTH_FAIL': 15,
    'ELOG_SUBTYPE_COMPLIANCE_CHECK': 9,
    'ELOG_SUBTYPE_CONNECTOR': 12,
    'ELOG_SUBTYPE_CRWL': 22,
    'ELOG_SUBTYPE_ENCRYPT': 17,
    'ELOG_SUBTYPE_ENDPOINT': 7,
    'ELOG_SUBTYPE_FORTIEXTENDER': 11,
    'ELOG_SUBTYPE_GTP': 6,
    'ELOG_SUBTYPE_HA': 8,
    'ELOG_SUBTYPE_KEVENT': 18,
    'ELOG_SUBTYPE_MAILEVENT': 16,
    'ELOG_SUBTYPE_MANAGE': 19,
    'ELOG_SUBTYPE_REST_API': 16,
    'ELOG_SUBTYPE_ROUTER': 3,
    'ELOG_SUBTYPE_SDWAN': 13,
    'ELOG_SUBTYPE_SECURITY_RATING': 10,
    'ELOG_SUBTYPE_SWITCH_CONTROLLER': 14,
    'ELOG_SUBTYPE_SYSLOG': 20,
    'ELOG_SUBTYPE_SYSTEM': 0,
    'ELOG_SUBTYPE_USER': 2,
    'ELOG_SUBTYPE_VPN': 1,
    'ELOG_SUBTYPE_WAD': 23,
    'ELOG_SUBTYPE_WANOPT': 5,
    'ELOG_SUBTYPE_WCS': 24,
    'ELOG_SUBTYPE_WDB': 25,
    'ELOG_SUBTYPE_WEBPROXY': 17,
    'ELOG_SUBTYPE_WIRELESS': 4,
    'ENABLE': 1,
    'EPEU_DEV_EMS': 100,
    'EXTRAINFO_TYPE_CUSTOM': 1,
    'EXTRAINFO_TYPE_DEFAULT': 0,
    'FAZ_SYSLOG_FACILITY_ALERT': 14,
    'FAZ_SYSLOG_FACILITY_AUDIT': 13,
    'FAZ_SYSLOG_FACILITY_AUTH': 4,
    'FAZ_SYSLOG_FACILITY_AUTHPRIV': 10,
    'FAZ_SYSLOG_FACILITY_CLOCK': 9,
    'FAZ_SYSLOG_FACILITY_CRON': 15,
    'FAZ_SYSLOG_FACILITY_DAEMON': 3,
    'FAZ_SYSLOG_FACILITY_FTP': 11,
    'FAZ_SYSLOG_FACILITY_KERNEL': 0,
    'FAZ_SYSLOG_FACILITY_LOCAL0': 16,
    'FAZ_SYSLOG_FACILITY_LOCAL1': 17,
    'FAZ_SYSLOG_FACILITY_LOCAL2': 18,
    'FAZ_SYSLOG_FACILITY_LOCAL3': 19,
    'FAZ_SYSLOG_FACILITY_LOCAL4': 20,
    'FAZ_SYSLOG_FACILITY_LOCAL5': 21,
    'FAZ_SYSLOG_FACILITY_LOCAL6': 22,
    'FAZ_SYSLOG_FACILITY_LOCAL7': 23,
    'FAZ_SYSLOG_FACILITY_LPR': 6,
    'FAZ_SYSLOG_FACILITY_MAIL': 2,
    'FAZ_SYSLOG_FACILITY_NEWS': 7,
    'FAZ_SYSLOG_FACILITY_NTP': 12,
    'FAZ_SYSLOG_FACILITY_SYSLOG': 5,
    'FAZ_SYSLOG_FACILITY_USER': 1,
    'FAZ_SYSLOG_FACILITY_UUCP': 8,
    'FILTER_ADOM': 'vd',
    'FILTER_DST_INT': 'dstintf',
    'FILTER_GROUP': 'group',
    'FILTER_HOSTNAME': 'hostname',
    'FILTER_MODE_INHERIT': 1,
    'FILTER_MODE_OVERRIDE': 0,
    'FILTER_REL_AND': 0,
    'FILTER_REL_OR': 1,
    'FILTER_SRC_INT': 'srcintf',
    'FILTER_USER': 'user',
    'FONT_TYPE_BOLD': 1,
    'FONT_TYPE_BOLD_ITALIC': 3,
    'FONT_TYPE_ITALIC': 2,
    'FONT_TYPE_NORMAL': 0,
    'FONT_TYPE_UNDEFINED': -1,
    'FREE_TYPE_TEXT': 2,
    'GOOGLE_CLOUD': 19,
    'HANDLER_TYPE_LOCAL': 0,
    'HANDLER_TYPE_REMOTE': 1,
    'INCLUDE_TYPE_EXCLUDE': 1,
    'INCLUDE_TYPE_INCLUDE': 0,
    'KAFKA': 1,
    'KINESIS_FUREHOSE': 4,
    'KINESIS_STREAMS': 3,
    'KINESIS_STREAMS_AGGREGATED': 5,
    'LANDSCAPE': 1,
    'LOGDEV_FAC': 12,
    'LOGDEV_FAD': 20,
    'LOGDEV_FAI': 22,
    'LOGDEV_FAZ': 7,
    'LOGDEV_FCA': 27,
    'LOGDEV_FCH': 6,
    'LOGDEV_FCT': 3,
    'LOGDEV_FDC': 19,
    'LOGDEV_FDD': 11,
    'LOGDEV_FED': 25,
    'LOGDEV_FFW': 21,
    'LOGDEV_FGT': 0,
    'LOGDEV_FIS': 24,
    'LOGDEV_FMG': 1,
    'LOGDEV_FML': 4,
    'LOGDEV_FNA': 17,
    'LOGDEV_FPA': 26,
    'LOGDEV_FPX': 15,
    'LOGDEV_FRA': 29,
    'LOGDEV_FSA': 10,
    'LOGDEV_FSF': 16,
    'LOGDEV_FSR': 23,
    'LOGDEV_FTC': 28,
    'LOGDEV_FWB': 5,
    'LOGDEV_SIEM': 18,
    'LOGDEV_SYS': 2,
    'LOGTYPE_ANYTYPE': 65535,
    'LOGTYPE_APPCTRL': 0,
    'LOGTYPE_ASSET': 24,
    'LOGTYPE_ATTACK': 1,
    'LOGTYPE_CONTENT': 2,
    'LOGTYPE_DLP': 3,
    'LOGTYPE_DNS': 20,
    'LOGTYPE_EMAILFILTER': 4,
    'LOGTYPE_EVENT': 5,
    'LOGTYPE_EVENT_EX': 15,
    'LOGTYPE_EXT_LOCAL_EVENT': 33,
    'LOGTYPE_FFLT': 23,
    'LOGTYPE_GENERIC': 6,
    'LOGTYPE_GTP': 19,
    'LOGTYPE_HISTORY': 7,
    'LOGTYPE_NETSCAN': 14,
    'LOGTYPE_NETSCAN_EX': 17,
    'LOGTYPE_PROTOCOL': 25,
    'LOGTYPE_SECURITY': 28,
    'LOGTYPE_SIEM': 26,
    'LOGTYPE_SNIFFER': 9,
    'LOGTYPE_SSH': 21,
    'LOGTYPE_SSL': 22,
    'LOGTYPE_TRAFFIC': 10,
    'LOGTYPE_TRAFFIC_EX': 16,
    'LOGTYPE_VIRUS': 11,
    'LOGTYPE_VOIP': 12,
    'LOGTYPE_WAF': 18,
    'LOGTYPE_WEBFILTER': 13,
    'LOGTYPE_ZTNA': 27,
    'LOGZIO': 8,
    'LOG_DEVICE_GROUP': 0,
    'LOG_DEVICE_HA': 1,
    'LOG_LEVEL_ALERT': 1,
    'LOG_LEVEL_CRITICAL': 2,
    'LOG_LEVEL_DEBUG': 7,
    'LOG_LEVEL_EMERG': 0,
    'LOG_LEVEL_ERROR': 3,
    'LOG_LEVEL_INFO': 6,
    'LOG_LEVEL_NOTIF': 5,
    'LOG_LEVEL_WARNING': 4,
    'LOKI': 15,
    'MODE_SPECIFY': 1,
    'MODE_VARIABLE': 0,
    'MONGO': 9,
    'OPCODE_CONTAIN': 6,
    'OPCODE_EQUAL': 0,
    'OPCODE_GREAT_OR_EQUAL': 2,
    'OPCODE_GREAT_THAN': 4,
    'OPCODE_IN': 8,
    'OPCODE_LESS_OR_EQUAL': 3,
    'OPCODE_LESS_THAN': 5,
    'OPCODE_NOT_CONTAIN': 7,
    'OPCODE_NOT_EQUAL': 1,
    'OPCODE_NOT_IN': 9,
    'OPCODE_REG_MATCH': 10,
    'ORIGIN_BUILTIN': 1,
    'ORIGIN_CUSTOM': 0,
    'ORIGIN_FORTIGUARD': 2,
    'PLOG_EX_SUBTYPE_ICAP': 0,
    'PLOG_EX_SUBTYPE_SCTP_FILTER': 1,
    'PORTRAIT': 0,
    'RABBITMQ': 12,
    'RLOG_SUBTYPE_CONNECTOR': 1,
    'RLOG_SUBTYPE_INCIDENT': 2,
    'RLOG_SUBTYPE_PLAYBOOK': 0,
    'RLOG_SUBTYPE_SIEM': 3,
    'RULE_AND': 0,
    'RULE_AND_NOT': 3,
    'RULE_FOLLOWEDBY_WITHIN': 2,
    'RULE_NOT_FOLLOWEDBY_WITHIN': 4,
    'RULE_OR': 1,
    'SENTRY': 16,
    'SEVERITY_CRITICAL': 0,
    'SEVERITY_HIGH': 1,
    'SEVERITY_LOW': 3,
    'SEVERITY_MEDIUM': 2,
    'SINGLE_SCHEDULE': 0,
    'SLOG_EX_SUBTYPE_CASB': 7,
    'SLOG_EX_SUBTYPE_CHECKOUT_IN': 2,
    'SLOG_EX_SUBTYPE_CLEAR_TEXT': 3,
    'SLOG_EX_SUBTYPE_FSW': 8,
    'SLOG_EX_SUBTYPE_PWD_CHG': 1,
    'SLOG_EX_SUBTYPE_SECRET': 0,
    'SLOG_EX_SUBTYPE_SECRET_DEP': 6,
    'SLOG_EX_SUBTYPE_SECRET_JOB': 5,
    'SLOG_EX_SUBTYPE_SECRET_REQUEST': 4,
    'SLOG_EX_SUBTYPE_VIRTUAL_PATCH': 6,
    'SOC_FILTER_TYPE_DEVICE': 1,
    'SOC_FILTER_TYPE_DSTIP': 4,
    'SOC_FILTER_TYPE_EPID': 8,
    'SOC_FILTER_TYPE_EUID': 16,
    'SOC_FILTER_TYPE_SRCIP': 2,
    'SORT_OPTION_BANDWIDTH': 2,
    'SORT_OPTION_NONE': 0,
    'SORT_OPTION_SESSION': 1,
    'SPLUNK': 6,
    'SQL': 14,
    'SQLTYPE_DATETIME': 1,
    'SQLTYPE_INT': 0,
    'SQLTYPE_IP': 3,
    'SQLTYPE_SERIAL': 4,
    'SQLTYPE_STRING': 2,
    'SQL_RPT_CHART_TYPE_AREA': 5,
    'SQL_RPT_CHART_TYPE_BAR': 2,
    'SQL_RPT_CHART_TYPE_BUBBLE': 13,
    'SQL_RPT_CHART_TYPE_BUB_RING': 12,
    'SQL_RPT_CHART_TYPE_CHORD': 7,
    'SQL_RPT_CHART_TYPE_DONUT': 6,
    'SQL_RPT_CHART_TYPE_HEATMAP': 11,
    'SQL_RPT_CHART_TYPE_LINE': 4,
    'SQL_RPT_CHART_TYPE_MAP': 9,
    'SQL_RPT_CHART_TYPE_NONE': 0,
    'SQL_RPT_CHART_TYPE_PIE': 3,
    'SQL_RPT_CHART_TYPE_RADAR': 8,
    'SQL_RPT_CHART_TYPE_SANKEY': 10,
    'SQL_RPT_CHART_TYPE_TABLE': 1,
    'SQL_RPT_COL_ATTR_APP_CATEGORY': 19,
    'SQL_RPT_COL_ATTR_APP_ID': 1,
    'SQL_RPT_COL_ATTR_ATTACK': 7,
    'SQL_RPT_COL_ATTR_BANDWIDTH': 11,
    'SQL_RPT_COL_ATTR_CAL_PERCENT': 20,
    'SQL_RPT_COL_ATTR_COUNT': 13,
    'SQL_RPT_COL_ATTR_COUNTRY_CODE': 32,
    'SQL_RPT_COL_ATTR_DETECTION_TYPE': 30,
    'SQL_RPT_COL_ATTR_DEV_TYPE': 3,
    'SQL_RPT_COL_ATTR_DST_COUNTRY': 9,
    'SQL_RPT_COL_ATTR_EMAIL_RECVER': 5,
    'SQL_RPT_COL_ATTR_EMAIL_SENDER': 4,
    'SQL_RPT_COL_ATTR_HTML': 24,
    'SQL_RPT_COL_ATTR_INC_CATEGORY': 33,
    'SQL_RPT_COL_ATTR_INC_STATUS': 28,
    'SQL_RPT_COL_ATTR_IPSEC_TUNNEL': 10,
    'SQL_RPT_COL_ATTR_IP_COUNTRY': 21,
    'SQL_RPT_COL_ATTR_KBPS': 25,
    'SQL_RPT_COL_ATTR_NONE': 0,
    'SQL_RPT_COL_ATTR_OBFUSCATED_URL': 27,
    'SQL_RPT_COL_ATTR_OBFUSCATED_URL2': 29,
    'SQL_RPT_COL_ATTR_PERCENT': 12,
    'SQL_RPT_COL_ATTR_PURDUE_LEVEL': 42,
    'SQL_RPT_COL_ATTR_RISK_LEVEL': 41,
    'SQL_RPT_COL_ATTR_SEARCH': 18,
    'SQL_RPT_COL_ATTR_SECURITY_AUDIT_DEV_TYPE': 37,
    'SQL_RPT_COL_ATTR_SECURITY_AUDIT_NAME': 35,
    'SQL_RPT_COL_ATTR_SECURITY_AUDIT_RESULT': 36,
    'SQL_RPT_COL_ATTR_SECURITY_AUDIT_SCOPE': 38,
    'SQL_RPT_COL_ATTR_SECURITY_AUDIT_SEVERITY': 39,
    'SQL_RPT_COL_ATTR_SECURITY_GRADE': 31,
    'SQL_RPT_COL_ATTR_SECURITY_RATING_CIS': 43,
    'SQL_RPT_COL_ATTR_SECURITY_RATING_HIPAA': 44,
    'SQL_RPT_COL_ATTR_SECURITY_RATING_SOC2': 45,
    'SQL_RPT_COL_ATTR_SECURITY_RECOMMENDATION': 34,
    'SQL_RPT_COL_ATTR_SEVERITY': 22,
    'SQL_RPT_COL_ATTR_SIAPP_CATEGORY': 40,
    'SQL_RPT_COL_ATTR_STATE_ICON': 15,
    'SQL_RPT_COL_ATTR_TIME': 14,
    'SQL_RPT_COL_ATTR_TIMESPAN': 16,
    'SQL_RPT_COL_ATTR_TREND': 26,
    'SQL_RPT_COL_ATTR_URL': 23,
    'SQL_RPT_COL_ATTR_USER': 2,
    'SQL_RPT_COL_ATTR_VIRUS': 6,
    'SQL_RPT_COL_ATTR_VULN': 8,
    'SQL_RPT_COL_ATTR_WEB_CATEGORY': 17,
    'SQL_RPT_COL_TYPE_AGGREGATE': 4,
    'SQL_RPT_COL_TYPE_CALCULATED': 5,
    'SQL_RPT_COL_TYPE_DRILLDOWN': 3,
    'SQL_RPT_COL_TYPE_RAW': 0,
    'SQL_RPT_DEF_TOPX': 6,
    'SQL_RPT_DEF_TOPY': 3,
    'SQL_RPT_LINE_BACK_TO_BACK': 2,
    'SQL_RPT_LINE_BASIC': 0,
    'SQL_RPT_LINE_STACKED': 1,
    'SQL_RPT_MACRO_DEF_TOP': 1,
    'SQL_RPT_MAX_TABLE_COLUMNS': 60,
    'SQL_RPT_STYLE_BAR': 1,
    'SQL_RPT_STYLE_LINE': 3,
    'SQL_RPT_STYLE_PIE': 2,
    'SQL_RPT_STYLE_TABLE': 0,
    'SQL_RPT_TABLE_BASIC': 0,
    'SQL_RPT_TABLE_BASIC_BAR': 9,
    'SQL_RPT_TABLE_BASIC_WITH_PIE': 3,
    'SQL_RPT_TABLE_BUBBLE': 18,
    'SQL_RPT_TABLE_BUB_RING': 17,
    'SQL_RPT_TABLE_CHORD': 12,
    'SQL_RPT_TABLE_COMPOSITE_BAR_WITH_PIE': 4,
    'SQL_RPT_TABLE_DONUT': 11,
    'SQL_RPT_TABLE_HEATMAP': 16,
    'SQL_RPT_TABLE_LINE': 6,
    'SQL_RPT_TABLE_LINE_BASIC': 5,
    'SQL_RPT_TABLE_MAP': 14,
    'SQL_RPT_TABLE_PIE_WITH_ICON': 8,
    'SQL_RPT_TABLE_RADAR': 13,
    'SQL_RPT_TABLE_SANKEY': 15,
    'SQL_RPT_TABLE_STACKED_BAR': 10,
    'SQL_RPT_TABLE_WRAP': 7,
    'SUBNET_BOTH': 0,
    'SUBNET_DST': 2,
    'SUBNET_SRC': 1,
    'SUBTYPE_ANY': 1000,
    'SVC_LICENSE_FGSA': 32,
    'SVC_LICENSE_FOAS': 8,
    'SVC_LICENSE_ISSS': 16,
    'SVC_LICENSE_NONE': 0,
    'SVC_LICENSE_PBDS': 1,
    'SVC_LICENSE_SCPC': 2,
    'SVC_LICENSE_SOAR': 4,
    'SYS_MODE_COLLECTOR': 1,
    'SYS_MODE_FAZ': 0,
    'SYS_MODE_STANDALONE': 2,
    'TIME_PERIOD_TILL_NOW_OPTIONS': ['last-n-hours', 'last-7-days', 'last-n-days', 'last-14-days', 'last-30-days'],
    'TLOG_SUBTYPE_ANY': 5,
    'TLOG_SUBTYPE_EXTENDED': 3,
    'TLOG_SUBTYPE_FORWARD': 0,
    'TLOG_SUBTYPE_LOCAL': 1,
    'TLOG_SUBTYPE_MULTICAST': 2,
    'TLOG_SUBTYPE_SNIFFER': 4,
    'VIEW_CATEGORY_APP_CTRL': 1,
    'VIEW_CATEGORY_SYSTEM_EVENT': 3,
    'VIEW_CATEGORY_TRAFFIC': 0,
    'VIEW_CATEGORY_VPN': 2,
    'VIEW_CATEGORY_WEBFILTER': 4,
    'WEBHOOK_AUTH_TYPE_BASIC': 0,
    'WEBHOOK_AUTH_TYPE_OAUTH2': 1,
    'WEBHOOK_CLT_AUTH_METH_BASIC': 0,
    'WEBHOOK_CLT_AUTH_METH_POST': 1,
  }
};
export default macros;
