import { fiFmgHttp } from 'fi-web/fi-http';
import { get } from 'lodash';

const getVpnConfigs = async (deviceOid) => {
  try {
    const resp = await fiFmgHttp.post({
      url: '/gui/device/vpn/configs',
      method: 'get',
      params: {
        deviceOid,
      },
    });
    const results = get(resp, '0.data');
    return results;
  } catch {
    return {};
  }
};

export const fiDeviceConfigUtil = {
  getVpnConfigs,
};
