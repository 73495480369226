import React, { useCallback, useEffect, useState } from 'react';
import { NwButton } from '@fafm/neowise-core';
import { fiSysConfig } from 'fi-session';
import { useNavigate } from 'react-router-dom';
import { useInterval } from 'rh_util_hooks';
import { fiHttpGet } from 'fi-http';
import { useDispatch } from 'react-redux';
import {
  setNotification,
  unsetNotification,
} from 'fistore/notifications/slice';

const CHECK_INTERVAL = 180000;

const commonCls = 'tw-h-9 ';

export const Fabric = () => {
  const [isMember, setIsMember] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (fiSysConfig.isFazSupervisor() || fiSysConfig.isFmgSupervisor()) {
      setIsMember(false);
      setShow(true);
      return;
    }

    if (fiSysConfig.isFazMember() || fiSysConfig.isFmgMember()) {
      setIsMember(true);
      setShow(true);
      return;
    }
  }, []);

  const checkMemberStatus = async () => {
    if (fiSysConfig.isFazSupervisor()) {
      let statsResp = await fiHttpGet('/p/fabric/socfabric/dvm/stats/', {
        params: {
          members: JSON.stringify([]),
          types: JSON.stringify(['member']),
        },
      });
      if (statsResp?.data?.length) {
        const notSyncedMembers = statsResp.data
          .filter((m) => m.auth_state === 'Accepted')
          .filter((m) => m.in_sync === 0);
        if (notSyncedMembers.length) {
          dispatch(
            setNotification({
              id: MACROS.NOTICE.NOTICE_TYPE_SOC_FABRIC_MEMBER_NOT_IN_SYNC,
              severity: MACROS.NOTICE.NOTICE_LEVEL_WARNING,
              params: {
                memberSns: notSyncedMembers.map((m) => m.hostname || m.devname),
              },
            })
          );
        } else {
          dispatch(
            unsetNotification({
              id: MACROS.NOTICE.NOTICE_TYPE_SOC_FABRIC_MEMBER_NOT_IN_SYNC,
            })
          );
        }
      }
    }
  };

  useInterval(checkMemberStatus, CHECK_INTERVAL);

  useEffect(() => {
    checkMemberStatus();
  }, []);

  const go2Fabric = useCallback(() => {
    navigate('/sys/fabric/settings');
  }, [navigate]);

  const isFaz = fiSysConfig.isFaz();

  return (
    <>
      {show && (
        <NwButton
          id='fabricSelect'
          onClick={go2Fabric}
          size='medium'
          type='primary'
          className={commonCls}
          automationId={isMember ? 'faz-is-member' : 'faz-is-supervisor'}
          title={
            isMember
              ? gettext(
                  'This unit is the member of %s Fabric. Click to view fabric settings.'
                ).printf(
                  isFaz ? [gettext('FortiAnalyzer')] : [gettext('FortiManager')]
                )
              : gettext(
                  'This unit is the supervisor of %s Fabric. Click to view fabric settings.'
                ).printf(
                  isFaz ? [gettext('FortiAnalyzer')] : [gettext('FortiManager')]
                )
          }
        >
          <span className='tw-font-bold button-text-color'>
            {isMember ? gettext('Member') : gettext('Supervisor')}
          </span>
        </NwButton>
      )}
    </>
  );
};
