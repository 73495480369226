import React, { useCallback, useEffect, useState } from 'react';
import {
  parseVdomData,
  getSysConfig,
  getDeviceStatusTooltipProps,
} from './util';
import { reloadDevices, getDevices } from './api';
import { ProTable } from '@fafm/neowise-pro';
import { NwProInputRow, ProLegoTooltipWrapper } from 'rc_layout';
import { FmkErrorSpan } from 'rc_form';
import { useFormikContext } from 'formik';
import { fiSysConfig, fiAdminProfile } from 'fi-session';
import { DeviceRowIcon, deviceIconFormatter } from 'ra_device_util';
import { fiDvmLogDataService } from 'fi-dvm';

const _renderContent = ({
  iconProps,
  className,
  txt,
  tooltipProps,
  title,
  style,
}) => {
  const renderIcon = () => {
    if (iconProps) {
      if (tooltipProps) {
        return (
          <ProLegoTooltipWrapper {...tooltipProps} placement='right'>
            <DeviceRowIcon
              iconProps={iconProps}
              className={className}
              title={title}
            />
          </ProLegoTooltipWrapper>
        );
      }
      return (
        <DeviceRowIcon
          iconProps={iconProps}
          className={className}
          title={title}
        />
      );
    }
  };
  return (
    <div style={style}>
      {renderIcon()}
      <span className='text'>{txt}</span>
    </div>
  );
};

const AdomDeciveTable = ({ isEdit, onClick }) => {
  const { values } = useFormikContext();
  const sysConfig = getSysConfig();
  const [initTableData, setInitTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const prepTableData = (members, adomName, tempAddedMembers, adomOid) =>
    parseVdomData({
      currentMembers: tableData,
      tempAddedMembers: tempAddedMembers || [],
      allDevices: [...members],
      forSelection: false,
      sysConfig,
      adomName,
      adomOid,
    });

  useEffect(() => {
    if (isEdit && values.name) {
      reloadDevices(values)
        .then(() => getDevices(values.adomId))
        .then((members) => {
          members.forEach((member) => {
            if (fiSysConfig.isFaz()) {
              const logStats = fiDvmLogDataService.getLogStatsByDevice(
                member,
                values.adomId
              );
              member.logStatus =
                logStats?.status || MACROS.DVM.LOGSTAT_DEVST_DOWN;
              member.logStats = logStats;
            }
            member.isCurrentMember = true;
            member.title = member.logs
              ? gettext('Last log received at ') + new Date(member.logs * 1000)
              : '';
          });
          const _initList = prepTableData(
            members,
            values.name,
            undefined,
            values.adomId
          );
          setInitTableData(_initList);
          setTableData(_initList);
        });
    }
  }, [values.name]);

  useEffect(() => {
    const nmem = values.newmembers.reduce(
      (acc, cur) => {
        // vdoms should always follow the device
        if (cur.isVdom) {
          const dkey = cur.uid.split('-')[0];
          if (acc.devs[acc.devs.length - 1].uid === dkey) {
            acc.devs[acc.devs.length - 1].children.push(cur);
          }
        } else {
          const dev = { ...cur, children: [] };
          acc.devs.push(dev);
          acc.dmap[cur.uid] = dev;
        }
        return acc;
      },
      { dmap: {}, devs: [] }
    );

    const ndkeys = Object.keys(nmem.dmap);

    let newTableData = initTableData.map((dev) => {
      const ovkeys = (dev?.vdoms || []).map((v) => v.oid);
      dev.children = dev?.children?.filter((v) => ovkeys.includes(v.oid)); // revert to the initial vdoms
      if (ndkeys.includes(dev.uid)) {
        dev.children = (dev?.children || []).concat(
          nmem.dmap[dev.uid].children
        );
        ndkeys.splice(ndkeys.indexOf(dev.uid), 1); // remove dkey from new members
      }
      return dev;
    });

    newTableData = newTableData.concat(
      nmem.devs.filter((d) => ndkeys.includes(d.uid))
    );

    setTableData(newTableData);
  }, [values.newmembers]);

  const getToolbarItems = useCallback(() => {
    return [
      {
        key: 'add',
        icon: 'add',
        label: gettext('Select Device'),
        disabled: !fiAdminProfile.hasRDWRPermitOn('adom_switch'),
        exec: () => {
          onClick();
        },
      },
    ];
  }, [onClick]);

  const getTableHeight = () => {
    const rowCounts = tableData.reduce(
      (acc, cur) => acc + (cur?.children?.length || 0) + 1,
      0
    );
    return `${
      Math.min(rowCounts || 1, 15) * MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_32 +
      112
    }px`;
  };

  return (
    <>
      <NwProInputRow label={gettext('Devices')}>
        <div style={{ height: getTableHeight() }}>
          <ProTable.TableView
            tableId='adomDeviceTable'
            data={tableData}
            rowKey='uid'
            expandColumnKey='uid'
            columns={columns}
            getToolbarItems={getToolbarItems}
            rowHeight={MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_24}
          />
        </div>

        <div className='fi-col-rest'>
          <FmkErrorSpan name='members' />
        </div>
      </NwProInputRow>
    </>
  );
};

const columns = [
  {
    key: 'name',
    title: gettext('Name'),
    width: 200,
    cellRenderer: ({ rowData }, highlighter) => {
      let { iconProps, className, title } = deviceIconFormatter(
        rowData,
        fiSysConfig.isFmg(),
        true
      );
      let tooltipProps = getDeviceStatusTooltipProps(rowData);
      return _renderContent({
        txt: highlighter(rowData.name),
        iconProps,
        className,
        tooltipProps,
        title,
        style: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
      });
    },
  },
  {
    key: 'ip',
    title: gettext('IP Address'),
    width: 150,
    cellRenderer: ({ rowData }, highlighter) => {
      return _renderContent({ txt: highlighter(rowData.ip) });
    },
  },
  {
    key: 'platform',
    title: gettext('Platform'),
    cellRenderer: ({ rowData }, highlighter) => {
      return _renderContent({ txt: highlighter(rowData.platform) });
    },
  },
];

AdomDeciveTable.displayName = 'AdomDeciveTable';
export default AdomDeciveTable;
