import { get, set } from 'lodash';

import { fiFmgHttp } from 'fi-http';

// adom_customize table, dvmCustomize data structure
// {
//   dvmConfigDisplayOpts: []
//   ptDisplayOpts: []
// }
const DC_DISPLAY_OPTS = 'dvmConfigDisplayOpts';
const PT_DISPLAY_OPTS = 'ptDisplayOpts';

/** ----------------------------------------------------------------------------
 * Public APIs
 * -------------------------------------------------------------------------- */
export async function dvmCustomizeApi(adom, opts) {
  const adomOid = adom.oid || adom;
  const url = `/gui/adoms/${adomOid}/customize`;

  // Setter
  if (opts) {
    return fiFmgHttp.post({
      url: url,
      method: 'update',
      params: {
        dvmCustomize: opts,
      },
    });
  }

  // Getter
  const resp = await fiFmgHttp.post({
    url: url,
    method: 'get',
  });
  return get(resp, '0.data.dvmCustomize', {});
}

// Generate functions with signature: (adom: object | number, opts: object) => object | null
// The passed key can be a path if you want to set nested object: e.g. 'customKey.nestedKey' etc.
export const dvmConfigDisplayOptsApi = makeDvmCustomizeApi(DC_DISPLAY_OPTS);
export const ptDisplayOptsApi = makeDvmCustomizeApi(PT_DISPLAY_OPTS);

/** ----------------------------------------------------------------------------
 * Helper functions
 * -------------------------------------------------------------------------- */

// migrate dvmCustomize to an object if it is still an array
async function getUpdatedDvmCustomize(adom) {
  const dvmCustomize = await dvmCustomizeApi(adom);

  if (Array.isArray(dvmCustomize)) {
    const toUpdate = {
      [DC_DISPLAY_OPTS]: dvmCustomize,
    };

    await dvmCustomizeApi(adom, toUpdate);

    return toUpdate;
  }

  return dvmCustomize;
}

function makeDvmCustomizeApi(key) {
  return async (adom, opts) => {
    const dvmCustomize = await getUpdatedDvmCustomize(adom);

    if (opts) {
      const updated = set({ ...dvmCustomize }, key, opts);
      return dvmCustomizeApi(adom, updated);
    }

    return get(dvmCustomize, key);
  };
}
