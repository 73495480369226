import React, { useState, useEffect } from 'react';
import { WizardStep, WizardView } from 'rc_wizard';
import { getStartupWizardOptions, STARTUP_STEPS } from './utils';

import { LoadingBar } from 'rc_toolbar';
import { NwSpinner } from '@fafm/neowise-core';
import { NwProHeader, NwProBody } from 'rc_layout';
import {
  WelcomeTab,
  ChangePasswordTab,
  FortiCareSSO,
  SetHostname,
  UpgradeFirmwareTab,
  EndTab,
} from '../tabs';
import './startup.less';
import { BackupStrategy } from '../tabs/backup_strategy/BackupStrategy';

export const StartupWizardModal = ({ $opener }) => {
  const [options, setOptions] = useState(false);
  const [shareObjs, setShareObjs] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState({});
  const [useEntitlmentFile, setUseEntitlementFile] = useState(false);
  const [licenseData, setLicenseData] = useState();

  useEffect(() => {
    async function getOptions() {
      const { options } = await getStartupWizardOptions();
      setOptions(options);
      setShareObjs(options.shareObjs);
      setTabs(
        options.tabs.reduce((prev, curr) => {
          return { ...prev, [curr.id]: curr };
        }, {})
      );
      setLoading(false);
    }
    getOptions();
  }, []);

  const { sysConfig, isForticareRegistered, ssoSettings, imageToUpgradeTo } =
    shareObjs;

  return (
    <>
      <LoadingBar
        isLoading={loading}
        zIndex={2}
        automation-id={'startup_wizard:loading-bar'}
      />
      {options ? (
        <WizardView
          title={options.title}
          initialStep={STARTUP_STEPS.STARTUP_WELCOME}
          stepSequence={options.tabs}
        >
          <WizardStep step={STARTUP_STEPS.STARTUP_WELCOME}>
            <WelcomeTab
              tab={tabs[STARTUP_STEPS.STARTUP_WELCOME]}
              setUseEntitlementFile={setUseEntitlementFile}
              setLicenseData={setLicenseData}
            />
          </WizardStep>
          <WizardStep step={STARTUP_STEPS.STARTUP_SSO}>
            <FortiCareSSO
              useEntitlmentFile={useEntitlmentFile}
              sysConfig={sysConfig}
              ssoSettings={ssoSettings}
              isForticareRegistered={isForticareRegistered}
              licenseData={licenseData}
            />
          </WizardStep>
          <WizardStep step={STARTUP_STEPS.STARTUP_HOSTNAME}>
            <SetHostname sysConfig={sysConfig} />
          </WizardStep>
          <WizardStep step={STARTUP_STEPS.STARTUP_PASSWORD}>
            <ChangePasswordTab
              sysConfig={sysConfig}
              tab={tabs[STARTUP_STEPS.STARTUP_PASSWORD]}
            />
          </WizardStep>
          <WizardStep step={STARTUP_STEPS.STARTUP_FIRMWARE}>
            <UpgradeFirmwareTab
              sysConfig={sysConfig}
              imageToUpgradeTo={imageToUpgradeTo}
            />
          </WizardStep>
          <WizardStep step={STARTUP_STEPS.STARTUP_BACKUP}>
            <BackupStrategy {...tabs[STARTUP_STEPS.STARTUP_BACKUP]?.props} />
          </WizardStep>
          <WizardStep step={STARTUP_STEPS.STARTUP_FINISH}>
            <EndTab
              tab={options.tabs[options.tabs.length - 1]}
              $opener={$opener}
            />
          </WizardStep>
        </WizardView>
      ) : (
        <>
          <NwProHeader> </NwProHeader>
          <NwProBody className='tw-flex tw-h-full tw-w-full tw-justify-center tw-items-center'>
            <NwSpinner style={{ fontSize: '4rem', textAlign: 'center' }} />
          </NwProBody>
        </>
      )}
    </>
  );
};
