import cn from 'classnames';

import { isString } from 'fiutil';

import './page_modal.less';

const textOverflowEllipsis = 'tw-truncate';

/**
 * @deprecated: Please use ProForm Header, Body, Section, and Footer instead
 * These components aim to make modal header, body, and footer work better in pages, modals, and drawers using flexbox.
 * limitation:
 *  - need to wrap PageModalHeader, PageModalBody, PageModalFooter with PageModalContainer
 *  - the container for PageModalHeader, PageModalBody, PageModalFooter needs to be a flexbox (suggest to add these classes to the container: tw-flex tw-flex-col)
 */
export const PageModalContainer = ({ extraClasses, children, ...rest }) => (
  <div className={cn('page_modal_container tw-h-full', extraClasses)} {...rest}>
    {children}
  </div>
);

/**
 * @deprecated: Please use ProForm Header, Body, Section, and Footer instead
 */
export const PageModalHeader = ({
  isInModal = false,
  extraClasses,
  onCloseClick,
  children,
  ...rest
}) => (
  <div
    className={cn('pm-header', {
      'tw-bg-neutral-50': isInModal,
      'tw-bg-neutral-200': !isInModal,
    })}
    slot='header'
    {...rest}
  >
    <span
      className={cn(
        { 'p-header': !isInModal, 'm-header': isInModal },
        textOverflowEllipsis,
        extraClasses
      )}
      title={isString(children) ? children : ''}
    >
      {children}
    </span>
    {onCloseClick && (
      <nw-icon-button
        name='close'
        style={{ float: 'right' }}
        onClick={onCloseClick}
      ></nw-icon-button>
    )}
  </div>
);

/**
 * @deprecated: Please use ProForm Header, Body, Section, and Footer instead
 */
export const PageModalHeaderEnhanced = ({
  isInModal = false,
  className,
  extraClasses,
  onCloseClick,
  label,
  children,
  ...rest
}) => (
  <div
    className={cn(
      'pm-header tw-group',
      { 'tw-bg-neutral-50': isInModal, 'tw-bg-neutral-200': !isInModal },
      className
    )}
    slot='header'
    {...rest}
  >
    <span
      className={cn(
        { 'p-header': !isInModal, 'm-header': isInModal },
        textOverflowEllipsis,
        extraClasses
      )}
      title={isString(children) ? children : ''}
    >
      {label ? label : children}
    </span>
    {label ? children : null}
    {onCloseClick && (
      <nw-icon-button
        name='close'
        style={{ float: 'right' }}
        onClick={onCloseClick}
      ></nw-icon-button>
    )}
  </div>
);

/**
 * @deprecated: Please use ProForm Header, Body, Section, and Footer instead
 */
export const PageModalBody = ({
  extraClasses,
  defaultPadding = true,
  children,
  ...rest
}) => (
  <div
    className={cn(
      'tw-h-full pm-body',
      { 'tw-p-4': defaultPadding },
      extraClasses
    )}
    {...rest}
  >
    {children}
  </div>
);

/**
 * @deprecated: Please use ProForm Header, Body, Section, and Footer instead
 */
export const PageModalFooter = ({
  isInModal = false,
  isInDrawer = true,
  extraClasses,
  children,
  ...rest
}) => (
  <div
    className={cn(
      'pm-footer',
      {
        'p-footer': !isInModal,
        'm-footer': isInModal,
        'drawer-footer': isInDrawer,
      },
      extraClasses
    )}
    slot='footer'
    {...rest}
  >
    {children}
  </div>
);
