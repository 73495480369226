import { fiSysConfig } from 'fi-web/fi-session';
import { copilotActions } from 'fistore/copilot';
import { fiSession, fiStore } from 'fistore/index';
import { getSessionAdomData } from 'fistore/session/adom/selectors';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useCloudAccount } from './useCloudAccount';
import { NwDrawer, NwSpinner } from '@fafm/neowise-core';
import { FortiAIErrorMessages } from './EnableFortiAiRoleButton';

import './shared_drawer.less';
import { getAdminUserName } from 'fistore/session/sysConfig/selectors';

const FmgAdvisorDrawer = lazy(() =>
  import('react_apps/ra_fmg_ai/AIAdvisor/AdvisorDrawer')
);
const FazAdvisorDrawer = lazy(() =>
  import('react_apps/ra_faz_ai/AIAdvisor/components/AdvisorDrawer')
);

const LoadingContent = () => {
  return (
    <div className='tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center'>
      <NwSpinner className='tw-text-xl' />
      Loading
    </div>
  );
};

const AIDrawer = ({ isOpen }) => {
  const isAiUser = useSelector(fiSession.getIsAiUser);
  const { getCloudLoginStatus, cloudLogin, cloudLogout, getFortiAiPermission } =
    useCloudAccount();
  const advisorRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      advisorRef.current.show();
    } else {
      advisorRef.current.hide();
    }
  }, [isOpen]);

  const loginAdomRef = useRef();
  const adom = useSelector(getSessionAdomData);
  const userName = useSelector(getAdminUserName);
  const [isReady, setIsReady] = useState(false);

  const { message, access, canEnable } = getFortiAiPermission();

  useEffect(() => {
    (async () => {
      if (!isOpen) {
        //console.log('NEVER RUN LOGIN when panel is NOT OPEN');
        // if you think you need to remove this please talk to me first.   --qifan
        return; // !important
      }
      if (!isAiUser) {
        // login only if fortiai user, mantis #1049288
        return;
      }
      if (!adom?.name) {
        //console.log('ignored auto login effect');
        setIsReady(true);
        return;
      }
      let forceRelogin = false;
      if (loginAdomRef.current && loginAdomRef.current !== adom.name) {
        forceRelogin = true;
        try {
          await cloudLogout();
        } catch (ex) {
          //console.error('Ignored', ex);
        }
      }
      if (loginAdomRef.current === adom.name && (await getCloudLoginStatus())) {
        //console.log('skipping because we are already logged in');
        setIsReady(true);
        return;
      }
      //console.log('doing login for ', adom?.name);
      loginAdomRef.current = adom?.name;
      try {
        await cloudLogin(forceRelogin).then(() => {
          //(resp) => {
          //console.log(
          //  'login result',
          //  resp?.data?.token,
          //  resp?.data?.domain,
          //  resp
          //);
        });
      } catch (ex) {
        console.error('failed to login', ex);
      }
      setIsReady(true);
    })();
  }, [adom, isOpen, isAiUser]);

  //const showEnableFortiAi = useMemo(() => {
  //  return !!getFortiAiPermission().canEnable;
  //}, [getFortiAiPermission]);

  return (
    <NwDrawer
      className='faz-advisor-drawer'
      ref={advisorRef}
      resizable
      noOverlay
      contained
      style={{ '--size': '40vw' }}
      onRequestClose={(e) => {
        e.preventDefault();
        fiStore.dispatch(copilotActions.setIsOpen(false));
      }}
    >
      {access ? (
        <Suspense fallback={<LoadingContent />}>
          {isReady ? (
            <>
              {fiSysConfig.isFaz() ? (
                <FazAdvisorDrawer />
              ) : (
                <FmgAdvisorDrawer />
              )}
            </>
          ) : (
            <LoadingContent />
          )}
        </Suspense>
      ) : (
        <FortiAIErrorMessages
          userName={userName}
          canEnable={canEnable}
          message={message}
        />
      )}
    </NwDrawer>
  );
};

function mapStateToProps(state) {
  return {
    isOpen: state.adom.copilot.isOpen,
  };
}

export default connect(mapStateToProps)(AIDrawer);
