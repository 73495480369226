import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';

import { LoginPageBase } from './LoginPageBase';
import { DefaultLoginForm } from './widgets/DefaultLoginForm';
import { removeLocalStorage } from 'fiutil/storage';

import './login.less';

export function LoginPage() {
  const navigate = useNavigate();
  const onLoginSuccess = useCallback(() => {
    navigate('/postlogin');
  }, [navigate]);

  // remove justLogin flag if it exists
  useEffect(() => {
    removeLocalStorage('justLogin');
  }, []);

  const renderLoginForm = useCallback(
    ({ loginEnv }) => {
      return (
        <DefaultLoginForm onLoginSuccess={onLoginSuccess} loginEnv={loginEnv} />
      );
    },
    [onLoginSuccess]
  );

  return <LoginPageBase renderLoginForm={renderLoginForm} />;
}
