import { fiWorkspaceRequest, fiWorkspaceDataSelector } from 'fistore';

import * as common_fns from './common';
import {
  _currentAdom,
  _hasLockedByMe,
  _isLockedByMe,
  _isLockedByOther,
  _hasLockedByOther,
  _hasLocked,
  _isUnlock,
  _hasDirty,
  _hasDirtyByMe,
  _isDirtyByMe,
  _isDirty,
  _getLockMsg,
} from './util';

export const plyMapDispatchToProps = (dispatch) => {
  return {
    init: (cate, pkg, adom = _currentAdom()) => {
      return fiWorkspaceRequest.load_workspace_policies(
        dispatch,
        adom.oid || adom,
        pkg.oid || pkg,
        cate
      );
    },
  };
};

export const plyMapStateToProps = (state) => {
  // do not put inline functions if may cause shadow compare fail?
  return {
    policies: fiWorkspaceDataSelector.get_workspace_policies(state),
    get: getPolicyLockInfo,
  };
};

export function getPolicyLockInfo(cate, pkg, adom = _currentAdom()) {
  return {
    policies: _selector_policies(this.policies, adom, pkg, cate) || {},
    ...common_fns,
    hasDirty: _hasPolicyDirty,
    hasDirtyByMe: _hasPolicyDirtyByMe,
    seqLockedByOther: _seqLockedByOther,
    seqLockedByMe: _seqLockedByMe,
    seqUnlocked: _seqUnlocked,
    seqLocked: _seqLocked, // me or other
    hasLocked: _hasPolicyLocked, // no matter me or other; seq or record
    hasRecordLocked: _hasRecordLocked, // me or other
    hasRecordLockedByMe: _hasRecordLockedByMe,
    hasRecordLockedByOther: _hasRecordLockedByOther,
    hasLockedByOther: _hasPolicyLockedByOther,
    isDirty: _isPolicyDirty,
    isLockedByMe: _isPolicyLockedByMe, // record
    isLockedByOther: _isPolicyLockedByOther, // record
    isUnlock: _isPolicyUnlock,
    lockMessage: _getLockMsg,
  };
}

export function getAllPolicyLockInfo(adom = _currentAdom()) {
  const policies = Object.entries(this.policies || {})
    .filter(([key]) => key.startsWith(`${adom.oid}:`))
    .map(([, policy]) => policy);

  return {
    hasRecordLockedByMe: function () {
      return policies.some((policy) => _hasLockedByMe(policy.records));
    },
  };
}

function _hasPolicyDirty() {
  return (
    _hasDirty(this.policies.records) || _isDirty(this.policies.sequence || {})
  );
}
function _hasPolicyDirtyByMe() {
  return (
    _hasDirtyByMe(this.policies.records) ||
    _isDirtyByMe(this.policies.sequence || {})
  );
}
function _isPolicyDirty(pid) {
  return this.policies.records ? _isDirty(this.policies.records[pid]) : false;
}
function _isPolicyLockedByMe(pid) {
  return this.policies.records
    ? _isLockedByMe(this.policies.records[pid])
    : false;
}
function _isPolicyLockedByOther(pid) {
  return _isLockedByOther(this.policies.records[pid]);
}
function _isPolicyUnlock(pid) {
  return _isUnlock(this.policies.records[pid]);
}

function _seqLockedByOther() {
  return this.policies.sequence
    ? this.policies.sequence.lock == MACROS.SYS.LOCK_STATE_BY_OTHER
    : false;
}
function _seqLockedByMe() {
  return this.policies.sequence
    ? this.policies.sequence.lock == MACROS.SYS.LOCK_STATE_LOCKED
    : false;
}
function _seqUnlocked() {
  return !_seqLocked.call(this);
}
function _seqLocked() {
  return this.policies.sequence
    ? this.policies.sequence.lock == MACROS.SYS.LOCK_STATE_LOCKED ||
        this.policies.sequence.lock == MACROS.SYS.LOCK_STATE_BY_OTHER
    : false;
}
function _hasRecordLocked() {
  return _hasLocked(this.policies.records);
}
function _hasRecordLockedByMe() {
  return _hasLockedByMe(this.policies.records);
}
function _hasRecordLockedByOther() {
  return _hasLockedByOther(this.policies.records);
}
function _hasPolicyLockedByOther() {
  return _seqLockedByOther.call(this) || _hasRecordLockedByOther.call(this);
}
function _hasPolicyLocked() {
  return _seqLocked.call(this) || _hasRecordLocked.call(this);
}

function _selector_policies(data, adom, pkg, cate) {
  let path = (adom.oid || adom) + ':' + (pkg.oid || pkg) + ':' + cate;
  return data && data[path] ? data[path] : {};
}
