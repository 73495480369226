import { useEffect, useState } from 'react';
import { isEmpty, get } from 'lodash';

import { fiStore, observeStore, fiSession } from 'fistore';
import { fiProxyHttp, fiFmgHttp } from 'fi-http';

import { makeObservers } from 'kit-observer';

import * as selectors from './selectors';

const _OsTypeAdomTypeMap = {};
const _OsTypeDevOsTypeMap = {};
const _os_types = MACROS.USER.PLATFORMS;
const DEFAULT_VER_STRING =
  MACROS.SYS.CONFIG_MAJOR_NUM + '.' + MACROS.SYS.CONFIG_MINOR_NUM;

for (let i = 0; i < _os_types.length; i++) {
  let key = MACROS.DVM['DVM_OS_TYPE_' + _os_types[i]];
  let value = MACROS.DVM['DVM_RESTRICTED_PRD_' + _os_types[i]];
  _OsTypeAdomTypeMap[key] = value;
  _OsTypeDevOsTypeMap[value] = key;
}

const getSysCfg = () => {
  return fiSession.getSysConfig(fiStore.getState()) ?? {};
};
export function current() {
  return fiSession.getSessionAdom(fiStore.getState())?.data ?? {};
}

export function attr(prop) {
  return current()?.[prop];
}

export function attrs(arr = []) {
  const data = current();
  return arr.reduce((acc, cur) => {
    acc[cur] = data[cur];
    return acc;
  }, {});
}

export const hasRemoteFAZ = (adom) => {
  const _adom = adom || current();
  return _adom.fazoid > 0;
};

export const hasCsfGroups = (adom) => {
  const _adom = adom || current();
  return _adom.csf_counts && _adom.csf_counts > 0;
};

export const isFmgAdomManagingFaz = (adom) => {
  const _adom = adom || current();
  return (
    _adom.type !== MACROS.DVM.DVM_RESTRICTED_PRD_FAZ &&
    _adom.fazoid > 0 &&
    selectors.hasFazAttached(getSysCfg())
  );
};

export const isFazAdomManagedByFmg = (adom) =>
  selectors.isFazAdomManagedByFmg(adom || current());

export const hasMultipleDevType = (adom) => {
  var _adom = adom || current();
  // currently only Fabric ADOM can contain multiple device type
  return _adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FSF;
};

export const checkVersion = (strVer, strOp, adom = null) => {
  let adomobj = adom || current();
  var res = false;
  if (typeof strVer !== 'string') {
    strVer += '';
  }
  var [ver, mr] = strVer.split('.').map((it) => parseInt(it, 10));
  let intVal = ver * 100 + mr;

  try {
    if (adomobj.version) {
      var curStr = adomobj.version.ver * 100 + adomobj.version.mr;
      switch (strOp) {
        case '<':
          return curStr < intVal;
        case '<=':
          return curStr <= intVal;
        case '>':
          return curStr > intVal;
        case '>=':
          return curStr >= intVal;
        default:
          return curStr == intVal;
      }
    }
  } catch (e) {
    //
  }
  return res;
};

export const isLatestVersion = (adom) => {
  let latest = selectors.latest_supported_adom_version(getSysCfg());
  return adom.version.ver === latest.ver && adom.version.mr === latest.mr;
};

export const isLatestVersionByOsType = (adom, osType) => {
  let latest = selectors.latest_supported_adom_version_by_os_type(getSysCfg())(
    osType
  );
  if (!latest) return true; //no versions for ostype
  return adom.version.ver === latest.ver && adom.version.mr === latest.mr;
};

export const isSupportedVersion = (adom) => {
  return selectors.is_adom_version_supported(getSysCfg())(
    adom.version.ver,
    adom.version.mr
  );
};

export const isConfigurableType = (adom) => {
  const _adom = adom || current();

  return (
    _adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FOS ||
    _adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FOC ||
    _adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FFW ||
    _adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FWC ||
    _adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FPX ||
    _adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FSF
  );
};

export const isGlobalAdom = (adom = null) => {
  let _adom = adom || current();
  return fiSession.isGlobalAdom(_adom);
};

export const isBackupAdom = (adom = null) => {
  let _adom = adom || current();
  // note the backup could be -1 for adoms that cannot be in backup mode.
  return _adom.backup === 1;
};

//get ADOM restricted product type by device os type
export const getAdomTypeByDevOsType = (dev_os_type) => {
  var type = MACROS.DVM.DVM_RESTRICTED_PRD_FOS;
  if (dev_os_type && _OsTypeAdomTypeMap[dev_os_type]) {
    type = _OsTypeAdomTypeMap[dev_os_type];
  }
  //FortiADC, FortiSOAR, FortiFAI, FIS, FED belongs to Fabric ADOMs
  const fabrics = new Set([
    MACROS.DVM.DVM_OS_TYPE_FAD,
    MACROS.DVM.DVM_OS_TYPE_FAI,
    MACROS.DVM.DVM_OS_TYPE_FSR,
    MACROS.DVM.DVM_OS_TYPE_FIS,
    MACROS.DVM.DVM_OS_TYPE_FED,
  ]);
  if (fabrics.has(dev_os_type)) {
    return MACROS.DVM.DVM_RESTRICTED_PRD_FSF; //Fabric ADOM
  }
  return type;
};

// Note: be careful to use this method since it does not work on Fabric ADOM!
export const getDevOsTypeByAdomType = (adom_type) => {
  var type = MACROS.DVM.DVM_OS_TYPE_FOS;
  if (adom_type && typeof _OsTypeDevOsTypeMap[adom_type] !== 'undefined') {
    type = _OsTypeDevOsTypeMap[adom_type];
  }
  return type;
};

export const genAdomVersionStr = (adom = null) => {
  let _adom = adom || current();
  if (_adom) {
    let admver = _adom.version || adom;
    return admver.ver + '.' + admver.mr;
  }
  return DEFAULT_VER_STRING; //default current Adom is latest release???
};

export const isFPXAdom = (adom = null) => {
  const _adom = adom || current();
  return _adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FPX;
};

export const isCentralManagement = (type, adom = null) => {
  const _adom = adom || current();
  return _adom[type] === 1;
};

export const isFFWAdom = (adom = current()) => {
  return adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FFW;
};

export const isFWCAdom = (adom = current()) => {
  return adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FWC;
};

export const isChassisAdom = (adom = current()) => {
  return adom.is_others;
};

// ============= query functions ============
export const adomIdNameMap = () => {
  return fiProxyHttp
    .post('/gui/alladom/groups', 'get', {})
    .then(
      (result) => {
        return result?.data ?? [];
      },
      () => []
    )
    .then((resp) => {
      return (resp || []).reduce((map, adom) => {
        map[adom.oid] = adom.name;
        return map;
      }, {});
    });
};

export const getAdomByDev = async (deviceOid) => {
  const assignedAdoms = await fiFmgHttp.post({
    url: `/gui/device/${deviceOid}/assigned-adoms`,
    method: 'get',
  });

  return get(assignedAdoms, '0.data', []);
};

// ============= React Hooks ==========================
export function useCurrent() {
  const [adom, setAdom] = useState(current);

  useEffect(() => {
    return observeStore(fiSession.getSessionAdom, (nadom) =>
      setAdom(nadom?.data ?? {})
    );
  }, []);

  return adom;
}

export function useCurrentAdomName() {
  const [adomName, setAdomName] = useState(current()?.name);

  useEffect(() => {
    return observeStore(fiSession.getSessionAdomName, (newName) => {
      setAdomName(newName);
    });
  }, []);

  return [adomName];
}

let _prevAdomRef = {};
let _switchAdomFlag = null;

export const makeAdomSubscriber = ({
  adomSwitchProps,
  adomUpdateProps,
} = {}) => {
  const { subscribe: subscribeAdomSwitched, notify: notifyAdomSwitched } =
    makeObservers(adomSwitchProps || {});
  const { subscribe: subscribeAdomUpdated, notify: notifyAdomUpdated } =
    makeObservers(adomUpdateProps || {});

  // quick fix/hack way to do so, top-react branch has better way
  // adom switch, include adom load and profile load,
  // need to wait for both done.
  // in src/web_new/static/js/fistore/session/reducer.js
  // two actions:
  //   switchSessionAdomAction.type (start switch)
  //   switchAdomDone.type (siwtch is done)
  const unSubAdomChange = observeStore(
    (state) => {
      return state.session;
    },
    (curr, prev) => {
      const currentAdom = curr?.adom?.data;
      const prevAdom = prev?.adom?.data;

      if (curr?.adom?.switchAdom === 'start') {
        _prevAdomRef = curr?.adom?.data;
        _switchAdomFlag = 'start';
      }

      if (_switchAdomFlag == 'start' && curr?.adom?.switchAdom !== 'done') {
        return;
      }

      if (curr?.adom?.switchAdom === 'done') {
        _switchAdomFlag = 'done';
      }

      if (
        !currentAdom ||
        !curr?.adom?.loaded ||
        isEmpty(currentAdom) ||
        isEmpty(prevAdom)
      ) {
        return;
      }

      const adomSwitched = _switchAdomFlag == 'done';
      _switchAdomFlag = null;

      if (adomSwitched) {
        notifyAdomSwitched(currentAdom, _prevAdomRef);
      } else {
        // to prevent from notifying adom switch event too many times
        if (currentAdom === prevAdom) return;
        notifyAdomUpdated(currentAdom, prevAdom);
      }
    }
  );

  return {
    //@deprecated
    //should call necessary functions on adom switch in a useeffect
    switched: subscribeAdomSwitched,
    updated: subscribeAdomUpdated,
    unsub: unSubAdomChange,
  };
};

export const adomSubscriber = makeAdomSubscriber();

// ============= Adom related loading functions ==========================
/**
 * Reload all the adoms.
 * @memberof util.adom
 * @return {promise} A promise.
 */
export const loadAdoms = () => {
  let req = {
    method: 'get',
    url: '/gui/alladom/list',
    params: {},
  };
  return fiFmgHttp.post(req).then((resp) => get(resp, '0.data', []));
};

export const loadAdomsQuery = () => {
  let req = {
    method: 'get',
    url: '/gui/alladom/list',
    params: {
      includeMembers: true,
      includeUnregMembers: true,
      includeMgtvdom: true,
    },
  };
  return fiFmgHttp.post(req).then((resp) => get(resp, '0.data', []));
};

export const loadAdomSwitchList = () => {
  return fiProxyHttp.post('/gui/switch/adoms/list', 'get', {}).then(
    (result) => {
      return result?.data ?? [];
    },
    () => []
  );
};

export const loadAdomSwitchListDetails = () => {
  return fiProxyHttp.post('/gui/switch/adoms/member', 'get', {}).then(
    (result) => {
      return result?.data ?? [];
    },
    () => []
  );
};
//
// export const changeAdom = async (newAdom) => {
//   const curAdom = current();
//
//   // if no need to change, return promise right away
//   if (newAdom.oid === curAdom.oid || newAdom.expired) {
//     return curAdom;
//   }
//
//   // dispatch switch adom request
//   const newAdomOid = parseInt(isObject(newAdom) ? newAdom.oid : newAdom);
//
//   // switch adom will trigger adomChangedNotify saga (in
//   // fistore/session/saga.js) when its dones
//   // no need to wait for adom, it's loaded in this step
//   await dispatch(fiSession.switchSessionAdom(newAdomOid));
//
//   const adom = fiSession.getSessionAdomData(fiStore.getState());
//   return adom;
// };
