import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import { fiSysConfig } from 'fi-session';
import { useDeferred } from 'rh_util_hooks';
import { useWizard } from 'rc_wizard';
import {
  wrapApiRequest,
  checkImage,
  doUpgrade,
  doBackup,
  genReleaseNoteLink,
} from './upgrade_firmware_util';
import { getTabAutoId } from '../tab_utils';

import {
  ConditionalComponent,
  NwProInputRow,
  NwProSection,
  NwProBody,
  NwProFooter,
  openConfirmModal,
} from 'rc_layout';
import { FmkPassword, FmkSwitch, FmkErrorSpan } from 'rc_form';
import { NwAlert, NwButton, NwIcon, NwSpinner } from '@fafm/neowise-core';
import { TaskMonitor } from 'rc_task_monitor';
import { setSkippedStep } from '../../modal/utils';
import { SysDashboardService } from 'fi-dashboard';
import { useSelector } from 'react-redux';
import { getIsSuperAdmin } from 'fistore/session/profile/selectors';
import { transformBackendFirmwareText } from 'fi-web/fi-api/system_operation';

export const UpgradeFirmwareTab = ({ sysConfig, imageToUpgradeTo }) => {
  const getAutoId = getTabAutoId('upgrade_firmware');

  const isSuperAdmin = useSelector(getIsSuperAdmin);
  const [message, setMessage] = useState('');
  const [taskId, setTaskId] = useState('');
  const deferred = useDeferred();

  const { goToNext } = useWizard();
  const formikRef = useRef(null);

  async function submit(_, { setSubmitting }) {
    try {
      if (Object.keys(formikRef.current.errors).length > 0)
        throw new Error('Form Errors`');

      const resp = await SysDashboardService.downloadByObjId(
        imageToUpgradeTo.objid
      );
      setTaskId(resp[0].data.taskid);
      setMessage(gettext('Downloading the selected image file...'));
      return deferred.promise;
    } catch (err) {
      setSubmitting(false);
      return Promise.reject(err);
    }
  }

  // Functions
  const runUpgradeSteps = async () => {
    await wrapApiRequest({
      request: checkImage,
      onSuccess: () =>
        setMessage(
          gettext(
            'The image has been uploaded successfully. The upgrade will start shortly.'
          )
        ),
      onError: (err) => deferred.reject(err),
    });

    await wrapApiRequest({
      request: () =>
        doBackup({
          ...formikRef.current.values,
          onNotify: (size) =>
            setMessage(
              gettext('The system is backing up... (%s)').printf([size])
            ),
        }),
      onError: (err) => deferred.reject(err),
    });

    await wrapApiRequest({
      request: doUpgrade,
      onSuccess: () => {
        setMessage(
          gettext(
            'The upgrade has started and the system is restarting. Please wait for a few moments.'
          )
        );
        deferred.resolve();
      },
    });

    return Promise.resolve();
  };

  return (
    <Formik
      initialValues={{
        backup: isSuperAdmin,
        encryption: false,
        password: '',
        confirm_password: '',
      }}
      innerRef={formikRef}
      onSubmit={submit}
    >
      {({ values, submitForm, isSubmitting }) => (
        <>
          <NwProBody>
            <NwProSection title={gettext('Upgrade Firmware')}>
              <ConditionalComponent condition={!isSubmitting}>
                <div className={'tw-mb-4'}>
                  <NwAlert type={'warning'} open>
                    <div className={'tw-flex'}>
                      <span className={'tw-ml-1'}>
                        {gettext('A new firmware version is available')}
                      </span>
                    </div>
                  </NwAlert>
                </div>

                <NwProInputRow label={gettext('Current Version')}>
                  <span>
                    {transformBackendFirmwareText(sysConfig.fmgversion)}
                  </span>
                </NwProInputRow>
                <NwProInputRow label={gettext('Latest Version')}>
                  <div style={{ display: 'flex' }}>
                    <span style={{ marginRight: '5px' }}>
                      {imageToUpgradeTo?.text}
                    </span>
                    <a
                      className={'tw-flex'}
                      href={genReleaseNoteLink(
                        fiSysConfig.isFmg(),
                        imageToUpgradeTo?.version
                      )}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <NwIcon
                        name={'download-pdf'}
                        label={gettext('Release Notes')}
                      />
                      <span className={'tw-ml-1'}>
                        {gettext('Release Notes')}
                      </span>
                    </a>
                  </div>
                </NwProInputRow>

                {isSuperAdmin ? (
                  <>
                    <NwProInputRow label={gettext('Backup Configuration')}>
                      <FmkSwitch
                        name={'backup'}
                        automationId={getAutoId('backup')}
                      />
                    </NwProInputRow>

                    <NwProInputRow label={gettext(gettext('Encryption'))}>
                      <FmkSwitch
                        name={'encryption'}
                        automationId={getAutoId('encryption')}
                      />
                    </NwProInputRow>

                    <ConditionalComponent condition={values.encryption}>
                      <NwProInputRow label={gettext('Password')}>
                        <FmkPassword
                          name={'password'}
                          automationId={'password'}
                          validate={(val) => {
                            if (values.encryption && !val)
                              return gettext('This field is required');
                          }}
                        />

                        <FmkErrorSpan name={'password'} />
                      </NwProInputRow>

                      <NwProInputRow label={gettext('Confirm Password')}>
                        <FmkPassword
                          name={'confirm_password'}
                          automationId={'confirm_password'}
                          validate={(val) => {
                            if (values.encryption) {
                              if (!val)
                                return gettext('This field is required.');
                              if (val !== values.password)
                                return gettext('Passwords do not match.');
                            }
                          }}
                        />

                        <FmkErrorSpan name={gettext('confirm_password')} />
                      </NwProInputRow>
                    </ConditionalComponent>
                  </>
                ) : null}
              </ConditionalComponent>

              <ConditionalComponent condition={isSubmitting}>
                <div className={'tw-flex'}>
                  <NwSpinner />
                  <span className={'tw-ml-1'}>{message}</span>
                </div>
                <div style={{ height: 300 }}>
                  <TaskMonitor
                    taskId={taskId}
                    onTaskDone={async () => {
                      return SysDashboardService.tarArchivedImage().then(
                        () => {
                          return runUpgradeSteps();
                        },
                        () => {
                          return runUpgradeSteps();
                        }
                      );
                    }}
                    onTaskError={() => {
                      setMessage(
                        gettext('Error download selected image file.')
                      );
                      deferred.reject();
                    }}
                  />
                </div>
              </ConditionalComponent>
            </NwProSection>
          </NwProBody>
          <NwProFooter>
            <ConditionalComponent condition={!isSubmitting}>
              <NwButton
                onClick={async () => {
                  try {
                    await openConfirmModal({
                      content: gettext(
                        'Are you sure you want to upgrade the firmware?'
                      ),
                      title: gettext('Upgrade Firmware'),
                    });

                    submitForm();
                  } catch {} //eslint-disable-line
                }}
                automation-id={getAutoId('next-btn')}
                className={'tw-min-w-32'}
                type={'primary'}
              >
                {gettext('Upgrade Now') + ' >'}
              </NwButton>
              <NwButton
                onClick={() => {
                  setSkippedStep();
                  goToNext();
                }}
                automation-id={getAutoId('later-btn')}
                className={'tw-min-w-32'}
                type={'default'}
              >
                {gettext('Upgrade Later')}
              </NwButton>
            </ConditionalComponent>
          </NwProFooter>
        </>
      )}
    </Formik>
  );
};
