import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fiHttp, fiHttpPost, fiHttpGet } from 'fi-web/fi-http/http';
import { fiCsrfToken } from 'fi-web/fi-cookies/util';
import { fetchSysConfig } from 'fistore/session/sysConfig/slice';
import { listenerMiddleware } from 'fistore/middlewares';
import { apiResultFirst } from 'fistore/utils/api';
import { setLocalStorage, removeLocalStorage } from 'fiutil/storage';
import {
  getAuthState,
  getActiveTimer,
  getVmLicense,
  getLife,
  getIgnoreError,
  getLoginEnv,
} from './selectors';
import { mergePayload } from 'fistore/utils/reducer';
import { postAuth, postResetTimer } from 'fistore/auth/api';
import { isString, find, isEmpty, assign, set } from 'lodash';
import { delay } from 'kit/async';

const KEEP_ALIVE_INTERVAL = 5000; // miliseconds
const WARN_THRESHOLD = 30; // seconds
export const RESET_DEBOUNCE = 5000; //miliseconds
const SYS_RECOVER_TIMER = 10000;

const initialState = {
  life: null,
  isLoadingEnv: false,
  isUpgrading: false,
  loginEnv: null,
  csrfToken: '',
  activeTimer: 0,
  authState: '', //'Polling', 'LostConn', 'Warn'
  vmLicense: null,
  ignoreError: false,
};

const _slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLife: mergePayload('life'),
    setLoggedIn(state) {
      set(state, 'life.valid', true);
    },
    setCsrfToken: mergePayload('csrfToken'),
    setIgnoreError(state, { payload = true }) {
      state.ignoreError = payload;
    },
    _setAuthState: mergePayload('authState'),
    _setActiveTimer: mergePayload('activeTimer'),
    _setVmLicense: mergePayload('vmLicense'),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginEnv.pending, (state) => {
        state.isLoadingEnv = true;
      })
      .addCase(fetchLoginEnv.fulfilled, (state, { payload: env }) => {
        state.isLoadingEnv = false;
        state.loginEnv = env;
        const status = env.upgrade_status;
        if (!status) {
          state.isUpgrading = false;
          return;
        }
        state.isUpgrading =
          (status.percent < 100 && status.status !== status.all_steps) ||
          status.migration_status !== 2 ||
          status.license_status !== 0;
      });
  },
});

export const { setIgnoreError, setCsrfToken, setLoggedIn } = _slice.actions;

// private actions
const { setLife, _setActiveTimer, _setAuthState, _setVmLicense } =
  _slice.actions;

export default _slice.reducer;

// thunks

export const tryFetchLogin = createAsyncThunk(
  'auth/tryFetchLogin',
  async (params) => {
    const valid = false;
    while (!valid) {
      try {
        const ret = await fiHttpGet('/p/loginenv/', { params });
        // for testing
        // return assign(await fiHttpGet('/p/loginenv/', { params }), {
        //   sso_role: 2,
        //   fab_idp_list: [
        //     {
        //       name: 'idp1',
        //       adom_name: 'adom1',
        //       adom_desc: 'Desc 1',
        //       csf_name: 'csf1',
        //       csf_num_devices: 3,
        //     },
        //     {
        //       name: 'idp2',
        //       adom_name: 'adom2',
        //       adom_desc: 'Desc 2',
        //       csf_name: 'csf2',
        //       csf_num_devices: 4,
        //     },
        //   ],
        // });
        // return assign(ret, {
        //   require_vm_lic: true,
        // });
        return ret;
      } catch (err) {
        console.error(err);
      }
      // keep on fetching login env if network failed
      await delay(KEEP_ALIVE_INTERVAL);
    }
  }
);

export const fetchLoginEnv = createAsyncThunk(
  'auth/fetchLoginEnv',
  async (params, { dispatch, getState }) => {
    const loginEnv = getLoginEnv(getState());
    if (!loginEnv) {
      return await dispatch(tryFetchLogin(params)).unwrap();
    }
    return loginEnv;
  }
);

const LOGIN_TYPE_NORMAL = 0;
const LOGIN_TYPE_CHALLENGE = 1;
const LOGIN_TYPE_PKI = 2;

export const tryLogin = createAsyncThunk(
  'auth/POST_LOGIN',
  async (
    { username, password, pkiUser, challengeInput },
    { rejectWithValue }
  ) => {
    const isChallenge = !isEmpty(challengeInput);

    const isPki = !isEmpty(pkiUser);

    const loginType = isChallenge
      ? LOGIN_TYPE_CHALLENGE
      : isPki
      ? LOGIN_TYPE_PKI
      : LOGIN_TYPE_NORMAL;

    const realUsername = isPki ? pkiUser : username;

    const params = assign(
      {
        secretkey: password,
        logintype: loginType,
      },
      isChallenge
        ? { challengekey: challengeInput }
        : { username: realUsername }
    );

    try {
      const resp = await postAuth({
        url: '/gui/userauth',
        method: isChallenge ? 'challenge' : 'login',
        params,
      });
      // remove justLogout status
      setLocalStorage('justLogout', false);
      return resp;
    } catch (err) {
      return rejectWithValue(err?.status);
    }
  }
);

export const checkAuth = createAsyncThunk(
  'auth/checkAuth',
  async (_, { dispatch, signal, rejectWithValue, getState }) => {
    try {
      const resp = await postAuth(
        { url: '/gui/session-live', method: 'get' },
        { signal, timeout: KEEP_ALIVE_INTERVAL * 3 }
      );
      const life = resp?.data;
      dispatch(setLife(life));
      const timeLeft = life.time_left;
      const state = getState();
      let activeTimer = getActiveTimer(state);
      if (activeTimer === 0) {
        activeTimer = life.timestamp;
        dispatch(_setActiveTimer(activeTimer));
      }
      if (
        timeLeft <= WARN_THRESHOLD &&
        life.timestamp - activeTimer >= WARN_THRESHOLD &&
        getAuthState(state) === 'Polling'
      ) {
        dispatch(_setAuthState('Warn'));
      }
      return Boolean(resp?.data?.valid);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const _postResetTimer = createAsyncThunk(
  'auth/resetTimer',
  async (_, { dispatch, getState }) => {
    const life = getLife(getState());
    dispatch(_setActiveTimer(life.timestamp));
    await postResetTimer();
  }
);

export const resetTimer = createAsyncThunk(
  'auth/resetTimer',
  async (_, { dispatch }) => {
    await dispatch(_postResetTimer());
    dispatch(_setAuthState('Polling'));
  }
);

export const setActive = createAsyncThunk(
  'auth/setActive',
  async (_, { dispatch, getState }) => {
    // block reset if in Warn mode.
    if (getAuthState(getState()) === 'Warn') return;
    dispatch(_postResetTimer());
  }
);

const LOST_CONN_RETRY = 3;

export const heartbeat = createAsyncThunk(
  'auth/heartbeat',
  async (_, { dispatch, getState }) => {
    let valid = true;
    let retry = LOST_CONN_RETRY;
    dispatch(_setAuthState('Polling'));
    while (valid) {
      await delay(KEEP_ALIVE_INTERVAL);
      const state = getState();
      const authState = getAuthState(state);
      const ignErr = getIgnoreError(state);

      try {
        valid = await dispatch(checkAuth()).unwrap();
        if (valid && authState === 'LostConn') {
          // network recovered
          retry = LOST_CONN_RETRY;
          dispatch(_setAuthState('Polling'));
        }
      } catch (err) {
        // network error
        if (err?.message === 'Rejected' || isString(err)) {
          // if ignore error break out
          if (ignErr) continue;
          if (retry >= 0) {
            retry--;
            continue;
          }
          dispatch(_setAuthState('LostConn'));
          // if IgnErr, continue polling until logout
        } else {
          // server error
          valid = false;
          // server is back up, but GUI might not be ready, continue waiting
          // for a while
          if (err?.status?.code !== 0) {
            await delay(SYS_RECOVER_TIMER);
          }
        }
      }
    }
    dispatch(_setAuthState(''));
    dispatch(logout());
  },
  {
    condition: (_, { getState }) => {
      // do not start heartbeat again once started
      return getAuthState(getState()) === '';
    },
  }
);

export const pushFortitokenNotification = createAsyncThunk(
  'auth/PUSH_FORTITOKEN_NOTIFICATION',
  () => {
    return postAuth({
      url: '/gui/userauth',
      method: 'pushftk',
    });
  }
);

export const initCSRF = createAsyncThunk(
  'auth/initCSRF',
  async (_, { dispatch }) => {
    const csrfToken = fiCsrfToken(true);
    dispatch(setCsrfToken(csrfToken));
    // Should move all the APIs to store to avoid this.
    fiHttp.defaults.headers.common['XSRF-TOKEN'] = csrfToken;
  }
);

export const logout = createAsyncThunk('auth/LOGOUT', async () => {
  // fiHttpGet('/cgi-bin/module/frame/logout').then(() => {
  //   setReloadPage();
  // });

  try {
    const resp = await fiHttpPost('/p/logout-api/');
    // for PKI login
    setLocalStorage('justLogout', true);
    removeLocalStorage('justLogin');
    const logoutUrl = find(
      ['sso_slo', 'cloud_logout'].map((key) => resp?.result?.[0]?.data[key])
    );
    if (logoutUrl) {
      const host = encodeURIComponent(location.host);
      return location.assign(`${logoutUrl}?host=${host}`);
    }
  } catch (e) {
    console.error('Error: ', e);
  }
  // jump to login page first to prevent pre-loading of module code.
  location.assign('/ui/login/');
});

export const forticloudGetTrialLicense = createAsyncThunk(
  'auth/GetTrialLicense',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const resp = await postAuth({
        url: '/gui/userauth',
        method: 'fc_vmtrial',
        params: {
          account: username,
          password,
        },
      });
      return resp?.result?.[0];
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const forticloudActivateLicense = createAsyncThunk(
  'auth/fortiCloudActivateLicense',
  async ({ username, password, regCode, ipAddr }, { rejectWithValue }) => {
    try {
      const resp = await postAuth({
        url: '/gui/userauth',
        method: 'fc_registernew',
        params: {
          account: username,
          password,
          registrationCode: regCode,
          ip: ipAddr,
        },
      });
      return resp?.result?.[0];
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// listeners
listenerMiddleware.startListening({
  actionCreator: fetchSysConfig.rejected,
  effect: async (action, { dispatch }) => {
    const license = await apiResultFirst(
      fiHttpPost,
      '/cgi-bin/module/flatui_auth',
      {
        url: '/gui/vmlicense/status',
        method: 'get',
      }
    );
    const valid =
      !MACROS.SYS.IS_VM ||
      MACROS.SYS.NOVMLICENSE ||
      MACROS.SYS.HAVE_ONDEMAND ||
      ![
        MACROS.SYS.VM_LIC_STATUS_EXPIRED,
        MACROS.SYS.VM_LIC_STATUS_MGMT_IP_NOMATCH,
        MACROS.SYS.VM_LIC_STATUS_LICENSE_DUPLICATE,
      ].includes(license['vmlic_status']);
    dispatch(_setVmLicense({ license, valid }));
  },
});

listenerMiddleware.startListening({
  actionCreator: fetchSysConfig.fulfilled,
  effect: async (action, { getState, dispatch }) => {
    const vmLicense = getVmLicense(getState());
    dispatch(_setVmLicense({ vmLicense, valid: true }));
  },
});

listenerMiddleware.startListening({
  actionCreator: fetchLoginEnv.fulfilled,
  effect: (action, { getState }) => {
    const state = getState();
    const loginEnv = getLoginEnv(state);
    const languageCode = loginEnv.language_code;
    document.querySelector('html').setAttribute('lang', languageCode || 'en');
  },
});
