export const dashboards = [
  {
    uuid: 'fmg-dash1',
    icon: 'applications',
    name: gettext('Device Status'),
    timePeriodType: 'normal',
    isDeviceHidden: true,
    isDateTimeHidden: true,
    widgets: [
      {
        grid: {
          id: 'device_status',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
        name: 'device_status',
      },
    ],
    faz: false,
    fmg: true,
  },
  {
    uuid: 'threat-monitor',
    icon: 'threats',
    name: gettext('Threats'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'fortiview_threatmap',
          cells: 2,
          sizeX: 8,
          sizeY: 16,
          row: 0,
          col: 0,
        },
        name: 'fortiview_threatmap',
      },
      {
        grid: {
          id: 'top_threat_dest',
          cells: 2,
          sizeX: 16,
          sizeY: 16,
          row: 0,
          col: 8,
        },
        name: 'top_threat_dest',
      },
      {
        grid: {
          id: 'top_threat',
          cells: 2,
          sizeX: 8,
          sizeY: 8,
          row: 16,
          col: 0,
        },
        name: 'top_threat',
      },
      {
        grid: {
          id: 'top_threat_weight',
          cells: 2,
          sizeX: 8,
          sizeY: 8,
          row: 24,
          col: 0,
        },
        name: 'top_threat_weight',
      },
      {
        grid: {
          id: 'virus_timeline',
          cells: 4,
          sizeX: 16,
          sizeY: 16,
          row: 16,
          col: 8,
        },
        name: 'virus_timeline',
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'l3-traffic-monitor',
    icon: 'traffic',
    name: gettext('Traffic'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'top_source',
          cells: 2,
          sizeX: 8,
          sizeY: 16,
          row: 0,
          col: 0,
        },
        name: 'top_source',
      },
      {
        grid: {
          id: 'top_country',
          cells: 2,
          sizeX: 16,
          sizeY: 16,
          row: 0,
          col: 8,
        },
        name: 'top_country',
      },
      {
        grid: {
          id: 'fortiview_policy_hits',
          cells: 2,
          sizeX: 10,
          sizeY: 8,
          row: 16,
          col: 0,
        },
        name: 'fortiview_policy_hits',
      },
      {
        grid: {
          id: 'fortiview_top_destination',
          cells: 2,
          sizeX: 14,
          sizeY: 8,
          row: 16,
          col: 10,
        },
        name: 'fortiview_top_destination',
      },
      {
        grid: {
          id: 'fortiview_top_destination_historical',
          cells: 4,
          sizeX: 24,
          sizeY: 8,
          row: 24,
          col: 0,
        },
        name: 'fortiview_top_destination_historical',
        defaultHide: true,
      },
      {
        grid: {
          id: 'fortiview_policy_hits_historical',
          cells: 4,
          sizeX: 24,
          sizeY: 8,
          row: 32,
          col: 0,
        },
        name: 'fortiview_policy_hits_historical',
        defaultHide: true,
      },
      {
        grid: {
          id: 'user_data_flow',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 40,
          col: 0,
        },
        name: 'user_data_flow',
        defaultHide: true,
      },
      {
        grid: {
          id: 'top_source_today',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 40,
          col: 12,
        },
        name: 'top_source_today',
        defaultHide: true,
      },
      {
        grid: {
          id: 'intf_sent_timeline',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 52,
          col: 0,
        },
        name: 'intf_sent_timeline',
        defaultHide: true,
      },
      {
        grid: {
          id: 'intf_rcvd_timeline',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 52,
          col: 12,
        },
        name: 'intf_rcvd_timeline',
        defaultHide: true,
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'l7-traffic-monitor',
    icon: 'applications',
    name: gettext('Applications & Websites'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'fortiview_top_website',
          cells: 2,
          sizeX: 12,
          sizeY: 16,
          row: 0,
          col: 0,
        },
        name: 'fortiview_top_website',
      },
      {
        grid: {
          id: 'fortiview_top_cloud_application',
          cells: 2,
          sizeX: 12,
          sizeY: 16,
          row: 0,
          col: 16,
        },
        name: 'fortiview_top_cloud_application',
      },
      {
        grid: {
          id: 'top_application',
          cells: 1,
          sizeX: 8,
          sizeY: 8,
          row: 16,
          col: 0,
        },
        name: 'top_application',
      },
      {
        grid: {
          id: 'fortiview_top_browsing_user',
          cells: 3,
          sizeX: 16,
          sizeY: 8,
          row: 16,
          col: 8,
        },
        name: 'fortiview_top_browsing_user',
      },
      {
        grid: {
          id: 'fortiview_top_cloud_application_historical',
          cells: 1,
          sizeX: 8,
          sizeY: 8,
          row: 24,
          col: 0,
        },
        name: 'fortiview_top_cloud_application_historical',
        defaultHide: true,
      },
      {
        grid: {
          id: 'top_application_historical',
          cells: 3,
          sizeX: 16,
          sizeY: 8,
          row: 24,
          col: 8,
        },
        name: 'top_application_historical',
        defaultHide: true,
      },
      {
        grid: {
          id: 'fortiview_fct_app',
          cells: 3,
          sizeX: 16,
          sizeY: 16,
          row: 32,
          col: 8,
        },
        name: 'fortiview_fct_app',
        defaultHide: true,
      },
      {
        grid: {
          id: 'fortiview_top_website_historical',
          cells: 1,
          sizeX: 8,
          sizeY: 8,
          row: 32,
          col: 0,
        },
        name: 'fortiview_top_website_historical',
        defaultHide: true,
      },
      {
        grid: {
          id: 'fortiview_top_browsing_user_historical',
          cells: 2,
          sizeX: 8,
          sizeY: 8,
          row: 40,
          col: 0,
        },
        name: 'fortiview_top_browsing_user_historical',
        defaultHide: true,
      },
    ],
    faz: true,
    fmg: false,
  },
  // move IOC & Vul up to under Secrity - bug: 469501
  {
    uuid: 'compromised-hosts',
    icon: 'th-list',
    iconLibrary: 'fa-solid',
    name: gettext('Indicator of Compromise'),
    timePeriodType: 'ioc',
    widgets: [
      {
        name: 'ioc_user',
        grid: {
          id: 'ioc_user',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    disableFmgHasFaz: true, // fmg has faz feature
    faz: true,
    fmg: false,
  },
  {
    uuid: 'sdwan-dashboard',
    icon: 'dashboard',
    name: gettext('Secure SD-WAN Monitor'),
    widgets: [
      {
        grid: {
          id: 'sdwan_bandwidth',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'sdwan_bandwidth',
      },
      {
        grid: {
          id: 'sdwan_performance',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 12,
        },
        name: 'sdwan_performance',
      },
      {
        grid: {
          id: 'sdwan_rules_intf',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'sdwan_rules_intf',
      },
      {
        grid: {
          id: 'sdwan_app',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 12,
        },
        name: 'sdwan_app',
      },
      {
        grid: {
          id: 'sdwan_per_dev_intf_per_stats',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 24,
          col: 0,
        },
        name: 'sdwan_per_dev_intf_per_stats',
      },
      {
        grid: {
          id: 'sdwan_alert',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 36,
          col: 0,
        },
        name: 'sdwan_alert',
      },
      {
        grid: {
          id: 'sdwan_app_bandwidth',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 48,
          col: 0,
        },
        name: 'sdwan_app_bandwidth',
      },
      {
        grid: {
          id: 'sdwan_per_app_performance',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 60,
          col: 0,
        },
        name: 'sdwan_per_app_performance',
      },
      {
        grid: {
          id: 'sdwan_global_app_performance',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 60,
          col: 12,
        },
        name: 'sdwan_global_app_performance',
      },
      {
        grid: {
          id: 'sdwan_interfaces',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 72,
          col: 0,
        },
        name: 'sdwan_interfaces',
      },
      {
        grid: {
          id: 'sdwan_per_intf_mos',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 84,
          col: 0,
        },
        name: 'sdwan_per_intf_mos',
      },
      {
        grid: {
          id: 'sdwan_speed_test_line',
          sizeX: 12,
          sizeY: 12,
          row: 84,
          col: 12,
        },
        name: 'sdwan_speed_test_line',
      },
      {
        grid: {
          id: 'sdwan_health_check',
          cells: 4,
          sizeX: 24,
          sizeY: 16,
          row: 96,
          col: 0,
        },
        name: 'sdwan_health_check',
      },
    ],
    isSdWan: true,
    faz: true,
    fmg: false,
  },
  {
    uuid: 'sdwan-summary',
    icon: 'dashboard',
    name: gettext('SD-WAN Summary'),
    widgets: [
      {
        grid: {
          id: 'sdwan_dev_stats',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'sdwan_dev_stats',
      },
      {
        grid: {
          id: 'sdwan_dev_intf_per_stats',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 12,
        },
        name: 'sdwan_dev_intf_per_stats',
      },
      {
        grid: {
          id: 'sdwan_summary_app',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'sdwan_summary_app',
      },
      {
        grid: {
          id: 'sdwan_dev_throughout',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 12,
        },
        name: 'sdwan_dev_throughout',
      },
      {
        grid: {
          id: 'sdwan_summary_user',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 24,
          col: 0,
        },
        name: 'sdwan_summary_user',
      },
      {
        grid: {
          id: 'sdwan_speed_test_by_bandwidth',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 36,
          col: 0,
        },
        name: 'sdwan_speed_test_by_bandwidth',
      },
      {
        grid: {
          id: 'sdwan_speed_test_summary',
          cells: 4,
          sizeX: 12,
          sizeY: 12,
          row: 36,
          col: 12,
        },
        name: 'sdwan_speed_test_summary',
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'traffic-shaping-monitor',
    icon: 'network-traffic',
    name: gettext('Traffic Shaping Monitor'),
    widgets: [
      {
        grid: {
          id: 'traffic_shaping_bandwidth',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'traffic_shaping_bandwidth',
      },
      {
        grid: {
          id: 'traffic_shaping_top_app',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 12,
        },
        name: 'traffic_shaping_top_app',
      },
      {
        grid: {
          id: 'traffic_shaping_dropped_bytes',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'traffic_shaping_dropped_bytes',
      },
    ],
    hasInterfaceSelector: true,
    faz: true,
    fmg: false,
  },
  {
    uuid: 'ztna-dashboard',
    icon: 'ztna',
    name: gettext('ZTNA'),
    widgets: [
      {
        grid: {
          id: 'ztna_stats',
          cells: 1,
          sizeX: 8,
          sizeY: 21,
          row: 0,
          col: 0,
        },
        name: 'ztna_stats',
      },
      {
        grid: {
          id: 'ztna_connection_attempts',
          cells: 1,
          sizeX: 8,
          sizeY: 14,
          row: 0,
          col: 8,
        },
        name: 'ztna_connection_attempts',
      },
      {
        grid: {
          id: 'ztna_devices',
          cells: 1,
          sizeX: 8,
          sizeY: 9,
          row: 0,
          col: 16,
        },
        name: 'ztna_devices',
      },
      {
        grid: {
          id: 'ztna_device_tags',
          cells: 1,
          sizeX: 8,
          sizeY: 5,
          row: 9,
          col: 16,
        },
        name: 'ztna_device_tags',
      },
      {
        grid: {
          id: 'ztna_user_overview',
          cells: 1,
          sizeX: 8,
          sizeY: 12,
          row: 14,
          col: 8,
        },
        name: 'ztna_user_overview',
      },
      {
        grid: {
          id: 'ztna_known_devices',
          cells: 1,
          sizeX: 8,
          sizeY: 12,
          row: 14,
          col: 16,
        },
        name: 'ztna_known_devices',
      },
      {
        grid: {
          id: 'ztna_bandwidth_trends',
          cells: 1,
          sizeX: 8,
          sizeY: 12,
          row: 26,
          col: 8,
        },
        name: 'ztna_bandwidth_trends',
      },
      {
        grid: {
          id: 'ztna_top_users_by_connections',
          cells: 1,
          sizeX: 8,
          sizeY: 12,
          row: 26,
          col: 16,
        },
        name: 'ztna_top_users_by_connections',
      },
      {
        grid: {
          id: 'ztna_private_apps_access',
          cells: 1,
          sizeX: 8,
          sizeY: 12,
          row: 38,
          col: 8,
        },
        name: 'ztna_private_apps_access',
      },
      {
        grid: {
          id: 'ztna_public_apps_access',
          cells: 1,
          sizeX: 8,
          sizeY: 12,
          row: 38,
          col: 16,
        },
        name: 'ztna_public_apps_access',
      },
      {
        grid: {
          id: 'ztna_users',
          cells: 1,
          sizeX: 8,
          sizeY: 15,
          row: 21,
          col: 0,
        },
        name: 'ztna_users',
      },
      {
        grid: {
          id: 'ztna_policy_overview',
          cells: 1,
          sizeX: 8,
          sizeY: 14,
          row: 36,
          col: 0,
        },
        name: 'ztna_policy_overview',
      },
      {
        grid: {
          id: 'ztna_apps_access_failure',
          cells: 4,
          sizeX: 24,
          sizeY: 14,
          row: 50,
          col: 0,
        },
        name: 'ztna_apps_access_failure',
      },
      {
        grid: {
          id: 'ztna_casb_apps_access',
          cells: 1,
          sizeX: 8,
          sizeY: 12,
          row: 64,
          col: 0,
        },
        name: 'ztna_casb_apps_access',
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'fortisandbox-detections',
    icon: 'fortisandbox',
    name: gettext('FortiSandbox Detections'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'fortiview_fsa_detection',
          cells: 4,
          sizeX: 24,
          sizeY: 16,
          row: 0,
          col: 0,
        },
        name: 'fortiview_fsa_detection',
      },
      {
        grid: {
          id: 'sandbox_overall',
          cells: 2,
          sizeX: 12,
          sizeY: 8,
          row: 16,
          col: 0,
        },
        name: 'sandbox_overall',
      },
      {
        grid: {
          id: 'sandbox_user',
          cells: 2,
          sizeX: 12,
          sizeY: 8,
          row: 16,
          col: 12,
        },
        name: 'sandbox_user',
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'fortimail-dashboard',
    icon: 'fortimail',
    name: gettext('FortiMail'),
    widgets: [
      {
        grid: {
          id: 'fml_received_vs_spam',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'fml_received_vs_spam',
      },
      {
        grid: {
          id: 'fml_top_senders',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'fml_top_senders',
      },
      {
        grid: {
          id: 'fml_top_recipients',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 24,
          col: 0,
        },
        name: 'fml_top_recipients',
      },
      {
        grid: {
          id: 'fml_threat_stats',
          cells: 4,
          sizeX: 24,
          sizeY: 16,
          row: 36,
          col: 0,
        },
        name: 'fml_threat_stats',
      },
      {
        grid: {
          id: 'fml_mail_stats',
          cells: 4,
          sizeX: 24,
          sizeY: 16,
          row: 52,
          col: 0,
        },
        name: 'fml_mail_stats',
      },
      {
        grid: {
          id: 'fml_sandbox_outbreak',
          cells: 4,
          sizeX: 24,
          sizeY: 16,
          row: 68,
          col: 0,
        },
        name: 'fml_sandbox_outbreak',
      },
      {
        grid: {
          id: 'fml_stats_summary',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 84,
          col: 0,
        },
        name: 'fml_stats_summary',
      },
    ],
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FML],
  },
  {
    uuid: 'fortiproxy-dashboard',
    icon: 'fortiproxy',
    name: gettext('FortiProxy'),
    widgets: [
      {
        grid: {
          id: 'fpx_top_sources',
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'fpx_top_sources',
      },
      {
        grid: {
          id: 'fpx_top_destinations',
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 12,
        },
        name: 'fpx_top_destinations',
      },
      {
        grid: {
          id: 'fpx_website_domain',
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'fpx_website_domain',
      },
      {
        grid: {
          id: 'fpx_threat_src_dst',
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 12,
        },
        name: 'fpx_threat_src_dst',
      },
      {
        grid: {
          id: 'fpx_top_threats',
          sizeX: 24,
          sizeY: 12,
          row: 24,
          col: 0,
        },
        name: 'fpx_top_threats',
      },
      {
        grid: {
          id: 'fpx_top_applications',
          sizeX: 12,
          sizeY: 12,
          row: 36,
          col: 0,
        },
        name: 'fpx_top_applications',
      },
      {
        grid: {
          id: 'fpx_top_dlp_events',
          sizeX: 12,
          sizeY: 12,
          row: 36,
          col: 12,
        },
        name: 'fpx_top_dlp_events',
      },
    ],
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FPX],
  },
  {
    uuid: 'endpoint-monitor',
    icon: 'monitor',
    name: gettext('Endpoints'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'vuln_user',
          cells: 4,
          sizeX: 24,
          sizeY: 16,
          row: 0,
          col: 0,
        },
        name: 'vuln_user',
      },
      {
        grid: {
          id: 'fortiview_all_endpoints',
          cells: 2,
          sizeX: 12,
          sizeY: 8,
          row: 16,
          col: 0,
        },
        name: 'fortiview_all_endpoints',
      },
      {
        grid: {
          id: 'fortiview_fct_threats',
          cells: 2,
          sizeX: 12,
          sizeY: 8,
          row: 16,
          col: 12,
        },
        name: 'fortiview_fct_threats',
      },
      {
        name: 'end_point_vul_fct',
        grid: {
          id: 'end_point_vul_fct',
          cells: 2,
          sizeX: 12,
          sizeY: 8,
          row: 24,
          col: 0,
        },
      },
      {
        name: 'end_point_vul_dev_fct',
        grid: {
          id: 'end_point_vul_dev_fct',
          cells: 2,
          sizeX: 12,
          sizeY: 8,
          row: 24,
          col: 12,
        },
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'fabric-state-of-security',
    icon: 'fabric-device',
    name: gettext('Fabric State of Security'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'fabric_audit',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'fabric_audit',
      },
      {
        grid: {
          id: 'fabric_members',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'fabric_members',
      },
      {
        grid: {
          id: 'fabric_history_compare',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 12,
        },
        name: 'fabric_history_compare',
      },
      {
        grid: {
          id: 'fsbp_region',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 24,
          col: 0,
        },
        name: 'fsbp_region',
      },
    ],
    isCsf: true,
    faz: true,
    fmg: false,
    licenses: [MACROS.FAZ_SYNTAX.SVC_LICENSE_FGSA],
  },
  {
    uuid: 'vpn-monitor',
    icon: 'vpn-lock',
    name: gettext('VPN'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'top_vpn',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'top_vpn',
      },
      {
        grid: {
          id: 'vpn_site2site',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'vpn_site2site',
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'wifi-monitor',
    icon: 'wifi',
    name: gettext('WiFi'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'top_ap',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'top_ap',
      },
      {
        grid: {
          id: 'top_rogue',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'top_rogue',
      },
      {
        grid: {
          id: 'top_ssid',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 12,
        },
        name: 'top_ssid',
      },
      {
        grid: {
          id: 'top_ssid_historical',
          cells: 4,
          sizeX: 24,
          sizeY: 8,
          row: 24,
          col: 0,
        },
        name: 'top_ssid_historical',
        defaultHide: true,
      },
      {
        grid: {
          id: 'top_wifi_clients',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 32,
          col: 0,
        },
        name: 'top_wifi_clients',
        defaultHide: true,
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'fct-software-inventory',
    icon: 'ems-software-inventory',
    name: gettext('FortiClient Software Inventory'),
    widgets: [
      {
        name: 'fct_software',
        grid: {
          id: 'fct_software',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FCT],
  },
  {
    uuid: 'iot',
    icon: 'fortiguard-iot-service',
    name: gettext('IoT'),
    widgets: [
      {
        name: 'iot_inventory',
        grid: {
          id: 'iot_inventory',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    faz: true,
    fmg: false,
  },
  {
    uuid: 'fct-threat-moonitor',
    icon: 'third-party-threat-feed',
    name: gettext('Threat'),
    adomName: gettext('FortiClient'),
    widgets: [
      {
        name: 'top_threat_fct',
        grid: {
          id: 'top_threat_fct',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    disableFmgHasFaz: true, // fmg has faz feature
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FCT],
  },
  {
    uuid: 'fct-traffic-moonitor',
    icon: 'applications',
    name: gettext('Applications & Websites'),
    adomName: gettext('FortiClient'),
    widgets: [
      {
        name: 'top_app_fct',
        grid: {
          id: 'top_app_fct',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
      },
      {
        name: 'top_website_fct',
        grid: {
          id: 'top_website_fct',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 12,
          col: 0,
        },
      },
    ],
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FCT],
  },
  {
    uuid: 'fct-endpoints-moonitor',
    icon: 'event-monitor',
    name: gettext('Endpoints'),
    adomName: gettext('FortiClient'),
    widgets: [
      {
        name: 'top_endpoints_fct',
        grid: {
          id: 'top_endpoints_fct',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
      },
    ],
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FCT],
  },
  {
    uuid: 'fddos-traffic-moonitor',
    icon: 'traffic',
    name: gettext('Traffic'),
    adomName: gettext('FortiDDoS'),
    widgets: [
      {
        name: 'top_source_fddos',
        grid: {
          id: 'top_source_fddos',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
      },
      {
        name: 'top_destination_fddos',
        grid: {
          id: 'top_destination_fddos',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 0,
        },
      },
      {
        name: 'top_type_fddos',
        grid: {
          id: 'top_type_fddos',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 12,
        },
      },
    ],
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FDD],
  },
  {
    uuid: 'ffw-traffic-monitor',
    icon: 'traffic',
    name: gettext('Traffic'),
    adomName: gettext('FortiFirewall'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'ffw_top_source',
          cells: 1,
          sizeX: 8,
          sizeY: 16,
          row: 0,
          col: 0,
        },
        name: 'ffw_top_source',
      },
      {
        grid: {
          id: 'ffw_top_country',
          cells: 3,
          sizeX: 16,
          sizeY: 16,
          row: 0,
          col: 8,
        },
        name: 'ffw_top_country',
      },
      {
        grid: {
          id: 'ffw_policy_hits',
          cells: 2,
          sizeX: 10,
          sizeY: 8,
          row: 16,
          col: 0,
        },
        name: 'ffw_policy_hits',
      },
      {
        grid: {
          id: 'ffw_top_destination',
          cells: 2,
          sizeX: 14,
          sizeY: 8,
          row: 16,
          col: 10,
        },
        name: 'ffw_top_destination',
      },
      {
        grid: {
          id: 'ffw_top_destination_historical',
          cells: 4,
          sizeX: 24,
          sizeY: 8,
          row: 24,
          col: 0,
        },
        name: 'ffw_top_destination_historical',
        defaultHide: true,
      },
      {
        grid: {
          id: 'ffw_policy_hits_historical',
          cells: 4,
          sizeX: 24,
          sizeY: 8,
          row: 32,
          col: 0,
        },
        name: 'ffw_policy_hits_historical',
        defaultHide: true,
      },
      {
        grid: {
          id: 'ffw_user_data_flow',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 40,
          col: 0,
        },
        name: 'ffw_user_data_flow',
        defaultHide: true,
      },
    ],
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'ffw-vpn-monitor',
    icon: 'vpn-lock',
    name: gettext('VPN'),
    adomName: gettext('FortiFirewall'),
    timePeriodType: 'normal',
    widgets: [
      {
        grid: {
          id: 'ffw_top_vpn',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'ffw_top_vpn',
      },
      {
        grid: {
          id: 'ffw_vpn_site2site',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'ffw_vpn_site2site',
      },
    ],
    faz: true,
    fmg: false,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FFW],
  },
  {
    uuid: 'faz-performance-monitor',
    icon: 'system-settings',
    name: gettext('Local System Performance'),
    widgets: [
      {
        grid: {
          id: 'multi_core',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'multi_core',
      },
      {
        grid: {
          id: 'lograte_insert_receive',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 0,
          col: 12,
        },
        name: 'lograte_insert_receive',
      },
      {
        grid: {
          id: 'cpu_memory',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'cpu_memory',
      },
      {
        grid: {
          id: 'disk_io',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 12,
          col: 12,
        },
        name: 'disk_io',
      },
      {
        grid: {
          id: 'lograte_receive_forward',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 24,
          col: 0,
        },
        name: 'lograte_receive_forward',
      },
      {
        grid: {
          id: 'resource_average',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 24,
          col: 12,
        },
        name: 'resource_average',
        defaultHide: true,
        adoms: [
          MACROS.DVM.DVM_RESTRICTED_PRD_FSF,
          MACROS.DVM.DVM_RESTRICTED_PRD_FOS,
        ],
      },
      {
        grid: {
          id: 'resource_peak',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 36,
          col: 0,
        },
        name: 'resource_peak',
        defaultHide: true,
        adoms: [
          MACROS.DVM.DVM_RESTRICTED_PRD_FSF,
          MACROS.DVM.DVM_RESTRICTED_PRD_FOS,
        ],
      },
      {
        grid: {
          id: 'fortiview_unauthorized_connections',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 36,
          col: 12,
        },
        name: 'fortiview_unauthorized_connections',
        defaultHide: true,
        adoms: [
          MACROS.DVM.DVM_RESTRICTED_PRD_FSF,
          MACROS.DVM.DVM_RESTRICTED_PRD_FOS,
        ],
      },
      {
        grid: {
          id: 'fortiview_system',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 48,
          col: 0,
        },
        name: 'fortiview_system',
        defaultHide: true,
        adoms: [
          MACROS.DVM.DVM_RESTRICTED_PRD_FSF,
          MACROS.DVM.DVM_RESTRICTED_PRD_FOS,
        ],
      },
      {
        grid: {
          id: 'fortiview_admin',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 48,
          col: 12,
        },
        name: 'fortiview_admin',
        defaultHide: true,
        adoms: [
          MACROS.DVM.DVM_RESTRICTED_PRD_FSF,
          MACROS.DVM.DVM_RESTRICTED_PRD_FOS,
        ],
      },
    ],
    faz: true,
    fmg: false,
    isDeviceHidden: true,
    adoms: [MACROS.DVM.DVM_RESTRICTED_PRD_FSF], // fabric
  },
  {
    uuid: 'threat-research-monitor',
    icon: 'third-party-threat-feed',
    name: gettext('Global Threat Research'),
    widgets: [
      {
        grid: {
          id: 'threat_prevalence',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
        name: 'threat_prevalence',
      },
    ],
    hasIndustrySelector: true,
    isDeviceHidden: true,
    timePeriodType: 'daily-threat',
    faz: true,
    fmg: false,
  },
  {
    uuid: 'local-threat-research-monitor',
    icon: 'third-party-threat-feed',
    name: gettext('Local Threat Research'),
    widgets: [
      {
        grid: {
          id: 'local_threat_prevalence',
          cells: 4,
          sizeX: 24,
          sizeY: 24,
          row: 0,
          col: 0,
        },
        name: 'local_threat_prevalence',
      },
    ],
    isDeviceHidden: true,
    timePeriodType: 'daily-threat',
    faz: true,
    fmg: false,
  },
  {
    uuid: 'dlp-dashboard',
    icon: 'data-leak-prevention',
    name: gettext('Data Loss Prevention'),
    widgets: [
      {
        grid: {
          id: 'dlp_trends',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 0,
          col: 0,
        },
        name: 'dlp_trends',
      },
      {
        grid: {
          id: 'dlp_top_dst_countries',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 12,
          col: 0,
        },
        name: 'dlp_top_dst_countries',
      },
      {
        grid: {
          id: 'dlp_top_users',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 24,
          col: 0,
        },
        name: 'dlp_top_users',
      },
      {
        grid: {
          id: 'dlp_top_protocols',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 24,
          col: 12,
        },
        name: 'dlp_top_protocols',
      },
      {
        grid: {
          id: 'dlp_top_events',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 36,
          col: 0,
        },
        name: 'dlp_top_events',
      },
      {
        grid: {
          id: 'dlp_profile_hit',
          cells: 4,
          sizeX: 24,
          sizeY: 12,
          row: 48,
          col: 0,
        },
        name: 'dlp_profile_hit',
      },
      {
        grid: {
          id: 'dlp_top_sensitive_files',
          cells: 2,
          sizeX: 12,
          sizeY: 12,
          row: 60,
          col: 0,
        },
        name: 'dlp_top_sensitive_files',
      },
    ],
    faz: true,
    fmg: false,
  },
];
