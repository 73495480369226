
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default                                                                                 
                                                                         
  
                                                               
  
                                 

{
  "default": [
    "fmg_dashboard",
    [
      "faz_dashboards",
      [
        "faz_dashboard",
        "dashboard_iot",
        "dashboard_email_metrics",
        "dashboard_soc"
      ]
    ],
    "fazdvm",
    [
      "dvm",
      [
        "dvm_device_list",
        "dvm_scripts",
        [
          "dvm_pt",
          [
            "dvm_pt_tmplgrp",
            "-",
            "dvm_pt_ipsectmpl",
            "dvm_pt_bgptmpl",
            "-",
            "dvm_pt_systmpl",
            "dvm_pt_routetmpl",
            "dvm_pt_ipstmpl",
            "dvm_pt_cstmpl",
            "-",
            "dvm_pt_clitmpl",
            "-",
            "dvm_pt_fbauth",
            "dvm_pt_certprof",
            "dvm_pt_crprof",
            "-",
            "dvm_pt_visibility"
          ]
        ],
        "dvm_firmware_template",
        [
          "dvm_monitors",
          [
                                    
            "dvm_monitors_vpn",
            "dvm_monitors_inventory",
            "dvm_monitors_lte"
          ]
        ],
        "dvm_fortimeter",
        "dvm_chassis"
      ]
    ],
    [
      "pno",
      [
        "pno_policy",
        "pno_hdr_ftr_ips",
        "-",
        "pno_obj_intf_single",
        [
          "pno_obj_normalized_intf",
          [
            "pno_obj_intf",
            "pno_obj_vwp_intf"
          ]
        ],
        [
          "pno_obj_fw",
          [
            "pno_obj_address",
            "pno_obj_app_isc",
            "pno_obj_network_service",
            "pno_obj_service",
            "pno_obj_schedule",
            "pno_obj_vip",
            "pno_obj_ippool",
            "-",
            "pno_obj_trafficshaper",
            "pno_obj_shapingprofile",
            "-",
            "pno_obj_virtualserver",
            "pno_obj_healthmonitor",
            "-",
            "pno_obj_fwdserver",
            "pno_obj_authscheme",
            "pno_obj_ztna-server",
            "pno_obj_ztna-tag"
          ]
        ],
        [
          "pno_obj_utmprofile",
          [
            "pno_obj_avprof",
            "pno_obj_casiprof",
            "pno_obj_webprof",
            "pno_obj_videoprof",
            "pno_obj_dnsprof",
            "pno_obj_appprof",
            "pno_obj_casbprof",
            "pno_obj_ipsprof",
            "pno_obj_filefilterprofile",
            "pno_obj_emailprof",
            "pno_obj_emailfilterprof",
            "pno_obj_dlpprof",
            "pno_obj_voipprof",
            "pno_obj_icapprof",
            "pno_obj_wafprof",
            "pno_obj_dlp",
            "pno_obj_virtualpatchprof",
            "pno_obj_proxyopt",
            "pno_obj_sslprof",
            "pno_obj_mmsprof",
            "pno_obj_gtpprof",
            "pno_obj_gtpobjs",
            "pno_obj_profgrp",
            "-",
            "pno_obj_appsignature",
            "pno_obj_app_grp",
            "pno_obj_ipssign",
            "pno_obj_virtualpatchsign",
            "pno_obj_emailfilterlist",
            "pno_obj_emailfilterblockallowlist",
            "pno_obj_webratingoverride",
            "pno_obj_weburlfilter",
            "pno_obj_webcontentfilter",
            "pno_obj_localcategory",
            "pno_obj_icapserver",
            "pno_obj_dlpfingerprint",
            "pno_obj_youtubechannelfilter"
          ]
        ],
        [
          "pno_obj_user",
          [
            "pno_obj_defineduser",
            "pno_obj_usrgrp",
            "-",
            "pno_obj_ldapusr",
            "pno_obj_radiususr",
            "pno_obj_tacacsusr",
            "pno_obj_samlusr",
            "-",
            "pno_obj_pop3",
            "pno_obj_pki",
            "pno_obj_pki_grp",
            "pno_obj_sms",
            "-",
            "pno_obj_fortitoken",
            "pno_obj_userexternalidentity"
          ]
        ],
        [
          "pno_obj_connectors",
          [
            "pno_obj_securityfabric",
            "pno_obj_sdn",
            "pno_obj_sso",
            "pno_obj_externalresource"
          ]
        ],
        [
          "pno_obj_advanced",
          [
            "pno_obj_fmgvariable",
            "pno_obj_cliopt",
            "pno_obj_replacemsg",
            "pno_obj_localcert",
            "pno_obj_cacert",
            "pno_obj_remotecert",
            "pno_obj_tags",
            "pno_obj_loccert",
            "pno_obj_vpntunnel"
          ]
        ],
        [
          "pno_obj_scripts"
        ],
              
        "pno_obj_fpx_intf_single",
        [
          "pno_obj_fpx_fw",
          [
            "pno_obj_fpx_address",
            "pno_obj_fpx_app_isc",
            "pno_obj_fpx_service",
            "pno_obj_fpx_schedule",
            "pno_obj_fpx_vip",
            "pno_obj_fpx_ippool",
            "-",
            "pno_obj_fpx_trafficshaper",
            "pno_obj_fpx_shapingprofile",
            "-",
            "pno_obj_fpx_virtualserver",
            "pno_obj_fpx_healthmonitor",
            "-",
            "pno_obj_fpx_fwdserver",
            "pno_obj_fpx_authscheme",
            "pno_obj_fpx_decryptedTrafficMirror",
            "pno_obj_fpx_ztna-server",
            "pno_obj_fpx_ztna-tag"
          ]
        ],
        [
          "pno_obj_fpx_utmprofile",
          [
            "pno_obj_fpx_avprof",
            "pno_obj_fpx_wafprof",
            "pno_obj_fpx_webprof",
            "pno_obj_fpx_videoprof",
            "pno_obj_fpx_dnsprof",
            "pno_obj_fpx_appprof",
            "pno_obj_fpx_casbprof",
            "pno_obj_fpx_ipsprof",
            "pno_obj_fpx_filefilterprofile",
            "pno_obj_fpx_emailfilterprof",
            "pno_obj_fpx_voipprof",
            "pno_obj_fpx_proxyopt",
            "pno_obj_fpx_sslprof",
            "-",
            "pno_obj_fpx_webratingoverride",
            "pno_obj_fpx_weburlfilter",
            "pno_obj_fpx_webcontentfilter",
            "pno_obj_fpx_localcategory",
            "-",
            "pno_obj_fpx_youtubechannelfilter",
            "pno_obj_fpx_appsignature",
            "pno_obj_fpx_app_grp",
            "pno_obj_fpx_ipssign",
            "pno_obj_fpx_virtualpatchsign",
            "pno_obj_fpx_emailfilterblockallowlist",
            "pno_obj_fpx_profgrp",
            "-",
            "pno_obj_fpx_dlpprof",
            "pno_obj_fpx_dlpfingerprint"
          ]
        ],
        [
          "pno_obj_fpx_contentanalyses",
          [
            "pno_obj_fpx_imageanalysis",
            "pno_obj_fpx_icapprof",
            "pno_obj_fpx_icapremoteserver",
            "pno_obj_fpx_icaploadbalance"
          ]
        ],
        [
          "pno_obj_fpx_user",
          [
            "pno_obj_fpx_defineduser",
            "pno_obj_fpx_usrgrp",
            "-",
            "pno_obj_fpx_usrdev",
            "-",
            "pno_obj_fpx_ldapusr",
            "pno_obj_fpx_radiususr",
            "pno_obj_fpx_tacacsusr",
            "-",
            "pno_obj_fpx_pop3",
            "pno_obj_fpx_pki",
            "pno_obj_fpx_sms",
            "-",
            "pno_obj_fpx_fortitoken"
          ]
        ],
        [
          "pno_obj_fpx_connectors",
          [
            [
              "pno_obj_fpx_securityfabric",
              [
                "pno_obj_fpx_core-network-security",
                "pno_obj_fpx_others"
              ]
            ],
            [
              "pno_obj_fpx_externalfabric",
              [
                "pno_obj_fpx_sdn-public",
                "pno_obj_fpx_sdn-private",
                "pno_obj_fpx_sso",
                "pno_obj_fpx_externalresource"
              ]
            ]
          ]
        ],
        [
          "pno_obj_fpx_advanced",
          [
            "pno_obj_fpx_fmgvariable",
            "pno_obj_fpx_cliopt",
            "pno_obj_fpx_replacemsg",
            "pno_obj_fpx_localcert",
            "pno_obj_fpx_cacert",
            "pno_obj_remotecert",
            "pno_obj_fpx_tags",
            "pno_obj_fpx_loccert",
            "pno_obj_fpx_vpntunnel"
          ]
        ]
      ]
    ],
    [
      "sdwan",
      [
        [
          "sdwan_network",
          [
            "sdwan_network_devices",
            "sdwan_network_monitor",
          ]
        ],
        [
          "sdwan_pt",
          [
            "sdwan_pt_tmplgrp",
            "-",
            "sdwan_pt_ipsectmpl",
            "sdwan_pt_bgptmpl",
            "-",
            "sdwan_pt_routetmpl",
            "-",
            "sdwan_pt_clitmpl"
          ]
        ],
        "sdwan_overlay_orchestration",
        "sdwan_rules"
      ],
    ],
    [
      "vpn",
      [
        "vpn_ipsec_commlist",
        "vpn_ipsec_commcentral",
        "vpn_ipsec_map",
        "-",
        "vpn_ssl_setting",
        "vpn_ssl_ptprof",
        "vpn_ssl_monitor"
      ]
    ],
    [
      "fap",
      [
        [
          "fap_device_pdm_root",
          [
            "fap_device_pdm",
            "-",
            "fap_device_template_ssid",
            [
              "fap_device_template_opprofs",
              [
                "fap_device_template_opprofs_approf",
                "fap_device_template_opprofs_qosprof",
                "fap_device_template_opprofs_cfgprof",
                "fap_device_template_opprofs_arrpprof"
              ]
            ],
            [
              "fap_device_template_conprofs",
              [
                "fap_device_template_conprofs_mpskprof",
                "fap_device_template_conprofs_bjrprof",
                "fap_device_template_conprofs_btprof"
              ]
            ],
            [
              "fap_device_template_protprof",
              [
                "fap_device_template_protprof_widsprof",
                "fap_device_template_protprof_l3prof"
              ]
            ],
            "fap_device_template_wifisetting"
          ]
        ],
        "fap_device",
        [
          "fap_map",
          [
            "fap_map_gmap",
            "fap_map_floor",
          ]
        ],
        "-",
        "fap_template_ssid",
        [
          "fap_template_opprofs",
          [
            "fap_template_opprofs_approf",
            "fap_template_opprofs_qosprof",
            "fap_template_opprofs_cfgprof",
            "fap_template_opprofs_arrpprof"
          ]
        ],
        [
          "fap_template_conprofs",
          [
            "fap_template_conprofs_mpskprof",
            "fap_template_conprofs_bjrprof",
            "fap_template_conprofs_btprof"
          ]
        ],
        [
          "fap_template_protprof",
          [
            "fap_template_protprof_widsprof",
            "fap_template_protprof_l3prof"
          ]
        ],
        "fap_template_wifisetting"
      ]
    ],
    [
      "fsw",
      [
        [
          "fsw_device_pdm_root",
          [
            "fsw_device_pdm",
            "-",
            "fsw_device_template_vlan",
            [
              "fsw_device_port_policies",
              [
                "fsw_device_template_securitypolicy",
                "fsw_device_template_ddp",
              ]
            ],
            "fsw_device_template_nacpolicy",
            "fsw_device_template_lldpprofile",
            [
              "fsw_device_template_qos",
              [
                "fsw_device_template_qospolicy",
                "fsw_device_template_queuepolicy",
                "fsw_device_template_ipdscpmap",
                "fsw_device_template_dot1pmap",
              ]
            ],
            "fsw_device_template_cuscmd",
            "fsw_device_template_aclassign",
            "-",
            "fsw_device_template_cliconf"
          ]
        ],
        "fsw_device",
        "fsw_topology",
        "fsw_faceplate",
        "-",
        "fsw_template_swprof",
        "fsw_template_objs_vlandef",
        "-",
        "fsw_template_linksett_flset",
        "fsw_template_linksett_vdomset",
        "-",
        [
          "fsw_port_policies",
          [
            "fsw_template_objs_secpol",
            "fsw_template_objs_ddpol",
          ]
        ],
        "fsw_template_objs_lldpprof",
        [
          "fsw_qos_policies",
          [
            "fsw_template_objs_qospol",
            "fsw_template_objs_eqpol",
            "fsw_template_objs_ipdscpmap",
            "fsw_template_objs_dot1pmap",
          ]
        ],
        "fsw_template_objs_cuscmd",
        "fsw_template_objs_aclassign",
      ]
    ],
    [
      "fext",
      [
        "fext_device",
        "fext_profiles_prof",
        "fext_profiles_template",
        "fext_profiles_simprof",
        "fext_profiles_dataplan"
      ]
    ],
    [
      "fortiview",
      [
        [
          "fortiview_threats",
          [
            "fortiview_threats_threat",
            "fortiview_threats_threat_map",
            "fortiview_threats_threat_detect_summary",
            "fortiview_threats_fsa_detection_view",
            "fortiview_threats_fct_threat"
          ]
        ],
        [
          "fortiview_traffic",
          [
            "fortiview_traffic_source_hosts",
            "fortiview_traffic_source_objects",
            "fortiview_traffic_destination_ips",
            "fortiview_traffic_destination_objects",
            "fortiview_traffic_country",
            "fortiview_traffic_policy_hits",
            "fortiview_traffic_create_policy_by_hits",
            "fortiview_traffic_dns_log_view",
            "fortiview_traffic_ztna_servers",
            "fortiview_traffic_fwb_traffic_source",
            "fortiview_traffic_fwb_traffic_country",
            "fortiview_traffic_fddos_source_view",
            "fortiview_traffic_fddos_destination_view",
            "fortiview_traffic_fddos_type_view",
            "fortiview_traffic_ffw_source_hosts",
            "fortiview_traffic_ffw_source_objects",
            "fortiview_traffic_ffw_destination_ips",
            "fortiview_traffic_ffw_destination_objects",
            "fortiview_traffic_ffw_country",
            "fortiview_traffic_ffw_policy_hits"
          ]
        ],
        [
          "fortiview_shadow_it",
          [
            "fortiview_shadow_it_cloud_application",
            "fortiview_shadow_it_cloud_user"
          ]
        ],
        [
          "fortiview_application",
          [
            "fortiview_application_app",
            "fortiview_application_website_domain",
            "fortiview_application_website_category",
            "fortiview_application_browsing_users",
            "fortiview_application_fct_app",
            "fortiview_application_fct_website"
          ]
        ],
        [
          "fortiview_vpn",
          [
            "fortiview_vpn_ssl",
            "fortiview_vpn_site",
            "fortiview_vpn_ffw_ssl",
            "fortiview_vpn_ffw_site"
          ]
        ],
        [
          "fortiview_system",
          [
            "fortiview_system_admin",
            "fortiview_system_system_events",
            "fortiview_system_resource",
            "fortiview_system_unauthorized_connections"
          ]
        ],
        [
          "fortiview_security",
          [
            "fortiview_security_fwb_security_country",
            "fortiview_security_fwb_security_threat",
            "fortiview_security_fwb_security_policy"
          ]
        ],
                           
        [
          "fortiview_fct_threats",
          [
            "fortiview_fct_threats_fct_threat"
          ]
        ],
        [
          "fortiview_fct_application",
          [
            "fortiview_fct_application_fct_app",
            "fortiview_fct_application_fct_website"
          ]
        ],
                         
        [
          "fortiview_fddos_traffic",
          [
            "fortiview_fddos_traffic_fddos_source_view",
            "fortiview_fddos_traffic_fddos_destination_view",
            "fortiview_fddos_traffic_fddos_type_view"
          ]
        ],
                             
        [
          "fortiview_ffw_traffic",
          [
            "fortiview_ffw_traffic_ffw_source_hosts",
            "fortiview_ffw_traffic_ffw_source_objects",
            "fortiview_ffw_traffic_ffw_destination_ips",
            "fortiview_ffw_traffic_ffw_destination_objects",
            "fortiview_ffw_traffic_ffw_country",
            "fortiview_ffw_traffic_ffw_policy_hits"
          ]
        ],
                             
        [
          "fortiview_ffw_vpn",
          [
            "fortiview_ffw_vpn_ffw_ssl",
            "fortiview_ffw_vpn_ffw_site"
          ]
        ],
        [
          "fortiview_ffw_ffw_system",
          [
            "fortiview_ffw_ffw_system_ffw_admin",
            "fortiview_ffw_ffw_system_ffw_system_events",
            "fortiview_ffw_ffw_system_ffw_unauthorized_connections"
          ]
        ],
                        
        [
          "fortiview_fwb_security",
          [
            "fortiview_fwb_security_fwb_security_country",
            "fortiview_fwb_security_fwb_security_threat",
            "fortiview_fwb_security_fwb_security_policy"
          ]
        ],
        [
          "fortiview_fwb_traffic",
          [
            "fortiview_fwb_traffic_fwb_traffic_source",
            "fortiview_fwb_traffic_fwb_traffic_country"
          ]
        ],
        "-",
        [
          "fortiview_threats_events",
          [
            "fortiview_threats_events_threat-monitor",
            "fortiview_threats_events_compromised-hosts",
            "fortiview_threats_events_fortisandbox-detections",
            "fortiview_threats_events_local-threat-research-monitor",
            "fortiview_threats_events_threat-research-monitor",
            "fortiview_threats_events_dlp-dashboard",
            "fortiview_threats_events_fct-threat-moonitor"
          ]
        ],
        [
          "fortiview_traffic_analysis",
          [
            "fortiview_traffic_analysis_l3-traffic-monitor",
            "fortiview_traffic_analysis_l7-traffic-monitor",
            "fortiview_traffic_analysis_ztna-dashboard",
            "fortiview_traffic_analysis_vpn-monitor",
            "fortiview_traffic_analysis_traffic-shaping-monitor",
            "fortiview_traffic_analysis_endpoint-monitor",
            "fortiview_traffic_analysis_fct-endpoints-moonitor",
            "fortiview_traffic_analysis_fddos-traffic-moonitor",
            "fortiview_traffic_analysis_ffw-traffic-monitor",
            "fortiview_traffic_analysis_fct-traffic-moonitor",
            "fortiview_traffic_analysis_ffw-vpn-monitor",
            "fortiview_traffic_analysis_fct-software-inventory",
            "fortiview_traffic_analysis_fortimail-dashboard",
            "fortiview_traffic_analysis_fortiproxy-dashboard"
          ]
        ],
        [
          "fortiview_sdwan",
          [
            "fortiview_sdwan_sdwan-dashboard",
            "fortiview_sdwan_sdwan-summary"
          ]
        ],
        [
          "fortiview_fabric_devices",
          [
            "fortiview_fabric_devices_wifi-monitor",
            "fortiview_fabric_devices_iot",
            "fortiview_fabric_devices_fabric-state-of-security"
          ]
        ],
        "fortiview_fmg-dash1",
        "fortiview_faz-performance-monitor",
        "fortiview_custom_view"
      ]
    ],
    [
      "logview",
      [
        [
          "logview_logs",
          [
            "logview_all",
            "logview_ftntlogs",
            "logview_threat_hunting",
            "logview_logbrowse"
          ]
        ],
        [
          "logview_logs_customview",
          []
        ],
        [
          "logview_log_settings",
          [
            "logview_loggroups"
          ]
        ]
      ]
    ],
    [
      "fabric",
      [
        "fabric_physicaltopo",
        "fabric_logicaltopo",
        "fabric_audit",
        "fabric_config_connectors",
        "fabric_config_extconnectors",
        [
          "fabric_asset",
          [
            "fabric_asset_dashboard",
            "fabric_asset_view",
            [
              "fabric_asset_custom",
              [
                [
                  "fabric_asset_custom_asset",
                  []
                ],
                [
                  "fabric_asset_custom_asset_ot",
                  []
                ],
                [
                  "fabric_asset_custom_identity",
                  []
                ]
              ]
            ]
          ]
        ],
        "fabric_config_subnets",
        [
          "fabric_cloudorchestration",
          [
            "fabric_cloudorchestration_main",
            "fabric_cloudorchestration_conn",
            "fabric_cloudorchestration_templates"
          ]
        ]
      ]
    ],
    [
      "fortisoc",
      [
        [
          "incident",
          [
            "incidents",
            [
              "mitre-enterprise",
              [
                "mitre-enterprise-attack",
                "mitre-enterprise-coverage"
              ]
            ],
            [
              "mitre-ics",
              [
                "mitre-ics-attack",
                "mitre-ics-coverage"
              ]
            ]
          ]
        ],
        [
          "event_monitor",
          [
            "alert_event_all"
          ]
        ],
        [
          "event_handlers",
          [
            "alert_basic_handlers",
            "alert_data_selectors",
            "alert_notification_profiles"
          ]
        ],
        "indicators",
        "fortisoc_outbreak_alerts",
        [
          "fortisoc_automation",
          [
            "fortisoc_dashboard_playbooks",
            "fortisoc_automation_connector",
            "fortisoc_automation_playbook",
            "fortisoc_automation_playbook_monitor"
          ]
        ],
        [
          "siem_parser",
          [
            "assigned_siem_parser",
            "siem_parser_list"
          ]
        ]
      ]
    ],
    "fortiai",
    [
      "fortisoc_supv",
      [
        [
          "fortisoc_supv_groups",
          [
            "fortisoc_supv_all"
          ]
        ],
        "fortisoc_supv_local",
        [
          "fortisoc_supv_incidents",
          [
            "incidents",
            [
              "mitre-enterprise",
              [
                "mitre-enterprise-attack",
                "mitre-enterprise-coverage"
              ]
            ],
            [
              "mitre-ics",
              [
                "mitre-ics-attack",
                "mitre-ics-coverage"
              ]
            ]
          ]
        ]
      ]
    ],
    [
      "report",
      [
        "report_generated",
        [
          "report_defs",
          [
            "report_defs_reports",
            "report_defs_templates",
            "report_defs_charts",
            "report_defs_macros",
            "report_defs_datasets"
          ]
        ],
        [
          "report_advanced",
          [
            "report_advanced_calendar",
            "report_advanced_lang",
            "report_advanced_outputprofiles"
          ]
        ]
      ]
    ],
    [
      "fgd",
      [
        "fgd_license",
        [
          "fgd_pkg",
          [
            "fgd_pkg_receive",
            "fgd_pkg_service"
          ]
        ],
        [
          "fgd_query",
          [
            "fgd_query_receive",
            "fgd_query_status"
          ]
        ],
        "fgd_firmware",
        "fgd_external_resource",
        "fgd_setting"
      ]
    ],
    [
      "docker"
    ],
    [
      "sys",
      [
        "sys_alladoms",
        "-",
        "sys_admin_user",
        "sys_admin_profile",
        "sys_admin_authserver",
        [
          "sys_supfabric",
          [
            "sys_supfabric_settings",
            "sys_fazsupfabric_groups",
            "sys_fazsupfabric_connectors"
          ]
        ],
        "sys_admin_sso",
        "sys_admin_setting",
        "-",
        "sys_ha",
        "sys_network_setting",
        "sys_raid",
        "-",
        "sys_task_fmg",
        "sys_eventlog",
        "sys_dbeventlog",
        "sys_cert",
        [
          "sys_adv",
          [
            "sys_admin_workspace",
            "sys_logforwarding",
            "sys_logtopology",
            "sys_adv_logsetting",
            "sys_adv_mail",
            "sys_adv_syslog",
            "sys_adv_meta",
            "sys_logfetch",
            "sys_task_faz",
            "sys_adv_setting"
          ]
        ]
      ]
    ]
  ],
  "restrictedAdmin": [
    [
      "rstadm_policy"
    ],
    [
      "rstadm_webfilter",
      [
        "rstadm_webfilter_prof",
        "rstadm_webfilter_ratingoverride",
        "rstadm_webfilter_urlfilter",
        "rstadm_webfilter_contentfilter",
        "rstadm_webfilter_localcategory"
      ]
    ],
    [
      "rstadm_ips",
      [
        "rstadm_ips_prof",
        "rstadm_ips_templates",
        "rstadm_ips_sign",
        "rstadm_ips_sslprof",
        "rstadm_ips_profgrp",
        "rstadm_ips_diag",
        "rstadm_ips_fgdpkg",
        "rstadm_ips_licserv"
      ]
    ],
    [
      "rstadm_hdr_ftr_ips"
    ],
    [
      "rstadm_appfilter",
      [
        "rstadm_appfilter_prof",
        "rstadm_appfilter_sign"
      ]
    ],
    [
      "rstadm_taskmonitor"
    ]
  ]
}
