import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useValidEffect } from 'rh_util_hooks';
import { NwRadioButton, NwRadioButtonGroup } from '@fafm/neowise-core';
import { fiAdom } from 'fi-session';
import {
  FmkSSelect2,
  FmkErrorSpan,
  FmkInput,
  FmkTextArea,
  FmkRadioBtnGroup,
  FmkIpInput,
} from 'rc_form';
import { NwProInputRow, NwProLine, ConditionalComponent } from 'rc_layout';
import { FormikConsumer, useFormikContext } from 'formik';
import { getSysConfig, autoId } from './util';
import { getTimezones } from './api';
import {
  getAvailableVersionsByOsType,
  getLatest3Versions,
  restrictedProdTypeToOsType,
  upgradeSelectedAdoms,
} from '../common/utils';
import AdomWorkspaceEdit from './AdomWorkspaceEdit';
import { isUndefined } from 'lodash';
import { fiSession } from 'fistore';

const { getNextVerStrByOsType } = fiSession;

let versionsFullSet = [];

// Update version choises when ADOM type has changed
const _onAdomResTypeSelect = (formik, typeOption, onChangeVersion) => {
  const { is_fabric, is_ffw, is_fosfoc, is_fpx, is_fwc } = typeOption;
  formik.setFieldValue('is_fosfoc', is_fosfoc);
  formik.setFieldValue('is_ffw', is_ffw);
  formik.setFieldValue('is_fwc', is_fwc);
  formik.setFieldValue('is_fpx', is_fpx);
  formik.setFieldValue('is_fabric', is_fabric);
  formik.setFieldValue('newmembers', []);

  let _displayVersions = [];
  if (is_fpx) {
    let nvers = [];
    _displayVersions = versionsFullSet.filter((cur) => {
      if (cur.ver >= 7) {
        nvers.push(cur);
        return true;
      } else {
        return false;
      }
    });
    if (nvers.length) {
      formik.setFieldValue('version', _displayVersions[0]);
    }
    onChangeVersion(_displayVersions);
  } else {
    onChangeVersion(versionsFullSet);
  }
};

const getLatest3VersionsByOsType = (
  { versionOptions, sysConfig, osType },
  onlyText = true
) => {
  if (isUndefined(osType)) return getLatest3Versions(versionOptions);

  const availableVersions = getAvailableVersionsByOsType(sysConfig, osType);
  let nver = availableVersions.length;
  const slicedVersions = availableVersions
    .slice(nver > 3 ? nver - 3 : 0)
    .map((v) => ({ ...v, text: `${v.ver}.${v.mr}` }));
  return onlyText ? slicedVersions.map((v) => v.text) : slicedVersions;
};

export const AdomVersions = ({ isEdit, isView, setReload, versionOptions }) => {
  const [displayVersions, setDisplayVersions] = useState([]);
  const { values } = useFormikContext();
  let sysConfig = getSysConfig();
  const ver = values.version.ver;
  const mr = values.version.mr;
  const osType = restrictedProdTypeToOsType(values.type);

  // Original adom version, null means current ver consistent with db
  const [origAdomVersion, setOrigAdomVersion] = useState(null);

  const nextVer = useSelector(getNextVerStrByOsType({ ver, mr, osType }));

  useEffect(() => {
    let _displayVersions = [];
    const curver = `${values.version.ver}.${values.version.mr}`;
    if (isEdit) {
      if (origAdomVersion) return;
      _displayVersions.push(curver);
      if (
        fiAdom.isConfigurableType(values) &&
        fiAdom.isSupportedVersion(values)
      ) {
        if (
          !_displayVersions.includes(nextVer) &&
          !((values.is_fosfoc || values.globaldb) && nextVer === '7.6')
        ) {
          // From 1035842, 1053903, adom upgrade to FOS/globaldb 7.6 not supported, will remove in 7.6.1
          _displayVersions.push(nextVer);
        }
      }
    } else {
      _displayVersions = getLatest3VersionsByOsType({
        versionOptions,
        sysConfig,
        osType,
      });
    }
    setDisplayVersions(_displayVersions);
    if (versionOptions.length > versionsFullSet.length) {
      versionsFullSet = versionOptions;
    }
  }, [versionOptions, values.type, values.version, origAdomVersion, nextVer]);

  const onSelectVersion = useCallback(
    (newVersion, setFieldValue) => {
      const curVer = `${values.version.ver}.${values.version.mr}`;
      if (curVer === newVersion) return;
      setOrigAdomVersion(values.version);
      const arr = newVersion.split('.');
      setFieldValue('version', {
        ver: parseInt(arr[0]),
        mr: parseInt(arr[1]),
        text: newVersion,
      });

      // Do upgrade
      if (isEdit) {
        // only check if ADOM is upgradeable when the the adom is being edited.
        if (!nextVer) return;
        upgradeSelectedAdoms(
          { ...values, version: values.version, _gui_nextVer: nextVer },
          () => {
            setOrigAdomVersion(null);
            // Reload All-ADOMs table
            setReload((prev) => prev + 1);
          }
        ).catch(() => {
          setFieldValue('version', origAdomVersion || values.version);
        });
      }
    },
    [origAdomVersion, values.globaldb, values.version]
  );

  return (
    <FormikConsumer>
      {(state) => {
        return (
          <NwRadioButtonGroup
            value={
              state.values.version.text ||
              `${state.values.version.ver}.${state.values.version.mr}`
            }
            onChange={(evt, val) => {
              onSelectVersion(val, state.setFieldValue);
            }}
          >
            {versionOptions
              .filter((v) => displayVersions.includes(v.text))
              .map((v) => (
                <NwRadioButton
                  key={v.text}
                  value={v.text}
                  name='adom_version'
                  automation-id={autoId(`version-${v.text}`)}
                  disabled={isView}
                >
                  {v.text}
                </NwRadioButton>
              ))}
          </NwRadioButtonGroup>
        );
      }}
    </FormikConsumer>
  );
};

const DEFAULT_TZ_CHOICE = { text: gettext('System Time Zone'), id: -1 };
const TimezoneSelect = ({ isView }) => {
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  useValidEffect((isValid) => {
    getTimezones().then((resp) => {
      if (resp?.[0]?.status?.code === 0) {
        const options = [DEFAULT_TZ_CHOICE].concat(
          resp?.[0]?.data?.timezones || []
        );
        if (isValid()) {
          setTimezoneOptions(options);
        }
      }
    });
  }, []);

  return (
    <NwProInputRow label={gettext('Time Zone')}>
      <FmkSSelect2
        name={'tz'}
        disabled={isView}
        source={timezoneOptions}
        automationId={autoId('tz')}
      />
    </NwProInputRow>
  );
};

export default function AdomCommonEdit({
  isCreateNew,
  isEdit,
  isView,
  selects,
  onChangeVersion,
  setReload,
}) {
  const state = useFormikContext();
  const { values } = state;
  const [versionOptions, setVersionOptions] = useState([]);

  const sysConfig = getSysConfig();
  let showGlbWorkspace =
    sysConfig.workspace_mode === MACROS.SYS.WORKSPACE_M_PERADOM &&
    sysConfig.hasFmgFeature &&
    values.name !== 'Chassis';
  const osType = restrictedProdTypeToOsType(values.type);

  //get available versions by os type
  useEffect(() => {
    const availableVersions = getAvailableVersionsByOsType(sysConfig, osType);
    const versionChoices = availableVersions.map((version) => ({
      ...version,
      text: `${version.ver}.${version.mr}`,
    }));
    setVersionOptions(versionChoices);
  }, [values.type, selects.adomVersionOptions]);

  return (
    <>
      <NwProInputRow label={gettext('Name')}>
        <FmkInput
          name={'name'}
          automation-id={autoId('name')}
          disabled={isView || (!values.renamable && !isCreateNew)}
        />
        <FmkErrorSpan name='name' />
      </NwProInputRow>

      {!!values.globaldb && sysConfig.isFMG && (
        <>
          <NwProInputRow label={gettext('Version')}>
            <AdomVersions
              versionOptions={versionOptions}
              isEdit={isEdit}
              isView={isView}
              setReload={setReload}
            />
          </NwProInputRow>
        </>
      )}
      {!!values.globaldb && showGlbWorkspace && (
        <AdomWorkspaceEdit isView={isView} />
      )}
      {!values.globaldb && (
        <>
          <NwProInputRow label={gettext('Type')}>
            <NwProLine>
              <>
                <ConditionalComponent
                  condition={!!selects.adomTypeSelectOptions.length}
                >
                  <FmkSSelect2
                    name='type'
                    className={'tw-w-3/6'}
                    source={selects.adomTypeSelectOptions}
                    disabled={isView || isEdit}
                    onChange={(id, option) => {
                      _onAdomResTypeSelect(state, option, onChangeVersion);
                    }}
                    automationId={autoId('type')}
                  />
                </ConditionalComponent>

                <ConditionalComponent
                  condition={
                    !!versionOptions.length &&
                    fiAdom.isConfigurableType(state.values) &&
                    sysConfig.isFMG
                  }
                >
                  <AdomVersions
                    versionOptions={versionOptions}
                    isEdit={isEdit}
                    isView={isView}
                    setReload={setReload}
                  />
                </ConditionalComponent>
              </>
            </NwProLine>
          </NwProInputRow>

          <TimezoneSelect isView={isView} />

          <NwProInputRow label={gettext('DNS')}>
            <FmkRadioBtnGroup
              name='gui_dns'
              automationId={autoId('gui_dns')}
              choices={[
                { text: gettext('Use System DNS'), id: 0 },
                { text: gettext('Specify'), id: 1 },
              ]}
              disabled={isView}
            />
          </NwProInputRow>

          <ConditionalComponent condition={values.gui_dns === 1}>
            <NwProInputRow label={gettext('Primary DNS Server')} indent={1}>
              <FmkIpInput
                type='ipv4'
                name='primary_dns_ip4'
                automationId={autoId('primary_dns_ip4')}
                disabled={isView}
              />
              <FmkErrorSpan name='primary_dns_ip4' />
            </NwProInputRow>

            <NwProInputRow label={gettext('Secondary DNS Server')} indent={1}>
              <FmkIpInput
                type='ipv4'
                name='secondary_dns_ip4'
                automationId={autoId('secondary_dns_ip4')}
                disabled={isView}
              />
              <FmkErrorSpan name='secondary_dns_ip4' />
            </NwProInputRow>
          </ConditionalComponent>

          <NwProInputRow label={gettext('Description')}>
            <FmkTextArea
              name='description'
              automation-id={autoId('description')}
              maxLength={MACROS.DVM.DVM_DESC_SZ}
              disabled={isView}
            />
            <FmkErrorSpan name='description' />
          </NwProInputRow>
        </>
      )}
    </>
  );
}

AdomCommonEdit.displayName = 'AdomCommonEdit';
