import { useRef } from 'react';

const createEventQueue = () => {
  let queue = [];

  return {
    subscribe: (cb) => queue.push(cb),
    cleanSubscribers: () => (queue = []),
    notify: (data) => {
      queue.forEach((cb) => cb(data));
    },
  };
};

export const useEventQueue = () => {
  const eventQueue = useRef(createEventQueue());
  return () => eventQueue.current;
};
