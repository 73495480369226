export { useDeferred };

export type Defer = {
  promise: Promise<any>;
  resolve: (val?: any) => Promise<any>;
  reject: (val?: any) => Promise<any>;
};

const useDeferred = (): Defer => {
  let resolve = (val: any) => {
    return Promise.resolve(val);
  };

  let reject = (val: any) => {
    return Promise.resolve(val);
  };

  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve as Defer['resolve'];
    reject = _reject as Defer['reject'];
  });

  return {
    promise,
    resolve,
    reject,
  };
};
