export const fiDvmActionsId = {
  device: {
    firmwareFdsUpgrade: 'dvmFirmwareFdsUpgradeDevice',
    firmwareUpdate: 'dvmFirmwareUpdateDevice',
    uploadLicense: 'dvmUploadLicenseDevice',
    refresh: 'dvmRefreshDevice',
    add: 'dvmAddDevice',
    del: 'dvmDelDevice',
    edit: 'dvmEditDevice',
    deviceBlueprint: 'deviceBlueprint',
    passwd: 'dvmChangeDevicePasswd',
    config: 'dvmConfigureDevice',
    addVdom: 'dvmAddVDom',
    launch: 'launchdevice',
    lock: 'dvmLockDevice',
    unlock: 'dvmUnlockDevice',
    retrieve: 'retrieveDevConfig',
    firmwareOverride: 'dvmModelDeviceFirmwareOverride',
    assignPolicyPkg: 'dvmModelDeviceAssignPolicyPackage',
    editHostName: 'dvmEditHostName',
    editInspectMode: 'dvmEditInspectMode',
    editSystemTime: 'dvmEditSystemTime',
    enableVdom: 'enableVdom',
    console: 'dvmConsole',
    reboot: 'dvmReboot',
    shutdown: 'dvmShutdown',
    changeFmgSSO: 'dvmchangeFmgSSO',
    pushSecurityPending: 'dvmPushSecurityPending',
    install: {
      wizard: 'dvmInstallWizard',
      config: 'dvmInstallConfigure',
      reInstallPolicy: 'dvmReInstallPolicy',
      importPolicy: 'dvmImportPolicy',
      diff: 'dvmPolicyDiff',
      instpreview: 'dvmDevCfgInstallPreview',
      instpreviewmap: 'dvmInstallPreviewMap',
    },
    viewDiff: 'dvmViewRevisionDiff',
    viewDBConfig: 'dvmViewDBConfig',
    topo: 'fabricTopology',
    interface: {
      disable: 'disableinterface',
      enable: 'enableinterface',
      mapping: 'devinterfacemapping',
      monitor: 'interfacemonitor',
    },
    revision: {
      del: 'dvmDevRevDel',
      viewconfig: 'dvmDevRevViewConf',
      viewlog: 'dvmDevRevViewLog',
      diff: 'dvmDevRevDiff',
      retrieve: 'dvmDevRevRetrieve',
      revert: 'dvmDevRevRevert',
      rename: 'dvmDevRevRename',
      install: 'dvmDevRevInstall',
      download: 'dvmDevRevDownload',
      import: 'dvmDevRevImport',
      factory: 'dvmDevRevFactory',
    },
    viewHA: 'dvmViewHADetails',
    viewLogRate: 'dvmViewLogRate',
    swapDevice: 'dvmSwapDevice',
    move_to_fmg_member: 'move_dev_to_fmg_member',
    enableApiAccess: 'dvmEnableApiAccess',
    disableApiAccess: 'dvmDisableApiAccess',
    enableSdwanManagement: 'enableSdwanManagement',
    disableSdwanManagement: 'disableSdwanManagement',
    upgradeStatus: 'dvmFirmwareUpgradeStatus',
  },
  views: {
    table: 'dvmManagedTableView',
    map: 'dvmManagedMapView',
    folder: 'dvmManagedAssetsView',
    ring: 'dvmManagedRingView',
  },
  unregDevice: {
    goback: 'dvmUnregGoback',
    add: 'dvmUnregAddDevice',
    del: 'dvmUnregDelDevice',
    hide: 'dvmUnregHideDevice',
    showUnauth: 'dvmUnregShowUnauth',
  },
  devgrp: {
    tools: 'dvmDevGroupTools',
    display: 'dvmDevGroupDisplay',
  },
  group: {
    create: 'dvmCreateGroup',
    edit: 'dvmEditGroup',
    del: 'dvmDelGroup',
    firmwareUpdate: 'dvmFirmwareUpdateGroup',
    firmwareFdsUpgrade: 'dvmFirmwareFdsUpgradeDevice',
    fgspConfig: 'fgspConfig',
    move_to_fmg_member: 'move_group_to_fmg_member',
  },
  folder: {
    create: 'createNewFolder',
    edit: 'editFolder',
    del: 'delFolder',
    move: 'moveFolder',
  },
  workspace: {
    save: 'dvmWorkspaceSave',
    discard: 'dvmWorkspaceDiscard',
    enable: 'dvmWorkspaceEnable',
    disable: 'dvmWorkspaceDisable',
  },
  prvtmpl: {
    save: 'dvmSavePrvtmpl',
    add: 'dvmAddPrvtmpl',
    import: 'dvmImportPrvtmpl',
    impcrprof: 'dvmImportCrProf',
    edit: 'dvmEditPrvtmpl',
    del: 'dvmDelPrvtmpl',
    rename: 'dvmRenamePrvtmpl',
    assigned: 'dvmAssignedPrvtmpl',
    assign_to_dev: 'dvmAssignPrvtmplToDevice',
    gencert: 'dvmGenerateCertProf',
    cli: 'cliConfiguration',
    clone: 'dvmClonePrvtmpl',
    import_pts: 'dvmImportProvisioningTemplates',
    export_pts: 'dvmExportProvisioningTemplates',
  },
  prvtmplGrp: {
    add: 'dvmAddPrvtmplGrp',
    edit: 'dvmEditPrvtmplGrp',
    del: 'dvmDelPrvtmplGrp',
    clone: 'dvmClonePrvtmplGrp',
    assigned: 'dvmAssignedPrvtmplGrp',
  },
  sdwanOverlayTmpl: {
    add: 'dvmAddSdwanOverlayTmpl',
    edit: 'dvmEditSdwanOverlayTmpl',
    del: 'dvmDelSdwanOverlayTmpl',
    clone: 'dvmCloneSdwanOverlayTmpl',
    assigned: 'dvmAssignedSdwanOverlayTmpl',
  },
  wizard: 'install_wizard',
  runScript: 'dvmRunScript',
  assignTemplates: 'dvmAssignTemplates',
  importAndExport: 'importAndExport',
  importDevList: 'dvmImportDeviceList',
  exportDevList: 'dvmExportDeviceList',
  exportCSV: 'exportCSV',
  exportExcel: 'exportExcel',
  addMultipleDev: 'addMultipleDev',
  verifyPrivateDataEncDevs: 'verifyPrivateDataEncDevs',
  chassis: {
    add: 'dvmAddChassis',
    edit: 'dvmEditChassis',
    del: 'dvmDelChassis',
    update: 'dvmUpdateChassis',
  },
  remoteFaz: {
    add: 'dvmAddRemoteFaz',
    del: 'dvmDelRemoteFaz',
  },
  script: {
    add: 'addScript',
    del: 'delScript',
    edit: 'editScript',
    exec: 'runScriptDvmlist',
    schedule: 'scheduleScript',
    assignTmpt: 'assignCLITemplate',
    run: 'runScript',
    clone: 'cloneScript',
    import: 'importScript',
    runGlobal: 'runScriptGlobal',
    export: 'exportScript',
  },
  scriptTmpl: {
    add: 'addScriptTmpl',
    del: 'delScriptTmpl',
    edit: 'editScriptTmpl',
    assign: 'assignScriptTmpl',
    clone: 'cloneScriptTmpl',
    import: 'importScriptTmpl',
    export: 'exportScriptTmpl',
    validate: 'validateScriptTmpl',
    validationResult: {
      toggle: 'toggleMissingMetaFieldDevices',
      preview: 'previewScript',
      refresh: 'refreshValidationReport',
    },
  },
  assignProvisioningTemplates: 'assignProvisioningTemplates',
  extender: {
    refresh: 'dvmFexRefreshDevice',
    add: 'dvmFexAddDevice',
    edit: 'dvmFexEditDevice',
    del: 'dvmFexDelDevice',
    upgrade: 'dvmFexUpgradeDevice',
    authorize: 'dvmFexAuthDevice',
    deauthorize: 'dvmFexDeauthDevice',
    restart: 'dvmFexRestartDevice',
    setPrimary: 'dvmFexSetPrimaryDevice',
    status: 'dvmFexStatusDevice',
    assign: 'dvmFexAssignDevice',
    assignProfile: 'dvmFexAssignProfileDevice',
  },
  cfgMenus: {
    vdom: {
      create: 'cfgvdom-create',
      delete: 'cfgvdom-delete',
    },
    adminProfile: {
      delete: 'cfgdev-admin-prof-del',
    },
    whereuse: 'cfgdev-where-use',
    interface: {
      delete: 'cfgdev-intf-del',
    },
    VPNPhase1: {
      delete: 'cfgdev-vpn-phase1-del',
    },
    VPNManualkey: {
      delete: 'cfgdev-vpn-manualkey-del',
    },
  },
  ipsectmpl: {
    add: 'dvmAddIPSectmpl',
    edit: 'dvmEditIPSectmpl',
    delete: 'dvmDeleteIPSectmpl',
    assigned: 'dvmAssignedIPSectmpl',
    import: 'dvmImportIPSectmpl',
  },
  devtmpls: {
    add: 'dvmAddTmpl',
    edit: 'dvmEditTmpl',
    delete: 'dvmDeleteTmpl',
    assigned: 'dvmAssignedTmpl',
  },
  csttmpl: {
    add: 'dvmAddCstTmpl',
    edit: 'dvmEditCstTmpl',
  },
  updateDevMetaField: 'dvmUpdateDevMetaField',
  editVariableMapping: 'editVariableMapping',
  fwmprof: {
    add: 'dvmFwmProfAdd',
    edit: 'dvmFwmProfEdit',
    del: 'dvmFwmProfDelete',
    clone: 'dvmFwmProfClone',
    assign: 'dvmFwmProfAssign',
    upgrade: 'dvmFwmProfUpgrade',
    preview: 'dvmFwmProfPreview',
    report: 'dvmFwmProfReport',
    local: 'dvmFwmProfLocalImage',
  },
  template: {
    assigned: 'dvmTemplateAssignToDevices',
  },
};
