import { isNil } from 'lodash';
import { getSysLicense } from 'react_apps/ra_dashboard_util/api.js';

import { fiFmgHttp } from 'fi-http';

function updateSystemGlobal(data) {
  let req = {
    method: 'update',
    params: [
      {
        url: '/cli/global/system/global',
        data: data,
      },
    ],
  };

  return fiFmgHttp.query(req).then(
    function (resp) {
      return resp[0].data;
    },
    function (err) {
      return err.data?.result[0]?.status?.message;
    }
  );
}

function toggleADOMFeature(isEnable) {
  return updateSystemGlobal({
    'adom-status': isEnable ? 1 : 0,
  }).then(
    (resp) => resp,
    (err) => (err ? err : gettext('Failed to change ADOM status.'))
  );
}

function toggleFAZFeature(isEnable) {
  return updateSystemGlobal({
    'faz-status': isEnable ? 1 : 0,
  }).then(
    (resp) => resp,
    (err) => (err ? err : gettext('Failed to change FAZ feature status.'))
  );
}

function toggleFMGFeature(isEnable) {
  return updateSystemGlobal({
    'fmg-status': isEnable ? 1 : 0,
  }).then(
    (resp) => resp,
    (err) => (err ? err : gettext('Failed to change FMG feature status.'))
  );
}

function changeFazMode(mode) {
  return updateSystemGlobal({
    'log-mode': mode,
  }).then(
    (resp) => resp,
    (err) => (err ? err : gettext('Failed to change FAZ log mode.'))
  );
}

function loadLicense() {
  return getSysLicense();
}

function enableDevVdom(devName, isEnable) {
  return new Promise(function (resolve, reject) {
    var reqObj = {
      method: 'set',
      params: [
        {
          url: '/pm/config/device/' + devName + '/global/system/global',
          data: {
            'vdom-admin': isEnable,
          },
        },
      ],
    };

    fiFmgHttp.query(reqObj).then(
      function (resp) {
        resolve(resp[0].data);
      },
      function (err) {
        reject(err.errors[0]);
      }
    );
  });
}

function getFirmwareUpgradePath(isFmg) {
  var reqObj = {
    url: '/gui/adom/dvm/device/firmware',
    method: 'getSomeUpdatePath',
    params: {
      platform: isFmg ? 'FMG' : 'FAZ',
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    var content = resp[0] ? resp[0].data : [];
    return parseBestFMGUpgradePath(content);
  });
}

function getAllFwObjects() {
  var reqObj = {
    url: '/gui/adom/dvm/device/firmware',
    method: 'getAllFWObjects',
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    var content = resp[0] ? resp[0].data : [];
    return parseFwObjectData(content);
  });
}

function getFilteredImage(pName) {
  var reqObj = {
    url: '/gui/adom/dvm/device/firmware',
    method: 'getFilteredFWObjects',
    params: {
      platformName: pName,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? parseImages(resp[0].data) : [];
  });
}

function parseImages(data) {
  return data.map((d) => {
    const ret = {};
    for (let key of Object.keys(d)) {
      switch (key) {
        case 'System':
          ret.system = d[key];
          break;
        case 'Platform':
          ret.platform = d[key];
          break;
        case 'ImageVersion':
          ret.version = d[key];
          break;
        case 'BuildNum':
          ret.buildnumber = d[key];
          break;
        case 'ImageIdentifier':
          ret.objid = d[key];
          break;
        default:
          break;
      }
    }
    return ret;
  });
}

function parseBestFMGUpgradePath(arrayData) {
  var objmap = {};
  if (arrayData && Array.isArray(arrayData)) {
    arrayData = arrayData.map((line) =>
      line.split('|').map((col) => col.split('=')[1])
    );
    objmap = arrayData.reduce(
      (
        acc,
        [platform, curr_ver, curr_build, upg_ver, upg_build, baseline_ver]
      ) => {
        if (!isNil(platform) && !isNil(curr_ver) && !isNil(curr_build)) {
          let ptr = (acc[platform] = acc[platform] || {});
          ptr = ptr[curr_ver] = ptr[curr_ver] || {};
          ptr = ptr[curr_build] = ptr[curr_build] || [];
          ptr.push({
            upgradeVer: upg_ver,
            upgradeBuild: upg_build,
            baseline: baseline_ver,
          });
        }
        return acc;
      },
      {}
    );
  }
  return objmap;
}

function parseFwObjectData(arrayData) {
  var result = [];
  if (!isNil(arrayData) && Array.isArray(arrayData)) {
    arrayData.forEach((current) => {
      var attrs = current.split('|');
      var obj = {};
      attrs.forEach((attr) => {
        var s = attr.split('=');
        var key = s[0];
        var value = s[1];
        switch (key) {
          case 'System':
            obj.system = value;
            break;
          case 'Platform':
            obj.platform = value;
            break;
          case 'ImageVersion':
            obj.version = value;
            break;
          case 'BuildNum':
            obj.buildnumber = value;
            break;
          case 'ImageIdentifier':
            obj.objid = value;
            break;
          case 'ImageType': {
            switch (value) {
              case 'F':
                obj.imageType = 'Feature';
                break;
              case 'M':
                obj.imageType = 'Mature';
                break;
            }
            break;
          }
          default:
            break;
        }
      });
      (obj.system === 'FMG' || obj.system === 'FAZ') && result.push(obj);
    });
  }
  return result;
}

function parseVersionBuildNumber(firmwareVersion) {
  var version = firmwareVersion.substring(
    firmwareVersion.lastIndexOf('v') + 1,
    firmwareVersion.lastIndexOf('-')
  );
  var build = firmwareVersion.substring(
    firmwareVersion.lastIndexOf('d') + 1,
    firmwareVersion.indexOf(' ')
  );
  return { version: version, build: build };
}

function getLatestVersionPatch(version, fullSet) {
  let digits = version.split('.'),
    maxPatch = -1;
  fullSet.forEach(function (v) {
    let d = v.split('.');
    if (d[0] === digits[0] && d[1] === digits[1] && d[2] > maxPatch)
      maxPatch = d[2];
  });
  return maxPatch === -1 ? null : [digits[0], digits[1], maxPatch].join('.');
}

function downloadByObjId(objid) {
  var data = {
    id: 1,
    method: 'exec',
    params: [
      {
        data: {
          object_list: [
            {
              objid: objid,
              file: 'fmg_img.dat',
            },
          ],
          create_task: 'enable',
        },
        url: 'object/download',
      },
    ],
    root: 'um',
  };
  return fiFmgHttp.query(data);
}

function getDevPlatformAbbrByName(pName) {
  var reqObj = {
    url: '/gui/adom/dvm/device/firmware',
    method: 'getDevPlatformAbbrByName',
    params: {
      platformName: pName,
    },
  };
  return fiFmgHttp.post(reqObj).then(function (resp) {
    return resp[0] ? resp[0].data : [];
  });
}

function tarArchivedImage() {
  var reqObj = {
    url: '/gui/adom/dvm/device/firmware',
    method: 'tarFMGImage',
  };
  return fiFmgHttp
    .post(reqObj)
    .then(function (resp) {
      return resp[0] ? resp[0].data : [];
    })
    .catch(function () {});
}

export const SysDashboardService = {
  toggleADOMFeature,
  toggleFAZFeature,
  toggleFMGFeature,
  changeFazMode,
  loadLicense,
  enableDevVdom,
  getFirmwareUpgradePath,
  getAllFwObjects,
  parseBestFMGUpgradePath,
  parseVersionBuildNumber,
  getLatestVersionPatch,
  downloadByObjId,
  getDevPlatformAbbrByName,
  tarArchivedImage,
  parseFwObjectData,
  getFilteredImage,
};
