import { capitalize, toString } from 'lodash';
import { UnitItem, UnitsDictionary } from './types';

export function scaleBytes(bytesStr: string): string {
  const unit = 'B';
  const s = 'KMGTPE';
  const unit_val = 1024;
  const bytes: number = parseInt(bytesStr, 10);
  if (bytes < unit_val) {
    return bytes + ' ' + unit;
  }

  const exp = Math.floor(Math.log(bytes) / Math.log(unit_val));
  const c = s[exp - 1];
  return round(bytes / Math.pow(unit_val, exp), 2) + ' ' + c + unit;
}

export const roundToPlaces = (num: number, places: number = 1): number => {
  return Math.round((num + Number.EPSILON) * 10 ** places) / 10 ** places;
};

function round(value: number, decimals: number): number {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
}

/**
 * Format number of bits
 * @param {number} value - Number of bits
 * @param {number} toFixed - Number of decimal places to round to
 * @returns {{ value: number, unit: string }}
 */
export const formatBits = (
  value: number,
  toFixed: number = 2
): {
  value: number;
  unit: string;
} => {
  const units: string[] = ['bps', 'Kbps', 'Mbps', 'Gbps'];
  const numDigits: number = toString(value)?.length || 0;
  const unit: number = Math.min(
    Math.floor((numDigits - 1) / 3),
    units.length - 1
  );
  let formattedValue: number = (value = value / Math.pow(1000, unit));
  formattedValue = toFixed
    ? parseFloat(formattedValue.toFixed(toFixed))
    : formattedValue;

  return {
    value: formattedValue,
    unit: units[unit],
  };
};

/**
 * Format number of bytes
 * @param {number | string} bytes The number in bytes that will be formatted
 * @param {number} precision The digits for the decimal, default is 1.
 *                  -1 means if first decimal is 0 then truncate, otherwise show 1 digit
 * @param {boolean} isKilo Use 1000 when true, otherwise 1024
 * @param {boolean} isFlooring Floor the fractions when true, round when false
 * @returns {string} Formatted string representing the bytes
 */
export function formatBytes(
  bytes: number | string | undefined | null,
  precision: number = 1,
  iskilo: boolean = false,
  isflooring: boolean = false
) {
  const parsedBytes = parseInt(toString(bytes), 10);
  // if (typeof precision === 'undefined') precision = 1;
  if (parsedBytes === 0) {
    return precision === -1 ? '0 KB' : (0.0).toFixed(precision) + ' KB';
  }

  if (isNaN(parsedBytes) || !isFinite(parsedBytes)) return '-';

  const kilo = iskilo ? 1000 : 1024;
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const exponent = Math.floor(Math.log(parsedBytes) / Math.log(kilo));
  let formatted = parsedBytes / Math.pow(kilo, Math.floor(exponent));

  if (isflooring) {
    const _shiftDigits = precision < 0 ? 1 : precision;
    formatted =
      Math.floor(formatted * Math.pow(10, _shiftDigits)) /
      Math.pow(10, _shiftDigits);
  }

  const formattedString = formatted.toFixed(precision === -1 ? 1 : precision);

  if (precision === -1) {
    const [integer, fraction] = formattedString.split('.');

    if (fraction && fraction.charAt(0) === '0') {
      return `${integer} ${units[exponent]}`;
    }
  }
  return `${formattedString} ${units[exponent]}`;
}

// From "fiNumberWithCommas"
export function formatNumberWithComma(num: number | undefined | null): string {
  return toString(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// From "fiBandwidth"
export function formatBandwidth(
  num: number | string | undefined | null,
  isByte: boolean = false,
  toFixed = 2
): string {
  const parsedNum = parseInt(toString(num), 10);
  if (isNaN(parsedNum)) return toString(parsedNum);

  const bitsNum = isByte ? parsedNum * 8 : parsedNum;

  const { value, unit } = formatBits(bitsNum, toFixed);
  const formattedUnit = unit !== 'bps' ? capitalize(unit) : unit;

  return `${value} ${formattedUnit}`;
}

// From "fiBigNumber"
export function formatBigNumber(
  num: number | string | undefined,
  decimalPlaces: number = 1
): string {
  const parsedNum: number = parseInt(toString(num), 10);
  if (isNaN(parsedNum)) return toString(parsedNum);
  if (parsedNum < 1000) return toString(parsedNum);
  else if (parsedNum >= 1000 && parsedNum < 1000000)
    return (parsedNum / 1000).toFixed(decimalPlaces) + 'k';
  else if (parsedNum >= 1000000 && parsedNum < 1000000000)
    return (parsedNum / 1000000).toFixed(decimalPlaces) + 'M';
  else return (parsedNum / 1000000000).toFixed(decimalPlaces) + 'B';
}

const genUnitItems = (units: string[], increment: number): UnitItem[] =>
  units.map((unit, idx) => ({
    val: Math.pow(increment, idx),
    unit,
  }));

export function formatUnit(value: number, unit: string = ''): string {
  const unitsDict: UnitsDictionary = {
    bps: genUnitItems(['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps', 'Pbps'], 1000),
    b: genUnitItems(['B', 'KB', 'MB', 'GB', 'TB', 'PB'], 1024),
  };

  let result: UnitItem = { val: 1, unit };

  if (value >= 0 && unit.toLowerCase() in unitsDict) {
    const dict = unitsDict[unit.toLowerCase()];
    dict.reverse().some((unitItem) => {
      if (value >= unitItem.val) {
        result = unitItem;
        return true;
      }
      return false;
    });
  }

  const divider = result.val;
  return `${parseFloat((value / divider).toFixed(1))} ${result.unit}`;
}

export function formatNumberFixedLen(
  n: number | string | undefined,
  len: number | string | undefined
): string {
  const num = parseInt(toString(n), 10);
  const length = parseInt(toString(len), 10);
  if (isNaN(num) || isNaN(length)) {
    return String(n);
  }
  let formattedNum = '' + num;
  while (formattedNum.length < length) {
    formattedNum = '0' + formattedNum;
  }
  return formattedNum;
}
