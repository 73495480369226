import { useCallback, useMemo } from 'react';
import { dashActions } from './dashActions';

import { frGo } from 'fi-routing';
import { fiStore, fiSession } from 'fistore';
import { getCurrentUserMenus } from './helpers';

import { goApp } from 'fistore/routing/slice';
import { getSideMenuTree } from 'fistore/routing/selectors';
import { useSelector, useDispatch } from 'react-redux';

export const useMenuActions = () => {
  const dispatch = useDispatch();
  const sideMenuTree = useSelector(getSideMenuTree);

  const isReadWrite = useMemo(() => {
    const getState = fiStore.getState;
    return fiSession.hasRDWRPermitOn('log_viewer', {
      haCheck: false,
    })(getState());
  }, []);

  const menuItemActions = useCallback(
    (item) => {
      if (!isReadWrite || !item.stateParams?.isUser) {
        return [];
      }
      let menus = [
        ...(item.stateParams.isFtv
          ? []
          : [
              {
                key: 'edit',
                label: gettext('Edit'),
                icon: 'edit',
                onClick: async () => {
                  frGo(item.path, { state: { isEdit: true } });
                },
              },
            ]),
        {
          key: 'rename',
          label: gettext('Rename'),
          icon: 'copy',
          onClick: async () => {
            let { dashId } = item.stateParams;
            let res = await dashActions.rename(dashId, item.label);
            if (res) {
              frGo(item.path);
            }
          },
        },
        {
          key: 'delete',
          label: gettext('Delete'),
          icon: 'delete',
          onClick: async () => {
            try {
              await dashActions.remove(item.stateParams.dashId);
              let userMenus = getCurrentUserMenus().filter(
                (x) => x.stateParams.dashId !== item.stateParams.dashId
              );
              if (userMenus.length) {
                let newDash = userMenus[0];
                frGo(newDash.path);
                return;
              }
              const appKey = sideMenuTree.find(
                (app) => app[0] === 'fortiview'
              )[1][0][0];
              dispatch(goApp({ key: appKey }));
            } catch {
              // skipped
            }
          },
        },
      ];

      return menus;
    },
    [dispatch, isReadWrite, sideMenuTree]
  );
  return { menuItemActions };
};
