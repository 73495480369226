import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isFunction, uniq } from 'lodash';

import { getSessionAdomOid } from 'fistore/session/adom/selectors';
import { appEnabled } from 'fistore/routing/selectors';
import { ptDisplayOptsApi } from '../dvm_customize/api';
import { refreshAppTree } from 'fistore/routing/slice';
import { getPtDisplayOptionsByAdomOid } from './selectors';
import { listenerMiddleware } from 'fistore/middlewares';

const initialState = {
  displayOptions: {},
};

const defaultPTs = [
  'dvm_pt_tmplgrp',
  'dvm_pt_ipsectmpl',
  'dvm_pt_sdwantmpl',
  'dvm_pt_systmpl',
  'dvm_pt_routetmpl',
  'dvm_pt_clitmpl',
];

const _slice = createSlice({
  name: 'dvm/pt',
  initialState,
  reducers: {
    setPtDisplayOptions: (state, { payload }) => {
      const { adomOid, displayOptions } = payload || {};
      state.displayOptions[adomOid] = displayOptions || [];
    },
  },
});

export const { setPtDisplayOptions } = _slice.actions;
export default _slice.reducer;

export const fetchPtDisplayOptions = createAsyncThunk(
  'dvm/pt/fetchDisplayOptions',
  async (
    payload,
    { getState, fulfillWithValue, rejectWithValue, dispatch }
  ) => {
    if (!appEnabled('dvm_pt')(getState())) {
      return rejectWithValue(
        gettext('No permission to provisioning templates.')
      );
    }

    const { adomOid: _adomOid, init } = payload || {};
    const adomOid = _adomOid || getSessionAdomOid(getState());
    const existingDisplayOpts = getPtDisplayOptionsByAdomOid(adomOid);
    if (existingDisplayOpts && existingDisplayOpts.length) {
      return fulfillWithValue(existingDisplayOpts);
    }

    let displayOpts = await ptDisplayOptsApi(adomOid);
    const allDefaultPts = displayOpts
      ? displayOpts.every((pt) => defaultPTs.includes(pt))
      : false;

    // initialize PT display options (e.g. entering a newly created ADOM)
    if (!displayOpts || !displayOpts.length || allDefaultPts) {
      let initPTs = defaultPTs;

      if (isFunction(init)) {
        // show template if it has data entries
        const nonEmptyPTs = await init();
        initPTs = uniq([...defaultPTs, ...nonEmptyPTs]);
      }

      await ptDisplayOptsApi(adomOid, initPTs);
      displayOpts = initPTs || [];
    }

    dispatch(setPtDisplayOptions({ adomOid, displayOptions: displayOpts }));

    return fulfillWithValue(displayOpts);
  }
);

export const updatePtDisplayOptions = createAsyncThunk(
  'dvm/pt/updatePtDisplayOptions',
  async (payload, { dispatch, getState }) => {
    const { displayOpts, adomOid: _adomOid } = payload || {};
    if (!displayOpts || !displayOpts.length) return;

    const adomOid = _adomOid || getSessionAdomOid(getState());

    // save to db
    await ptDisplayOptsApi(adomOid, displayOpts);

    // save to store
    dispatch(setPtDisplayOptions({ adomOid, displayOptions: displayOpts }));
  }
);

listenerMiddleware.startListening({
  actionCreator: setPtDisplayOptions,
  effect: async (action, { dispatch }) => {
    dispatch(refreshAppTree());
  },
});
