import { useState, useRef, useCallback } from 'react';

import { isFunction } from 'lodash';

/**
 * To allow getting the latest value (via ref) while using setState, which usually wont give you current state right away
 * @param {any} initialState initial value for react state
 * @returns [react state, Function, react ref]
 */
export const useStateRef = (initialState) => {
  const [state, setState] = useState(initialState);
  const ref = useRef(state);

  const setStateRef = useCallback((setStateCallback) => {
    ref.current = isFunction(setStateCallback)
      ? setStateCallback(ref.current)
      : setStateCallback;
    setState(ref.current);
  }, []);

  return [state, setStateRef, ref];
};
