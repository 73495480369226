import { getPackageList, getPolicyBlockList } from './api/pkg';
import { escapeHtml } from 'kit-escape';
import _ from 'lodash';

export { getFullPPkgData, FOLDER_PB_OID };

const FOLDER_PB_OID = -2;

// Get ppkg + pb list and parse for GUI use
// Return a list and map for cache use
const getFullPPkgData = async (adom) => {
  const [ppkglistResp, pblistResp] = await Promise.all([
    getPackageList({ adom }),
    getPolicyBlockList({ adom }),
  ]);
  const ppkglist = _.get(ppkglistResp, '[0].data', []);
  const pblist = _.get(pblistResp, '[0].data', []).map((pb) => ({
    ...pb,
    // Treats Policy Block as a special case of Package.
    type: 'pkg',
    pblock: true,
  }));
  const fulllist = [
    ...ppkglist,
    {
      name: 'Policy Blocks',
      displayName: gettext('Policy Blocks'),
      pblocks: true, // Uses this flag to check if this "folder" is pblocks set.
      oid: FOLDER_PB_OID,
      type: 'folder',
      subobj: pblist,
    },
  ];
  const cache = {
    list: null,
    map: {},
  };
  cache.list = _parseData(fulllist, null, function mapListNode(parsedNode) {
    cache.map[parsedNode.oid] = parsedNode;
  });
  return cache;
};

/**
 *@description parse package data, add folder attribute to each node
 *@param {object} data - array of package tree node
 *@param {object} [folder] - parent tree node object
 *@param {Function} [forEach] - callback when parsed each node
 */
function _parseData(data, folder, forEach) {
  var ii, len, node;
  var returnData = [];
  if (!data) {
    data = [];
  }
  data = Array.isArray(data) ? data : [];
  for (ii = 0, len = data.length; ii < len; ii++) {
    // Make a shallow copy of a node
    node = Object.assign({}, data[ii]);
    if (folder) {
      node.folder = folder;
      if (folder.path) {
        node.path = folder.path + '/' + node.name.replace(/\//g, '\\/');
      }
    } else {
      node.path = node.name.replace(/\//g, '\\/');
    }
    if (!node.hasOwnProperty('displayName')) {
      // Bug #405226: Escapes HTML content for the package name to avoid security issue
      node.displayName = escapeHtml(node.name);
    }
    returnData.push(node);
    if (node.subobj && node.subobj.length > 0) {
      // Replaces the node's children with shallow copy
      node.subobj = _parseData(node.subobj, node, forEach);
    }
    if (forEach) {
      forEach(node);
    }
  }
  return returnData;
}
