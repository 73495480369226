import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { isArray, isNil } from 'lodash';
import * as fiUUID from 'fi-uuid';
import { fiMessageBox } from 'fi-messagebox';
import { fiCache } from 'kit-cache';
import { fiQuotaService } from 'fi-quota';
import { fiWorkspace } from 'fi-workspace';
import { getAdomMetaFields } from 'fi-meta-fields';

import {
  updateAdom,
  generatePostDataForNewMembers,
  formCheckPromise,
  autoId,
  getSysConfig,
  validateAdomEdit,
} from './util';
import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProFooter,
  OkBtn,
  CancelBtn,
  NwProInputRow,
  openConfirmModal,
} from 'rc_layout';
import { ProToolkit } from '@fafm/neowise-pro';
import { FmkSwitch } from 'rc_form';
import { getAdomTemplate, getAdomData, setAdomData, getAdomTypes } from './api';
import AdomCommonEdit from './AdomCommonEdit';
import AdomFmgEdit from './AdomFmgEdit';
import { AdomFazEdit } from './AdomFazEdit';
import AdomDeviceTable from './AdomDeviceTable';
import AdomMetaFields from './AdomMetaFields';
import DeviceSelectPane from './DeviceSelectPane';
import { fiFmgHttp } from 'fi-http';
import { fiSysConfig, fiAdom, fiLogout } from 'fi-session';
import { openTaskByWebSocketMonitor } from 'rc_task_monitor';
import { initSupportedVersions } from '../common/utils';
import { UserService } from 'fi-user';
import { Provider } from 'react-redux';
import { fiStore } from 'fistore';
const URL_WORKFLOW_APPROVAL = '/cli/global/system/workflow/approval-matrix';
const IP_0 = '0.0.0.0';

const _getLastSupportedVersion = (sysConfig) => {
  const allvers = sysConfig.supported_adom_vers;
  const lastver = allvers[allvers.length - 1];
  return { ...lastver, text: `${lastver.ver}.${lastver.mr}` };
};

const _getInitialState = (sysConfig) => ({
  adomId: 0,
  name: '',
  description: '',
  type: MACROS.DVM.DVM_RESTRICTED_PRD_FOS,
  is_fosfoc: sysConfig.hasFmgFeature ? 1 : 0,
  version: _getLastSupportedVersion(sysConfig),
  status: true,
  vpn: 0, // by default central vpn is disabled
  wllb: 0, // by default SD-WAN is disabled
  fap: 1, // by default central fortiap is enabled
  fsw: 1, // by default central fortiswitch is enabled
  globaldb: 0,
  backup: 0,
  policy_check: false,
  policy_check_stop: false,
  members: [],
  newmembers: [],
  install_scope: false,
  available_quota: 0,
  auto_push: false, // automatically push configuration to devices when they are connected.
  is_others: 0,
  db_days: MACROS.ADOM.KEEP_DB_ONLINE_DEFAULT_DAYS,
  file_days: MACROS.ADOM.KEEP_LOG_OFFLINE_DEFAULT_DAYS,
  db_ratio: MACROS.ADOM.DISK_USAGE_DB_PERCENTAGE_DEFAULT,
  quota: sysConfig.hasFazFeature ? MACROS.ADOM.ADOM_LOG_DISK_QUOTA_DEFAULT : 0,
  quota_select: 1,
  old_quota: MACROS.ADOM.ADOM_LOG_DISK_QUOTA_DEFAULT,
  alert_threshold: MACROS.ADOM.DISK_USAGE_ALERT_PERCENTAGE_DEFAULT,
  metas: [],
  workspace_mode: 'disabled',
  approval: {},
  tz: -1,
  lock_override: 0,
  gui_dns: 0, // 0: use system dns, 1: specify
  primary_dns_ip4: null,
  secondary_dns_ip4: null,
});

const _isConfigurableAdom = (values) => {
  return !!(
    values.is_fosfoc ||
    values.is_fabric ||
    values.is_ffw ||
    values.is_fwc ||
    values.is_fpx
  );
};

export function AdomEdit({ stateParams, setReload, isView }) {
  const $opener = ProToolkit.useOpener();
  const { adomId } = stateParams;
  const isCreateNew = !adomId || parseInt(adomId) === 0;
  const isEdit = adomId > 0;
  let sysConfig = getSysConfig();

  // ADOM resource
  const [adomRes, setAdomRes] = useState({
    ..._getInitialState(sysConfig),
    adomId: adomId || 0,
  });
  const [loadTypes, setLoadTypes] = useState(false);

  // Original ws mode
  const [wsOrig, setWsOrig] = useState(null);

  // Adom limit response
  const [adomLimit, setAdomLimit] = useState(null);

  // Selections
  const [selects, setSelects] = useState({
    adomTypeSelectOptions: [],
    adomVersionOptions: [],
  });

  const onFormSubmit = function (data) {
    return checkAdomLimit()
      .then(() => formCheckPromise(data, sysConfig))
      .then(async () => {
        await doSubmit(data);
        $opener.resolve();
      })
      .catch(() => {});
  };

  const onCancel = () => {
    $opener.reject();
  };

  const checkAdomLimit = function () {
    // Check adom limit
    if (!isEdit && adomLimit && adomLimit.warning) {
      let title = gettext('Exceed ADOM Limit');
      let msg = gettext(
        'The incoming new ADOM will exceed the recommended number of ADOMs (%s) that can be added to the current device. Are you sure you  want to proceed?'
      ).printf([adomLimit.max]);

      return openConfirmModal({
        title,
        content: msg,
      });
    } else {
      return Promise.resolve();
    }
  };

  const parseDnsIp = function (specify, ip) {
    if (specify && ip) return ip;
    return IP_0;
  };

  const preparePostData = function (data) {
    const adomPostData = {
      ...data,
      new_quota: data.quota * data.quota_select,
      new_vpn: !data.vpn,
      newmembers: generatePostDataForNewMembers(data.newmembers, sysConfig),
      primary_dns_ip4: parseDnsIp(data.gui_dns, data.primary_dns_ip4),
      secondary_dns_ip4: parseDnsIp(data.gui_dns, data.secondary_dns_ip4),
    };
    if (isArray(data.newmembers) && data.newmembers.length > 0) {
      fiCache.clean('devgrp:data');
    }

    // Remove extra data fields from other cate
    delete adomPostData.approvalData;
    return adomPostData;
  };

  const doSubmit = async function (data) {
    try {
      const tid = fiUUID.gen();

      // Prepare post data
      const adomResPost = preparePostData(data);
      await setAdomData({ tid, adomRes: adomResPost, isCreateNew });
      await openTaskByWebSocketMonitor(tid, {
        title: isCreateNew ? gettext('Creating ADOM') : gettext('Editing ADOM'),
      });
      await updateAdom(data.adomId);
      sysConfig.hasFazFeature && fiQuotaService.dismissWarningAll(data.name);

      let shouldLogout =
        fiAdom.current().oid === data.oid &&
        !isCreateNew &&
        wsOrig !== data.workspace_mode;

      // Update approval matrix if necessary
      if (
        data.workspace_mode === 'workflow' &&
        sysConfig.workspace_mode === MACROS.SYS.WORKSPACE_M_PERADOM
      ) {
        let req = data.approvalData.approvalRes; // formValToApprovalData(data.approvalData.approvalRes);
        if (data.globaldb) {
          req['adom-name'] = 'rootp';
        }
        let _ind = -1;
        for (let i = 0; i < data.approvalData.matrices.length; i++) {
          if (data.approvalData.matrices[i]['adom-name'] === req['adom-name']) {
            _ind = i;
          }
        }
        // Modify existing approval
        if (_ind >= 0) {
          data.approvalData.matrices[_ind] = req;
          // New approval
        } else {
          if (!req['adom-name']) {
            req = Object.assign(req, { ['adom-name']: data.name });
          }
          data.approvalData.matrices.push(req);
        }
        fiFmgHttp
          .query({
            method: 'set',
            params: [
              {
                url: URL_WORKFLOW_APPROVAL,
                data: data.approvalData.matrices,
              },
            ],
          })
          .then()
          .catch(() => {
            console.error('Error update approval');
          });
      }

      if (shouldLogout) fiLogout();
    } catch (err) {
      let errmsg = err?.message || err?.result?.[0]?.status?.message || 'Error';
      fiMessageBox.show(errmsg, 'danger');
    }
  };

  const FMG_SUPPORT_CFG_TYPES = [
    MACROS.DVM.DVM_RESTRICTED_PRD_FOS,
    MACROS.DVM.DVM_RESTRICTED_PRD_FOC,
    MACROS.DVM.DVM_RESTRICTED_PRD_FFW,
    MACROS.DVM.DVM_RESTRICTED_PRD_FWC,
    MACROS.DVM.DVM_RESTRICTED_PRD_FPX,
    MACROS.DVM.DVM_RESTRICTED_PRD_FSF,
  ];
  const _initSupportTypes = async () => {
    setLoadTypes(false);
    let types = [];
    let resp = await getAdomTypes();

    if (!isEdit) {
      if (fiSysConfig.isFmg()) {
        types = resp.filter(function (ee) {
          return FMG_SUPPORT_CFG_TYPES.includes(ee.id);
        });
        setAdomRes((prevState) => {
          return { ...prevState, type: MACROS.DVM.DVM_RESTRICTED_PRD_FOS };
        });
      } else if (fiSysConfig.isFaz()) {
        setAdomRes((prevState) => {
          return { ...prevState, type: MACROS.DVM.DVM_RESTRICTED_PRD_FSF };
        });
        types = resp;
      } else {
        types = resp;
      }
      types = types.filter(
        (ee) => ee.text !== MACROS.DVM.DVM_UNMANAGED_DEVICES_NAME
      );
    } else {
      types = resp
        .filter((ee) => ee.id === adomRes.type)
        .map((ee) => ({
          ...ee,
          text:
            ee.text === MACROS.DVM.DVM_UNMANAGED_DEVICES_NAME
              ? adomRes.name
              : ee.text,
        }));
    }
    types.sort(function (a, b) {
      return a.text > b.text ? 1 : a.text < b.text ? -1 : 0;
    });
    return types;
  };

  const addDevicesToAdom = (selectedRows, setFieldValue) => {
    setFieldValue('newmembers', selectedRows);
  };

  const openDevicePane = (setFieldValue, values) => async () => {
    try {
      await $opener.open(
        <DeviceSelectPane
          addDevicesToAdom={(selectedRows) =>
            addDevicesToAdom(selectedRows, setFieldValue)
          }
          adomId={values?.adomId}
          formValueType={values?.type}
          newMembers={values?.newmembers}
        />,
        { size: 'lg' }
      ).result;
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch selections
  useEffect(() => {
    if (!loadTypes) return;
    _initSupportTypes().then((types) =>
      setSelects((prevState) => ({
        ...prevState,
        adomTypeSelectOptions: types,
      }))
    );
  }, [adomRes, loadTypes]);

  // Fetch ADOM version
  useEffect(() => {
    const versions = initSupportedVersions(sysConfig, adomRes.version);
    //if adomRes.version is higher than the highestVer, then set adomRes.version as the highestVer
    const latest_ver = versions[versions.length - 1];
    const cur_ver = adomRes.version;
    if (
      cur_ver.ver > latest_ver.ver ||
      (cur_ver.ver === latest_ver.ver && cur_ver.mr > latest_ver.mr)
    ) {
      setAdomRes((prev) => ({
        ...prev,
        version: latest_ver,
      }));
    }
    setSelects((prevState) => ({
      ...prevState,
      adomVersionOptions: versions,
    }));
  }, []);

  // Fetch ADOM data
  useEffect(() => {
    const fetchAdomData = async () => {
      let adomResp = {};
      let editable = true;
      if (isCreateNew) {
        // Get ADOM template and mete data fields
        const [adomTmplResp, adomMetaResp] = await Promise.all([
          getAdomTemplate(),
          getAdomMetaFields(),
        ]);
        const adomTmpl = adomTmplResp.data;
        const adomMeta = adomMetaResp;
        setAdomRes((prev) => {
          return {
            ...prev,
            version: {
              ver: adomTmpl.os_ver,
              mr: adomTmpl.mr,
              text: adomTmpl.os_ver + '.' + adomTmpl.mr,
            },
            metas: adomMeta,
            editable,
          };
        });
      } else {
        adomResp = await getAdomData(adomId);
        let quota = adomResp.quota,
          quota_select = 1;
        if (!(quota % MACROS.USER.SYS.TB_VS_MB_NUM)) {
          quota = quota / MACROS.USER.SYS.TB_VS_MB_NUM;
          quota_select = MACROS.USER.SYS.TB_VS_MB_NUM;
        } else if (!(quota % MACROS.USER.SYS.GB_VS_MB_NUM)) {
          quota = quota / MACROS.USER.SYS.GB_VS_MB_NUM;
          quota_select = MACROS.USER.SYS.GB_VS_MB_NUM;
        }
        setWsOrig(adomResp.workspace_mode);
        setAdomRes((prev) => {
          return { ...prev, ...adomResp, quota, quota_select };
        });

        let adomLock = fiWorkspace.adomInfo({
          oid: adomId,
          name: adomResp.name || '',
        });
        let _temp = {
          ...adomResp,
          lock: isNil(adomResp.lock) ? {} : adomResp.lock.lock,
        };
        editable =
          adomLock.isWorkspaceDisabled(adomResp) ||
          adomId === 0 ||
          adomLock.isLockedByMe(_temp, _temp);
      }
      setLoadTypes(true);

      let avatarPrefix = UserService.AVATAR_DATA_PREFIX;
      let approvalRes = null;
      let approvalReady = false;
      let matrices = [];

      if (sysConfig.hasFmgFeature) {
        let req = {
          method: 'get',
          params: [
            {
              url: URL_WORKFLOW_APPROVAL,
            },
          ],
        };

        let resp = await fiFmgHttp.query(req);
        matrices = resp[0].data;
        if (adomResp.globaldb) {
          approvalRes = matrices.find((rec) => rec['adom-name'] === 'rootp');
        } else {
          approvalRes = matrices.find(
            (rec) => rec['adom-name'] === adomResp.name
          );
        }
        approvalReady = true;

        // Fetch approval tempalte
        if (!approvalRes) {
          let resp = await fiFmgHttp
            .forward({
              method: 'get',
              params: [
                {
                  url: URL_WORKFLOW_APPROVAL,
                  'object template': 1,
                },
              ],
            })
            .then((resp) => resp?.[0]?.data || {});

          var _templ = {
            ...resp,
            notify: '',
            'mail-server': '',
            'adom-name': adomResp.name,
            approver: [{ seq_num: 0, member: '' }],
          };
          approvalRes = _templ;
        }
      }

      setAdomRes((prev) => ({
        ...prev,
        approvalData: {
          avatarPrefix,
          approvalRes,
          approvalReady,
          matrices,
        },
        editable: editable,
        gui_dns:
          (adomResp.primary_dns_ip4 && adomResp.primary_dns_ip4 !== IP_0) ||
          (adomResp.secondary_dns_ip4 && adomResp.secondary_dns_ip4 !== IP_0)
            ? 1
            : 0,
      }));
    };

    fetchAdomData();
  }, [adomId]);

  // Fetch ADOM limit
  useEffect(() => {
    let req = {
      id: 1,
      method: 'get',
      params: [
        {
          url: '/dvmdb/query/adomlimit',
        },
      ],
    };
    fiFmgHttp
      .forward(req)
      .then((resp) => {
        let respData = resp[0].data;
        setAdomLimit(respData);
      })
      .catch(() => {
        fiMessageBox.show(gettext('Failed to get ADOM limit.', 'danger'));
      });
  }, []);

  return (
    <Formik
      initialValues={adomRes}
      enableReinitialize={true}
      onSubmit={onFormSubmit}
      validate={validateAdomEdit}
    >
      {({ values, setFieldValue, submitForm, isSubmitting }) => (
        <Provider store={fiStore}>
          <NwProHeader>
            {isView
              ? values.globaldb
                ? gettext('View Global Database - %s').printf([values.name])
                : gettext('View ADOM - %s').printf([values.name])
              : isEdit
              ? values.globaldb
                ? gettext('Edit Global Database - %s').printf([values.name])
                : gettext('Edit ADOM - %s').printf([values.name])
              : gettext('Create ADOM')}
          </NwProHeader>
          <NwProBody labelSize={'md'}>
            <>
              <NwProSection>
                <AdomCommonEdit
                  selects={selects}
                  onChangeVersion={(vers) => {
                    setSelects((prevState) => ({
                      ...prevState,
                      adomVersionOptions: vers,
                    }));
                  }}
                  isCreateNew={isCreateNew}
                  isEdit={isEdit}
                  isView={isView}
                  setReload={setReload}
                />
                {!values.globaldb && (
                  <AdomDeviceTable
                    ref={null}
                    onClick={openDevicePane(setFieldValue, values)}
                    isEdit={isEdit}
                  />
                )}
                {!values.globaldb &&
                  sysConfig.hasFmgFeature &&
                  _isConfigurableAdom(values) && (
                    <AdomFmgEdit isView={isView} />
                  )}
              </NwProSection>

              {!values.globaldb && sysConfig.hasFazFeature && (
                <AdomFazEdit setAvailableQuota={setAdomRes} isView={isView} />
              )}
              {!values.globaldb &&
                isArray(values.metas) &&
                values.metas.length > 0 && <AdomMetaFields isView={isView} />}
              {!isCreateNew && values.name !== 'root' && (
                <NwProSection>
                  <NwProInputRow label={gettext('Status')}>
                    <FmkSwitch
                      name='status'
                      automation-id={autoId('status')}
                      disabled={isView}
                    />
                  </NwProInputRow>
                </NwProSection>
              )}
            </>
          </NwProBody>
          <NwProFooter>
            {adomRes.editable && !isView && (
              <OkBtn
                name='ok'
                onClick={submitForm}
                automation-id={autoId('btn-ok')}
                loading={isSubmitting}
              >
                {gettext('OK')}
              </OkBtn>
            )}
            <CancelBtn
              name='cancel'
              style={{ marginLeft: '5px', width: 100 }}
              onClick={onCancel}
              automation-id={autoId(
                `btn-${adomRes.editable ? 'cancel' : 'return'}`
              )}
            >
              {adomRes.editable && !isView
                ? gettext('Cancel')
                : gettext('Close')}
            </CancelBtn>
          </NwProFooter>
        </Provider>
      )}
    </Formik>
  );
}
