import { fiHttpPost, fiFmgHttp } from 'fi-web/fi-http';
import { fiAdom } from 'fi-web/fi-session';
import { deviceStatus } from 'fi-dvm';
import { fiMessageBox } from 'fi-messagebox';
import { isUndefined } from 'lodash';
import { escapeSlash } from 'kit/kit-regexp';

const genDidMap = function (rows, result) {
  var oData;
  for (var i = 0; i < rows.length; i++) {
    oData = rows[i]._oData;
    if (
      oData.rtype === deviceStatus.rtype.device ||
      oData.rtype === deviceStatus.rtype.deviceHasVdom ||
      oData.rtype === deviceStatus.rtype.deviceWithoutMgt
    ) {
      result['' + oData.did] = oData;
    }
  }
  return result;
};

const getoDataNameAndVdom = function (oData, didMap) {
  if (oData.isGrp) {
    return { name: oData.name };
  }
  if (oData.rtype === deviceStatus.rtype.device) {
    return {
      name: oData.name,
      vdom: !oData.vdom ? 'root' : oData.vdom,
    };
  } else {
    var devName = oData.name;
    if (oData.did && didMap.hasOwnProperty('' + oData.did)) {
      devName = didMap['' + oData.did].name;
    }
    return {
      name: devName,
      vdom: !oData.vdom ? 'root' : oData.vdom,
    };
  }
};

export const fiDeviceHttpRequest = {
  /**
   * Creates a device group
   * @param {Object} grpObj - the group object
   * @param {Array} selectedDevs - the array of all devices that need to be grouped
   *                  It's the array of rows
   * @param {Integer} grp_oid - the group oid, specified if we are editing group instead of creating
   * @return {Promise}
   */
  setDevGrp: function (grpObj, selectedDevs, allDevs, grp_oid, rAdom) {
    var data = grpObj;
    var ObjMembers = [];
    var didMap = {};
    genDidMap(allDevs, didMap);

    for (var i = 0; i < selectedDevs.length; i++) {
      ObjMembers.push(getoDataNameAndVdom(selectedDevs[i]._oData, didMap));
    }

    var isEditing = grp_oid;

    return new Promise((resolve, reject) => {
      fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, {
        method: isEditing ? 'update' : 'add',
        params: [
          {
            url: '/dvmdb/adom/' + rAdom + '/group/' + escapeSlash(data.name),
            data: data,
          },
        ],
      }).then(
        function (resp_grp) {
          if (isEditing || (!isEditing && ObjMembers.length > 0)) {
            ///save scope member AGAIN...
            fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, {
              method: 'set',
              params: [
                {
                  url:
                    '/dvmdb/adom/' +
                    rAdom +
                    '/group/' +
                    escapeSlash(data.name) +
                    '/object member',
                  data: ObjMembers,
                },
              ],
            }).then(
              function (resp_member) {
                resolve(resp_member.data.result[0]);
              },
              function (resp_member) {
                reject(resp_member);
              }
            );
          } else {
            resolve(resp_grp.data.result[0]);
          }
        },
        function (err) {
          reject(err);
        }
      );
    });
  },

  getDevGrp: function (grpName, rAdom) {
    return new Promise((resolve, reject) => {
      var req = {
        method: 'get',
        params: [
          {
            url: '/dvmdb/adom/' + rAdom + '/group/' + escapeSlash(grpName),
            'expand member': [
              {
                url: '/group',
                fields: ['name', 'oid'],
              },
              {
                url: '/device',
                fields: ['name', 'oid', 'vdom'],
              },
            ],
          },
        ],
      };

      fiFmgHttp.forward(req).then(
        function (resp) {
          resolve(resp[0].data);
        },
        function (err) {
          reject(err.message);
        }
      );
    });
  },

  /**
   * Deletes a device group
   * @param {Integer} groupName - the name of the group which will be deleted
   * @return {Promise}
   */
  deleteDevGrp: function (groupName) {
    return new Promise(function (resolve, reject) {
      const req = {
        method: 'delete',
        params: [
          {
            url: `/dvmdb/adom/${fiAdom.current().name}/group/${escapeSlash(
              groupName
            )}`,
          },
        ],
      };
      fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, req).then(
        function (resp) {
          resolve(resp);
        },
        function (err) {
          reject(err);
        }
      );
    });
  },

  /**
   * Adds a device
   * @param {String} adomName - the ADOM name
   * @param {Object} devParams - an object contains fields to add a device
   * @return {Promise}
   */
  addDev: function ({ adomName, devParams, addToGroups, isAddMgtFaz }) {
    // if the current ADOM is of type FABRIC and the new device has non-fos type
    // then the 'log_dev' flag should be added to flags
    let flags = ['create_task', 'nonblocking'];
    if (
      fiAdom.current().is_fabric &&
      !isUndefined(devParams['os_type']) &&
      devParams['os_type'] !== MACROS.DVM.DVM_OS_TYPE_FOS &&
      !isAddMgtFaz
    ) {
      flags.push('log_dev');
    }

    if (fiAdom.current().is_fabric && isUndefined(devParams['os_type'])) {
      devParams['os_type'] = MACROS.DVM.DVM_OS_TYPE_FOS;
    }
    var reqObj = {
      method: 'exec',
      params: [
        {
          url: 'dvm/cmd/add/device',
          data: {
            adom: adomName,
            flags: flags,
            device: devParams,
          },
        },
      ],
    };
    if (Array.isArray(addToGroups) && addToGroups.length > 0) {
      reqObj.params[0].data.groups = addToGroups;
    }

    return fiHttpPost(MACROS.USER.DEF.URL_DVM_API, reqObj).then(
      function (resp) {
        return resp.data.result[0].data.taskid;
      },
      function (resp) {
        return resp.errors[0].status;
      }
    );
  },

  /**
   * promote/link a device
   * @param {String} adomID - the ADOM name
   * @param {Object} devParams - an object contains fields to add a device
   * @return {Promise}
   */
  promoteDev: function (adomID, devParams, addToGroups) {
    var reqObj = {
      method: 'exec',
      params: [
        {
          data: {
            'add-dev-list': [devParams],
            adom: adomID,
            'del-dev-list': null,
            flags: 3,
          },
          url: 'dvm/cmd/promote/dev-list',
        },
      ],
    };
    if (Array.isArray(addToGroups) && addToGroups.length > 0) {
      reqObj.params[0].data.groups = addToGroups;
    }

    return fiHttpPost(MACROS.USER.DEF.URL_DVM_API, reqObj).then(
      function (resp) {
        return resp.data.result[0].data.taskid;
      },
      function (resp) {
        return resp.errors[0].status;
      }
    );
  },

  /**
   * Adds a device from Unregistered devices
   * @param {Int} adomOid - the ID of the adom that the device is added to
   * @param {Array} devices - an array of all devices need to be added
   *              Element in the array should has the following fields:
   *               oid, new_name, ip, user_name, password, quotas
   * @return {Promise}
   */
  addUnRegDev: function (adomOid, devices) {
    let flags =
      MACROS.DVM.DVM_OP_FLAG_NONBLOCKING |
      MACROS.DVM.DVM_OP_FLAG_CREATE_TASK |
      MACROS.DVM.DVM_DEV_FLAG_FORCE_PROBE |
      MACROS.DVM.DVM_DEV_FLAG_RELOAD |
      MACROS.DVM.DVM_DEV_FLAG_DISCOVER;
    let devList = devices.reduce((acc, cur) => {
      acc.push({
        oid: parseInt(cur.oid, 10),
        name: cur.new_name || cur.name,
        adm_usr: cur.user_name,
        adm_pass: cur.password,
        'device action': 'promote_unreg',
      });
      return acc;
    }, []);
    let req = {
      method: 'exec',
      params: [
        {
          url: '/dvm/cmd/add/dev-list',
          data: {
            adom: adomOid,
            flags: flags,
            'add-dev-list': devList,
          },
        },
      ],
    };

    return fiFmgHttp.query(req).then((resp) => resp?.[0]?.data);
  },

  delDev2: function (arrIds, devIdNameMap, rAdom, fazDeletedVdoms = []) {
    var arrDevs = [],
      arrVdoms = [],
      delDevsMap = {},
      tmpId,
      idPair,
      i;
    for (i = 0; i < arrIds.length; i++) {
      //{"name": "fg1", "vdom": "root"   }
      tmpId = arrIds[i];
      if (tmpId.indexOf('|') < 0) {
        //device
        delDevsMap[tmpId] = true;
        arrDevs.push({
          oid: parseInt(tmpId),
        });
      }
    }
    for (i = 0; i < arrIds.length; i++) {
      //{"name": "fg1", "vdom": "root"   }
      tmpId = arrIds[i];
      if (tmpId.indexOf('|') > 0) {
        idPair = tmpId.split('|');
        if (idPair.length === 2) {
          if (delDevsMap[idPair[0]]) {
            continue; //if device is deleted, no need to send delete vdom request...
          }
          if (
            fazDeletedVdoms[idPair[0]] &&
            fazDeletedVdoms[idPair[0]].includes(idPair[1])
          ) {
            continue; // if vdom is already deleted by fazdel, no need to delete again
          }
          //device's vdom
          arrVdoms.push({
            url:
              '/dvmdb/adom/' +
              rAdom.name +
              '/device/' +
              escapeSlash(devIdNameMap[idPair[0]]) +
              '/vdom/' +
              idPair[1],
            flags: ['create_task', 'nonblocking'],
          });
        }
      }
    }

    var jsonreq_dev = {
      method: 'exec',
      params: [
        {
          url: 'dvm/cmd/del/dev-list', //del dev-list, note device...
          data: {
            adom: rAdom.oid,
            flags: ['create_task', 'nonblocking'],
            'del-dev-member-list': arrDevs,
          },
        },
      ],
    };

    var jsonreq_vdom = {
      method: 'delete',
      params: arrVdoms,
    };

    return new Promise((resolve, reject) => {
      var handleResp = function (resp) {
        try {
          resolve({
            tid: resp.data.result[0].data.taskid,
          });
        } catch (e) {
          // delete vdom only, no taskid
          resolve();
        }
      };

      var handleErr = function (err) {
        var msg, eResult, eStatus;
        try {
          eResult = err.data.result || err.data;
          eStatus = eResult[0].status || eResult[0];
          msg = eStatus.message;
          fiMessageBox.show(msg, 'danger', null);
        } catch (e) {
          fiMessageBox.show(gettext('Fail to delete device'), 'danger', null);
        }
        reject(err);
      };

      if (arrDevs.length > 0 && arrVdoms.length > 0) {
        let delPromises = [];
        delPromises.push[
          fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, jsonreq_dev)
        ];

        delPromises.push[
          fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, jsonreq_vdom)
        ];

        Promise.all(delPromises).then(
          function (resolveObjs) {
            var del_devs = resolveObjs[0];
            handleResp(del_devs.data);
          },
          function () {
            fiMessageBox.show(gettext('Fail to delete device'), 'danger', null);
          }
        );
      } else {
        let req = null;
        if (arrVdoms.length > 0) {
          req = jsonreq_vdom;
        } else if (arrDevs.length > 0) {
          req = jsonreq_dev;
        } else {
          resolve();
          return;
        }
        fiHttpPost(MACROS.USER.DEF.URL_FLATUI_API, req).then(
          handleResp,
          handleErr
        );
      }
    });
  },

  /**
   * Refresh devices
   * @param devOIds
   * @param adom
   * @returns {Promise}
   */
  refDev: function (devs, adom, isFAZFabricAdom = false) {
    let flags = isFAZFabricAdom
      ? ['create_task', 'nonblocking', 'log_dev']
      : ['create_task', 'nonblocking'];
    let req = {
      id: 1,
      method: 'exec',
      params: [
        {
          url: '/dvm/cmd/update/dev-list',
          data: {
            adom: adom.oid || adom,
            flags: flags,
            'update-dev-member-list': devs,
          },
        },
      ],
    };
    return new Promise((resolve, reject) => {
      fiFmgHttp.query(req).then(
        function (resp) {
          resolve(resp[0].data);
        },
        function (e) {
          try {
            reject(e.data.result[0].status.message);
          } catch (e) {
            e.message;
          }
        }
      );
    });
  },

  /**
   * Add multiple devices
   * @param allDevInfo
   * @param adom
   * @returns {Promise}
   */
  addMultipleDev: function (allDevInfo, adom, isFAZFabricAdom) {
    let devList = allDevInfo.map((dev) => {
      let flag = 0;
      flag |=
        MACROS.DVM.DVM_DEV_FLAG_FORCE_PROBE |
        MACROS.DVM.DVM_DEV_FLAG_RELOAD |
        MACROS.DVM.DVM_DEV_FLAG_DISCOVER;
      return {
        os_type: dev.devtype,
        name: dev.name,
        ip: dev.ip,
        adm_pass: dev.pswd || dev.password,
        adm_usr: dev.usr || dev.username,
        mgmt_mode: MACROS.DVM.DVM_MGMT_MODE_FMG_FAZ,
        'faz.quota': 0,
        flags: flag,
        sn: dev.sn,
      };
    });
    let flags =
      MACROS.DVM.DVM_OP_FLAG_NONBLOCKING | MACROS.DVM.DVM_OP_FLAG_CREATE_TASK;
    if (isFAZFabricAdom) flags |= MACROS.DVM.DVM_OP_FLAG_ADD_LOG;
    let req = {
      id: 1,
      method: 'exec',
      params: [
        {
          url: '/dvm/cmd/add/dev-list',
          data: {
            adom: adom,
            flags: flags,
            'add-dev-list': devList,
          },
        },
      ],
    };
    return new Promise((resolve, reject) => {
      fiFmgHttp.query(req).then(
        function (resp) {
          resolve(resp[0].data);
        },
        function (e) {
          try {
            reject(e.data.result[0].status.message);
          } catch (e) {
            e.message;
          }
        }
      );
    });
  },
};
