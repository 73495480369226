import { TaskDetails } from './TaskDetails';
import { useRef, useState } from 'react';
import { isTaskDone, isTaskError, getTaskWithTimestampById } from './task_util';
import { isNil } from 'lodash';
import { usePollRequest, useAbortEffect } from 'rh_util_hooks';

import './task_monitor.less';

const THREESEC = 3000;

export const TaskMonitor = ({
  taskId,
  interval = 1000,
  onTaskDone,
  onTaskError,
  onTaskUpdate,
  taskAccessor,
  viewer: Viewer = TaskDetails,
  ...rest
}) => {
  const [serverTime, setServerTime] = useState(0);
  const [taskData, setTaskData] = useState(null);
  const timeout2 = useRef(null);
  const buffer = useRef(null);
  const initRef = useRef(false);

  const handleTaskData = (_taskData) => {
    buffer.current = _taskData;
    const timeout = initRef.current ? THREESEC : 0;
    if (!timeout2.current) {
      timeout2.current = setTimeout(() => {
        const taskData = buffer.current;
        timeout2.current = null;
        buffer.current = null;
        setTaskData(taskData);
        initRef.current = true;

        if (!taskData) return;

        if (!isTaskDone(taskData)) return onTaskUpdate?.(taskData);
        if (!isTaskError(taskData)) return onTaskDone?.(taskData);
        return onTaskError?.(taskData);
      }, timeout);
    }
  };

  useAbortEffect(
    (abortCtrl) => {
      let unmounted = false;

      if (isNil(taskId)) return;

      const cleanup = usePollRequest({
        timeout: interval,
        init: () => {
          // reset on task change
          setTaskData(null);
        },
        pollFn: async () => {
          try {
            const resp = await getTaskWithTimestampById(taskId, abortCtrl);
            return resp || {};
          } catch (error) {
            console.error(error);
            return {};
          }
        },
        onNotify: (resp) => {
          if (unmounted) return;

          const task = resp.data || resp || {};
          setServerTime((prev) =>
            Math.max(
              prev,
              resp['x-time-response'] || resp['x-time-request'] || 0
            )
          );
          handleTaskData(task);
          const isComplete = isTaskDone(task); // return true will clear interval
          return isComplete;
        },
      });

      return () => {
        unmounted = true;
        cleanup();
        if (timeout2.current) clearTimeout(timeout2.current);
      };
    },
    [taskId]
  );

  if (taskAccessor) {
    Object.assign(taskAccessor, {
      getTaskData: () => {
        return taskData;
      },
    });
  }

  return <Viewer taskData={taskData} serverTime={serverTime} {...rest} />;
};
