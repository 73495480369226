import {
  NwProHeader,
  NwProBody,
  NwProSection,
  NwProInputRow,
  NwProLine,
  openConfirmModal,
} from 'rc_layout';
import { Formik } from 'formik';
import {
  FmkErrorSpan,
  FmkPassword,
  FmkSwitch,
  FmkObjSSelect2,
  FmkFooter,
} from 'rc_form';
import { useState, useMemo, useRef, useCallback } from 'react';
import { NwBadge } from '@fafm/neowise/react';
import {
  NwTooltip,
  NwIcon,
  NwProgressBar,
  NwSpinner,
} from '@fafm/neowise-core';
import { useValidEffect } from 'rh_util_hooks';
import { fiSysConfig, fiAdminProfile } from 'fi-session';
import { DropUploader, FileLister } from 'rc_drop_uploader';
import { TaskMonitor, fiTaskByWebSocket } from 'rc_task_monitor';
import { fiSocket } from 'fi-websocket';
import { fiFmgHttp } from 'fi-http';
import * as fiUUID from 'fi-uuid';
import { get, isNil } from 'lodash';
import { fiSystemOperationApi } from 'fi-api';
import { FileTransferService, UploaderService } from 'fi-file';
import { SysDashboardService } from 'fi-dashboard';
import { fiIdleTimer } from 'fiutil/idle_timer';
import { dispatch } from 'fistore/index';
import { setIgnoreError } from 'fistore/auth/slice';
import { getSysInfo } from 'react_apps/ra_dashboard_util/api.js';
import {
  transformBackendFirmwareText,
  validateUploadedImage,
} from 'fi-web/fi-api/system_operation';

const getAutoId = (...suffix) => 'sys_firmware_upgrade:' + suffix.join('-');

const initialProgressValue = {
  step: 0,
  dlTaskId: null,
  showDlProgress: false,
  message: '',
  messageClass: '',
};

export const FirmwareUpgrade = ({ $opener }) => {
  const [progress, setProgress] = useState(initialProgressValue);
  const { data, supportedVersions, staticData, isSuperAdmin } = useSetup();
  const filePondRef = useRef(null);
  const formikRef = useRef();

  const [uploadingFile, setUploadingFile] = useState(null);
  const uploader = useMemo(
    () =>
      UploaderService.create({
        url: '/flatui/api/gui/firmware/import',
        data: {},
        key: 'filepath',
        onProgress: function (fileInfo) {
          setUploadingFile(fileInfo);
        },
        // step1: upload the image
        onCompleted: async function () {
          setUploadingFile(null);
          afterImageUploaded((message) => {
            formikRef.current?.setFieldError('filepath', message);
          });
        },
      }),
    []
  );

  const onAddFile = useCallback((e, setFieldValue) => {
    setFieldValue('filepath', e.detail?.file);
    setProgress((pre) => ({
      ...pre,
      message: '',
      messageClass: '',
    }));
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.filepath) {
      if (!values.selectedImg)
        errors['filepath'] = gettext('Please choose a valid file.');
    } else if (values.filepath.active) {
      errors['filepath'] = gettext(
        'The file was just uploaded. Please choose another one.'
      );
    }
    if (values.backup) {
      if (!values.password)
        errors['password'] = gettext('The password cannot be empty.');
      if (values.password !== values.cpassword)
        errors['cpassword'] = gettext('The passwords do not match.');
    }
    return errors;
  };

  const submitForm = (values) => {
    if (!values.filepath) {
      let _startDownload = function () {
        SysDashboardService.downloadByObjId(values.selectedImg).then(function (
          resp
        ) {
          setProgress((pre) => ({
            ...pre,
            dlTaskId: resp[0].data.taskid,
            showDlProgress: true,
            step: 1,
            message: gettext('Downloading the selected image file...'),
            messageClass: '',
          }));
          dispatch(setIgnoreError(true));
        });
      };
      if (
        !supportedVersions.filter((v) => v.id === values.selectedImg)[0]
          .recommended
      ) {
        openConfirmModal({
          title: gettext('Firmware Download'),
          content: gettext(
            'Cannot find upgrade path file. No upgrade recommendation can be provided. Would you like to continue?'
          ),
        }).then(
          () => {
            _startDownload();
          },
          () => {
            formikRef.current?.setSubmitting(false);
          }
        );
      } else {
        _startDownload();
      }
    } else {
      uploader.removeAll();
      setUploadingFile(null);
      uploader.addFiles([values.filepath]);
      setProgress((pre) => ({
        ...pre,
        step: 1,
        message: values.backup
          ? gettext(
              'Uploading the selected image file... The system will automatically backup the configurations if a valid image file is uploaded.'
            )
          : gettext('Uploading the selected image file...'),
        messageClass: '',
      }));

      // Because a successful image upload would cause the server
      // to shutdown http services, and we do not want to jump
      // to the login page at that time, we tell heartbeat to ignore
      // connection errors.
      dispatch(setIgnoreError(true));
      uploader.start();
    }
  };

  async function showUploadSuccessMessage() {
    const values = formikRef.current?.values || {};
    setProgress((pre) => ({
      ...pre,
      messageClass: '',
      message: (
        <>
          <NwSpinner className='tw-mr-1' />
          {values.backup
            ? gettext(
                'The image has been uploaded successfully. The upgrade will start after the backup file is saved.'
              )
            : gettext(
                'The image has been uploaded successfully. The upgrade will start shortly.'
              )}
        </>
      ),
    }));
  }

  function doBackup() {
    const values = formikRef.current?.values || {};
    if (!values.backup || !isSuperAdmin) return Promise.resolve();

    return new Promise((resolve, reject) => {
      let tid = fiUUID.gen();
      let req = {
        id: tid,
        url: '/gui/sys/db',
        method: 'backup',
        params: {
          password: values.password,
        },
      };

      const listener = (data) => {
        if (data?.collection === 'file-download') {
          fiSocket.get_ws_proxy().removeListener('changed', listener);
          resolve();
        }
      };

      fiSocket.get_ws_proxy().addListener('changed', listener);

      fiTaskByWebSocket.register(tid, function (resp) {
        fiIdleTimer.active({ type: 'sys:backup' });

        if (!resp) return;

        let meta = resp.fields.meta || {};
        let completedSize = FileTransferService.bytesFormatter(meta.bytes || 0);
        let fileSize = meta.bytes || 0;
        setProgress((pre) => ({
          ...pre,
          message:
            gettext('The system is backing up...') + '(' + completedSize + ')',
          messageClass: '',
        }));

        if (0 == resp.fields.code && meta.downloadname && meta.filepath) {
          let fname = FileTransferService.appendTimestampToFilename(
            meta.downloadname
          );
          // download as a single file
          FileTransferService.downloadByForm(meta.filepath, fname, fileSize);
          fiTaskByWebSocket.unregister(tid);
        }
      });

      fiFmgHttp.post(req).then(
        () => {},
        (e) => {
          fiTaskByWebSocket.unregister(tid);
          reject(e);
        }
      );
    });
  }

  function doUpgrade({ downgrading, targetBuild }) {
    return fiSystemOperationApi.upgrade().then(function (resp) {
      if (resp[0].data.status === MACROS.SYS.UPG_OK) {
        let message;
        if (downgrading) {
          message = (
            <>
              <div>{`${gettext('Downgrading to')} ${targetBuild}`}</div>
              <span>
                <NwSpinner className='tw-mr-1' />
                {gettext(
                  'The downgrade has started and the system is restarting. Please wait for a few moments.'
                )}
              </span>
            </>
          );
        } else {
          message = (
            <>
              <NwSpinner className='tw-mr-1' />
              {gettext(
                'The upgrade has started and the system is restarting. Please wait for a few moments.'
              )}
            </>
          );
        }
        setProgress((pre) => ({
          ...pre,
          message,
          messageClass: '',
        }));
      } else {
        return Promise.reject(
          downgrading ? gettext('Downgrade Failed') : gettext('Upgrade Failed')
        );
      }
    });
  }

  const chainUpgradeJobs = (data) => {
    showUploadSuccessMessage()
      .then(doBackup)
      .then(() => doUpgrade(data))
      .catch((e) => {
        // When upload was not successful, we restore
        // heartbeat's check for bad connection, so it can
        // open the login page if user clears all browse history.
        dispatch(setIgnoreError(false));
        resetProgressWithErrorMessage(
          typeof e === 'string' ? e : JSON.stringify(e)
        );
        formikRef.current?.setValues((values) => ({
          ...values,
          filepath: null,
          selectedImg: 0,
        }));
      });
  };

  const afterImageUploaded = async (showFileValidateError) => {
    try {
      const data = await validateUploadedImage();

      setProgress(() => ({
        ...initialProgressValue,
        step: 1,
      }));

      chainUpgradeJobs(data);
    } catch ({ status, message }) {
      setProgress(initialProgressValue);

      if (status && message) {
        showFileValidateError(message);
      }
    }
    formikRef.current?.setSubmitting(false);
  };

  const resetProgressWithErrorMessage = (message) => {
    setProgress(() => ({
      ...initialProgressValue,
      message: message,
      messageClass: 'color-red',
    }));
    formikRef.current?.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={data}
      enableReinitialize={true}
      onSubmit={submitForm}
      validate={validate}
      innerRef={formikRef}
    >
      {({ values, setFieldValue }) => (
        <>
          <NwProHeader>{gettext('Firmware Management')}</NwProHeader>
          <NwProBody>
            <NwProSection>
              {progress.step === 0 ? (
                <>
                  <NwProInputRow label={gettext('Current Version')}>
                    {transformBackendFirmwareText(
                      staticData.panelData?.fwversion
                    ) || ''}
                  </NwProInputRow>
                  {staticData.suggested ? (
                    <NwProInputRow label=' '>
                      <NwProLine>
                        <NwTooltip
                          content={gettext('Recommended upgrade step.')}
                        >
                          <NwBadge type='info' class='tw-pt-1'></NwBadge>
                        </NwTooltip>
                        <label>{staticData.suggested}</label>
                      </NwProLine>
                    </NwProInputRow>
                  ) : null}
                  <NwProInputRow label={gettext('Upload Firmware')}>
                    <DropUploader
                      ref={filePondRef}
                      onProcess={(e) => onAddFile(e, setFieldValue)}
                    />
                    <FmkErrorSpan name='filepath' />
                  </NwProInputRow>
                  <FileLister
                    file={values['filepath']}
                    clear={() => setFieldValue('filepath', null)}
                  />
                  {!values.filepath ? (
                    <NwProInputRow label={gettext('FortiGuard Firmware')}>
                      {supportedVersions.length > 0 ? (
                        <FmkObjSSelect2
                          name='selectedImg'
                          source={supportedVersions}
                          automationId={getAutoId('selectedImg')}
                        />
                      ) : (
                        <div className='color-warning'>
                          <NwIcon name='warning' className='tw-mr-1' />
                          {gettext('No firmware available from FortiGuard')}
                        </div>
                      )}
                    </NwProInputRow>
                  ) : null}
                  {isSuperAdmin ? (
                    <>
                      <NwProInputRow label={gettext('Backup Configuration')}>
                        <FmkSwitch
                          name='backup'
                          automation-id={getAutoId('backup')}
                        />
                      </NwProInputRow>
                      {values.backup ? (
                        <>
                          <NwProInputRow label={gettext('Password')}>
                            <FmkPassword
                              name='password'
                              maxLength={63}
                              placeholder={gettext(
                                'Maximum password length: 63'
                              )}
                              automation-id={getAutoId('password')}
                            />
                            <FmkErrorSpan name='password' />
                          </NwProInputRow>
                          <NwProInputRow label={gettext('Confirm Password')}>
                            <FmkPassword
                              name='cpassword'
                              maxLength={63}
                              placeholder={gettext(
                                'Maximum password length: 63'
                              )}
                              automation-id={getAutoId('cpassword')}
                            />
                            <FmkErrorSpan name='cpassword' />
                          </NwProInputRow>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
              <div className={progress.messageClass}>{progress.message}</div>
              {uploadingFile ? (
                <NwProInputRow>
                  <NwProgressBar
                    percentage={(
                      ((uploadingFile.loaded <= uploadingFile.size
                        ? uploadingFile.loaded
                        : uploadingFile.size) /
                        uploadingFile.size) *
                      100
                    ).toFixed(0)}
                  >
                    {uploadingFile.humanSize}
                  </NwProgressBar>
                </NwProInputRow>
              ) : null}
              {progress.showDlProgress ? (
                <div className='tw-h-80'>
                  <TaskMonitor
                    taskId={progress.dlTaskId}
                    onTaskDone={async () => {
                      await SysDashboardService.tarArchivedImage();

                      setProgress((pre) => ({
                        ...pre,
                        showDlProgress: false,
                      }));
                      afterImageUploaded((message) => {
                        resetProgressWithErrorMessage(message);
                      });
                    }}
                    onTaskError={() => {
                      resetProgressWithErrorMessage(
                        gettext('Error download selected image file.')
                      );
                    }}
                  />
                </div>
              ) : null}
            </NwProSection>
          </NwProBody>
          {progress.step === 0 ? (
            <FmkFooter
              canWrite={true}
              getAutoId={() => getAutoId('buttons-')}
              onCancel={() => $opener.reject()}
            />
          ) : null}
        </>
      )}
    </Formik>
  );
};

const useSetup = () => {
  const isSuperAdmin = useMemo(() => fiAdminProfile.isSuperAdmin(), []);
  const [data, setData] = useState({ backup: isSuperAdmin });
  const [supportedVersions, setSupportedVersions] = useState([]);
  const [staticData, setStaticData] = useState({});

  useValidEffect(async (isValid) => {
    try {
      let [sysInfo, fmgImgFullSet, upgMap, abbrData] = await Promise.all([
        getSysInfo(),
        SysDashboardService.getAllFwObjects(),
        SysDashboardService.getFirmwareUpgradePath(fiSysConfig.isFmg()),
        SysDashboardService.getDevPlatformAbbrByName(
          MACROS.SYS.CONFIG_PROD_NAME
        ),
      ]);
      if (!isValid()) return;
      const panelData = sysInfo;
      let curVerBuild = SysDashboardService.parseVersionBuildNumber(
        panelData.fwversion
      );
      let platAbbrev = abbrData['abbr'];

      // Filter firmware fullset by platform
      fmgImgFullSet = fmgImgFullSet.filter(
        (img) => img.platform === platAbbrev
      );
      fmgImgFullSet.sort((a, b) => {
        let valA = a.version
          .split('.')
          .reduce((acc, val) => parseInt(val) + parseInt(acc) * 10);
        let valB = b.version
          .split('.')
          .reduce((acc, val) => parseInt(val) + parseInt(acc) * 10);
        return valB - valA;
      });

      //no firmware license, need filter out all other major/minor versions
      if (!fiSysConfig.hasValidLicense('FMWR')) {
        const [major, minor] = curVerBuild.version.split('.');
        fmgImgFullSet = fmgImgFullSet.filter((img) => {
          const [_major, _minor] = img.version.split('.');
          if (_major > major) return false;
          else if (_major === major && _minor > minor) return false;
          return true;
        });
      }

      // Display all downloadable images
      const supportedVersions = fmgImgFullSet.map((v) => {
        return {
          id: v.objid,
          text: [
            `${v.version} build ${parseInt(v.buildnumber, 10)}`,
            v.imageType ? `(${v.imageType})` : false,
          ]
            .filter(Boolean)
            .join(' '),
          recommended: false,
        };
      });

      // Set default selection
      if (supportedVersions[0]) {
        setData((pre) => ({ ...pre, selectedImg: supportedVersions[0].id }));
      }

      // Mark suggested upgrade versions (intersect upg matrix and downloadable full set)
      // fmim.dat might not have the suggested data, as a result we need to guard it.
      let recommendedList = [];
      if (get(upgMap, [platAbbrev, curVerBuild.version, curVerBuild.build])) {
        recommendedList = upgMap[platAbbrev][curVerBuild.version][
          curVerBuild.build
        ].map((v) => v.upgradeVer.trim());
      }
      supportedVersions.map((v) => {
        let ver = v.text.split(' ')[0].trim();
        if (recommendedList.indexOf(ver) != -1) {
          v.recommended = true;
          v.icon = { name: 'check', classes: 'color-green' };
        }
      });
      let vSuggested = SysDashboardService.getLatestVersionPatch(
        curVerBuild.version,
        recommendedList
      );
      setStaticData((pre) => ({
        ...pre,
        panelData,
        suggested: isNil(vSuggested)
          ? null
          : `${platAbbrev} v${vSuggested} available`,
      }));
      setSupportedVersions(supportedVersions);
    } catch {
      //
    }
  }, []);

  return { data, supportedVersions, staticData, isSuperAdmin };
};
