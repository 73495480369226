import { getSyntax } from 'fistore/adomSyntax/selectors';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApiRequest, useStateRef } from 'react_hooks/rh_util_hooks';
import { ErrorObj } from './types';
import { BoxType, fiMessageBox } from 'fi_widgets/fi-messagebox';
import { debounce, DebouncedFunc, get } from 'lodash';
import { fuzzySearchStringArray } from 'kit/kit-fuzzy';
import { ScriptSuggestionProvider } from './suggestions/suggestion';
import { ScriptValidator } from './validation/validate';
import CodeMirror from '@fafm/codemirror';
import {
  useAllModelStruct,
  useCurrentFirmware,
  useOsType,
} from '../hooks/model_device_hooks';
import { fiFmgHttp } from 'fi-web/fi-http';
import { getSessionAdomData } from 'fistore/session/adom/selectors';

/*
scriptTarget from Scripts edit (CLI template always device)
device:
  MACROS.DVM.DVM_SCRIPT_TARGET_DEVICE_DB 
  MACROS.DVM.DVM_SCRIPT_TARGET_DEVICE_REMOTE

ADOM:
  MACROS.DVM.DVM_SCRIPT_TARGET_GLOBAL

*/
let suggestionProvider: ScriptSuggestionProvider | null = null;
export const useCliScriptValidationAndSuggestion = (
  scriptTarget: number = MACROS.DVM.DVM_SCRIPT_TARGET_DEVICE_DB,
  disableValidateAndSuggest: boolean = false,
  handleMetaVariables: boolean = true, //ignore lines with $()
  extraErrors: any = []
) => {
  const adomSyntax = useSelector(getSyntax);
  const adom = useSelector(getSessionAdomData);
  const validatorRef = useRef<ScriptValidator>();
  const { platformList, currentFirmware } = useDevicePlatformList();

  const [validateOnChange, setValidateOnChange] = useState(false);
  const [errors, setErrors] = useState([] as ErrorObj[]);
  const [hoveredLine, setHoveredLine] = useState<number>(-1);
  const [hoveredLineCoords, setHoveredLineCoords] = useState({ x: 0, y: 0 });
  const [selectedPlatform, setSelectedPlatform] = useState(
    adom.adom_os_type === MACROS.DVM.DVM_OS_TYPE_FOS ||
      adom.type === MACROS.DVM.DVM_RESTRICTED_PRD_FSF
      ? 'FortiGate-VM64'
      : ''
  );
  const [showPlatformSelectError, setShowPlatformSelectError] = useState(false);

  const { setCmAccessors, cmAccessorRef } = useInitCodemirrorValidateAndSuggest(
    {
      setHoveredLine,
      setHoveredLineCoords,
      disableValidateAndSuggest,
    }
  );

  const [platformSyntax, isLoadingPlatformSyntax] = usePlatformSyntax({
    firmwareVersion: currentFirmware,
    platformName: selectedPlatform,
  });

  //change validator/suggestion if adom syntax, target, or platform changes
  useEffect(() => {
    if (!adomSyntax) return;
    if (
      scriptTarget === MACROS.DVM.DVM_SCRIPT_TARGET_DEVICE_DB ||
      scriptTarget === MACROS.DVM.DVM_SCRIPT_TARGET_DEVICE_REMOTE
    ) {
      validatorRef.current = new ScriptValidator(platformSyntax);
      suggestionProvider = new ScriptSuggestionProvider(platformSyntax);
    } else if (scriptTarget === MACROS.DVM.DVM_SCRIPT_TARGET_GLOBAL) {
      validatorRef.current = new ScriptValidator(adomSyntax);
      suggestionProvider = new ScriptSuggestionProvider(adomSyntax);
    }
  }, [adomSyntax, scriptTarget, platformSyntax]);

  const validateScript = useCallback(
    debounce(
      (
        scriptContent: string,
        fromButton: boolean = false,
        overrideClearErrors: boolean = false
      ) => {
        if (disableValidateAndSuggest || overrideClearErrors)
          return setErrors([]);
        if (
          scriptTarget !== MACROS.DVM.DVM_SCRIPT_TARGET_GLOBAL &&
          !selectedPlatform
        ) {
          setShowPlatformSelectError(true);
          if (fromButton)
            fiMessageBox.show(
              gettext('Please select a device platform before validating.'),
              BoxType.danger
            );
          return;
        }
        if (!validatorRef?.current?.parse) {
          if (fromButton)
            fiMessageBox.show(
              gettext('Failed to validate script, please try again.'),
              BoxType.danger
            );
          return;
        }
        const _errors = validatorRef.current.parse(
          scriptContent,
          handleMetaVariables,
          scriptTarget !== MACROS.DVM.DVM_SCRIPT_TARGET_GLOBAL
        );

        if (Array.isArray(_errors)) {
          setErrors(_errors);
          if (_errors.length) {
            if (fromButton)
              fiMessageBox.show(
                gettext('Script validated with errors.'),
                BoxType.warning
              );
            return { content: 'Script has errors' };
          }
        }

        if (fromButton)
          fiMessageBox.show(gettext('Script validated with no errors.'));
      },
      250
    ),
    [
      setErrors,
      handleMetaVariables,
      setShowPlatformSelectError,
      scriptTarget,
      selectedPlatform,
      disableValidateAndSuggest,
    ]
  );

  const autoFormatConfig = getAutoFormatConfig({
    cmAccessorRef,
    validateScript,
  });

  function replaceLineWithSuggestedCorrection() {
    if (!cmAccessorRef.current) return;
    const editor = cmAccessorRef.current.getCmInstance();
    const correction =
      errors.find((err: ErrorObj) => {
        return err.line === hoveredLine + 1 && err.correction;
      })?.correction || '';
    if (!correction) return;

    const lineStart = { line: hoveredLine, ch: 0 };
    const lineEnd = {
      line: hoveredLine,
      ch: editor.getLine(hoveredLine).length,
    };

    // Replace the selected line with the new string
    editor.replaceRange(correction, lineStart, lineEnd);

    // Move the cursor to the end of the replaced line
    editor.setCursor({ line: hoveredLine, ch: correction.length });
  }

  return {
    validateOnChange,
    setValidateOnChange,
    errors: [...errors, ...extraErrors],
    setErrors,
    setCmAccessors,
    hoveredLine,
    platformList,
    selectedPlatform,
    setSelectedPlatform,
    showPlatformSelectError,
    setShowPlatformSelectError,
    hoveredLineCoords,

    isLoadingValidation:
      scriptTarget !== MACROS.DVM.DVM_SCRIPT_TARGET_GLOBAL &&
      isLoadingPlatformSyntax,

    validateScript,
    replaceLineWithSuggestedCorrection,
    autoFormatConfig,
  };
};

const getAutoFormatConfig = ({
  cmAccessorRef,
  validateScript,
}: {
  cmAccessorRef: any;
  validateScript: DebouncedFunc<
    (scriptContent: string) =>
      | {
          content: string;
        }
      | undefined
  >;
}) => {
  /*
  Autoformat script:
  1. Format by checking 'config', 'edit', 'next', 'end' commands
  2. Revalidate script after formatting
  We ignore all errors, including unclosed contexts so we can always format
  */
  const autoFormatConfig = {
    exec: () => {
      if (!cmAccessorRef.current) return;
      const cm = cmAccessorRef.current.getCmInstance();
      const scriptContent = cm.getValue();

      const scriptLines = scriptContent.split('\n');
      const completeScriptLines = [];
      let indents = 0;
      const commandContextStack: string[] = [];

      const getLastCommandFromStack = () => {
        return commandContextStack.length
          ? commandContextStack[commandContextStack.length - 1]
          : '';
      };

      const commandHandlers = {
        edit: () => {
          //can't call edit in edit
          if (getLastCommandFromStack() === 'edit') indents = -1;
          indents++;
          commandContextStack.push('edit');
        },
        config: () => {
          indents++;
          commandContextStack.push('config');
        },

        next: () => {
          //can only use next in edit
          if (getLastCommandFromStack() !== 'edit') indents = -1;
          indents--;
          commandContextStack.pop();
        },
        end: () => {
          if (getLastCommandFromStack() === 'edit') {
            indents -= 2;
            commandContextStack.pop();
            commandContextStack.pop();
          } else {
            indents--;
            commandContextStack.pop();
          }
        },
      };

      for (const line of scriptLines) {
        const splitLine = line.trim().split(/[\s\t\r\n\f]/);
        const cleanedLine = splitLine.join(' ');

        if (indents < 0) {
          completeScriptLines.push(line);
          continue;
        }

        if (cleanedLine.startsWith('next')) {
          commandHandlers.next();
        } else if (cleanedLine.startsWith('end')) {
          commandHandlers.end();
        }

        if (indents < 0) {
          completeScriptLines.push(line);
          continue;
        }
        const newLine =
          MACROS.USER.SYS.INDENT_4SPACES.repeat(indents) + cleanedLine;
        completeScriptLines.push(newLine);

        if (cleanedLine.startsWith('edit')) {
          commandHandlers.edit();
        } else if (cleanedLine.startsWith('config')) {
          commandHandlers.config();
        }
      }
      if (indents !== 0) {
        fiMessageBox.show(
          gettext('Format completed partially with errors'),
          BoxType.warning
        );
      }

      cm.setValue(completeScriptLines.join('\n'));
      validateScript(completeScriptLines.join('\n'));
    },
    text: gettext('Format CLI script'),
  };

  return autoFormatConfig;
};

const useInitCodemirrorValidateAndSuggest = ({
  setHoveredLine,
  setHoveredLineCoords,
  disableValidateAndSuggest,
}: {
  setHoveredLine: React.Dispatch<React.SetStateAction<number>>;
  setHoveredLineCoords: React.Dispatch<
    React.SetStateAction<{
      x: number;
      y: number;
    }>
  >;
  disableValidateAndSuggest: boolean;
}) => {
  const [cmAccessorState, setCmAccessors, cmAccessorRef] = useStateRef({});
  const initedRef = useRef<boolean>(false);

  useEffect(() => {
    /*
    Get hints to display while typing

    parse script for suggestions every time getHints is called
    getHints is called while typing, after selecting a suggestion, or on press the key '?'
    */

    //extremely hacky solution to fit the hint popup
    const intervalId = setInterval(() => {
      const hintEl: any = document.querySelector('.CodeMirror-hints');
      if (!cmInstance) return;
      if (hintEl) {
        const cmTextareaRect = cmInstance.display.sizer.getBoundingClientRect();
        // const bodyRect = document.body.getBoundingClientRect();

        hintEl.style.maxWidth = `${Math.round(cmTextareaRect.width)}px`;
        setTimeout(() => {
          const cmCursorRect =
            cmInstance.display.cursorDiv.children[0].getBoundingClientRect();

          const hintElRect = hintEl.getBoundingClientRect();

          if (cmCursorRect.left > hintElRect.right) {
            hintEl.style.left = `${
              hintElRect.left + cmCursorRect.left - hintElRect.right
            }px`;
          }
        }, 0);
      }
    }, 0);

    //next attach event handlers to cm instance
    const cmInstance = cmAccessorRef.current?.getCmInstance?.();
    if (disableValidateAndSuggest) {
      //if disabled, remove event handlers
      if (!cmInstance)
        return () => {
          clearInterval(intervalId);
        };

      cmInstance.off('keyup', onKeyup);
      cmInstance.off('change', onChange);
      initedRef.current = false;
      return () => {
        clearInterval(intervalId);
      };
    }
    if (!cmInstance)
      return () => {
        clearInterval(intervalId);
      };

    if (initedRef.current)
      return () => {
        clearInterval(intervalId);
      };

    //check event key to show suggestions
    cmInstance.on('keyup', onKeyup);

    // Listen for completion change event to show hints again
    cmInstance.on('change', onChange);

    cmInstance
      .getWrapperElement()
      .addEventListener('mouseover', function (event: any) {
        const pos = cmInstance.coordsChar({
          left: event.clientX,
          top: event.clientY,
        });
        const line = pos.line;
        const cmRect = cmInstance.display.wrapper.getBoundingClientRect();

        let marks = [];

        if (pos.ch === 0) {
          const allMarks = cmInstance.getAllMarks();
          const markForLine = allMarks.find((mark: any) => {
            return mark.__annotation.from.line === line;
          });
          marks = markForLine ? [markForLine] : [];
        } else {
          marks = cmInstance.findMarksAt(pos);
        }

        if (!marks.length) {
          setHoveredLine(-1);
          return;
        }

        setHoveredLine(line);
        const gutterMarker = get(
          marks[0],
          'lines.0.gutterMarkers.CodeMirror-lint-markers'
        );
        if (!gutterMarker) return;
        const rect = gutterMarker.getBoundingClientRect();
        setHoveredLineCoords({
          x: cmRect.x,
          y: rect.y,
        });
      });

    //? is reserved for suggestions, cannot type ?
    cmInstance
      .getWrapperElement()
      .addEventListener('keydown', function (e: any) {
        if (e.key === '?') {
          e.preventDefault();
        }
      });

    initedRef.current = true;

    return () => {
      clearInterval(intervalId);
    };
  }, [cmAccessorState, disableValidateAndSuggest]);

  return {
    setCmAccessors,
    cmAccessorRef,
  };
};

//same logic as add device wizard model device list
const useDevicePlatformList = () => {
  const [osType] = useOsType();
  const {
    state: { currentFirmware },
  } = useCurrentFirmware({ osType });

  const { state: allModelsStruct } = useAllModelStruct({
    osType,
    firmwareVersion: currentFirmware,
    usePreviousMr: false,
  });

  return {
    platformList: useMemo(() => allModelsStruct.list ?? [], [allModelsStruct]),
    currentFirmware,
  };
};

//firmwareversion e.g. "7.4"
export const usePlatformSyntax = ({
  platformName,
  firmwareVersion,
}: {
  platformName: string;
  firmwareVersion: string;
}) => {
  const { state: deviceSyntax, isLoading: isLoadingPlatformSyntax } =
    useApiRequest({
      defaultValue: {},
      loader: () => {
        if (!firmwareVersion) return {};
        const [ver, mr] = firmwareVersion.split('.');
        const majorVer = parseInt(ver) * 100;
        return fiFmgHttp.query(
          {
            method: 'get',
            params: [
              {
                url: `/pm/config/devicetemplate/${platformName}/version/${majorVer}/mr/${mr}/global/`,
                option: 'syntax',
              },
            ],
          },
          undefined
        );
      },
      parser: (resp: any) => {
        return get(resp, '0.data', {});
      },
      dependencies: [platformName, firmwareVersion],
    });

  return [deviceSyntax, isLoadingPlatformSyntax];
};

function showHints(cmInstance: any) {
  CodeMirror.showHint(cmInstance, getHints, {
    selectOnPick: true,
    completeSingle: false,
    extraKeys: {
      Tab: function (cm: any, handle: any) {
        // rotate to next hint
        handle.moveFocus(1, false);
      },
      Enter: function (cm: any, handle: any) {
        // allow Enter to pick the selected hint
        handle.pick();
      },
    },
  });
}

function getHints(editor: any) {
  const suggestions = parseSuggestions(editor);
  const cursor = editor.getCursor();

  const lineContent: string = editor.getLine(cursor.line).slice(0, cursor.ch);
  const trimmedContent = lineContent.trim();
  const isConfigLine = trimmedContent.startsWith('config');

  // Check if the last character is a space, so we append suggestion after it
  const appendAfterSpace =
    lineContent.endsWith(' ') || lineContent.endsWith('\t');
  let startCh = appendAfterSpace
    ? cursor.ch
    : (lineContent.endsWith(' ')
        ? lineContent.lastIndexOf(' ')
        : lineContent.lastIndexOf('\t')) + 1;

  //get start ch for suggestion replacement
  const tokens = trimmedContent.split(' ');
  let latestWord = '';
  if (isConfigLine) {
    //set start of section to replace at after config + space ("config ")
    latestWord = tokens
      .filter((char: string) => char.trim())
      .slice(1)
      .join(' ');
    startCh = lineContent.indexOf('config') + 7;
  } else {
    const words = lineContent.split(/\s/);
    if (!words.length) {
      startCh = cursor.ch;
    } else {
      latestWord = words[words.length - 1].trim();
      //set start of section to replace at latest partially-typed word
      startCh = lineContent.lastIndexOf(latestWord);
    }
  }

  let resultHints: string[] = [];
  if (latestWord) {
    //fuzzy search all possible suggestions for current context if there is a partial word
    resultHints = fuzzySearchStringArray(Object.keys(suggestions), latestWord);
  } else {
    resultHints = Object.keys(suggestions);
  }

  return {
    list: resultHints.map((suggestion: string) => ({
      text: suggestion + ' ', //add space after selecting suggestion
      displayText: suggestions[suggestion].required
        ? suggestion + '*'
        : suggestion,
    })),
    from: CodeMirror.Pos(cursor.line, startCh),
    to: CodeMirror.Pos(cursor.line, cursor.ch),
    selectedHint: 0,
  };
}

//get hints for current cursor location
function parseSuggestions(cm: any) {
  const cursor = cm.getCursor();
  const line = cursor.line;
  const scriptContent = cm.getValue();
  if (suggestionProvider) {
    const _hints = suggestionProvider.parse(scriptContent, line, cursor.ch);
    return _hints;
  }
  return {};
}

function onKeyup(cm: any, event: any) {
  const key = event.key.toLowerCase();
  if (key.length !== 1) {
    return;
  }

  //show suggestions while typing for the following keys:
  const isLetter = key >= 'a' && key <= 'z';
  const isNumber = key >= '0' && key <= '9';
  const isSymbol = ['-', '_', ' ', '?'].includes(key);

  if (isLetter || isNumber || isSymbol) {
    event.preventDefault(); // Prevent default behavior
    showHints(cm);
    // Show hints
  }
}

function onChange(cm: any, changeObj: any) {
  if (changeObj.origin == 'complete') {
    showHints(cm);
  }
}
