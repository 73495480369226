import { fiMessageBox } from 'fi-messagebox';
import { escapeHtml } from 'kit-escape';
import { isNumber, get } from 'lodash';
import {
  openConfirmModal,
  makeConfirmModalRenderFn,
  OkBtn,
  CancelBtn,
} from 'rc_layout';
import { lockOverride } from './common';
import { _currentAdom, getLockTimeStr } from './util';
import { wsConnector } from './wsconnector';
import { ProForm } from '@fafm/neowise-pro';
const { Header, Body, Row, Column, Footer } = ProForm;

export function _doAdomLock(adomObj) {
  return new Promise((resolve, reject) => {
    wsConnector.adomLock(adomObj).then(
      () => {
        resolve();
      },
      (err) => {
        // need to show details of errors here in popup
        let data = err.data || {};
        if (data.dirty) {
          _confirmAdomLockWithDirty(adomObj, data.dirty).then(
            () => resolve(),
            () => reject()
          );
        } else if (data.locked_by_other) {
          _confirmAdomLockWithPreempt(adomObj, data.locked_by_other).then(
            () => resolve(),
            () => reject()
          );
        } else {
          reject(err);
        }
      }
    );
  });
}

export function _doAdomUnlock(adomObj, unlock_dirty, unlock_other) {
  return new Promise((resolve, reject) => {
    wsConnector.adomUnlock(adomObj, unlock_dirty, unlock_other).then(
      () => {
        resolve();
      },
      (err) => {
        let data = err.data || {};
        if (data.dirty) {
          _confirmAdomUnlockWithDirty(adomObj, data.dirty).then(
            () => resolve(),
            () => reject()
          );
        } else {
          reject();
        }
      }
    );
  });
}

export function _confirmAdomLockWithPreempt(adomObj, others) {
  const renderFn = ($opener) => {
    let info = _summarizeInfo(others);
    const preempt = !!lockOverride(adomObj);

    return (
      <>
        <Header>{gettext('Lock ADOM Confirmation')}</Header>
        <Body>
          <Row>
            <Column>
              {gettext('Some resource was locked by other administrators.')}
            </Column>
          </Row>
          {preempt && (
            <Row>
              <Column>
                {gettext(
                  'If continuing to lock ADOM will cause others changes lost.'
                )}
              </Column>
            </Row>
          )}
          <Row>
            <Column>
              {
                <ul>
                  {Object.keys(info).map((prop, idx) => {
                    let sz = info[prop].length;
                    let data = get(info, [prop, idx], {});
                    let timeStr = isNumber(data.lock_time)
                      ? getLockTimeStr(data)
                      : data.lock_time;
                    if (sz == 1) {
                      return (
                        <li key={idx}>
                          {gettext(
                            'There is %(size)s %(type)s locked by %(admin)s since %(time)s.'
                          ).printfd({
                            size: sz,
                            admin: prop,
                            time: timeStr,
                            type: _getLockResourceType(data.type),
                          })}
                        </li>
                      );
                    }

                    return (
                      <li key={idx}>
                        {gettext(
                          'There are %(size)s resources locked by %(admin)s since %(time)s.'
                        ).printfd({
                          size: sz,
                          admin: prop,
                          time: timeStr,
                        })}
                      </li>
                    );
                  })}
                </ul>
              }
            </Column>
          </Row>
        </Body>
        <Footer>
          {preempt && (
            <OkBtn
              onClick={(e) => {
                e.preventDefault();
                wsConnector.adomUnlock(adomObj, true, true).then(() => {
                  wsConnector
                    .adomLock(adomObj)
                    .then(() => $opener.resolve(), _showError);
                }, _showError);
              }}
              automation-id={'confirm-modal-lock-anyway-btn'}
            >
              {gettext('Lock Anyway')}
            </OkBtn>
          )}
          {!preempt && (
            <OkBtn
              onClick={(e) => {
                e.preventDefault();
                $opener.resolve();
              }}
              automation-id={'confirm-modal-ok-btn'}
            >
              {gettext('OK')}
            </OkBtn>
          )}
          <CancelBtn
            onClick={(e) => {
              e.preventDefault();
              $opener.reject();
            }}
            automation-id={'confirm-modal-cancel-btn'}
          >
            {gettext('Cancel')}
          </CancelBtn>
        </Footer>
      </>
    );
  };

  return openConfirmModal({
    renderer: renderFn,
  });
}

export function _confirmAdomLockWithDirty(adomObj, dirty) {
  const renderFn = ($opener) => {
    let info = _summarizeInfo(dirty, 'dirty');
    let devCnt = 0;

    return (
      <>
        <Header>{gettext('Lock ADOM Confirmation')}</Header>
        <Body>
          <Row>
            <Column>{gettext('There are some unsaved changes.')}</Column>
          </Row>
          <Row>
            <Column>
              {Object.keys(info).map((prop, idx) => {
                return (
                  <ul key={idx}>
                    {info[prop].map((it, i) => {
                      if (it.type == MACROS.SYS.WORKSPACE_DEVICE) {
                        devCnt++;
                      } else {
                        let tag =
                          it.type == MACROS.SYS.WORKSPACE_PKG_CATE_SEQUENCE
                            ? gettext('Sequence')
                            : gettext('Content');
                        return (
                          <li key={i}>
                            [{tag}]{' '}
                            {gettext(
                              '"%(name)s", by %(user)s since %(time)s.'
                            ).printfd({
                              name: escapeHtml(it.pkg_name),
                              time: getLockTimeStr(it),
                              user: prop,
                            })}
                          </li>
                        );
                      }
                    })}

                    {!!devCnt && (
                      <li>
                        {devCnt == 1
                          ? gettext(
                              'There is one device configuration changed by %(user)s.'
                            ).printfd({ user: prop })
                          : gettext(
                              'There are %(count)s devices configuration changed by %(user)s.'
                            ).printfd({ count: devCnt, user: prop })}
                      </li>
                    )}
                  </ul>
                );
              })}
            </Column>
          </Row>
        </Body>
        <Footer>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector.adomSave(adomObj).then(() => {
                _doAdomLock(adomObj).then(() => $opener.resolve(), _showError);
              }, _showError);
            }}
            automation-id={'confirm-modal-save-n-lock-btn'}
          >
            {gettext('Save and Lock')}
          </OkBtn>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector.adomUnlock(adomObj, true, true).then(() => {
                _doAdomLock(adomObj).then(() => $opener.resolve(), _showError);
              }, _showError);
            }}
            automation-id={'confirm-modal-discard-n-lock-btn'}
          >
            {gettext('Discard and Lock')}
          </OkBtn>
          <CancelBtn
            onClick={(e) => {
              e.preventDefault();
              $opener.reject();
            }}
            automation-id={'confirm-modal-cancel-btn'}
          >
            {gettext('Cancel')}
          </CancelBtn>
        </Footer>
      </>
    );
  };

  return openConfirmModal({
    renderer: renderFn,
  });
}

export function _doPkgLock(pkgObj, adomObj = _currentAdom()) {
  return new Promise((resolve, reject) => {
    wsConnector.pkgLock(pkgObj, adomObj).then(
      () => {
        resolve();
      },
      (err) => {
        let data = err.data || {};
        if (data.dirty) {
          _confirmPackageLockWithDirty(data.dirty, pkgObj, adomObj).then(
            () => resolve(),
            () => reject()
          );
        } else if (data.locked_by_other) {
          _confirmPackageLockWithPreempt(
            data.locked_by_other,
            pkgObj,
            adomObj
          ).then(
            () => resolve(),
            () => reject()
          );
        } else {
          reject(err);
        }
      }
    );
  });
}

export function _doPkgUnlock(pkgObj, adomObj = _currentAdom()) {
  return new Promise((resolve, reject) => {
    wsConnector.pkgUnlock(pkgObj, false, false, adomObj).then(
      () => {
        resolve();
      },
      (err) => {
        let data = err.data || {};

        if (data.dirty) {
          _confirmPackageUnlockWithDirty(data.dirty, pkgObj, adomObj).then(
            () => resolve(),
            () => reject()
          );
        } else {
          reject(err);
        }
      }
    );
  });
}

export function _confirmPackageLockWithPreempt(others, pkgObj, adomObj) {
  let info = _summarizeInfo(others);
  const preempt = !!lockOverride(adomObj);

  let title = gettext('Lock Policy Package Confirmation');
  const RowRender = ({ admin, data }) => {
    let single = {};

    return (
      <p>
        {data.map((it, idx) => {
          if (it.type == MACROS.SYS.WORKSPACE_PKG) {
            return (
              <div key={idx}>
                {gettext(
                  'Package "%(pkg)s" was locked by %(admin)s since %(time)s.'
                ).printfd({
                  pkg: it.pkg_name,
                  admin: admin,
                  time: getLockTimeStr(it),
                })}
              </div>
            );
          } else {
            // Handle other type [WORKSPACE_PKG_CATE, WORKSPACE_PKG_CATE_SEQUENCE, WORKSPACE_PKG_CATE_RECORD];
            if (!single[admin]) {
              single[admin] = true;

              return (
                <div key={idx}>
                  {gettext(
                    'Package "%(pkg)s" was changed by %(admin)s since %(time)s.'
                  ).printfd({
                    pkg: it.pkg_name,
                    admin: admin,
                    time: getLockTimeStr(it),
                  })}
                </div>
              );
            }
          }
        })}
      </p>
    );
  };
  const content = () => {
    return (
      <>
        {Object.keys(info).map((it, idx) => {
          return <RowRender admin={it} data={info[it]} key={idx} />;
        })}
        {preempt && (
          <div>
            {gettext(
              'If continuing to lock this package will cause others changes lost.'
            )}
          </div>
        )}
      </>
    );
  };
  return preempt
    ? openConfirmModal({
        renderer: makeConfirmModalRenderFn({
          title,
          content,
          okLabel: gettext('Lock Anyway'),
          onOkClick: ($opener) => {
            wsConnector.pkgUnlock(pkgObj, true, true, adomObj).then(() => {
              wsConnector
                .pkgLock(pkgObj, adomObj)
                .then(() => $opener.resolve(), _showError);
            }, _showError);
          },
        }),
      })
    : openConfirmModal({
        title,
        content,
        buttons: ['ok'],
      });
}

export function _confirmPackageLockWithDirty(dirty, pkgObj, adomObj) {
  const renderFn = ($opener) => {
    let info = _summarizeInfo(dirty, 'dirty');

    return (
      <>
        <Header>{gettext('Lock Package Confirmation')}</Header>
        <Body>
          <Row>
            <Column>{gettext('There are some unsaved changes.')}</Column>
          </Row>
          {Object.keys(info).map((prop, idx) => {
            return (
              <Row key={idx}>
                <Column>
                  <ul>
                    {info[prop].map((it, i) => {
                      let tag =
                        it.type == MACROS.SYS.WORKSPACE_PKG_CATE_SEQUENCE
                          ? gettext('Sequence')
                          : gettext('Content');
                      return (
                        <li key={i}>
                          [{tag}]{' '}
                          {gettext('"%(name)s", since %(time)s.').printfd({
                            name: escapeHtml(it.pkg_name),
                            time: getLockTimeStr(it),
                          })}
                        </li>
                      );
                    })}
                  </ul>
                </Column>
              </Row>
            );
          })}
        </Body>
        <Footer>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector.pkgSave(pkgObj, adomObj).then(() => {
                _doPkgLock(pkgObj, adomObj).then(
                  () => $opener.resolve(),
                  _showError
                );
              }, _showError);
            }}
            automation-id={'confirm-modal-save-n-lock-btn'}
          >
            {gettext('Save and Lock')}
          </OkBtn>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector.pkgUnlock(pkgObj, true, false, adomObj).then(() => {
                _doPkgLock(pkgObj, adomObj).then(
                  () => $opener.resolve(),
                  _showError
                );
              }, _showError);
            }}
            automation-id={'confirm-modal-discard-n-lock-btn'}
          >
            {gettext('Discard and Lock')}
          </OkBtn>
          <CancelBtn
            onClick={(e) => {
              e.preventDefault();
              $opener.reject();
            }}
            automation-id={'confirm-modal-cancel-btn'}
          >
            {gettext('Cancel')}
          </CancelBtn>
        </Footer>
      </>
    );
  };

  return openConfirmModal({
    renderer: renderFn,
  });
}

export function _confirmAdomUnlockWithDirty(adomObj /*dirty = []*/) {
  const renderFn = ($opener) => {
    return (
      <>
        <Header>{gettext('Unlock ADOM Confirmation')}</Header>
        <Body>
          <Row>
            <Column>{gettext('There are some unsaved changes.')}</Column>
          </Row>
        </Body>
        <Footer>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector.adomSave(adomObj).then(() => {
                wsConnector
                  .adomUnlock(adomObj)
                  .then(() => $opener.resolve(), _showError);
              }, _showError);
            }}
            automation-id={'confirm-modal-save-n-unlock-btn'}
          >
            {gettext('Save and Unlock')}
          </OkBtn>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector
                .adomUnlock(adomObj, true, true)
                .then(() => $opener.resolve(), _showError);
            }}
            automation-id={'confirm-modal-discard-n-lock-btn'}
          >
            {gettext('Discard and Unlock')}
          </OkBtn>
          <CancelBtn
            onClick={(e) => {
              e.preventDefault();
              $opener.reject();
            }}
            automation-id={'confirm-modal-cancel-btn'}
          >
            {gettext('Cancel')}
          </CancelBtn>
        </Footer>
      </>
    );
  };

  return openConfirmModal({
    renderer: renderFn,
  });
}

// eslint-disable-next-line
export function _confirmPackageUnlockWithDirty(dirty, pkgObj, adomObj) {
  const renderFn = ($opener) => {
    return (
      <>
        <Header>{gettext('Unlock Package Confirmation')}</Header>
        <Body>
          <Row>
            <Column>
              {gettext('Package "%s" has unsaved changes.').printf([
                pkgObj.name,
              ])}
            </Column>
          </Row>
          <Row>
            <Column>
              {gettext('Please choose one of the following options.')}
            </Column>
          </Row>
        </Body>
        <Footer>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector.pkgSave(pkgObj, adomObj).then(() => {
                wsConnector
                  .pkgUnlock(pkgObj, false, false, adomObj)
                  .then(() => $opener.resolve(), _showError);
              }, _showError);
            }}
            automation-id={'confirm-modal-save-n-unlock-btn'}
          >
            {gettext('Save and Unlock')}
          </OkBtn>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector
                .pkgUnlock(pkgObj, true, false, adomObj)
                .then(() => $opener.resolve(), _showError);
            }}
            automation-id={'confirm-modal-discard-n-unlock-btn'}
          >
            {gettext('Discard and Unlock')}
          </OkBtn>
          <CancelBtn
            onClick={(e) => {
              e.preventDefault();
              $opener.reject();
            }}
            automation-id={'confirm-modal-cancel-btn'}
          >
            {gettext('Cancel')}
          </CancelBtn>
        </Footer>
      </>
    );
  };

  return openConfirmModal({
    renderer: renderFn,
  });
}

export function _doDevLock(dev, adomObj) {
  return new Promise((resolve, reject) => {
    wsConnector.devLock(dev, adomObj).then(
      () => {
        resolve();
      },
      (err) => {
        let data = err.data || {};

        if (data.locked_by_other) {
          _confirmDevLockWithPreempt(dev, data.locked_by_other, adomObj).then(
            () => resolve(),
            () => reject()
          );
        } else {
          reject(err);
        }
      }
    );
  });
}

export function _doDevUnlock(dev, adomObj) {
  return new Promise((resolve, reject) => {
    wsConnector.devUnlock(dev, false, false, adomObj).then(
      () => {
        resolve();
      },
      (err) => {
        let data = err.data || {};

        if (data.dirty) {
          _confirmDevUnlockWithDirty(data.dirty, dev, adomObj).then(
            () => resolve(),
            () => reject()
          );
        } else {
          reject(err);
        }
      }
    );
  });
}

export function _confirmDevLockWithPreempt(devObj, others, adomObj) {
  let info = _summarizeDevInfo(others);
  const preempt = !!lockOverride(adomObj);

  const renderFn = ($opener) => {
    return (
      <>
        <Header>
          {gettext('Lock Device Confirmation')} {devObj.name}
        </Header>
        <Body>
          <Row>
            <Column>
              {gettext('Device was locked by other administrators.')}
            </Column>
          </Row>
          {preempt && (
            <Row>
              <Column>
                {gettext(
                  'If continuing to lock this device will cause others changes lost.'
                )}
              </Column>
            </Row>
          )}
          <Row>
            <Column>
              <ul>
                {Object.keys(info).map((prop, idx) => {
                  return (
                    <li key={idx}>
                      {gettext(
                        'This device was locked by %(admin)s since %(time)s.'
                      ).printfd({
                        admin: prop,
                        time: getLockTimeStr(info[prop][0]),
                      })}
                    </li>
                  );
                })}
              </ul>
            </Column>
          </Row>
        </Body>
        <Footer>
          {preempt && (
            <OkBtn
              onClick={(e) => {
                e.preventDefault();
                wsConnector.devUnlock(devObj, true, true).then(() => {
                  wsConnector
                    .devLock(devObj)
                    .then(() => $opener.resolve(), _showError);
                }, _showError);
              }}
              automation-id={'confirm-modal-lock-anyway-btn'}
            >
              {gettext('Lock Anyway')}
            </OkBtn>
          )}
          <CancelBtn
            onClick={(e) => {
              e.preventDefault();
              $opener.reject();
            }}
            automation-id={'confirm-modal-cancel-btn'}
          >
            {preempt ? gettext('Cancel') : gettext('OK')}
          </CancelBtn>
        </Footer>
      </>
    );
  };

  return openConfirmModal({
    renderer: renderFn,
  });
}

// eslint-disable-next-line
export function _confirmDevUnlockWithDirty(dirty, devObj, adomObj) {
  const renderFn = ($opener) => {
    return (
      <>
        <Header>{gettext('Unlock Device Confirmation')}</Header>
        <Body>
          <Row>
            <Column>
              {gettext('Device "%s" has unsaved changes.').printf([
                devObj.name,
              ])}
            </Column>
          </Row>
          <Row>
            <Column>
              {gettext('Please choose one of the following options.')}
            </Column>
          </Row>
        </Body>
        <Footer>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector.devSave(devObj, adomObj).then(() => {
                wsConnector
                  .devUnlock(devObj, false, false, adomObj)
                  .then(() => $opener.resolve(), _showError);
              }, _showError);
            }}
            automation-id={'confirm-modal-save-n-unlock-btn'}
          >
            {gettext('Save and Unlock')}
          </OkBtn>
          <OkBtn
            onClick={(e) => {
              e.preventDefault();
              wsConnector
                .devUnlock(devObj, true, false, adomObj)
                .then(() => $opener.resolve(), _showError);
            }}
            automation-id={'confirm-modal-discard-n-unlock-btn'}
          >
            {gettext('Discard and Unlock')}
          </OkBtn>
          <CancelBtn
            onClick={(e) => {
              e.preventDefault();
              $opener.reject();
            }}
            automation-id={'confirm-modal-cancel-btn'}
          >
            {gettext('Cancel')}
          </CancelBtn>
        </Footer>
      </>
    );
  };

  return openConfirmModal({
    renderer: renderFn,
  });
}

export const showWorkspaceLockError = _showError;

//========== internal functions ===========
function _showError(err) {
  err &&
    fiMessageBox.show(
      // lock ADOM or another pkg after locking a pkg block
      err.data?.action?.[0]?.message ||
        err.status.message ||
        gettext('Unknown error %s').printf([err.status.code]),
      'danger',
      0
    );
}

function _summarizeInfo(data, cond) {
  let info = {};

  data?.forEach((it) => {
    if (cond && !it[cond]) return;
    info[it.lock_user] = info[it.lock_user] || [];

    if (it.type == MACROS.SYS.WORKSPACE_DEVICE) {
      info[it.lock_user].push({
        lock_time: getLockTimeStr(it),
        oid: it.oid,
        name: it.name,
        type: it.type,
      });
    } else {
      info[it.lock_user].push({
        lock_time: getLockTimeStr(it),
        pkg_oid: it.pkg_oid,
        pkg_name: it.pkg_name || it.name,
        type: it.type,
      });
    }
  });

  return info;
}

function _summarizeDevInfo(devs, cond) {
  let info = {};
  devs.forEach((it) => {
    if (cond && !it[cond]) return;
    info[it.lock_user] = info[it.lock_user] || [];
    info[it.lock_user].push({
      lock_time: getLockTimeStr(it),
      oid: it.oid,
      name: it.name,
      type: it.type,
    });
  });

  return info;
}

function _getLockResourceType(type) {
  switch (type) {
    case MACROS.SYS.WORKSPACE_ADOM:
      return 'ADOM';
    case MACROS.SYS.WORKSPACE_DEVICE:
      return gettext('device');
    case MACROS.SYS.WORKSPACE_PKG:
      return gettext('package');
    case MACROS.SYS.WORKSPACE_PKG_CATE:
      return gettext('policy');
    case MACROS.SYS.WORKSPACE_PKG_CATE_SEQUENCE:
      return gettext('policy');
    case MACROS.SYS.WORKSPACE_PKG_CATE_RECORD:
      return gettext('policy');
    case MACROS.SYS.WORKSPACE_CATE_OBJECT:
      return gettext('object');
    default:
      return gettext('resource');
  }
}
