import { useCallback, useMemo, useRef } from 'react';

const buildFullMap = (all, getOid, isVdom) => {
  const result = {};

  function addToMap(row) {
    const did = getOid(row);
    if (!result[did]) {
      result[did] = {
        data: null,
        child: [],
      };
    }
    if (!isVdom(row)) {
      result[did].data = row;
    } else {
      result[did].child.push(row);
    }
  }

  for (let row of all) {
    addToMap(row);
    if (row.children) {
      row.children.forEach(addToMap);
    }
  }
  return result;
};

export const useDeviceTableSelect = (
  devices,
  rowKey,
  selectedIds,
  fixed_id = [],
  getOid = (row) => {
    return row._oData.isGrp ? row._oData.oid : row._oData.did;
  },
  isVdom = (row) => {
    return !(row._oData._isDevice || row._oData.isGrp);
  }
) => {
  const ignoreCallBack = useRef(false);

  const devIdMap = useMemo(() => {
    const result = {};
    devices.forEach((r) => {
      result[r[rowKey]] = r;
      if (r.children) {
        r.children.forEach((child) => (result[child[rowKey]] = child));
      }
    });
    return result;
  }, [devices]);
  const fullDevMap = useMemo(() => {
    return buildFullMap(devices, getOid, isVdom);
  }, [devices]);

  const selectedIdNotInFilteredRows = useRef([]);

  const beforeSelect = useCallback(
    ({ rows, isSelect, instance }) => {
      if (ignoreCallBack.current) {
        return [...instance.select.getSelectedRows()];
      }
      let [add, remove] = [rows, []];
      if (!isSelect) {
        [add, remove] = [remove, add];
      }

      const _devMap = {};
      const selectedRows = Array.from(
        new Set([
          ...instance.select.getSelectedRows(),
          ...fixed_id.map((r) => ({ [rowKey]: r })),
        ])
      );

      const selectedRowsData = selectedRows.map((r) => devIdMap[r[rowKey]]);

      selectedRowsData.forEach((row) => {
        const did = getOid(row);
        if (!_devMap[did]) {
          _devMap[did] = {
            data: null,
            child: [],
          };
        }
        if (!isVdom(row)) {
          _devMap[did].data = row;
        } else {
          _devMap[did].child.push(row);
        }
      });

      remove.forEach((row) => {
        if (fixed_id.includes(row[rowKey])) return;
        row = devIdMap[row[rowKey]];
        const did = getOid(row);
        if (!isVdom(row)) {
          delete _devMap[did];
        } else if (_devMap[did]) {
          _devMap[did].child = _devMap[did].child.filter(
            (vd) => vd[rowKey] !== row[rowKey]
          );
          if (!_devMap[did].child.length) delete _devMap[did];
        }
      });

      add.forEach((row) => {
        row = devIdMap[row[rowKey]];
        const did = getOid(row);
        if (!isVdom(row)) {
          _devMap[did] = {
            data: fullDevMap[did].data,
            child: fullDevMap[did].child,
          };
        } else {
          if (!_devMap[did]) {
            _devMap[did] = {
              data: fullDevMap[did].data,
              child: [row],
            };
          } else {
            _devMap[did].child.push(row);
          }
        }
      });
      let newRows = [];
      for (let entry of Object.values(_devMap)) {
        newRows.push(entry.data);
        newRows = [...newRows, ...entry.child];
      }
      return [
        ...newRows,
        ...selectedIdNotInFilteredRows.current.map((d) => ({ [rowKey]: d })),
      ];
    },
    [selectedIds, fullDevMap]
  );
  const onFilterRows = useCallback(
    (rows) => {
      const filtered = new Set(rows.map((d) => d[rowKey]));
      const currentSet = new Set(selectedIds);
      const result = [];

      function addToResult(row) {
        if (currentSet.has(row[rowKey]) && !filtered.has(row[rowKey])) {
          result.push(row[rowKey]);
        }
      }

      for (let row of devices) {
        addToResult(row);
        if (row.children) {
          row.children.forEach(addToResult);
        }
      }
      selectedIdNotInFilteredRows.current = result;
    },
    [selectedIds]
  );

  return {
    devIdMap,
    ignoreCallBack,
    onFilterRows,
    beforeSelect,
    selectedIdNotInFilteredRows,
  };
};
