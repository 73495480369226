import { useRef, useState, useMemo, useEffect } from 'react';
import renderTerminal, {
  getDefaultActions,
  getDefaultAddons,
} from './Terminal';
import fiWebsocketLocalAddon from '../../terminal/fiWebSocketLocalAddon';
import { renderDropdown } from './util';
import { ProForm } from '@fafm/neowise-pro';
import { fiWebSocket } from 'fi-websocket';
import { useMatches } from 'react-router-dom';
import { findLast } from 'lodash';

export default function Terminal({ $opener, initialCommands }) {
  const instance = useRef({}).current;

  const numOfTerminalRendered = useComponentCount();

  const formatLabel = (label) => {
    return numOfTerminalRendered === 0
      ? label
      : numOfTerminalRendered + '. ' + label;
  };

  const [label, setLabel] = useState(() => {
    return formatLabel(gettext('CLI Console'));
  });

  const routeMatches = useMatches();
  const currentPageCLI = findLast(routeMatches, (match) => {
    return match?.handle?.cli;
  });

  const initAddons = () => {
    const addons = {
      ...getDefaultAddons(instance, { $opener }),
      websocket: new fiWebsocketLocalAddon({
        fiWebSocket: fiWebSocket,
        initialCommands,
      }),
    };

    addons.history.addCommandListenr((command) => {
      setLabel(formatLabel(command.replace(/(.{20})..+/, '$1…')));
    });

    return addons;
  };

  const renderHeader = () => {
    const getAutoId = (suffix) => 'terminal:' + suffix;

    const {
      clear_console,
      copy_history,
      record_command,
      download_history,
      cli_current_page,
      ...rest
    } = getDefaultActions(instance, {
      getAutoId,
      getCLI: () => currentPageCLI?.handle.cli,
    });

    return (
      <ProForm.Header
        actionSlot={
          <ProForm.HeaderActions>
            {[clear_console, copy_history, record_command, download_history]
              .map((icon) => (
                <nw-icon-button key={icon.key} {...icon}></nw-icon-button>
              ))
              .concat(
                renderDropdown(Object.values(rest).concat(cli_current_page), {
                  'automation-id': getAutoId('more'),
                })
              )}
          </ProForm.HeaderActions>
        }
      >
        {label}
      </ProForm.Header>
    );
  };

  return renderTerminal({ instance, initAddons, renderHeader, $opener });
}

const numbers = new Set();

function useComponentCount() {
  const number = useMemo(() => {
    let i = 0;
    while (numbers.has(i)) {
      ++i;
    }
    numbers.add(i);
    return i;
  }, []);

  useEffect(() => {
    return () => {
      numbers.delete(number);
    };
  }, [number]);

  return number;
}
