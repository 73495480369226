import $ from 'jquery';
import moment from 'moment';

import { fiIdleTimer } from 'fiutil/idle_timer';
import { saveBlob } from 'fiutil/file_util';
import { fiFmgHttp } from 'fi-http';
import { fiCsrfToken } from 'fi-cookies';

const BYTES_PER_CHUNK = 1024 * 1024 * 200;
const MAX_CONCURRENCY = 2;

export const FileTransferService = {
  BYTES_PER_CHUNK: BYTES_PER_CHUNK,
  bytesFormatter: _format_bytes,
  genChunks: _gen_chunks,
  download: _download,
  downloadByForm: _downloadByForm,
  upload: _upload,
  appendTimestampToFilename,
  fileExport,
};

/**
 * download file from server side
 * @param {string} filePath from server side response. it match with API: "/gui/download"
 * @param {string} downloadName
 * @param {array} chunks
 */
function _download(filePath, downloadName, chunks) {
  let genFn = _create_downloads_request(chunks, filePath, downloadName);
  let numReq = chunks.length;
  let processed = 0,
    cnt = 0;
  let status = {
    total: numReq,
    success: [],
    failed: [],
  };
  let deferred = $.Deferred();

  queryFn();

  return deferred.promise();

  function queryFn() {
    // keep session active
    fiIdleTimer.active({ type: 'download:chunky' });

    if (processed >= numReq) {
      if (cnt <= 0) {
        deferred.resolve({
          status: status,
          file: downloadName,
        });
      }
      return;
    }

    if (cnt < MAX_CONCURRENCY && processed < numReq) {
      let req = genFn(processed);
      fiFmgHttp
        .blobDownload('/cgi-bin/module/flatui_proxy', req)
        .then(
          (resp) => {
            status.success.push(resp.filename);
            deferred.notify({
              status: status,
              current: {
                idx: parseInt(req.params.suffix, 10),
                file: resp.filename,
              },
            });

            saveBlob(resp.data, resp.filename);
            return true;
          },
          function () {
            status.failed.push(req.params);
            deferred.notify({
              status: status,
              current: {
                idx: parseInt(req.params.suffix, 10),
              },
            });
            return false;
          }
        )
        .finally(function () {
          cnt--;
          queryFn();
        });

      processed++;
      cnt++;
      queryFn();
    }
  }
}

function _downloadByForm(filePath, downloadName, filesize) {
  let form = $('<form></form>');

  form.attr('action', '/flatui/api/gui/download');

  $('<input>')
    .attr({
      type: 'hidden',
      id: 'filepath',
      name: 'filepath',
      value: filePath,
    })
    .appendTo(form);

  if (filesize) {
    $('<input>')
      .attr({
        type: 'hidden',
        id: 'range',
        name: 'range',
        value: '0-' + filesize,
      })
      .appendTo(form);
  }

  $('<input>')
    .attr({
      type: 'hidden',
      id: 'downloadname',
      name: 'downloadname',
      value: downloadName,
    })
    .appendTo(form);

  // keep session active
  fiIdleTimer.active({ type: 'download:form' });

  form.appendTo('body').submit().remove();
}

function fileExport({ url, filename, downloadname }) {
  let form = $('<form></form>');

  url = url?.[0] === '/' ? url.substring(1) : url;
  form.attr('action', `/flatui/api/${url}`);

  $('<input>')
    .attr({
      type: 'hidden',
      id: 'filename',
      name: 'filename',
      value: filename,
    })
    .appendTo(form);

  $('<input>')
    .attr({
      type: 'hidden',
      id: 'downloadname',
      name: 'downloadname',
      value: downloadname,
    })
    .appendTo(form);

  // keep session active
  fiIdleTimer.active({ type: 'download:form' });

  form.appendTo('body').submit().remove();
}

function _format_bytes(bytes, decimals) {
  if (bytes == 0) return '0 Bytes';
  var k = 1024,
    dm = decimals <= 0 ? 0 : decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * generate chunks
 * @param {integer} bytes
 * @param {integer} chunkSize optional, max 500MB for blob size depend on different browsers.
 */
function _gen_chunks(bytes, chunkSize) {
  chunkSize = chunkSize || BYTES_PER_CHUNK;
  const num = Math.floor(bytes / chunkSize);

  let start = 0;
  let end = 0;

  let chunks = [];

  for (let i = 0; i < num; i++) {
    end = start + chunkSize - 1;
    chunks.push({
      start: start,
      end: end,
    });
    start = end + 1;
  }

  if (end + 1 < bytes) {
    end = bytes - 1;
    chunks.push({
      start: start,
      end: end,
    });
  }

  return chunks;
}

function create_suffix(len, index) {
  let s = (index + 1).toString();
  while (s.length < len) {
    s = '0' + s;
  }
  return s;
}

function _create_downloads_request(chunks, filepath, downloadname) {
  let len = chunks.length.toString().length;

  return (index) => {
    const suffix = chunks.length > 1 ? create_suffix(len, index) : '';
    const { start, end } = chunks[index];

    let req = {
      url: '/gui/download',
      method: 'get',
      params: {
        filepath: filepath,
        range: `${start}-${end}`,
        suffix: suffix,
      },
    };
    if (downloadname) {
      req['params']['downloadname'] = downloadname;
    }

    return req;
  };
}

function create_chunk_form({ filesize, range, folder, filename }, blob) {
  let formData = new FormData();
  if (folder) {
    formData.append('folder', folder);
  }
  formData.append('filesize', filesize);
  formData.append('filename', filename);
  formData.append('range', range);

  if (blob) {
    formData.append('filepath', blob, filename);
  }

  return formData;
}

function create_chunks(size, per_chunk) {
  var start = 0;
  var end = Math.min(per_chunk, size);
  let chunkid = 0;
  let chunks = [];
  while (start < size) {
    chunks.push({
      start: start,
      end: end,
      chunkid: chunkid,
    });

    start = end;
    end = Math.min(start + per_chunk, size);
    chunkid = chunkid + 1;
  }
  return chunks;
}

function _upload(blob, folder, { onNext, onComplete, bytesPerChunk }) {
  //console.time('uploading')

  const url = '/flatui/api/gui/upload';
  const filesize = blob.size;
  const async_upload = create_upload(url, true);
  const sync_upload = create_upload(url, false);
  const bytes_per_chunk = bytesPerChunk ? bytesPerChunk : BYTES_PER_CHUNK;

  let chunks = create_chunks(blob.size, bytes_per_chunk);
  let totalchunks = Math.ceil(blob.size / bytes_per_chunk);

  let filename = blob.name;
  let create_form = (range, chunk) =>
    create_chunk_form(
      {
        folder,
        filesize,
        filename,
        range,
      },
      chunk
    );

  let que = {
    doneSize: 0,
    doneChunks: 0,
    done: {},
    process: {},
    failed: {},
  };

  function complete_all() {
    if (que.doneChunks !== totalchunks) return;

    if (totalchunks > 1)
      //this request is used to compose chunks
      sync_upload(totalchunks, create_form(`${filesize}-${filesize}`), {});

    //console.timeEnd('uploading')

    if (onComplete) {
      onComplete();
    }
  }

  function create_upload(url, async) {
    return (chunkid, formData, { onUploadComplete, onProgress, onFailed }) => {
      if (onProgress) {
        onProgress(chunkid, 0);
      }

      let csrfToken = fiCsrfToken();
      let xhr = new XMLHttpRequest();

      xhr.addEventListener('error', transferFailed);
      xhr.addEventListener('abort', transferCanceled);
      xhr.addEventListener('load', transferComplete);

      if (xhr.upload) {
        xhr.upload.onprogress = function (e) {
          // keep session active
          fiIdleTimer.active({ type: 'upload:progress' });

          var done = e.position || e.loaded;
          if (onProgress) onProgress(chunkid, done);
        };
      }

      try {
        // eslint-disable-next-line
        //console.time("Upload Timing");
        xhr.open('post', url, async);
        xhr.setRequestHeader('XSRF-TOKEN', csrfToken);
        xhr.send(formData);
      } catch (e) {
        //console.log(e)
      }

      // eslint-disable-next-line
      function transferComplete(evt) {
        // eslint-disable-next-line
        //console.timeEnd("Upload Timing");
        if (onUploadComplete) onUploadComplete(chunkid);
        //console.log("The transfer is complete.");
      }

      // eslint-disable-next-line
      function transferFailed(evt) {
        if (onFailed) onFailed(chunkid);
        //console.log("An error occurred while transferring the file.");
      }

      // eslint-disable-next-line
      function transferCanceled(evt) {
        if (onFailed) onFailed(chunkid);

        //console.log("The transfer has been canceled by the user.");
      }
    };
  }

  function start_tasks() {
    while (
      Object.keys(que.process).length < MAX_CONCURRENCY &&
      tasks.length > 0
    ) {
      let task = tasks.shift();

      que.process[task.chunkid] = task;

      task.job();
    }
  }

  function refresh_process() {
    que.doneSize =
      Object.values(que.done).reduce((prev, curr) => prev + curr.process, 0) +
      que.doneSize;
    que.doneChunks = Object.values(que.done).length + que.doneChunks;
    que.done = {};

    let finished =
      que.doneSize +
      Object.values(que.process).reduce((prev, curr) => prev + curr.process, 0);

    if (onNext) onNext(finished);
  }

  function add_failed_to_tasks() {
    tasks.concat(Object.values(que.failed).map((x) => x.task));
    que.failed = {};
  }

  function refresh() {
    refresh_process();
    add_failed_to_tasks();
    start_tasks();
    complete_all();
  }

  function onUploadComplete(chunkid) {
    let val = que.process[chunkid] || {};
    que.done[chunkid] = val;
    delete que.process[chunkid];
    refresh();
  }

  function onProgress(chunkid, done) {
    que.process[chunkid].process = done;
    refresh();
  }

  function onFailed(chunkid) {
    let val = que.process[chunkid] || {};
    que.failed[chunkid] = val;
    delete que.process[chunkid];
    refresh();
  }

  function create_task(chunkid, start, end) {
    return () => {
      async_upload(
        chunkid,
        create_form(`${start}-${end}`, blob.slice(start, end)),
        {
          onUploadComplete,
          onProgress,
          onFailed,
        }
      );
    };
  }

  let tasks = chunks.map(({ start, end, chunkid }) => {
    // let chunk= blob.slice(start, end);
    return {
      chunkid: chunkid,
      job: create_task(chunkid, start, end),
      process: 0,
    };
  });

  start_tasks();
}

function appendTimestampToFilename(fname) {
  let pos = fname.lastIndexOf('.');
  let str = pos > 0 ? fname.substr(0, pos) : fname;
  let ext = pos > 0 ? fname.substr(pos) : '';
  let now = new Date();
  var ts = moment(now.getTime()).format('YYYYMMDD-HHmmss');

  return str + '-' + ts + ext;
}
