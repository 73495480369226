import { memo, useEffect, useState } from 'react';
import cn from 'classnames';
import { isFunction } from 'lodash';
import $ from 'jquery';

import { NwTooltip } from '@fafm/neowise-core';

import { useWizard } from './useWizard';

export { useWizardProgressBar, WizardProgressBar };

const useWizardProgressBar = (accessor) => {
  const {
    activeKey,
    getWizardStepByKey,
    stepSequence,
    headerHeight,
    headerRef,
  } = accessor || useWizard();
  const { index: currentStepIndex } = getWizardStepByKey(activeKey);

  return (
    <WizardProgressBar
      currentStepIndex={currentStepIndex}
      stepSequence={stepSequence}
      headerHeight={headerHeight}
      headerRef={headerRef}
    />
  );
};

const WizardProgressBar = memo(
  ({ stepSequence, currentStepIndex, headerHeight, headerRef }) => {
    const makeSteps = () => {
      return stepSequence.map((stepObj, index) => {
        const { id, text } = stepObj;
        const _text = isFunction(text)
          ? text({
              currentStep: stepSequence[currentStepIndex],
              currentStepIndex,
            })
          : text || '';
        const currOrPassStep = index <= currentStepIndex;
        const isLastStep = index === stepSequence.length - 1;

        const tooltipProps = {
          placement: 'bottom',
          distance: 3,
        };

        return (
          <NwTooltip
            key={`wizard_progress_bar:${id}`}
            {...tooltipProps}
            content={
              <div slot='body' className='tw-w-48 tw-p-2'>
                <div className='tw-mb-1 tw-text-base'>
                  <b>{gettext('Step %s').printf([index + 1])}</b>
                </div>
                <div>{_text || ''}</div>
              </div>
            }
          >
            {/* Progress bar segment */}
            <div
              className={cn(
                'tw-cursor-pointer tw-w-full rc-wizard-progress-bar-segment',
                currOrPassStep
                  ? 'tw-bg-primary-600 hover:tw-bg-primary-500'
                  : 'tw-bg-neutral-300 hover:tw-bg-neutral-200',
                {
                  'tw-mr-px': !isLastStep,
                }
              )}
            ></div>
          </NwTooltip>
        );
      });
    };

    return (
      <WizardProgressBarContainer
        headerHeight={headerHeight}
        headerRef={headerRef}
      >
        {makeSteps()}
      </WizardProgressBarContainer>
    );
  }
);

const WizardProgressBarContainer = ({ headerHeight, headerRef, children }) => {
  /** ----------------------------------------------------------------------------
   * States
   * -------------------------------------------------------------------------- */
  const [toggled, setToggled] = useState(false);

  const headerEl = headerRef.current;

  const isInModal = getIsInModal(headerEl);

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  // prevent drawer horizontal scrollbar from showing up due to progress bar taking extra width
  useEffect(() => {
    if (isInModal || !mounted) return;

    const drawer = getDrawerEl(headerEl);
    if (!drawer) return;

    const shadowDom = drawer.shadowRoot;
    if (shadowDom) {
      const drawerPanel = shadowDom.querySelector('[part="panel"]');
      $(drawerPanel).css({
        overflow: 'hidden',
      });
    }
  }, [headerEl?.offsetHeight, isInModal, mounted]);

  const heightInPx = headerHeight || headerEl?.offsetHeight + 'px';

  // Increase progress bar height on hover or on click
  return (
    <div
      id='rc-wizard-progress-bar'
      className={cn(
        'tw-absolute tw-z-10 tw-flex tw-h-1 tw-w-full hover:tw-h-2.5 group-hover:tw-h-2.5',
        {
          'tw-h-2.5': toggled,
        }
      )}
      onClick={() => setToggled(!toggled)}
      style={{ top: heightInPx }}
    >
      {children}
    </div>
  );
};

function getIsInModal(headerEl) {
  return !!(
    $(headerEl).closest('nw-dialog').get(0) ||
    $(headerEl).closest('.np-toolkit-modal').get(0)
  );
}

function getDrawerEl(headerEl) {
  return (
    $(headerEl).closest('nw-drawer').get(0) ||
    $(headerEl).closest('.np-toolkit-drawer').get(0)
  );
}
