import { useCallback, useEffect, useMemo, useState } from 'react';
import { FmkSSelect2, FmkErrorSpan } from 'rc_form';
import { isString, isArray } from 'fiutil';
import { escapeHtml } from 'kit-escape';
import { fiMessageBox } from 'fi-messagebox';
import { Formik, FormikConsumer } from 'formik';
import { OkBtn, CancelBtn, NwProInputRow } from 'rc_layout';
import { ProLego, ProForm } from '@fafm/neowise-pro';
import { fiAdom } from 'fi-web/fi-session';
import { fiFmgHttp } from 'fi-web/fi-http';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { hasRDWRPermitOnSysSettingConfig } from 'fistore/session/profile/selectors';

const { Header, Body, Footer, Section, Row, Column } = ProForm;
const { SSelect } = ProLego;

const getAutoId = (name) => 'sys_admin_approval_matrix_edit:' + name;
const MAX_APPROVAL_GROUP = 8;
const getDftApprovalData = () => {
  return {
    'adom-name': null, //string
    approver: [
      {
        member: '', // admin1,admin2
        seq_num: 0,
      },
    ],
    'mail-server': '',
    notify: '', // admin1,admin2
  };
};
const onSetValue = (formik, newApproval) => {
  formik.setValues(newApproval);
};

const strToArr = (str, separator = ',') => {
  return (
    (str && isString(str) && str.split(separator)) ||
    (isArray(str) && str) ||
    []
  );
};

const arrToStr = (arr, separator = ',') => {
  return isArray(arr) ? arr.join(separator) : '';
};

const approvalDataToFormVal = (approval) => {
  return {
    ...approval,
    notify: strToArr(approval.notify),
    approver: (approval.approver || []).map((apvr) => {
      return {
        // ...apvr,
        member: strToArr(apvr.member),
      };
    }),
  };
};

const formValToApprovalData = (formVal) => {
  return {
    ...formVal,
    notify: arrToStr(formVal.notify),
    approver: (formVal.approver || [])
      .filter((apvr) => isArray(apvr.member))
      .map((apvr, i) => {
        return {
          ...apvr,
          seq_num: i + 1,
          member: arrToStr(apvr.member),
        };
      }),
  };
};

const validateApprovalGrp = (values) => {
  const hasApvrGrp = (values.approver || []).some(
    (apvr) => isArray(apvr.member) && apvr.member.length
  );
  return hasApvrGrp
    ? null
    : {
        'approval-group': gettext('Please create at least one Approval Group.'),
      };
};

/**
 * @param {{
 * getAdminAvatar: (adminName: string) => string,
 * approval: object | undefined,
 * $opener: {resolve: Function, reject: Function}
 * }} param0
 */
export const WorkflowApprovalMatrix = ({
  adomsHasApprovers,
  // get admin avatar image string by admin's name, may use useAdminAvatar hook to get this function
  getAdminAvatar,
  // a non parsed approval entry data to be edited or nill for create one
  approval,
  $opener,
}) => {
  const rwUser = useSelector(hasRDWRPermitOnSysSettingConfig());

  const isEdit = !!approval;
  const approvalData = useMemo(
    () => (approval ? approval : getDftApprovalData()),
    [approval]
  );

  const adomOpts = useAdomOpts(approvalData, adomsHasApprovers);

  const [selectedAdomName, setSelectedAdomName] = useState(
    getApprovalAdomName(approvalData)
  );

  const onSubmit = useCallback(
    (values) => {
      $opener.resolve(formValToApprovalData(values));
    },
    [$opener]
  );

  const initialValues = useMemo(
    () => approvalDataToFormVal(approvalData),
    [approvalData]
  );
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validate={validateApprovalGrp}
    >
      {({ isSubmitting }) => (
        <>
          <Header>
            {isEdit
              ? gettext('Edit Approval Matrix')
              : gettext('New Approval Matrix')}
          </Header>
          <Body>
            <Section labelSize='lg'>
              <Row label='ADOM'>
                <Column>
                  <FieldContainer>
                    <FmkSSelect2
                      source={adomOpts}
                      name='adom-name'
                      automationId={getAutoId('adom-name')}
                      onChange={setSelectedAdomName}
                      disabled={isEdit}
                      value={selectedAdomName}
                      required
                    />
                    <FmkErrorSpan name='adom-name' />
                  </FieldContainer>
                </Column>
              </Row>

              <FormikConsumer>
                {(formik) => (
                  <WorkflowApprovalMatrixCommon
                    getAdminAvatar={getAdminAvatar}
                    adomName={selectedAdomName}
                    approval={formik.values}
                    skipParser={true}
                    onSetValue={onSetValue}
                  />
                )}
              </FormikConsumer>
            </Section>
          </Body>
          <Footer>
            <FormikConsumer>
              {(formik) =>
                rwUser && (
                  <OkBtn
                    onClick={formik.submitForm}
                    automation-id={getAutoId('btn-ok')}
                    loading={isSubmitting}
                  >
                    {gettext('OK')}
                  </OkBtn>
                )
              }
            </FormikConsumer>
            <CancelBtn
              onClick={$opener.reject}
              automation-id={getAutoId('btn-cancel')}
            >
              {rwUser ? gettext('Cancel') : gettext('Close')}
            </CancelBtn>
          </Footer>
        </>
      )}
    </Formik>
  );
};

const getApprovalAdomName = (approval) => approval?.['adom-name'] || null;

const useAdomOpts = (approvalEntry, adomsHasApprovers = []) => {
  const [adomOpts, setAdomOpts] = useState([]);
  const editingAdomName = getApprovalAdomName(approvalEntry);

  useEffect(() => {
    let req = {
      method: 'get',
      params: [
        {
          url: '/dvmdb/adom',
        },
      ],
    };

    fiFmgHttp.forward(req).then((resp) => {
      let data = get(resp, '0.data', []);
      let opts = data.reduce((acc, cur) => {
        if (
          !fiAdom.isConfigurableType({
            type: cur.restricted_prds,
          })
        )
          return acc;

        if (cur.flags & MACROS.ADOM.DVM_ADOM_FLAG_BACKUP_MODE) return acc;
        if (cur.flags & MACROS.ADOM.DVM_ADOM_FLAG_OTHER_DEVICES) return acc;

        if (cur.name === MACROS.DVM.DVM_UNMANAGED_DEVICES_NAME) return acc;
        if (adomsHasApprovers.includes(cur.name)) return acc;
        acc.push({
          id: cur.name,
          text: cur.name === 'rootp' ? MACROS.ADOM.GLB_ADOM_NAME : cur.name,
        });

        return acc;
      }, []);

      if (editingAdomName) {
        opts.push({ id: editingAdomName, text: editingAdomName });
      }
      setAdomOpts(opts);
    });
  }, [editingAdomName, adomsHasApprovers]);

  return adomOpts;
};

const useMailServer = () => {
  const [mailServerOpts, setMailServerOpts] = useState([]);
  let req = {
    method: 'get',
    params: [
      {
        url: '/cli/global/system/mail',
      },
    ],
  };

  useEffect(() => {
    fiFmgHttp.forward(req).then((resp) => {
      let data = get(resp, '0.data', []);
      let opts = data.map((it) => {
        return {
          id: it.id,
          text: it.id,
        };
      });

      setMailServerOpts(opts);
    });
  }, []);

  return mailServerOpts;
};

const useAdminOpts = (selectedAdomName) => {
  const [adminOpts, setAdminOpts] = useState([]);

  useEffect(() => {
    if (!selectedAdomName) return setAdminOpts([]);

    if (fiAdom.isGlobalAdom(selectedAdomName)) {
      selectedAdomName = MACROS.DVM.PM2_GUI_GLOBAL_ADOM_NAME; // 'Global'
    }

    let req = {
      url: `/gui/adom/${selectedAdomName}/access/admins`,
      method: 'get',
      params: {},
    };
    fiFmgHttp.post(req).then((resp) => {
      let data = get(resp, '0.data', []);
      setAdminOpts(data);
    }, showRequestError);
  }, [selectedAdomName]);

  return adminOpts;
};

const showRequestError = () => {
  fiMessageBox.show(gettext('Fail to load data from server.'), 'danger');
};

const FieldContainer = ({ children }) => (
  <div className='fi-col-12'>{children}</div>
);

// Experiments to extract common (context-free) parts from component above
export const WorkflowApprovalMatrixCommon = ({
  // get admin avatar image string by admin's name, may use useAdminAvatar hook to get this function
  getAdminAvatar,
  // a non parsed approval entry data to be edited or nill for create one
  approval,
  adomName,
  skipParser,
  onSetValue,
  isView,
}) => {
  const mailServerOpts = useMailServer();
  const [selectedAdomName, setSelectedAdomName] = useState();

  adomName = adomName || getApprovalAdomName(approval);
  useEffect(() => {
    if (adomName) {
      setSelectedAdomName(adomName);
    }
  }, [adomName, getAdminAvatar]); //pass in a new approval data afater first render

  const adminOpts = useAdminOpts(selectedAdomName);

  const formatMselectUser = useCallback(
    ({ id, text }) => {
      return (
        '' +
        '<div class="obj-box-msel" style="height: 2rem">' +
        '<div class="obj-name">' +
        '<span class="ss-icon-margin">' +
        `<nw-avatar class="fi-user-avatar" style="--size: 2rem" initials="${text.charAt(
          0
        )}" image="${getAdminAvatar(id)}" ></nw-avatar>` +
        '</span>' +
        '<span class="icon-text">' +
        escapeHtml(text) +
        '</span>' +
        '</div>' +
        '</div>'
      );
    },
    [getAdminAvatar]
  );

  // var initialValues = approvalData; // useMemo(() => approvalData, [approvalData]);
  approval = skipParser ? approval : approvalDataToFormVal(approval);
  const apvrs = approval.approver;
  return (
    <FormikConsumer>
      {(formik) => {
        return (
          <>
            <NwProInputRow label={gettext('Approval Group')}>
              <ProLego.MutableList
                items={apvrs || []}
                initValue={{ member: [] }}
                maxLength={MAX_APPROVAL_GROUP}
                allowAddCondition={(index) => {
                  const isLastEl = index + 1 === apvrs.length;
                  return isLastEl && apvrs.length < MAX_APPROVAL_GROUP;
                }}
                onAddCallback={() => {
                  const newApvrs = apvrs.slice();
                  // do not set seq_num, it is not accurate after adding and removing groups,
                  // will set it onSubmit based on the approver group index
                  newApvrs.push({ member: [] });
                  // onFormChange('approver', newApvrs);
                  let newData = {
                    ...approval,
                    approver: newApvrs,
                  };
                  onSetValue(
                    formik,
                    skipParser ? newData : formValToApprovalData(newData)
                  );
                }}
                onRemoveCallback={(index) => {
                  const newApvrs = apvrs.slice();
                  newApvrs.splice(index, 1);
                  // onFormChange('approver', newApvrs);
                  let newData = {
                    ...approval,
                    approver: newApvrs,
                  };
                  onSetValue(
                    formik,
                    skipParser ? newData : formValToApprovalData(newData)
                  );
                }}
              >
                {({ index }) => (
                  <div className={'tw-w-full'}>
                    <SSelect
                      multipleSelect
                      source={adminOpts}
                      value={approval.approver[index]?.member}
                      itemHeight={46}
                      paneItemHeight={46}
                      formatChoiceHTML={formatMselectUser}
                      automationId={getAutoId('group-' + index + '-name')}
                      onChange={(ids) => {
                        const memberSet = new Set(ids);
                        const newApvrs = apvrs.map((apvr) => ({
                          ...apvr,
                          member: apvr.member.filter(
                            (mem) => !memberSet.has(mem)
                          ), // remove the selected approver from other group
                        }));
                        newApvrs[index] = { member: ids };
                        let newData = {
                          ...approval,
                          approver: newApvrs,
                        };
                        onSetValue(
                          formik,
                          skipParser ? newData : formValToApprovalData(newData)
                        );
                      }}
                      disabled={isView}
                    />

                    <FmkErrorSpan name='approval-group' />
                  </div>
                )}
              </ProLego.MutableList>
            </NwProInputRow>

            <NwProInputRow label={gettext('Send an Email Notification to')}>
              <SSelect
                multipleSelect
                source={adminOpts}
                value={approval.notify}
                itemHeight={46}
                paneItemHeight={46}
                formatChoiceHTML={formatMselectUser}
                automationId={getAutoId('notify')}
                onChange={useCallback((ids) => {
                  let newData = {
                    ...approval,
                    notify: ids,
                  };
                  onSetValue(
                    formik,
                    skipParser ? newData : formValToApprovalData(newData)
                  );
                })}
                disabled={isView}
              />
            </NwProInputRow>

            <NwProInputRow label={gettext('Mail Server')}>
              <SSelect
                source={mailServerOpts}
                value={approval['mail-server'] || null}
                automationId={getAutoId('mail-server')}
                onChange={useCallback((id) => {
                  let newData = {
                    ...approval,
                    'mail-server': id,
                  };
                  onSetValue(
                    formik,
                    skipParser ? newData : formValToApprovalData(newData)
                  );
                })}
                disabled={isView}
              />
            </NwProInputRow>
          </>
        );
      }}
    </FormikConsumer>
  );
};
