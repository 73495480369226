import { createSelector } from '@reduxjs/toolkit';
import { getSessionAdomOid } from '../../session/adom/selectors';

export const getDvmPtState = (state) => state.dvm.pt || {};

export const getPtDisplayOptions = createSelector(
  getDvmPtState,
  (ptState) => ptState.displayOptions || {}
);

export const getPtDisplayOptionsByAdomOid = (adomOid) =>
  createSelector(
    getPtDisplayOptions,
    (displayOptions) => displayOptions[adomOid]
  );

// this selector needs an additional param: app
export const ptDisplayOptChecker = (appKey, defaultShow) =>
  createSelector(
    (state) => {
      const adomOid = getSessionAdomOid(state);
      return getPtDisplayOptionsByAdomOid(adomOid)(state);
    },
    (displayOpts) => {
      if (!displayOpts || !displayOpts.length) {
        return defaultShow;
      }
      return displayOpts.includes(appKey);
    }
  );
