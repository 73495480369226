import { useDeferred } from 'rh_util_hooks';
import { fiSocket } from 'fi-websocket';
import { fiFmgHttp } from 'fi-http';
import * as fiUUID from 'fi-uuid';
import { fiSystemOperationApi } from 'fi-api';
import { compareFirmwares } from 'kit-compare';
import { fiTaskByWebSocket } from 'rc_task_monitor';
import { FileTransferService } from 'fi-file';
import { fiIdleTimer } from 'fiutil/idle_timer';
import { fiAdminProfile } from 'fi-session';
import { validateUploadedImage } from 'fi-web/fi-api/system_operation';

export const checkImage = async () => {
  try {
    await validateUploadedImage();
  } catch ({ message }) {
    throw message;
  }
};

export const doBackup = async ({ backup, encryption, password, onNotify }) => {
  if (!backup || !fiAdminProfile.isSuperAdmin()) return Promise.resolve();
  const deferred = useDeferred();

  let tid = fiUUID.gen();
  let req = {
    id: tid,
    url: '/gui/sys/db',
    method: 'backup',
    params: {
      password: encryption ? password : '',
    },
  };

  const listener = (data) => {
    if (data?.collection === 'file-download') {
      fiSocket.get_ws_proxy().removeListener('changed', listener);
      deferred.resolve();
    }
  };

  fiSocket.get_ws_proxy().addListener('changed', listener);
  fiTaskByWebSocket.register(tid, function (resp) {
    fiIdleTimer.active({ type: 'sys:backup' });

    if (!resp) return;

    let meta = resp.fields.meta || {};
    let completedSize = FileTransferService.bytesFormatter(meta.bytes || 0);
    let fileSize = meta.bytes || 0;

    onNotify(completedSize);

    if (0 == resp.fields.code && meta.downloadname && meta.filepath) {
      let fname = FileTransferService.appendTimestampToFilename(
        meta.downloadname
      );
      // download as a single file
      FileTransferService.downloadByForm(meta.filepath, fname, fileSize);
      fiTaskByWebSocket.unregister(tid);
    }
  });

  fiFmgHttp.post(req).then(
    () => {},
    (e) => {
      fiTaskByWebSocket.unregister(tid);
      deferred.reject(e);
    }
  );

  return deferred.promise;
};

export const doUpgrade = async () => {
  const resp = await fiSystemOperationApi.upgrade();
  if (resp[0].data.status === MACROS.SYS.UPG_OK) {
    return Promise.resolve();
  }

  return Promise.reject(gettext('Upgrade Failed'));
};

export const wrapApiRequest = async ({
  request,
  onSuccess,
  onError,
  args = [],
}) => {
  try {
    const resp = await request(...args);
    if (onSuccess) onSuccess(resp);
    return resp;
  } catch (err) {
    console.error(err);
    if (onError) onError(err);
  }
};

const DEFAULT_RELEASE_NOTE_ID = 723553;
const OLD_FAZ_RELEASE_NOTE_ID = 492625;

const generateFMGReleaseNoteLink = (version) => {
  const dashedVersion = version?.replaceAll('.', '-');
  return `https://docs.fortinet.com/document/fortimanager/${version}/release-notes/${DEFAULT_RELEASE_NOTE_ID}/fortimanager-${dashedVersion}-release`;
};

const generateFAZReleaseNoteLink = (version) => {
  const notesId = compareFirmwares(version, '7.0.3', '>=')
    ? DEFAULT_RELEASE_NOTE_ID
    : OLD_FAZ_RELEASE_NOTE_ID;
  const dashedVersion = version?.replaceAll('.', '-');
  return `https://docs.fortinet.com/document/fortianalyzer/${version}/release-notes/${notesId}/fortianalyzer-${dashedVersion}-release`;
};

export const genReleaseNoteLink = (isFMG, version) => {
  return isFMG
    ? generateFMGReleaseNoteLink(version)
    : generateFAZReleaseNoteLink(version);
};
