import { fiAdom } from 'fi-web/fi-session';
import { Outlet, redirect } from 'react-router-dom';
import { ReactContentViewContainer } from 'react_apps/ra_main/main_layout/ReactContentViewContainer';

export default [
  {
    path: 'devices',
    handle: {
      appUniKey: 'fap_device_pdm_root',
    },
    element: <ReactContentViewContainer menuId='fap_pdm' />,
    children: [
      // Device list
      getPdmDeviceRoute(),

      // Health monitor
      getPdmMonitorRoute(),

      // Ap profiles
      getPdmTemplatesRoute(),
    ],
  },
];

function getPdmDeviceRoute() {
  return {
    path: 'list',
    lazy: async () => {
      const { ApList } = await import('./ap_table/ApList');
      return {
        Component: ApList,
      };
    },
    loader: () => {
      if (fiAdom.isCentralManagement('fap')) {
        return redirect('/fortiap/centrallist');
      }
      return null;
    },
    handle: {
      appUniKey: 'fap_device_pdm',
    },
  };
}

function getPdmMonitorRoute() {
  return {
    path: 'monitor',
    handle: {
      appUniKey: 'fap_device_pdm',
    },
    element: (
      <div className='tw-w-full tw-h-full'>
        <Outlet />
      </div>
    ),
    children: [
      {
        path: 'health',
        handle: {
          appUniKey: 'fap_device_pdm',
        },
        lazy: async () => {
          const { ApHealthMonitor } = await import(
            './managed_ap/ApHealthMonitor/ApHealthMonitor'
          );
          return { Component: ApHealthMonitor };
        },
      },
    ],
  };
}

function getPdmTemplatesRoute() {
  return {
    path: 'wifitemplates',
    handle: {
      appUniKey: 'fap_device_template',
    },
    element: (
      <div className='tw-w-full tw-h-full'>
        <Outlet />
      </div>
    ),
    children: [
      // SSIDs
      {
        path: 'ssids',
        handle: {
          appUniKey: 'fap_device_template_ssid',
        },
        lazy: () => import('./ap_profiles/Ssids/SsidsTable'),
      },
      // AP Profiles
      {
        path: 'profiles',
        handle: {
          appUniKey: 'fap_device_template_opprofs_approf',
        },
        lazy: () => import('./ap_profiles/WtpProfile/WtpProfileTable'),
      },
      // QoS Profiles
      {
        path: 'qosprofiles',
        handle: {
          appUniKey: 'fap_device_template_opprofs_qosprof',
        },
        lazy: () => import('./ap_profiles/QosProfile/QosProfileTable'),
      },
      // FortiAP Configuration Profiles
      {
        path: 'apcfgprofiles',
        handle: {
          appUniKey: 'fap_device_template_opprofs_cfgprof',
        },
        lazy: () => import('./ap_profiles/ApcfgProfile/ApcfgProfileTable'),
      },
      // ARRP Profiles
      {
        path: 'arrpprofiles',
        handle: {
          appUniKey: 'fap_device_template_opprofs_arrpprof',
        },
        lazy: () => import('./ap_profiles/ArrpProfile/ArrpProfileTable'),
      },
      // MPSK Profiles
      {
        path: 'mpskprofiles',
        handle: {
          appUniKey: 'fap_device_template_conprofs_mpskprof',
        },
        lazy: () => import('./ap_profiles/MpskProfile/MpskProfileTable'),
      },
      // Bonjour Profiles
      {
        path: 'bjrprofiles',
        handle: {
          appUniKey: 'fap_device_template_conprofs_bjrprof',
        },
        lazy: () => import('./ap_profiles/BonjourProfile/BonjourProfileTable'),
      },
      // Bonjour Profiles
      {
        path: 'bjrprofiles',
        handle: {
          appUniKey: 'fap_template_conprofs_bjrprof',
        },
        lazy: () => import('./ap_profiles/BonjourProfile/BonjourProfileTable'),
      },
      // Bluetooth Profiles
      {
        path: 'bleprofiles',
        handle: {
          appUniKey: 'fap_device_template_conprofs_btprof',
        },
        lazy: () => import('./ap_profiles/BleProfile/BleProfileTable'),
      },
      // WIDS Profiles
      {
        path: 'widsprofiles',
        handle: {
          appUniKey: 'fap_device_template_protprof_widsprof',
        },
        lazy: () => import('./ap_profiles/WidsProfile/WidsProfileTable'),
      },
      // WIDS Profiles
      {
        path: 'accesscontrol',
        handle: {
          appUniKey: 'fap_device_template_protprof_l3prof',
        },
        lazy: () => import('./ap_profiles/AccessControl/AccessControlTable'),
      },
      // WIDS Profiles
      {
        path: 'setting',
        handle: {
          appUniKey: 'fap_device_template_wifisetting',
        },
        lazy: () => import('./ap_profiles/ApSetting/ApSettingEdit'),
      },
    ],
  };
}
