import React, { useState, useEffect } from 'react';
import { ProTable } from '@fafm/neowise-pro';
import {
  parseVdomData,
  formatSSAdomDevWithIcon,
  vdomCounts,
  ADOM_TYPE_MAP,
  getSysConfig,
} from './util';
import { getAllAdomsDevicesNonCsf, getAdomData } from './api';
import { canDevBeManaged } from 'ra_dvm_util';
import { NwProHeader, NwProFooter, OkBtn, CancelBtn } from 'rc_layout';
import { fiSysConfig } from 'fi-session';
import { fiDvmLogDataService } from 'fi-dvm';
import { useDeviceTableSelect } from 'ra_device_util';

const DeviceVdomName = ({ node, highlighter }) => {
  return formatSSAdomDevWithIcon(node, highlighter);
};

const DeviceSelectPane = (props) => {
  const { $opener, addDevicesToAdom, adomId, formValueType, newMembers } =
    props;

  const [selectedDev, setSelectedDev] = useState([]);
  const [allDevices, setAllDevices] = useState([]);

  useEffect(() => {
    let fetchAllDevices = () => {
      Promise.all([
        getAllAdomsDevicesNonCsf(),
        adomId === 0 ? Promise.resolve([]) : getAdomData(adomId),
      ]).then(([allDevs, currentAdom]) => {
        // init the vdom counts for each device
        vdomCounts.clear();
        let allDevicesTmp = [];

        for (let key in allDevs) {
          let val = allDevs[key];
          val.members.forEach((member) => {
            if (
              member.os_type === MACROS.DVM.DVM_OS_TYPE_FAZ &&
              member.manage_mode & (MACROS.DVM.DVM_MGMT_MODE_FMG !== 0)
            )
              return;
            if (canDevBeManaged(member.os_type)) {
              if (vdomCounts._allVdomCountsByDevName[member.name]) {
                vdomCounts._allVdomCountsByDevName[member.name] +=
                  member.total_vdoms;
              } else {
                vdomCounts._allVdomCountsByDevName[member.name] =
                  member.total_vdoms;
              }
              if (val.is_backup) {
                vdomCounts._backVdomCountsByDevName[member.name] =
                  vdomCounts._allVdomCountsByDevName[member.name];
              }
            }
            // You cannot move a device under remote-FMG managed ADOM.
            if (!val.is_autosync) {
              if (
                ADOM_TYPE_MAP[formValueType] == member.os_type ||
                formValueType === MACROS.DVM.DVM_RESTRICTED_PRD_FSF
              ) {
                // FABRIC adom can have all type devices
                member.adomname = key;
                member.adomOid = val.oid;
                allDevicesTmp.push(member);
              }
            }
            if (fiSysConfig.isFaz()) {
              const logStats = fiDvmLogDataService.getLogStatsByDevice(
                member,
                val.oid
              );
              member.logStatus =
                logStats?.status || MACROS.DVM.LOGSTAT_DEVST_DOWN;
              member.logStats = logStats;
            }
          });
        }

        // Parse all devices
        let parsedDevices = parseVdomData({
          currentMembers: allDevicesTmp.filter(
            (dev) => dev.adomname === currentAdom.name
          ),
          tempAddedMembers: [],
          allDevices: allDevicesTmp,
          forSelection: true,
          sysConfig: getSysConfig(),
        });

        setAllDevices(parsedDevices);
      });
    };
    fetchAllDevices();
  }, []);

  const { beforeSelect, onFilterRows, selectedIdNotInFilteredRows, devIdMap } =
    useDeviceTableSelect(
      allDevices,
      'uid',
      selectedDev.map((r) => r['uid']),
      newMembers.map((r) => r['uid']),
      (row) => (row.isVdom ? row.devoid : row.oid),
      (row) => row.isVdom
    );

  return (
    <>
      <NwProHeader>{gettext('Select Device')}</NwProHeader>

      <ProTable.TableView
        tableId='sys-adom-dev-selection'
        rowKey='uid'
        expandColumnKey='uid'
        data={allDevices}
        columns={colDef}
        rowHeight={MACROS.USER.SYS.DIV_TABLE.ENTRY_HEIGHT_45}
        beforeSelectRows={beforeSelect}
        onSelectRows={(currentSelected) => {
          setSelectedDev([
            ...currentSelected,
            ...selectedIdNotInFilteredRows.current.map((id) => devIdMap[id]),
          ]);
        }}
        onFilterRows={onFilterRows}
        initialSelect={{
          keysSelected: new Set(newMembers.map(({ uid }) => uid)),
        }}
      />

      <NwProFooter>
        <OkBtn
          onClick={() => {
            addDevicesToAdom(selectedDev);
            $opener.resolve();
          }}
        >
          {gettext('Add to ADOM')}
        </OkBtn>
        <CancelBtn onClick={() => $opener.reject()}>
          {gettext('Cancel')}
        </CancelBtn>
      </NwProFooter>
    </>
  );
};

DeviceSelectPane.displayName = 'DeviceSelectPane';

const colDef = [
  {
    key: 'name',
    title: gettext('Name'),
    cellRenderer: ({ rowData }, highlighter) => {
      return (
        <div style={{ display: 'flex', flexGrow: 1 }}>
          <span className={rowData.isVdom ? 'list-name-indent' : null}>
            <DeviceVdomName node={rowData} highlighter={highlighter} />
            <div className='subtitle-line' style={{ display: 'flex' }}>
              <span className='platform' style={{ flexGrow: 1 }}>
                {highlighter(rowData.platform_str || rowData.platform)}
              </span>
              {rowData.ip && (
                <span className='ip' title={rowData.ip}>
                  , {highlighter(rowData.ip)}
                </span>
              )}
            </div>
          </span>
        </div>
      );
    },
    toSearchString: ({ rowData }) => {
      return [
        rowData.isVdom ? `${rowData.devname}[${rowData.name}]` : rowData.name,
        rowData.platform_str || rowData.platform,
        rowData.ip || '',
      ].join('##');
    },
  },
  {
    key: 'frmversion',
    title: gettext('Version'),
    width: 150,
  },
  {
    key: 'adomname',
    title: gettext('From ADOM'),
  },
];
export default DeviceSelectPane;
