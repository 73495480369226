import { fiDvmListViewType, selectedManagedView } from 'fi-dvm';
import { fiSysConfig } from 'fi-session';
import { dispatch } from 'fistore';
import { go } from 'fistore/routing/slice';

function currentView() {
  return {
    get: () => selectedManagedView(),
    set: (v) => selectedManagedView(v),
  };
}

const fiDvmListViewStates = {
  [fiDvmListViewType.MANAGED_TABLE_VIEW]: '/dvm/main/groups',
  [fiDvmListViewType.MANAGED_MAP_VIEW]: '/dvm/main/map',
  [fiDvmListViewType.MANAGED_RING_VIEW]: '/dvm/main/ring/fmg-dash1',
  [fiDvmListViewType.MANAGED_FOLDER_VIEW]: '/dvm/main/folder',
};

const fiFAZDvmListViewStates = {
  [fiDvmListViewType.MANAGED_TABLE_VIEW]: '/dvm/main/fazgroups',
  [fiDvmListViewType.MANAGED_MAP_VIEW]: '/dvm/main/fazmap',
};

////////////////////////////////////////////////////
function changeView({ newView }) {
  const states = fiSysConfig.hasFmgFeature()
    ? fiDvmListViewStates
    : fiFAZDvmListViewStates;
  dispatch(go({ to: states[newView] }));
}

const setView = (newView) => {
  const oldView = currentView().get();
  if (oldView === newView) return;

  currentView().set(newView);
  changeView({ oldView, newView });
};

export const viewCommands = (fiDvmActionsId) => ({
  [fiDvmActionsId.views.table]: {
    key: fiDvmActionsId.views.table,
    label: gettext('Table View'),
    icon: 'chart-table',
    exec: () => setView(fiDvmListViewType.MANAGED_TABLE_VIEW),
  },
  [fiDvmActionsId.views.map]: {
    key: fiDvmActionsId.views.map,
    label: gettext('Map View'),
    icon: 'globe',
    exec: () => setView(fiDvmListViewType.MANAGED_MAP_VIEW),
  },
  [fiDvmActionsId.views.ring]: {
    key: fiDvmActionsId.views.ring,
    label: gettext('Ring View'),
    icon: 'chart-donut',
    exec: () => setView(fiDvmListViewType.MANAGED_RING_VIEW),
  },
  [fiDvmActionsId.views.folder]: {
    key: fiDvmActionsId.views.folder,
    label: gettext('Folder View'),
    icon: 'folder',
    exec: () => setView(fiDvmListViewType.MANAGED_FOLDER_VIEW),
  },
});
