import {
  put,
  select,
  call,
  takeLatest,
  takeEvery,
  delay,
  all,
} from 'redux-saga/effects';
import { get, sum } from 'lodash';
import * as actions from './actions';
import { insertAppTree } from 'fistore/routing/slice';
import { genAppTree } from 'fistore/routing/utils';
import { fiHttpGet } from 'fi-http';
import {
  getIsFazSupervisor,
  getUsername,
  hasFazFeature,
} from 'fistore/session/sysConfig/selectors';
import {
  getLogStorageStats,
  getLogSustainRateNotify,
  LOG_SUSTAIN_RATE_INIT,
  getCustomviewMenu,
} from './helpers';
import { callPromiseAction } from '../utils';
import { runIfAvailable } from '../routing/saga';
import { appEnabled } from 'fistore/routing/selectors';
import { setTask, getNumByGroup, setNum } from '../tasks';
import { notifyNotifyAction } from '../utils/action';
import { setLogDbRebuild, setLogFetcher, setLogviewContentMenu } from './slice';
import {
  setNotification,
  unsetNotification,
} from 'fistore/notifications/slice';
import {
  getSessionAdomOid,
  getSessionAdomName,
} from 'fistore/session/adom/selectors';
import { fetchSessionAdom } from 'fistore/session/adom/slice';

const INTERVAL = 500;

export function* watchLogviewChange() {
  yield takeEvery(
    // Should not watch admin profile load, because first profile loading there is no
    // app menu yet.
    actions.fetchLogviewContentMenu.type,
    function* (action) {
      yield callPromiseAction(action, fetchLogviewContentMenu);
    }
  );
  yield takeEvery(fetchSessionAdom.fulfilled.type, function* () {
    yield call(fetchLogviewContentMenu);
  });
  yield takeLatest(actions.fetchMemberTopology.type, function* (action) {
    yield callPromiseAction(
      action,
      runIfAvailable(appEnabled('logview'), function* () {
        // must return the response in order to pass it back to dispatcher.
        return yield call(fiHttpGet, '/p/fabric/socfabric/dvm/?level=member');
      })
    );
  });
  yield takeEvery(
    notifyNotifyAction.type,
    runIfAvailable(hasFazFeature, logFetchTasks)
  );
}

function getTreeMenu(params) {
  return fiHttpGet('/p/logview/loadLogviewMenu/', {
    params,
  });
}

function* fetchLogviewContentMenu() {
  const isFazSupervisor = yield select(getIsFazSupervisor);
  const adminUserName = yield select(getUsername);
  let resp = yield call(getTreeMenu);
  // resp can be an error object
  if (!resp?.code) {
    while (
      isFazSupervisor &&
      resp.tid &&
      (!resp.percentage || resp.percentage < 100)
    ) {
      yield delay(INTERVAL);
      resp = yield call(getTreeMenu, { tid: resp.tid });
    }

    const customviewApps = {};
    const customviewTree = genAppTree((node) => node.items)({
      items: getCustomviewMenu(adminUserName, resp.customview),
      fn: (def) => {
        const key = def.stateParams.appUniKey;
        customviewApps[key] = def;
        return key;
      },
    });
    const customviewMenu = {
      key: 'logview_logs_customview',
      subTree: customviewTree,
      apps: customviewApps,
    };
    yield put(setLogviewContentMenu(resp));
    yield put(insertAppTree(customviewMenu));
  }
  console.error(resp.message);
}

const TASK_FAZ = 'task_faz';
const LOG_FETCH_REQUEST_RUNNING_GROUP = 'log_fetcher_req_running';
const LOG_FETCH_RUNNING_GROUP = 'log_fetcher_running';

// logfetcher in progress
function _isFetchingLogs(fetchState) {
  return (
    MACROS.SYS.LOG_FETCH_SESSION_ST_SVR_LISTFILE <= fetchState &&
    fetchState <= MACROS.SYS.LOG_FETCH_SESSION_ST_STOPPING
  );
}

function _isNewFetchRequest(fetchState) {
  return fetchState === MACROS.SYS.LOG_FETCH_SESSION_ST_NEW;
}

function* setDbRebuild(payload) {
  const logDb = get(payload, ['fields', 'gLogdb']);
  yield put(setLogDbRebuild(logDb));

  const adom = yield select(getSessionAdomName);
  const newLogdbNum =
    adom === logDb.adom && logDb?.percent < 100 && logDb?.percent > 0 ? 1 : 0;
  // update the session logdb data

  if (newLogdbNum > 0) {
    yield put(
      setTask({
        id: 'db_rebuild',
        percent: logDb.percent /* || 10*/,
        title: gettext('Rebuild Log Database'),
        group: TASK_FAZ,
        params: logDb, // TODO: useless?
      })
    );
  } else {
    yield put(
      unsetNotification({
        id: 'db_rebuild',
      })
    );
  }
  // return the number of tasks here
  return yield newLogdbNum;
}
/*
function* setLogFetch(payload) {
  const logFetchRequests = get(payload, ['fields', 'gLogfetchRequests']);
  const logFetchings = get(payload, ['fields', 'gLogfetchings']);
  yield put(setLogFetcher({fetchings: logFetchings, requests: logFetchRequests}));

  const newReqNum = logFetchRequests?.result?.filter(i=>i['session-status']?.['comp-percent'] < 100)?.length || 0;
  const reqNum = (yield select(getNumByGroup(LOG_FETCH_REQUEST_GROUP))) || 0;
  if (newReqNum !==reqNum) {
    yield put(
      setTask({
        id: 'log_fetcher_requests',
        percent: 10, //logDb.percent || 10,
        title: gettext('log fetch request 1'),
        group: LOG_FETCH_REQUEST_GROUP,
        // params: logDb,
      })
    );
  }
  return yield newReqNum;
}
*/
/** combines all log db tasks
 */
function* logFetchTasks({ payload }) {
  if (payload.collection !== 'faz_status') return;
  const adomOid = yield select(getSessionAdomOid);
  const taskResults = yield all([
    call(setDbRebuild, payload),
    // more task handlers
    // call(setLogFetch, payload),
  ]);
  const numTasks = sum(taskResults);
  const logdbNum = yield select(getNumByGroup(TASK_FAZ));
  if (numTasks !== logdbNum) {
    yield put(setNum({ num: numTasks, group: TASK_FAZ }));
  }

  // const logFetchRequests = get(payload, ['fields', 'gLogfetchRequests']);
  // notifications
  // log fetcher request
  // const NOTICE_TYPE_LOG_FETCHER_REQUEST = 'gLogfetchRequests';
  const logFetchRequests = get(payload, ['fields', 'gLogfetchRequests']);
  const logFetchings = get(payload, ['fields', 'gLogfetchings']);
  yield put(
    setLogFetcher({ fetchings: logFetchings, requests: logFetchRequests })
  );

  // for task center
  const runningReqs =
    logFetchRequests?.result.filter((i) =>
      _isFetchingLogs(i['session-status']['state'])
    ) || [];
  if (runningReqs.length > 0) {
    let r;
    for (let i = 0; i < runningReqs.length; i++) {
      r = runningReqs[i]?.['session-status'];
      yield put(
        setTask({
          id: `log_fetcher_requests-${r['session-uuid']}`,
          percent: r['comp-percent'],
          title: gettext('Sending logs to %s').printf([r['clt.hostname']]),
          group: LOG_FETCH_REQUEST_RUNNING_GROUP,
          params: { task: runningReqs[i] },
        })
      );
    }
  }
  const oldRunningReqsNum = yield select(
    getNumByGroup(LOG_FETCH_REQUEST_RUNNING_GROUP)
  );
  if (runningReqs.length !== oldRunningReqsNum) {
    yield put(
      setNum({
        num: runningReqs.length,
        group: LOG_FETCH_REQUEST_RUNNING_GROUP,
      })
    );
  }

  const running =
    logFetchings?.result.filter((i) =>
      _isFetchingLogs(i['session-status']['state'])
    ) || [];
  if (running.length > 0) {
    let r;
    for (let i = 0; i < running.length; i++) {
      r = running[i];
      yield put(
        setTask({
          id: `log_fetchering-${r['session-status']['session-uuid']}`,
          percent: r['session-status']['comp-percent'],
          title: gettext('Fetching logs from %s').printf([
            r['clt.profile']['server-ip'],
          ]),
          group: LOG_FETCH_RUNNING_GROUP,
          params: { task: r },
        })
      );
    }
  }
  const oldRunningNum = yield select(getNumByGroup(LOG_FETCH_RUNNING_GROUP));
  if (running.length !== oldRunningNum) {
    yield put(setNum({ num: running.length, group: LOG_FETCH_RUNNING_GROUP }));
  }

  // for notification center
  const requestNum = (
    logFetchRequests?.result.filter((i) =>
      _isNewFetchRequest(i['session-status']['state'])
    ) || []
  ).length;
  if (requestNum > 0) {
    yield put(
      setNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_LOG_FETCHER_REQUESTS,
        // adomOid,
        severity: MACROS.NOTICE.NOTICE_LEVEL_NOTIFICATION,
        params: { num: requestNum },
      })
    );
  } else {
    yield put(
      unsetNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_LOG_FETCHER_REQUESTS,
      })
    );
  }

  // fabric supervisor auth member
  const fabricAuthState = get(payload, ['fields', 'fabricAuthState']);
  let fabricPendingNum;
  if ((fabricPendingNum = fabricAuthState?.data?.length) > 0) {
    yield put(
      setNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_FABRIC_PENDING_MEMBER,
        // adomOid,
        severity: fabricAuthState.severity, //MACROS.NOTICE.NOTICE_LEVEL_WARNING,
        params: {
          fabricPendingNum,
        },
      })
    );
  } else {
    yield put(
      unsetNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_FABRIC_PENDING_MEMBER,
      })
    );
  }

  // log sustain rate
  const gLogSustainRate = get(payload, ['fields', 'gLogSustainRate']);
  let logSustainNotf = false;
  if (gLogSustainRate) {
    let newLogSustainRate = { ...LOG_SUSTAIN_RATE_INIT };
    getLogSustainRateNotify(gLogSustainRate, newLogSustainRate);
    if (newLogSustainRate.notifLastMinuteDropped) {
      yield put(
        setNotification({
          id: MACROS.NOTICE.NOTICE_TYPE_LOG_SUSTAIN_RATE,
          // adomOid,
          severity: MACROS.NOTICE.NOTICE_LEVEL_WARNING,
          params: { logSustainRate: newLogSustainRate },
        })
      );
      logSustainNotf = true;
    }
  }

  if (!logSustainNotf) {
    yield put(
      unsetNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_LOG_SUSTAIN_RATE,
      })
    );
  }

  // log storage statics
  const logStorageStats = get(payload, ['fields', 'logStorageStats']);
  let dbWarning = false,
    fileWarning = false;
  if (
    logStorageStats?.db_quota_warning ||
    logStorageStats?.file_quota_warning
  ) {
    const adom = yield select(getSessionAdomName);
    if (logStorageStats.adom === adom) {
      let newLogStorageStats = getLogStorageStats(logStorageStats);
      if (newLogStorageStats.db_percent) {
        yield put(
          setNotification({
            id: MACROS.NOTICE.NOTICE_TYPE_LOG_STORAGE_STATICS_DB,
            adomOid,
            severity: MACROS.NOTICE.NOTICE_LEVEL_CRITICAL,
            params: { percent: newLogStorageStats.db_percent },
          })
        );
        dbWarning = true;
      }
      if (newLogStorageStats.file_percent) {
        yield put(
          setNotification({
            id: MACROS.NOTICE.NOTICE_TYPE_LOG_STORAGE_STATICS_FILE,
            adomOid,
            severity: MACROS.NOTICE.NOTICE_LEVEL_CRITICAL,
            params: { percent: newLogStorageStats.file_percent },
          })
        );
        fileWarning = true;
      }
    }
  }
  if (!dbWarning) {
    yield put(
      unsetNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_LOG_STORAGE_STATICS_DB,
      })
    );
  }
  if (!fileWarning) {
    yield put(
      unsetNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_LOG_STORAGE_STATICS_FILE,
      })
    );
  }

  // log
  const logInfo = get(payload, ['fields', MACROS.NOTICE.NOTICE_TYPE_LOG_INFO]);
  // if (!logInfo) {
  //   logInfo = {
  //     daily_max: 2000,
  //   };
  // }
  if (logInfo) {
    yield put(
      setNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_LOG_INFO,
        // adomOid,
        severity: MACROS.NOTICE.NOTICE_LEVEL_WARNING,
        params: { ...logInfo },
      })
    );
  } else {
    yield put(
      unsetNotification({
        id: MACROS.NOTICE.NOTICE_TYPE_LOG_INFO,
      })
    );
  }
}
