/**
 * Referenced from axios-concurrency
 * https://github.com/bernawil/axios-concurrency
 */

import type {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

type RequestHandler = {
  request: InternalAxiosRequestConfig;
  resolver: (req: InternalAxiosRequestConfig) => void;
};

type ManagerInstance = {
  maxConcurrent: number;
  queue: RequestHandler[];
  running: RequestHandler[];
  interceptors: {
    request: number | null;
    response: number | null;
  };
  shiftInitial: VoidFunction;
  push: (reqHandler: RequestHandler) => void;
  shift: VoidFunction;
  requestHandler: (
    req: InternalAxiosRequestConfig
  ) => Promise<InternalAxiosRequestConfig>;
  responseHandler: <T extends AxiosResponse | AxiosError>(res: T) => T;
  responseErrorHandler: (res: AxiosError) => Promise<never>;
  detach: VoidFunction;
};

export const concurrencyManager = (
  axios: AxiosInstance,
  maxConcurrent: number = 25
) => {
  if (maxConcurrent < 1)
    throw new Error(
      'Concurrency Manager Error: minimum concurrent requests is 1'
    );

  const instance: ManagerInstance = {
    maxConcurrent,
    queue: [],
    running: [],
    interceptors: {
      request: null,
      response: null,
    },
    shiftInitial: () => {
      setTimeout(() => {
        if (instance.running.length < instance.maxConcurrent) {
          instance.shift();
        }
      }, 0);
    },
    push: (reqHandler) => {
      instance.queue.push(reqHandler);
      instance.shiftInitial();
    },
    shift: () => {
      if (instance.queue.length) {
        const queued = instance.queue.shift() as RequestHandler;
        if (queued.request.signal?.aborted) {
          // the request was already cancelled - do not even start it, just forget it
          instance.shift();
        } else {
          queued.resolver(queued.request);
          instance.running.push(queued);
        }
      }
    },
    // Use as interceptor. Queue outgoing requests
    requestHandler: (req) => {
      return new Promise((resolve) => {
        instance.push({ request: req, resolver: resolve });
      });
    },
    // Use as interceptor. Execute queued request upon receiving a response
    responseHandler: (res) => {
      instance.running.shift();
      instance.shift();
      return res;
    },
    responseErrorHandler: (res) => {
      return Promise.reject(instance.responseHandler(res));
    },
    detach: () => {
      if (instance.interceptors.request !== null) {
        axios.interceptors.request.eject(instance.interceptors.request);
      }
      if (instance.interceptors.response !== null) {
        axios.interceptors.response.eject(instance.interceptors.response);
      }
    },
  };
  // queue concurrent requests
  instance.interceptors.request = axios.interceptors.request.use(
    instance.requestHandler
  );
  instance.interceptors.response = axios.interceptors.response.use(
    instance.responseHandler,
    instance.responseErrorHandler
  );
  return instance;
};
