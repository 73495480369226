import { getRedirectIndexRoute } from 'react_apps/components/redirect/redirect_route';
import { RenderErrorBoundary } from '../components/main/RenderErrorBoundary';
import { ReactContentViewContainer } from 'ra_main/main_layout/ReactContentViewContainer';

export const ALERT_ROUTES = {
  RenderErrorBoundary,
  children: [
    {
      path: '/alert-supv',
      handle: {
        appUniKey: 'fortisoc_supv',
      },
      children: [
        getRedirectIndexRoute({ redirectAppKey: 'fortisoc_supv_all' }),
        {
          handle: {
            appUniKey: 'fortisoc_supv_groups',
          },
          path: 'allgroups',
          lazy: () => import('./eventMonitor/index_lazy'),
          element: <ReactContentViewContainer menuId='fortisoc_supv_groups' />,
          children: [
            {
              // path: 'allgroups',
              path: ':mainType?/:groupUuid?',
              handle: {
                appUniKey: function (args) {
                  return args?.groupUuid || 'fortisoc_supv_all';
                },
              },
              lazy: () => import('./allAlertFabric/AlertGroupList'),
            },
            {
              path: 'settings',
              handle: {
                appUniKey: 'alert_supv_event_settings',
              },
              lazy: () => import('./OrganizeViews'),
            },
          ],
        },
        {
          path: 'local',
          handle: {
            appUniKey: 'fortisoc_supv_local',
          },
          lazy: () => import('./eventMonitor/EventMonitorMain'),
        },
        {
          element: <ReactContentViewContainer menuId='incidents' />,
          path: 'incident',
          handle: {
            appUniKey: 'fortisoc_supv_incidents',
          },
          children: [
            {
              path: '',
              handle: {
                appUniKey: 'incidents',
              },
              lazy: () => import('./incident/incidentList/IncidentList'),
            },
            {
              path: 'mitre/enterprise',
              handle: {
                appUniKey: 'mitre-enterprise',
              },
              children: [
                {
                  path: 'attack',
                  handle: {
                    appUniKey: 'mitre-enterprise-attack',
                    mitreDomain: 'enterprise',
                  },
                  lazy: () => import('./mitre/MitreAttackGrid'),
                },
                {
                  path: 'coverage',
                  handle: {
                    appUniKey: 'mitre-enterprise-coverage',
                    mitreDomain: 'enterprise',
                  },
                  lazy: () => import('./mitre/MitreCoverageGrid'),
                },
              ],
            },
            {
              path: 'mitre/ics',
              handle: {
                appUniKey: 'mitre-ics',
              },
              children: [
                {
                  path: 'attack',
                  handle: {
                    appUniKey: 'mitre-ics-attack',
                    mitreDomain: 'ics',
                  },
                  lazy: () => import('./mitre/MitreAttackGrid'),
                },
                {
                  path: 'coverage',
                  handle: {
                    appUniKey: 'mitre-ics-coverage',
                    mitreDomain: 'ics',
                  },
                  lazy: () => import('./mitre/MitreCoverageGrid'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/alert',
      handle: {
        appUniKey: 'fortisoc',
      },
      children: [
        getRedirectIndexRoute({ redirectAppKey: 'event_monitor' }),
        {
          path: 'eventmonitor',
          handle: {
            appUniKey: 'event_monitor',
          },
          lazy: () => import('./eventMonitor/index_lazy'),
          element: <ReactContentViewContainer menuId='event_monitor' />,
          children: [
            {
              path: ':mainType/:groupUuid?',
              handle: {
                appUniKey: function (args) {
                  return args?.groupUuid || 'alert_event_all';
                },
              },
              lazy: () => import('./eventMonitor/EventMonitorMain'),
            },
            {
              path: 'settings',
              handle: {
                appUniKey: 'alert_event_settings',
              },
              lazy: () => import('./OrganizeViews'),
            },
          ],
        },
        {
          path: 'handlers',
          handle: {
            appUniKey: 'event_handlers',
          },
          element: <ReactContentViewContainer menuId='event_handlers' />,
          children: [
            {
              path: 'ds',
              handle: {
                appUniKey: 'alert_data_selectors',
              },
              lazy: () =>
                import('./eventHandler/dataSelector/DataSelectorList'),
            },
            {
              path: 'np',
              handle: {
                appUniKey: 'alert_notification_profiles',
              },
              lazy: () =>
                import(
                  './eventHandler/notificationProfile/NotificationProfileList'
                ),
            },
            {
              path: 'bh',
              handle: {
                appUniKey: 'alert_basic_handlers',
              },
              lazy: () => import('./eventHandler/list/BasicEventHandlerList'),
            },
            {
              path: 'ch',
              handle: {
                appUniKey: 'alert_correlation_handlers',
              },
              lazy: () =>
                import('./eventHandler/list/CorrelationEventHandlerList'),
            },
          ],
        },
        {
          element: <ReactContentViewContainer menuId='incidents' />,
          path: 'incident-list',
          handle: {
            appUniKey: 'incidents',
          },
          children: [
            {
              path: '',
              handle: {
                appUniKey: 'incidents',
              },
              lazy: () => import('./incident/incidentList/IncidentList'),
            },
            {
              path: 'mitre/enterprise',
              handle: {
                appUniKey: 'mitre-enterprise',
              },
              children: [
                {
                  path: 'attack',
                  handle: {
                    appUniKey: 'mitre-enterprise-attack',
                    mitreDomain: 'enterprise',
                  },
                  lazy: () => import('./mitre/MitreAttackGrid'),
                },
                {
                  path: 'coverage',
                  handle: {
                    appUniKey: 'mitre-enterprise-coverage',
                    mitreDomain: 'enterprise',
                  },
                  lazy: () => import('./mitre/MitreCoverageGrid'),
                },
              ],
            },
            {
              path: 'mitre/ics',
              handle: {
                appUniKey: 'mitre-ics',
              },
              children: [
                {
                  path: 'attack',
                  handle: {
                    appUniKey: 'mitre-ics-attack',
                    mitreDomain: 'ics',
                  },
                  lazy: () => import('./mitre/MitreAttackGrid'),
                },
                {
                  path: 'coverage',
                  handle: {
                    appUniKey: 'mitre-ics-coverage',
                    mitreDomain: 'ics',
                  },
                  lazy: () => import('./mitre/MitreCoverageGrid'),
                },
              ],
            },
          ],
        },
        {
          // element: <ReactContentViewContainer menuId='indicators' />,
          path: 'indicator-list',
          handle: {
            appUniKey: 'indicators',
          },
          lazy: () => import('./indicators/IndicatorList'),
          // children: [
          //   {
          //     path: '',
          //     element: <React.Suspense fallback={<div>Loading...</div>}>
          //                {React.lazy(() => import('./indicators/IndicatorList'))}
          //              </React.Suspense>,
          //     handle: {
          //       appUniKey: 'indicators',
          //     },
          //   }
          // ]
        },

        {
          path: 'incident-analysis/:incid',
          handle: {
            appUniKey: 'incidents_analysis',
          },
          lazy: () => import('./incident/incidentAnalysis/IncidentAnalysis'),
        },
        {
          path: 'incident-demo',
          lazy: () => import('./incident/routes'),
        },
        // {
        //   path: 'incident',
        //   lazy: () => import('./ra_alert/incident/incidentAnalysis/IncidentAnalysis'),
        // },
        {
          element: <ReactContentViewContainer menuId='siem_parser' />,
          path: 'logparser',
          handle: {
            appUniKey: 'siem_parser',
          },
          children: [
            {
              path: 'assigned',
              handle: {
                appUniKey: 'assigned_siem_parser',
              },
              lazy: () => import('./logparser/AssignedParsers'),
            },
            {
              path: 'list',
              handle: {
                appUniKey: 'siem_parser_list',
              },
              lazy: () => import('./logparser/LogParsers'),
            },
          ],
        },
      ],
    },
  ],
};

export default ALERT_ROUTES;
