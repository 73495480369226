import { pick, negate, mapValues } from 'lodash';
import { inGlobalAdom, checkVersion } from 'fistore/session/adom/selectors';
import { hasRDPermitOn } from 'fistore/session/profile/selectors';
import { getShowScript } from 'fistore/session/sysConfig/selectors';
import {
  isVwpInDisplayOpts,
  vwpChecker,
  syntaxChecker,
  makeDisplayOptChecker,
} from './common_checkers';

const checkerDefs = {
  intf_single: {
    mustShow: true,
    showIfDisplayOpts: negate(isVwpInDisplayOpts),
    checkers: [
      negate(vwpChecker),
      syntaxChecker(['dynamic interface', 'system interface']),
    ],
  },
  intf: {
    mustShow: true,
    showIfDisplayOpts: isVwpInDisplayOpts,
    checkers: [
      vwpChecker,
      syntaxChecker(['dynamic interface', 'system interface']),
    ],
  },
  vwp_intf: {
    showIfDisplayOpts: isVwpInDisplayOpts,
    checkers: [syntaxChecker(['system virtual-wire-pair'])],
  },
  address: {
    mustShow: true,
    checkers: [
      syntaxChecker([
        'firewall address',
        'firewall addrgrp',
        'firewall address6',
        'firewall addrgrp6',
        'firewall address6-template',
        'firewall proxy-address',
        'firewall proxy-addrgrp',
        'firewall multicast-address',
        'firewall multicast-address6',
        'firewall wildcard-fqdn custom',
        'firewall wildcard-fqdn group',
      ]),
    ],
  },
  app_isc: {
    defaultShow: true,
    checkers: [
      syntaxChecker([
        'firewall internet-service',
        'firewall internet-service-name',
        'firewall internet-service-group',
        'firewall internet-service-custom',
        'firewall internet-service-custom-group',
      ]),
    ],
  },
  network_service: {
    defaultShow: true,
    checkers: [syntaxChecker(['firewall network-service-dynamic'])],
  },
  service: {
    mustShow: true,
    checkers: [
      syntaxChecker(['firewall service custom', 'firewall service group']),
    ],
  },
  schedule: {
    mustShow: true,
    checkers: [
      syntaxChecker([
        'firewall schedule onetime',
        'firewall schedule recurring',
        'firewall schedule group',
      ]),
    ],
  },
  vip: {
    mustShow: true,
    checkers: [
      syntaxChecker([
        'firewall vip',
        'firewall vipgrp',
        'firewall vip6',
        'firewall vipgrp6',
        'firewall vip46',
        'firewall vipgrp46',
        'firewall vip64',
        'firewall vipgrp64',
      ]),
    ],
  },
  ippool: {
    mustShow: true,
    checkers: [
      syntaxChecker([
        'firewall ippool',
        'firewall ippool6',
        'firewall ippool_grp',
      ]),
    ],
  },
  trafficshaper: {
    checkers: [
      syntaxChecker([
        'firewall shaper traffic-shaper',
        'firewall shaper per-ip-shaper',
      ]),
    ],
  },
  shapingprofile: {
    checkers: [syntaxChecker(['firewall shaping-profile'])],
  },
  virtualserver: {
    checkers: [syntaxChecker(['firewall vip', 'firewall vip6'])],
  },
  healthmonitor: {
    checkers: [syntaxChecker(['firewall ldb-monitor'])],
  },
  fwdserver: {
    checkers: [syntaxChecker(['web-proxy forward-server'])],
  },
  authscheme: {
    checkers: [syntaxChecker(['authentication scheme'])],
  },
  ztna: {
    defaultShow: true,
    checkers: [
      syntaxChecker(['firewall access-proxy', 'firewall access-proxy6']),
    ],
  },
  'ztna-server': {
    showIfDisplayOpts: (dspOpts) => dspOpts.includes('ztna'),
    checkers: [
      syntaxChecker(['firewall access-proxy', 'firewall access-proxy6']),
    ],
  },
  'ztna-tag': {
    showIfDisplayOpts: (dspOpts) => dspOpts.includes('ztna'),
    checkers: [
      syntaxChecker(['firewall address', 'firewall addrgrp']),
      checkVersion('7.0', '>='),
    ],
  },
  avprof: {
    mustShow: true,
    checkers: [syntaxChecker(['antivirus profile'])],
  },
  casiprof: {
    defaultShow: true,
    checkers: [syntaxChecker(['application casi profile'])],
  },
  webprof: {
    mustShow: true,
    checkers: [syntaxChecker(['webfilter profile'])],
  },
  videoprof: {
    checkers: [syntaxChecker(['videofilter profile'])],
  },
  dnsprof: {
    defaultShow: true,
    checkers: [syntaxChecker(['dnsfilter profile'])],
  },
  appprof: {
    mustShow: true,
    checkers: [syntaxChecker(['application list'])],
  },
  ipsprof: {
    mustShow: true,
    checkers: [syntaxChecker(['ips sensor']), hasRDPermitOn('ips_object_cfg')],
  },
  filefilterprofile: {
    defaultShow: true,
    checkers: [syntaxChecker(['file-filter profile'])],
  },
  emailprof: {
    checkers: [syntaxChecker(['spamfilter profile'])],
  },
  emailfilterprof: {
    checkers: [syntaxChecker(['emailfilter profile'])],
  },
  dlpprof: {
    checkers: [syntaxChecker(['dlp sensor']), checkVersion('6.2', '<')],
  },
  virtualpatchprof: {
    checkers: [syntaxChecker(['virtual-patch profile'])],
  },
  voipprof: {
    checkers: [syntaxChecker(['voip profile'])],
  },
  icapprof: {
    checkers: [syntaxChecker(['icap profile'])],
  },
  wafprof: {
    defaultShow: true,
    checkers: [syntaxChecker(['waf profile'])],
  },
  dlp: {
    defaultShow: true,
    checkers: [
      syntaxChecker(['dlp profile', 'dlp sensor', 'dlp dictionary']),
      checkVersion('7.2', '>='),
    ],
  },
  proxyopt: {
    defaultShow: true,
    checkers: [syntaxChecker(['firewall profile-protocol-options'])],
  },
  sslprof: {
    mustShow: true,
    checkers: [
      syntaxChecker(['firewall ssl-ssh-profile']),
      hasRDPermitOn('ips_object_cfg'),
    ],
  },
  mmsprof: {
    checkers: [syntaxChecker(['firewall mms-profile'])],
  },
  gtpprof: {
    checkers: [syntaxChecker(['firewall gtp'])],
  },
  gtpobjs: {
    checkers: [
      syntaxChecker([
        'gtp apn',
        'gtp apngrp',
        'gtp tunnel-limit',
        'gtp ie-white-list',
        'gtp message-filter-v0v1',
        'gtp message-filter-v2',
      ]),
    ],
  },
  profgrp: {
    checkers: [syntaxChecker(['firewall profile-group'])],
  },
  appsignature: {
    mustShow: true,
    checkers: [syntaxChecker(['application custom'])],
  },
  virtualpatchsign: {
    mustShow: true,
  },
  app_grp: {
    checkers: [syntaxChecker(['application group'])],
  },
  ipssign: {
    mustShow: true,
    checkers: [syntaxChecker(['ips custom']), hasRDPermitOn('ips_object_cfg')],
  },
  emailfilterlist: {
    checkers: [syntaxChecker(['emailfilter bwl'])],
  },
  emailfilterblockallowlist: {
    checkers: [syntaxChecker(['emailfilter block-allow-list'])],
  },
  webratingoverride: {
    checkers: [syntaxChecker(['webfilter ftgd-local-rating'])],
  },
  weburlfilter: {
    checkers: [syntaxChecker(['webfilter urlfilter'])],
  },
  webcontentfilter: {
    checkers: [syntaxChecker(['webfilter content'])],
  },
  localcategory: {
    checkers: [syntaxChecker(['webfilter ftgd-local-cat'])],
  },
  icapserver: {
    checkers: [syntaxChecker(['icap server'])],
  },
  dlpfingerprint: {
    checkers: [syntaxChecker(['dlp filepattern'])],
  },
  youtubechannelfilter: {
    checkers: [syntaxChecker(['videofilter youtube-channel-filter'])],
  },
  securityfabric: {
    defaultShow: true,
    checkers: [syntaxChecker(['endpoint-control fctems', 'user fsso'])],
  },
  sdn: {
    defaultShow: true,
    checkers: [syntaxChecker(['system sdn-connector'])],
  },
  sso: {
    defaultShow: true,
    checkers: [
      syntaxChecker([
        'user fsso-polling',
        'user fsso',
        'user radius',
        'user connector',
        'user pxgrid',
        'user clearpass',
        'user nsx',
        'user flexvm',
        'user vcenter',
        'system sdn-connector',
        'user exchange',
        'user json',
      ]),
    ],
  },
  externalresource: {
    checkers: [syntaxChecker(['system external-resource'])],
  },
  'cloud-orchestration': {
    mustShow: true,
    checkers: [syntaxChecker(['cloud orchestration'])],
  },
  'cloud-orchestration-aws': {
    mustShow: true,
    checkers: [syntaxChecker(['cloud orchest-aws'])],
  },
  'cloud-orchestration-awsconn​': {
    mustShow: true,
    checkers: [syntaxChecker(['cloud orchest-awsconnector'])],
  },
  'cloud-orchestration​-aws-templates': {
    mustShow: true,
    checkers: [
      syntaxChecker([
        'cloud orchest-awstemplate autoscale-existing-vpc',
        'cloud orchest-awstemplate autoscale-new-vpc',
        'cloud orchest-awstemplate autoscale-tgw-new-vpc',
      ]),
    ],
  },
  defineduser: {
    mustShow: true,
    checkers: [syntaxChecker(['user local'])],
  },
  usrgrp: {
    mustShow: true,
    checkers: [syntaxChecker(['user group'])],
  },
  ldapusr: {
    mustShow: true,
    checkers: [syntaxChecker(['user ldap'])],
  },
  radiususr: {
    mustShow: true,
    checkers: [syntaxChecker(['user radius'])],
  },
  tacacsusr: {
    checkers: [syntaxChecker(['user tacacs+'])],
  },
  samlusr: {
    defaultShow: true,
    checkers: [syntaxChecker(['user saml'])],
  },
  pop3: {
    checkers: [syntaxChecker(['user pop3'])],
  },
  pki: {
    checkers: [syntaxChecker(['user peer'])],
  },
  pki_grp: {
    checkers: [syntaxChecker(['user peergrp'])],
  },
  sms: {
    checkers: [syntaxChecker(['system sms-server'])],
  },
  fortitoken: {
    defaultShow: true,
    checkers: [syntaxChecker(['user fortitoken'])],
  },
  wanoptprof: {
    checkers: [syntaxChecker(['wanopt profile'])],
  },
  peer: {
    checkers: [syntaxChecker(['wanopt peer'])],
  },
  authgrp: {
    checkers: [syntaxChecker(['wanopt auth-group'])],
  },
  loccert: {
    checkers: [syntaxChecker(['dynamic certificate local'])],
  },
  vpntunnel: {
    checkers: [syntaxChecker(['dynamic vpntunnel'])],
  },
  replacemsg: {
    checkers: [syntaxChecker(['system replacemsg-group'])],
  },
  localcert: {
    checkers: [syntaxChecker(['certificate local'])],
  },
  cacert: {
    checkers: [syntaxChecker(['vpn certificate ca'])],
  },
  remotecert: {
    checkers: [syntaxChecker(['vpn certificate remote'])],
  },
  tags: {
    checkers: [syntaxChecker(['system object-tag'])],
  },
  fmgvariable: {
    defaultShow: true,
    checkers: [syntaxChecker(['fmg variable'])],
  },
  scripts: {
    mustShow: true,
    checkers: [
      inGlobalAdom,
      getShowScript,
      hasRDPermitOn(MACROS.USER.DVM.ADMINPRIV_SCRIPT_ACCESS),
    ],
  },
  decryptedTrafficMirror: {
    defaultShow: true,
    checkers: [syntaxChecker(['firewall decrypted-traffic-mirror'])],
  },
  imageanalysis: {
    defaultShow: true,
    checkers: [syntaxChecker(['image-analyzer'])],
  },
  icapremoteserver: {
    defaultShow: true,
    checkers: [syntaxChecker(['icap remote-server'])],
  },
  icaploadbalance: {
    defaultShow: true,
    checkers: [syntaxChecker(['icap remote-server-group'])],
  },
  usrdev: {
    mustShow: true,
    checkers: [
      syntaxChecker([
        'user device',
        'user device-category',
        'user device-group',
      ]),
    ],
  },
  service_now: {
    checkers: [syntaxChecker(['/system/webhook'])],
  },
  faz_conn: {
    checkers: [syntaxChecker(['/system/connectors'])],
  },
  cliopt: {
    defaultShow: true,
  },
  casbprof: {
    checkers: [syntaxChecker(['casb profile'])],
  },
  userexternalidentity: {
    checkers: [syntaxChecker(['user external-identity-provider'])],
  },
};

export const objAppCheckers = mapValues(checkerDefs, (checkerDef, appkey) => ({
  ...pick(checkerDef, [
    'checkers',
    'mustShow', // display option use
    'defaultShow', // display option use
  ]),
  displayOptChecker: makeDisplayOptChecker(appkey, checkerDef),
}));
