const v = {
  pno_obj_normalized_intf: {
    label: gettext('Normalized Interface'),
    // icon: 'interface',
    section: true,
  },
  pno_obj_intf_single: {
    olhTag: 'pno_obj_intf_single',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Normalized Interface"',

    label: gettext('Normalized Interface'),
  },
  pno_obj_intf: {
    olhTag: 'pno_obj_intf',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Normalized Interface" -> "Normalized Interface"',

    label: gettext('Normalized Interface'),
  },
  pno_obj_vwp_intf: {
    olhTag: 'pno_obj_vwp',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Normalized Interface" -> "Virtual Wire Pair"',

    label: gettext('Virtual Wire Pair'),
  },
  pno_obj_fw: {
    olhTag: 'pno_obj_fw',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects"',

    label: gettext('Firewall Objects'),
    // icon: 'policy-objects',
    section: true,
  },
  pno_obj_address: {
    olhTag: 'pno_obj_fw_addr',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Addresses"',

    label: gettext('Addresses'),
  },
  pno_obj_app_isc: {
    olhTag: 'pno_obj_fw_isc',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Internet Service"',

    label: gettext('Internet Service'),
  },
  pno_obj_network_service: {
    olhTag: 'pno_obj_fw_network_svc',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Network Service"',

    label: gettext('Network Service'),
  },
  pno_obj_service: {
    olhTag: 'pno_obj_fw_svc',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Services"',

    label: gettext('Services'),
  },
  pno_obj_schedule: {
    olhTag: 'pno_obj_fw_schd',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Schedules"',

    label: gettext('Schedules'),
  },
  pno_obj_vip: {
    olhTag: 'pno_obj_fw_vip',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Virtual IPs"',

    label: gettext('Virtual IPs'),
  },
  pno_obj_ippool: {
    olhTag: 'pno_obj_fw_ippool',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "IP Pools"',

    label: gettext('IP Pools'),
  },
  pno_obj_trafficshaper: {
    olhTag: 'pno_obj_fw_tshapper',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Traffic Shapers"',

    label: gettext('Traffic Shapers'),
  },
  pno_obj_shapingprofile: {
    olhTag: 'pno_obj_fw_shaping',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Shaping Profile"',

    label: gettext('Shaping Profile'),
  },
  pno_obj_virtualserver: {
    olhTag: 'pno_obj_fw_vsvr',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Virtual Servers"',

    label: gettext('Virtual Servers'),
  },
  pno_obj_healthmonitor: {
    olhTag: 'pno_obj_fw_healthchk',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "Health Check"',

    label: gettext('Health Check'),
  },
  pno_obj_fwdserver: {
    olhTag: 'pno_obj_fw_proxy_fwd_svr',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Firewall Objects" -> "Web Proxy Forwarding Server"',

    label: gettext('Web Proxy Forwarding Server'),
  },
  pno_obj_authscheme: {
    olhTag: 'pno_obj_fw_auth_scheme',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Firewall Objects" -> "Authentication Scheme"',

    label: gettext('Authentication Scheme'),
  },
  'pno_obj_ztna-server': {
    olhTag: 'pno_obj_fw_ztna_svr',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Firewall Objects" -> "ZTNA Server"',

    label: gettext('ZTNA Server'),
  },
  'pno_obj_ztna-tag': {
    olhTag: 'pno_obj_fw_ztna_tag',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Firewall Objects" -> "Security Posture Tag"',

    label: gettext('Security Posture Tag'),
  },
  pno_obj_utmprofile: {
    olhTag: 'pno_obj_prof',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles"',

    label: gettext('Security Profiles'),
    // icon: 'security',
    section: true,
    notBackupAdom: true,
  },
  pno_obj_avprof: {
    olhTag: 'pno_obj_prof_av',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "AntiVirus"',

    label: gettext('AntiVirus'),
  },
  pno_obj_casiprof: {
    olhTag: 'pno_obj_prof_casi',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "CASI"',

    label: gettext('CASI'),
  },
  pno_obj_webprof: {
    olhTag: 'pno_obj_prof_wf',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Web Filter"',

    label: gettext('Web Filter'),
  },
  pno_obj_videoprof: {
    olhTag: 'pno_obj_prof_video',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Video Filter"',

    label: gettext('Video Filter'),
  },
  pno_obj_dnsprof: {
    olhTag: 'pno_obj_prof_dns_fltr',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "DNS Filter"',

    label: gettext('DNS Filter'),
  },
  pno_obj_appprof: {
    olhTag: 'pno_obj_prof_dns_fltr',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "DNS Filter"',

    label: gettext('Application Control'),
  },
  pno_obj_casbprof: {
    olhTag: 'pno_obj_prof_inline_casb',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Inline CASB"',

    label: gettext('Inline CASB'),
  },
  pno_obj_ipsprof: {
    olhTag: 'pno_obj_prof_ips',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Intrusion Prevention"',

    label: gettext('Intrusion Prevention'),
  },
  pno_obj_filefilterprofile: {
    olhTag: 'pno_obj_prof_file_fltr',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "File Filter Profile"',

    label: gettext('File Filter Profile'),
  },
  pno_obj_emailprof: {
    olhTag: 'pno_obj_prof_antispam',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Anti-Spam"',

    label: gettext('Anti-Spam'),
  },
  pno_obj_emailfilterprof: {
    olhTag: 'pno_obj_prof_email_fltr',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Email Filter"',

    label: gettext('Email Filter'),
  },
  pno_obj_dlpprof: {
    olhTag: 'pno_obj_prof_dlp_prof',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Data Loss Prevention"',

    label: gettext('Data Loss Prevention'),
  },
  pno_obj_virtualpatchprof: {
    olhTag: 'pno_obj_prof_vp',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Virtual Patching"',

    label: gettext('Virtual Patching'),
  },
  pno_obj_voipprof: {
    olhTag: 'pno_obj_prof_voip',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "VoIP"',

    label: gettext('VoIP'),
  },
  pno_obj_icapprof: {
    olhTag: 'pno_obj_prof_icap',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "ICAP"',

    label: gettext('ICAP'),
  },
  pno_obj_wafprof: {
    olhTag: 'pno_obj_prof_waf',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Web Application Firewall"',

    label: gettext('Web Application Firewall'),
  },
  pno_obj_dlp: {
    olhTag: 'pno_obj_prof_dlp',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Data Loss Prevention"',

    label: gettext('Data Loss Prevention'),
  },
  pno_obj_proxyopt: {
    olhTag: 'pno_obj_prof_protocol_opt',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Protocol Options"',

    label: gettext('Protocol Options'),
  },
  pno_obj_sslprof: {
    olhTag: 'pno_obj_prof_ssl_ssh',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "SSL/SSH Inspection"',

    label: gettext('SSL/SSH Inspection'),
  },
  pno_obj_mmsprof: {
    olhTag: 'pno_obj_prof_mms',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "MMS Profile"',

    label: gettext('MMS Profile'),
  },
  pno_obj_gtpprof: {
    olhTag: 'pno_obj_prof_gtp',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "GTP Profile"',

    label: gettext('GTP Profile'),
  },
  pno_obj_gtpobjs: {
    olhTag: 'pno_obj_prof_gtp_obj',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "GTP Objects"',

    label: gettext('GTP Objects'),
  },
  pno_obj_profgrp: {
    olhTag: 'pno_obj_prof_grp',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Profile Group"',

    label: gettext('Profile Group'),
  },
  pno_obj_appsignature: {
    olhTag: 'pno_obj_prof_app_sign',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Application Signatures"',

    label: gettext('Application Signatures'),
  },
  pno_obj_virtualpatchsign: {
    olhTag: 'pno_obj_prof_vp_sign',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Virtual Patching Signatures"',

    label: gettext('Virtual Patching Signatures'),
  },
  pno_obj_app_grp: {
    olhTag: 'pno_obj_prof_app_grp',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Application Group"',

    label: gettext('Application Group'),
  },
  pno_obj_ipssign: {
    olhTag: 'pno_obj_prof_ips_sign',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "IPS Signatures"',

    label: gettext('IPS Signatures'),
  },
  pno_obj_emailfilterlist: {
    olhTag: 'pno_obj_prof_emailfilterlist',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Email List"',

    label: gettext('Email List'),
  },
  pno_obj_emailfilterblockallowlist: {
    olhTag: 'pno_obj_prof_emailfilterblockallowlist',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Email List"',

    label: gettext('Email List'),
  },
  pno_obj_webratingoverride: {
    olhTag: 'pno_obj_prof_webratingoverride',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Web Rating Overrides"',

    label: gettext('Web Rating Overrides'),
  },
  pno_obj_weburlfilter: {
    olhTag: 'pno_obj_prof_weburlfilter',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "Web URL Filter"',

    label: gettext('Web URL Filter'),
  },
  pno_obj_webcontentfilter: {
    olhTag: 'pno_obj_prof_webcontentfilter',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Web Content Filter"',

    label: gettext('Web Content Filter'),
  },
  pno_obj_localcategory: {
    olhTag: 'pno_obj_prof_localcategory',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Web Filter Local Category"',

    label: gettext('Web Filter Local Category'),
  },
  pno_obj_icapserver: {
    olhTag: 'pno_obj_prof_icapserver',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "ICAP Servers"',

    label: gettext('ICAP Servers'),
  },
  pno_obj_dlpfingerprint: {
    olhTag: 'pno_obj_prof_dlpfingerprint',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "File Filter"',

    label: gettext('File Filter'),
  },
  pno_obj_youtubechannelfilter: {
    olhTag: 'pno_obj_prof_youtubechannelfilter',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Security Profiles" -> "Video YouTube Channel Filter"',

    label: gettext('Video YouTube Channel Filter'),
  },
  pno_obj_connectors: {
    olhTag: 'pno_obj_connectors',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Fabric"',

    label: gettext('Security Fabric'),
    section: true,
  },
  pno_obj_sdn: {
    olhTag: 'pno_obj_connectors_sdn',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "SDN Connectors"',

    label: gettext('SDN Connectors'),
  },
  pno_obj_securityfabric: {
    olhTag: 'pno_obj_connectors_sc_fabric',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Fabric Connectors"',

    label: gettext('Fabric Connectors'),
  },
  pno_obj_sso: {
    olhTag: 'pno_obj_connectors_sso',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Endpoint/Identity"',

    label: gettext('Endpoint/Identity'),
  },
  pno_obj_externalresource: {
    olhTag: 'pno_obj_connectors_threat',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Threat Feeds"',

    label: gettext('Threat Feeds'),
  },
  pno_obj_user: {
    olhTag: 'pno_obj_user',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication"',

    label: gettext('User & Authentication'),
    // icon: 'administrators',
    section: true,
    notBackupAdom: true,
  },
  pno_obj_defineduser: {
    olhTag: 'pno_obj_user_def',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "User & Authentication" -> "User Definition"',

    label: gettext('User Definition'),
  },
  pno_obj_usrgrp: {
    olhTag: 'pno_obj_user_grp',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "User Groups"',

    label: gettext('User Groups'),
  },
  pno_obj_ldapusr: {
    olhTag: 'pno_obj_user_grp',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "LDAP Servers"',

    label: gettext('LDAP Servers'),
  },
  pno_obj_radiususr: {
    olhTag: 'pno_obj_user_radius',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "User & Authentication" -> "RADIUS Servers"',

    label: gettext('RADIUS Servers'),
  },
  pno_obj_tacacsusr: {
    olhTag: 'pno_obj_user_tacacs',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "User & Authentication" -> "TACACS+ Servers"',

    label: gettext('TACACS+ Servers'),
  },
  pno_obj_samlusr: {
    olhTag: 'pno_obj_user_saml_svr',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "User & Authentication" -> "Single Sign-On"',

    label: gettext('Single Sign-On'),
  },
  pno_obj_pop3: {
    olhTag: 'pno_obj_user_pop3',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "POP3 Users"',

    label: gettext('POP3 Users'),
  },
  pno_obj_pki: {
    olhTag: 'pno_obj_user_pki',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "PKI"',

    label: gettext('PKI'),
  },
  pno_obj_pki_grp: {
    olhTag: 'pno_obj_user_pki_grp',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "PKI Groups"',

    label: gettext('PKI Groups'),
  },
  pno_obj_sms: {
    olhTag: 'pno_obj_user_sms',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "SMS Services"',

    label: gettext('SMS Services'),
  },
  pno_obj_fortitoken: {
    olhTag: 'pno_obj_user_ftoken',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "FortiTokens"',

    label: gettext('FortiTokens'),
  },
  pno_obj_userexternalidentity: {
    olhTag: 'pno_obj_user_ftoken',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "FortiTokens"',

    label: gettext('External Identity Provider'),
  },
  pno_obj_wanopt: {
    olhTag: 'pno_obj_wanopt',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "WAN Optimize"',

    label: gettext('WAN Optimize'),
    icon: 'wifi',
    section: true,
  },
  pno_obj_wanoptprof: {
    olhTag: 'pno_obj_wanoptprof',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Security Profiles" -> "WAN Optimize"',

    label: gettext('Profile'),
  },
  pno_obj_peer: {
    olhTag: 'pno_obj_user_peer',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "User & Authentication" -> "Peer"',

    label: gettext('Peer'),
  },
  pno_obj_authgrp: {
    olhTag: 'pno_obj_user_authgrp',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "User & Authentication" -> "Authentication Group"',

    label: gettext('Authentication Group'),
  },
  pno_obj_extradynobj: {
    olhTag: 'pno_obj_extradynobj',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Dynamic Object"',

    label: gettext('Dynamic Object'),
    // icon: 'ha',
    section: true,
  },
  pno_obj_loccert: {
    olhTag: 'pno_obj_loccert',
    olhCategory: 'pno',
    olhText:
      '"Policy & Objects" -> "Dynamic Object" -> "Dynamic Local Certificate"',

    label: gettext('Dynamic Local Certificate'),
  },
  pno_obj_vpntunnel: {
    olhTag: 'pno_obj_vpntunnel',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Dynamic Object" -> "Dynamic VPN Tunnel"',

    label: gettext('Dynamic VPN Tunnel'),
  },
  pno_obj_advanced: {
    olhTag: 'pno_obj_advanced',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Advanced"',

    label: gettext('Advanced'),
    // icon: 'advanced-settings',
    section: true,
  },
  pno_obj_replacemsg: {
    olhTag: 'pno_obj_replacemsg',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Advanced" -> "Replacement Message Group"',

    label: gettext('Replacement Message Group'),
  },
  pno_obj_localcert: {
    olhTag: 'pno_obj_localcert',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Advanced" -> "Local Certificates"',

    label: gettext('Local Certificates'),
  },
  pno_obj_cacert: {
    olhTag: 'pno_obj_cacert',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Advanced" -> "CA Certificates"',

    label: gettext('CA Certificates'),
  },
  pno_obj_remotecert: {
    label: gettext('Remote Certificates'),
  },
  pno_obj_tags: {
    olhTag: 'pno_obj_tags',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Advanced" -> "Tag Management"',

    label: gettext('Tag Management'),
  },
  pno_obj_fmgvariable: {
    olhTag: 'pno_obj_fmgvariable',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Advanced" -> "Metadata Variables"',

    label: gettext('Metadata Variables'),
  },
  pno_obj_cliopt: {
    olhTag: 'pno_obj_cliopt',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Advanced" -> "CLI Configurations"',

    label: gettext('CLI Configurations'),
    // icon: 'advanced',
  },
  pno_obj_scripts: {
    olhTag: 'pno_obj_scripts',
    olhCategory: 'pno',
    olhText: '"Policy & Objects" -> "Advanced" -> "Scripts"',

    label: gettext('Scripts'),
    // icon: 'cli',
  },
};

export default v;
