import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, head, isFunction, isNil } from 'lodash';

import { fiFmgHttp } from 'fi-http';
import { listenerMiddleware } from 'fistore/middlewares';

import {
  getSessionAdomData,
  getSessionAdomName,
  getSessionAdomOid,
} from '../../session/adom/selectors';
import { flatui_proxy, getDataFromResponse } from '../../utils';
import { getSaseDevicesList } from './selector';
import { getHasSaseLicense, isFmg } from 'fistore/session/sysConfig/selectors';
import { fetchSessionAdom } from 'fistore/session/adom/slice';

const initialState = {};

const _slice = createSlice({
  name: 'sase',
  initialState,
  reducers: {
    startFetchingSaseDevices(state, { payload }) {
      const { adomOid } = payload || {};
      if (isNil(adomOid)) return;

      if (!state[adomOid]) {
        state[adomOid] = {};
      }
      state[adomOid].data = {
        loaded: false,
        loading: true,
      };
    },
    startFetchingSaseDeviceStatus(state, { payload }) {
      const { adomOid } = payload || {};
      if (isNil(adomOid)) return;

      if (!state[adomOid]) {
        state[adomOid] = {};
      }
      state[adomOid].status = {
        loaded: false,
        loading: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // fetching sase devices
      .addCase(fetchSaseDevices.fulfilled, (state, { payload }) => {
        const { adomOid, data } = payload || {};
        if (isNil(adomOid)) return;

        state[adomOid].data = {
          loaded: true,
          loading: false,
          ...(data || {}),
        };
      })
      .addCase(fetchSaseDevices.rejected, (state, { payload }) => {
        const { adomOid } = payload || {};
        if (isNil(adomOid)) return;

        state[adomOid].data = {
          loaded: false,
          loading: false,
        };
      })

      // fetching sase device status
      .addCase(fetchSaseDeviceStatus.fulfilled, (state, { payload }) => {
        const { adomOid, data } = payload || {};
        if (isNil(adomOid)) return;

        state[adomOid].status = {
          loaded: true,
          loading: false,
          ...(data || {}),
        };
      })
      .addCase(fetchSaseDeviceStatus.rejected, (state, { payload }) => {
        const { adomOid } = payload || {};
        if (isNil(adomOid)) return;

        state[adomOid].status = {
          loaded: false,
          loading: false,
        };
      })

      // fetching sase central management status
      .addCase(getSaseController.fulfilled, (state, { payload }) => {
        state.connectorStatus = payload;
      });
  },
});

export default _slice.reducer;
export const { startFetchingSaseDevices, startFetchingSaseDeviceStatus } =
  _slice.actions;

export const checkIfShowSaseNotification = createAction(
  'devices/checkIfShowSaseNotification'
);

export const fetchSaseDevices = createAsyncThunk(
  'devices/fetchSaseDevices',
  async (
    payload,
    { getState, dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    const state = getState();
    const adomOid = payload?.adomOid || getSessionAdomOid(state);
    const fields = payload?.fields || [
      'all',
      {
        vdoms: ['all'],
      },
    ];

    try {
      dispatch(startFetchingSaseDevices({ adomOid }));
      const resp = await flatui_proxy({
        url: `/gui/adoms/${adomOid}/devices/items`,
        method: 'getByOsType',
        params: {
          os_type: MACROS.DVM.DVM_OS_TYPE_FSS, // fortisase os type
          fields,
        },
      });

      const fulfilledValue = {
        adomOid,
        data: getDataFromResponse(resp),
      };
      return fulfillWithValue(fulfilledValue);
    } catch (error) {
      return rejectWithValue({ error, adomOid });
    }
  }
);

// TODO: This status object might be per sase device later > change if needed
export const fetchSaseDeviceStatus = createAsyncThunk(
  'devices/fetchSaseDeviceStatus',
  async (
    payload,
    { getState, dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    const adom = payload?.adom || getSessionAdomData(getState());
    const device = head(getSaseDevicesList(getState()) || []);
    const deviceName = payload?.deviceName || get(device, 'name');
    dispatch(startFetchingSaseDeviceStatus({ adomOid: adom?.oid }));

    if (!deviceName) {
      return {
        adomOid: adom.oid,
        data: null,
      };
    }

    try {
      const scope = {
        name: deviceName,
        vdom: MACROS.DVM.DVM_ADOM_FOS_ROOT_NAME,
      };
      const resp = await fiFmgHttp.forward({
        method: 'get',
        params: [
          {
            url: `pm/config/adom/${adom.name}/obj/fmg/sase-manager/status`,
            'scope member': scope,
          },
        ],
      });

      const fulfilledValue = {
        adomOid: adom.oid,
        data: get(resp, '0.data'),
      };
      return fulfillWithValue(fulfilledValue);
    } catch (error) {
      return rejectWithValue({ error, adomOid: adom.oid });
    }
  }
);

export const addSaseDevice = createAsyncThunk(
  'devices/addSaseDevice',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
      const adomName = payload?.adomName || getSessionAdomName(getState());
      const callback = payload?.callback;
      const createTask = payload?.createTask;

      const flags = [];
      if (createTask) {
        flags.push('create-task');
      }

      const resp = await fiFmgHttp.query({
        method: 'exec',
        params: [
          {
            url: 'dmsase/add/service',
            data: {
              adom: adomName,
              flags,
            },
          },
        ],
      });
      if (isFunction(callback)) {
        await callback(resp);
      }

      // reload sase device list/status
      await dispatch(fetchSaseDevices());

      return getDataFromResponse(resp);
    } catch (err) {
      // return the full error object, otherwise default error object { message: '' } will be returned
      return rejectWithValue(err);
    }
  }
);
/**
 * Get if sase device exists in FMG
 * Note: only allow one FortiSASE in FMG across all ADOMs for now
 */
export const getHasSaseDevice = createAsyncThunk(
  'devices/getHasSaseDevice',
  async () => {
    const resp = await flatui_proxy({
      method: 'hasSaseDevice',
      url: '/gui/alladom/sase',
    });
    const data = getDataFromResponse(resp);
    return get(data, 'sase', 0) > 0;
  }
);

export const getHasSaseDeviceInOtherAdom = createAsyncThunk(
  'devices/getHasSaseDeviceInOtherAdom',
  async (_, { dispatch, getState }) => {
    const hasSaseDevice = await dispatch(getHasSaseDevice()).unwrap();
    const saseDeviceInCurrentAdom = getSaseDevicesList(getState());
    return (
      hasSaseDevice &&
      !(saseDeviceInCurrentAdom && saseDeviceInCurrentAdom.length)
    );
  }
);

export const getSaseController = createAsyncThunk(
  'devices/getSASEController',
  async () => {
    const resp = await flatui_proxy({
      method: 'getConnectorStatus',
      url: '/gui/sase/service',
    });
    const data = getDataFromResponse(resp);
    return get(data, 'data', {}) || {};
  }
);

export const getSaseCmStatus = createAsyncThunk(
  'devices/getSaseCmStatus',
  async (_, { dispatch }) => {
    const controllerInfo = await dispatch(getSaseController()).unwrap();
    const isCentrallyManaged = get(controllerInfo, 'centrallyManaged', false);
    return isCentrallyManaged;
  }
);

/** ----------------------------------------------------------------------------
 * FortiSASE connector / notification:
 * Need to satisfy the following to show notification
 * - No FortiSASE device added to any ADOM (only support one FortiSASE per FMG right now)
 * - Has FCEM contract for the current FMG
 * - centrallyManaged is true
 * -------------------------------------------------------------------------- */
export const getShouldShowSaseNotification = createAsyncThunk(
  'devices/getShouldShowSaseNotification',
  async (_, { getState, dispatch }) => {
    if (!isFmg()) return false;

    const state = getState();

    const hasSaseDevice = await dispatch(getHasSaseDevice()).unwrap();
    if (hasSaseDevice) return;

    // get sase contract by current FMG SN
    if (!getHasSaseLicense(state)) return false;

    // fetch sase info
    const isCentrallyManaged = await dispatch(getSaseCmStatus()).unwrap();

    return isCentrallyManaged;
  }
);

// fetch on adom change
listenerMiddleware.startListening({
  actionCreator: fetchSessionAdom.fulfilled,
  effect: async (action, { dispatch, cancelActiveListeners }) => {
    cancelActiveListeners();

    if (!isFmg()) return;

    dispatch(fetchSaseDevices());
  },
});

// Start fetching sase device status after sase devices are loaded,
// because it depends on the device names to fetch status
listenerMiddleware.startListening({
  actionCreator: fetchSaseDevices.fulfilled,
  effect: async (action, { getState, dispatch, cancelActiveListeners }) => {
    cancelActiveListeners();

    // TODO: Support multiple sase devices when needed
    const saseDevices = getSaseDevicesList(getState());
    dispatch(
      fetchSaseDeviceStatus({ deviceName: get(head(saseDevices), 'name') })
    );

    // update notification after sase devices are loaded
    dispatch(checkIfShowSaseNotification());
  },
});
