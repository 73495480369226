import { redirect } from 'react-router-dom';

import { fiDvmListViewType, selectedManagedView } from 'fi-apps/fi-dvm';

import { getViewMap } from './constant';
import { dvmConfigRoutes } from './routes_dvm_config';

export const dvmMainRoute = {
  path: 'main',
  lazy: () =>
    import(/* webpackChunkName: "mod-dvm-main" */ 'react_apps/ra_dvm/DvmMain'),
  children: [
    // FMG DVM Routes
    {
      // use this route to redirect to groups/:groupId or other views and make sure appUniKey is set correctly
      path: 'groups',
      handle: {
        appUniKey: 'dvm_device_list',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-device-list" */ 'react_apps/ra_dvm/dvm_table/FmgDvmTableMain'
        ),
      loader: dvmTableViewLoader,
    },
    {
      path: 'groups/:groupId',
      handle: {
        appUniKey: 'dvm_device_list',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-device-list" */ 'react_apps/ra_dvm/dvm_table/FmgDvmTableMain'
        ),
    },

    // DVM Config
    ...dvmConfigRoutes,

    // Other DVM Views
    {
      path: 'ring/:dashId',
      handle: {
        appUniKey: 'dvm_device_list',
      },
      loader: async () => {
        selectedManagedView(fiDvmListViewType.MANAGED_RING_VIEW);
        const { Layout } = await import('react_apps/ra_noc/dashboard/Layout');
        return { Layout };
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-ring-view" */ './other_views/DvmRingView'
        ),
    },
    {
      path: 'folder',
      handle: {
        appUniKey: 'dvm_device_list',
      },
      loader: () => {
        selectedManagedView(fiDvmListViewType.MANAGED_FOLDER_VIEW);
        return null;
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-folder-view" */ './other_views/DvmFolderView'
        ),
    },
    {
      path: 'map',
      handle: {
        appUniKey: 'dvm_device_list',
      },
      loader: () => {
        selectedManagedView(fiDvmListViewType.MANAGED_MAP_VIEW);
        return null;
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-map-view" */ './other_views/DvmMapView'
        ),
    },

    // FAZ DVM Routes
    {
      // use this route to redirect to fazgroups/:groupId or other views and make sure appUniKey is set correctly
      path: 'fazgroups',
      handle: {
        appUniKey: 'fazdvm',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-device-list" */ 'react_apps/ra_dvm/dvm_table/FazDvmTableMain'
        ),
      loader: dvmTableViewLoader,
    },
    {
      path: 'fazgroups/:groupId',
      handle: {
        appUniKey: 'fazdvm',
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-device-list" */ 'react_apps/ra_dvm/dvm_table/FazDvmTableMain'
        ),
    },
    {
      path: 'fazmap',
      handle: {
        appUniKey: 'fazdvm',
      },
      loader: () => {
        selectedManagedView(fiDvmListViewType.MANAGED_MAP_VIEW);
        return null;
      },
      lazy: () =>
        import(
          /* webpackChunkName: "mod-dvm-map-view" */ './other_views/FazDvmMapView'
        ),
    },
  ],
};

// redirect to other same level route (/ring, /map, or /folder) based on the cached selected view
function dvmTableViewLoader() {
  const viewMap = getViewMap();
  const viewType = selectedManagedView();
  const viewConfig = viewMap[viewType];
  const subPath = viewConfig?.subPath;

  if (subPath) {
    return redirect(`../${subPath}`);
  }

  return null;
}
